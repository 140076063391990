<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn
          class="white--text text-capitalize mt-1 mb-5"
          color="secondary"
          v-on="on"
          @click="dialogOpen"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon>Add
        </v-btn>
      </template>

      <v-spacer></v-spacer>
      <v-card class="pa-6" color="pop_bg">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2"
            >Add Universal Pause</v-card-title
          > 
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2 mb-6"></v-divider>
        <v-container>
          <v-form @submit="createUniversalPause()" v-model="valid" ref="form">
            <v-layout justify-center>
              <v-date-picker
                :min="minDate"
                v-model="pausedDate"
                :allowed-dates="allowedDates"
              />
              <v-text-field
                v-model="pausedDate"
                v-show="false"
                :rules="[requiredValidator('payable amount')]"
                solo
                dense
              ></v-text-field>
            </v-layout>
            <v-layout row class="mt-4" justify-end>
              <v-btn
                width="120"
                color="secondary"
                class="text-capitalize white--text"
                @click="createUniversalPause()"
                :loading="btnloading"
                :disabled="!valid"
              >
                <v-icon left>mdi-plus</v-icon> Pause</v-btn
              >
            </v-layout>
          </v-form>
        </v-container>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackColor"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
import moment from "moment";
// import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  components: {},
  props: {
    universalPauses: {
      required: true
    }
  },
  created() {
  },
  data() {
    return {
      avalilablePaymentMethod: [],
      pausedDate: "",
      disabledDates: [],
      days: [],
      awaitingSearch: false,
      btnDisabled: false,
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackColor: "",
      snackbar: false,
      text: "",
      variables: {
        limit: 0,
        skip: 0
      },
      minDate: moment(new Date()).format("YYYY-MM-DD"),
      allDates: []
    };
  },
  methods: {
    dialogOpen() {
      this.allDates = this.universalPauses.map(x =>
        moment(new Date(parseInt(x.date))).format("YYYY-MM-DD")
      );
    },
    allowedDates(val) {
      var date = this.allDates;
      if (date.indexOf(val) == -1) {
        return true;
      } else {
        return false;
      }
    },
    close() {
      this.show_dialog_box = false;
    },
    createUniversalPause() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/createUniversalPause.graphql"),
          variables: {
            date: this.pausedDate
          }
        })
        .then(() => {
          this.snackbar = true;
          this.snackColor = "success";
          this.text = "Pause date added";
          this.$emit("updateUniversalPause");
          this.$refs.form.reset();
          this.btnloading = false;
          this.close();
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
            // if (error.graphQLErrors[0].message === "ALREADY_CREATED") {
            this.text = "some error";
            this.snackColor = "error";
            this.snackbar = true;
            // }
          }
          this.btnloading = false;
        });
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    }
  }
};
</script>
