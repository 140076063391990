<template>
  <div>
    <v-container class="pa-4 pa-sm-8" fluid>
      <v-row class="mt-3 mt-sm-0 mb-4 ">
        <h2 class="black--text text-h5 font-weight-bold ms-3">
          Monthly Purchase Report
        </h2>
      </v-row>
      <!-- <v-row class="mt-4 mt-sm-8"> -->
        <v-layout wrap > 
        <v-flex xs8 sm4 md3 class="mr-5">
          <!-- <span class="black--text me-3"></span> -->
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formattedMonth"
                readonly
                label="Month"
                solo
                append-icon="fa-calendar-alt black--text"
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              color="secondary"
              v-model="month"
              type="month"
              :max="minDate"
              @change="menu = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs8 sm4 md3 >
          <v-text-field
            placeholder="Name.."
            v-model="search"
            solo
            dense
            @keydown.enter="runSearch()"
          >
            <template v-slot:append>
                <v-icon
                  color="black"
                  v-if="search.length > 0"
                  @click="clearSearch()"
                  >mdi-close</v-icon
                >
                <v-icon color="black" v-if="search.length == 0" @click="runSearch()"
                  >mdi-magnify</v-icon>
            </template>
        </v-text-field>
        </v-flex>
        </v-layout>
      <!-- </v-row> -->

      <monthly-purchase-table :month="month" :search="search"></monthly-purchase-table>
    </v-container>
  </div>
</template>

<script>
import MonthlyPurchaseTable from "../components/MonthlyPurchaseTable.vue";
import moment from "moment";

export default {
  components: {
    "monthly-purchase-table": MonthlyPurchaseTable

  },
  computed: {
    formattedMonth() {
      return this.month ? moment()
        .set({ year: this.month.split('-')[0], month: this.month.split('-')[1] - 1 , day: 2 })
        .format("MMMM - YYYY") : ""
    }

  },
  data() {
    return{
      search: "",
      minDate: moment(new Date()).format("YYYY-MM"),
      searchKey: "",
      menu: false,
      month: new Date().toISOString().substr(0, 7),

    }
  },
    methods: {
    clearSearch() {
      this.search = "";
      this.searchKey = "";
    },
    runSearch() {
      this.searchKey = this.search;
    }
  },

}


</script>
