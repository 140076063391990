<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" height="40px" class="btncolor white--text text-capitalize btn_fnt">
          <v-icon small class="me-1" size="17">mdi-export</v-icon>Export
        </v-btn>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-layout justify-end>
          <v-btn color="btncolor" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>

        <v-card-text class="subtitle-1 mb-5 text-center">Export Customer Data
          <v-spacer></v-spacer>
        </v-card-text>
        <v-form @submit="exportExcel()" v-model="valid" ref="form">
          <v-layout wrap>
            <v-flex xs12 sm4 md1 class="mt-2">
              <h4 class="text-body-2 black--text">From</h4>
            </v-flex>
            <v-flex xs12 sm8 md4>
              <v-menu v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field solo dense :value="formatStartDate" append-icon="fa-calendar-alt black--text" readonly
                    :rules="[requiredValidator('start date')]" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="btncolor" v-model="startDate" @input="menuStartDate = false"
                  :min="startDateMinDates" :max="startDateMaxDates"></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm4 md1 class="ms-md-16 mt-2">
              <h4 class="text-body-2 black--text">To</h4>
            </v-flex>
            <v-flex xs12 sm8 md4>
              <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field solo dense :value="formatEndDate" append-icon="fa-calendar-alt black--text" readonly
                    :rules="[requiredValidator('end date')]" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="btncolor" v-model="endDate" @input="menuEndDate = false" :max="endDateMaxDates"
                  :min="endDateMinDates"></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout justify-center>
            <v-btn @click="exportExcel" :loading="btnloading" :disabled="!valid" class="primary white--text">Export
            </v-btn>
          </v-layout>
          <v-layout justify-center v-if="snackbar">
            <span class="red--text mt-3">{{ text }}</span>
          </v-layout>
        </v-form>
        <div v-show="false">
          <v-data-table id="exportCustomerList" hide-default-footer :items-per-page="-1" :headers="headers"
            :items="customerList" class="elevation-1 mt-6">
            <template v-slot:[`item.customerId`]="{ item }">
              {{ padLeft(item.customerId, 4) }}
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{
              item.user.firstnameEn[0].toUpperCase() +
              item.user.firstnameEn.substring(1)
              }}
              <!-- <div class="mx-1" /> -->
              {{
              item.user.lastnameEn[0].toUpperCase() +
              item.user.lastnameEn.substring(1)
              }}
              <!-- </v-row> -->
            </template>
            <template v-slot:[`item.tag`]="{ item }">
              <span v-for="tag in item.tags" :key="tag._id">
                {{ tag.name }}<br />
              </span>
            </template>

            <template v-slot:[`item.area`]="{ item }">
              {{ defaultArea(item.addressDetails) }}
            </template>

            <template v-slot:[`item.block`]="{ item }">
              {{ defaultBlock(item.addressDetails) }}
            </template>

            <template v-slot:[`item.building`]="{ item }">
              {{ defaultBuilding(item.addressDetails) }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ createdDate(item.createdAt) }}
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar v-show="false" v-model="snackbar" timeout="3000" right top :color="snackbarColor">{{ text }}
    </v-snackbar>
  </div>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
import { DATE_FORMAT, Utils } from "../../utils/utils";
import moment from "moment";
export default {
  props: {

    fieldToSearch: {
      required: true
    },
    filter: {
      required: true
    },
    areas: {
      required: true
    },
    blocks: {
      required: true
    },
  },
  data() {
    return {
      snackbar: false,
      text: "",
      snackbarColor: "",
      valid: true,
      dialog: false,
      btnloading: false,
      show_alert: false,
      error: null,
      requiredValidator,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,
      customerList: [],
      // startDateAllowedDates:''
      headers: [
        {
          text: "#",
          value: "customerId",
          width: 100
        },
        { text: "Customer Name", value: "name", width: 150 },
        { text: "Tag", value: "tag", width: 100, sortable: false },
        {
          text: "Contact Number",
          value: "user.phone",
          width: 170,
          sortable: false
        },

        { text: "Area", value: "area", width: 150, sortable: false },
        {
          text: "Block, Street, Jedha",
          value: "block",
          width: 220,
          sortable: false
        },
        { text: "Building", value: "building", width: 200, sortable: false },
        { text: "Created At", value: "createdAt", width: 200, sortable: false }
      ]
    };
  },
  methods: {
    customersList() {
      let variables = {
        fromDate: this.startDate,
        toDate: this.endDate,
        tag: this.filter.tag,
        area: this.filter.area,
        block: this.filter.block,
        fieldToSearch: this.fieldToSearch,
        limit: 0,
        skip: 0
      };
      this.$apollo
        .query({
          query: require("@/customer/api/customers.graphql"),
          variables
        })
        .then(async data => {
          if (data.data) {
            this.customerList = await data.data.customers
              .customers;


            this.customerList.forEach(element => {
              if (element.addressDetails) {
                element.addressDetails.forEach(address => {
                  if (address.area) {
                    let area = this.areas.find(x => x._id == address.area._id);
                    address.area = area;
                  }
                  if (address.block) {
                    let block = this.blocks.find(x => x._id == address.block._id);
                    address.block = block;
                  }
                });
              }
            });

            console.log(this.customerList);
            if (this.customerList.length) {
              setTimeout(() => {
                Utils.htmlToExcel(
                  "exportCustomerList",
                  "Customers",
                  false
                );
              }, 100);
              this.dialog = false;
            } else {
              this.snackbarColor = "red";
              this.text = "No Data is available on selected dates";
              this.snackbar = true;
            }
            this.btnloading = false;
            return true;
          }
          this.snackbarColor = "red";
          this.text = "some error";
          this.snackbar = true;
          return false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
      this.$refs.form.reset();
      this.btnloading = false;
    },
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    async exportExcel() {
      this.btnloading = true;
      await this.customersList();

    },
    defaultArea(addresses) {
      if (addresses.length > 0) {
        let defaultAddress = addresses.find(x => x.is_default == true);
        if (defaultAddress) {
          return defaultAddress.area.nameEn;
        } else {
          return addresses[0].area.nameEn;
        }
      }
    },
    defaultBuilding(addresses) {
      let address = "";
      if (addresses.length > 0) {
        let defaultAddress = addresses.find(x => x.is_default == true);
        if (defaultAddress) {
          address = `${defaultAddress.house_number}, ${defaultAddress.building_number}, ${defaultAddress.apartment_number}`;
          return address;
        } else {
          address = `${addresses[0].house_number}, ${addresses[0].building_number}, ${addresses[0].apartment_number}`;
          return address;
        }
      }
    },
    defaultBlock(addresses) {
      let address = "";
      if (addresses.length > 0) {
        let defaultAddress = addresses.find(x => x.is_default == true);
        if (defaultAddress) {
          address = `${defaultAddress.block.block}, ${defaultAddress.street}, ${defaultAddress.jedha}`;
          return address;
        } else {
          address = `${addresses[0].block.block}, ${addresses[0].street}, ${addresses[0].jedha}`;
          return address;
        }
      }
    },
    createdDate(date) {
      return date ? moment(parseInt(date)).format(DATE_FORMAT) : "";
    }
  },
  computed: {
    formatStartDate() {
      return this.startDate ? moment(this.startDate).format(DATE_FORMAT) : "";
    },
    formatEndDate() {
      return this.endDate ? moment(this.endDate).format(DATE_FORMAT) : "";
    },
    startDateMinDates() {
      return this.endDate
        ? moment(this.endDate)
          .subtract(1, "month")
          .format("YYYY-MM-DD")
        : "";
    },
    startDateMaxDates() {
      let today = moment().format("YYYY-MM-DD");
      return this.endDate ? moment(this.endDate).format("YYYY-MM-DD") : today;

    },
    endDateMaxDates() {
      let today = moment();
      if (this.startDate) {
        let day = moment(this.startDate).add(1, "month");
        if (day > today) {
          return today.format("YYYY-MM-DD");
        } else {
          return day.format("YYYY-MM-DD");
        }
      } else {
        return today.format("YYYY-MM-DD");
      }
    },
    endDateMinDates() {
      return this.startDate ? moment(this.startDate).format("YYYY-MM-DD") : "";
    }
  }
};
</script>
