<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="notificationList"
      class="elevation-1"
      :items-per-page="25"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.createdDate="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.createTime="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("hh:mm:A") }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  apollo: {
    getAllNotifications() {
      return {
        query: require("../api/getAllNotifications.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.notificationList = data.getAllNotifications.notifications;
          this.totalItemsCount = data.getAllNotifications.totalCount;
        },
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.variables.limit = this.options.itemsPerPage;
        this.variables.skip =
          this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage;
        this.$apollo.queries.getAllNotifications.refetch({
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500,
          skip: this.variables.skip,
        });
      },
      deep: true,
    },
  },
  data: () => ({
    dialog: false,
    notificationList: [],
    totalItemsCount: 25,
    options: {
      itemsPerPage: 25,
      page: 1,
    },
    variables: {
      limit: 25,
      skip: 0,
    },

    loading: true,
    headers: [
      {
        text: "Title",
        value: "title",
        width: 400,
        sortable: false,
      },
      { text: "Date", value: "createdDate", width: 120, sortable: false },
      { text: "Time", value: "createTime", width: 120, sortable: false },
    ],
  }),

  methods: {},
};
</script>

