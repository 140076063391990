<template>
  <v-flex col-12 xs12 md6 lg4 class="px-md-7 px-lg-6">
    <v-card
      height="440"
      tile
      class="pt-8 mx-auto"
      :max-width="
        $vuetify.breakpoint.xl
          ? 400
          : $vuetify.breakpoint.lg
          ? 350
          : $vuetify.breakpoint.md
          ? 390
          : $vuetify.breakpoint.sm
          ? 420
          : 340
      "
    >
      <label class="text-h6 ms-4"> Birthday </label>

      <div v-if="birthDayDatas.length">
        <v-container
          v-scroll.self="onScrollTop"
          id="scroll-target"
          style="height: 360px"
          class="overflow-y-auto"
        >
          <v-row
            v-for="(item, index) in birthDayDatas"
            :key="index"
            style="height: 60px"
          >
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar>
                  <v-img
                    :src="require('../../assets/user.jpg')"
                    contain
                    max-width="50"
                    max-height="50"
                    position="left"
                  />
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content style="width: 60px" class="overflow-x-auto">
                <v-list-item-title
                  ><span class="font-weight-medium">
                    {{ capitalizeFirstLetter(`${item.user.firstnameEn}`) }}
                    {{ capitalizeFirstLetter(item.user.lastnameEn) }}
                  </span></v-list-item-title
                >
              </v-list-item-content>

              <v-list-item-action>
                <router-link
                  style="text-decoration: none"
                  :to="{
                    name: 'CustomerDetails',
                    params: { customerId: item._id },
                    query: { customerId: item._id }
                  }"
                  class="mx-auto "
                >
                  <v-btn depressed x-small color="transparent ">
                    <v-icon color="orange darken-4" right>
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </router-link>
              </v-list-item-action>
            </v-list-item>
            <!-- <p id="listTile">{{index}}</p>   -->
            <!-- </v-card> -->
          </v-row>
        </v-container>
        <!-- <v-virtual-scroll
          height="300"
          item-height="64"
          :bench="benched"
          :items="customers"
          class="mt-4"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item._i">
              <v-list-item-action>
                <v-avatar :width="80" :height="80">
                 
                  <v-img
                    :src="require('../../assets/user.jpg')"
                    contain
                    max-width="50"
                    max-height="50"
                    position="left"
                  />
                </v-avatar>
              </v-list-item-action>

              <v-row>
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link
                      :to="{
                        name: 'CustomerDetails',
                        params: { customerId: item._id },
                        query: { customerId: item._id },
                      }"
                      class="mx-auto"
                    >
                      <span class="font-weight-medium">
                        {{ capitalizeFirstLetter(item.user.firstnameEn) }}
                        {{ capitalizeFirstLetter(item.user.lastnameEn) }}
                      </span>
                    </router-link>
                  </v-list-item-title>
                  <v-list-item-title>
                    <span class="font-weight-light"
                      >{{
                        new Date(parseInt(item.user.dob))
                          | moment("DD-MMM-YYYY")
                      }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-row>
            </v-list-item>
          </template>
        </v-virtual-scroll> -->
      </div>

      <div v-else>
        <v-list-item-title class="text-center mb-10 mt-16"
          ><v-layout justify-center class="pt-16"
            >******No Birthdays******</v-layout
          ></v-list-item-title
        >
      </div>
    </v-card>
  </v-flex>
</template>
<script>
export default {
  // apollo: {
  //   getTodayCustomerBirthdays() {
  //     return {
  //       query: require("../api/getTodayCustomerBirthdays.graphql"),
  //       result({ data, loading }) {
  //         this.loading = loading;
  //         this.customers = data.getTodayCustomerBirthdays;
  //       }
  //     };
  //   }
  // },
  watch: {
    offsetTop: {
      handler(e, previousValue) {
        if (e > previousValue) {
          let itemHeight = 60;
          let initialLength = 264;
          let firstChange = initialLength + (this.limit - 10) * itemHeight;
          if (!this.dataIndex) {
            if (e == firstChange) {
              this.firstTotalHeight = firstChange;
              this.dataIndex++;
              this.variables.input.skip = this.limit * this.dataIndex;
              this.variables.input.limit = (this.dataIndex + 1) * this.limit;
              this.getBirthdayData();
            }
          } else {
            let noOfItem = this.limit;
            let height = itemHeight * noOfItem;
            if (e == this.firstTotalHeight + height) {
              this.firstTotalHeight += height;
              this.dataIndex++;
              this.variables.input.skip = this.limit * this.dataIndex;
              this.variables.input.limit = (this.dataIndex + 1) * this.limit;
              this.getBirthdayData();
            }
          }
        }
      }
    }
  },
  methods: {
    onScrollTop(e) {
      this.offsetTop = e.target.scrollTop;
    },
    getBirthdayData() {
      this.$apollo
        .watchQuery({
          query: require("../api/getTodayCustomerBirthdays.graphql"),
          variables: this.variables
        })
        .refetch({
          // variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.customers = data.data.getTodayCustomerBirthdays;
            if (this.customers.length)
              this.birthDayDatas.push(...this.customers);
          }
        });
    }
  },
  mounted() {
    this.variables.input.limit = this.limit;
    this.variables.input.skip = this.skip;
    this.getBirthdayData();
  },
  data() {
    return {
      offsetTop: 0,
      dataIndex: 0,
      firstTotalHeight: 0,
      loading: true,
      birthDayDatas: [],
      customers: [],
      headingStyle: {
        fontFamily: "Franklin Gothic",
        fontSize: "20px"
      },
      limit: 10,
      skip: 0,
      variables: {
        input: {
          limit: "",
          skip: ""
        }
      }
    };
  }
};
</script>
