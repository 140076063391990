<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,

  props: {
    date: {
      required: true,
    },
  },
  created() {
    this.getPackageCount();
  },
  watch: {
    date: {
      handler() {
        this.getPackageCount();
      },
      deep: true,
    },
  },
  methods: {
    getPackageCount() {
      this.$apollo
        .watchQuery({
          query: require("../api/getPackageCount.graphql"),
          variables: {
            date: this.date,
          },
        })
        .refetch({
          variables: {
            date: this.date,
          },
        })
        .then((data) => {
          let packageList = data.data.getPackageCount;
          let graphLabels = new Array();
          let graphCount = new Array();
          packageList.forEach((element) => {
            graphLabels.push(element.package);
            graphCount.push(element.count);
          });

          this.renderChart(
            {
              labels: graphLabels,

              datasets: [
                {
                  label: "PACKAGES",

                  backgroundColor: [
                    "#337AB7",
                    "#90EE90",
                    "#BC8F8F",
                    "#00BFFF",
                    "#FFA07A",
                    "#85C1E9",
                    "#FAD7A0",
                    "#D7DBDD",
                    "#D98880",
                    "#C39BD3",
                    "#2980B9",
                    "#27AE60",
                    "#F4D03F",
                    "#BA4A00",
                    "#CB4335",
                    "#1B4F72",
                    "#E8F6F3"
                  ],
                  data: graphCount,
                },
              ],
            },
            {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                //hides the legend
                display: false,
              },
            }
          );
        });
    },
  },
};
</script>