<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading" 
        class="mt-n3   white--text text-capitalize btn_fnt"
        color="btncolor"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="exportdietPlans"
        hide-default-footer
        :items-per-page="-1"
        :headers="head"
        :items="dietPlansExportList"
        class="elevation-1 "
      >
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    }
  },
  data() {
    return {
      dietPlansExportList: [],
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      head: []
    };
  },

  created() {
    this.head = this.headers.filter(
      x => x.value != "actions" && x.value != "slNo"
    );
    // this.getAllDietPlans();
  },

  methods: {
    getAllDietPlans() {
      this.$apollo
        .query({
          query: require("../api/getAllDietPlans.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.dietPlansExportList = data.data.getAllDietPlans;

            setTimeout(() => {
              Utils.htmlToExcel("exportdietPlans", "Diet Plans", false);
              this.loading = false;
            }, 100);
          }
        });
    },
    exportExcel() {
      this.loading = true;
      this.getAllDietPlans();
    }
  }
};
</script>
