<template>
  <div>
    <!-- {{ allergyItems == null }} -->
    <CustomerAddAllergy @updateAllergies="updateAllergies" class="bg" />
    <v-data-table
      v-if="allergyItems"
      :mobile-breakpoint="0"
      :headers="headers"
      :items="allergyItems"
      :items-per-page="20"
    >
      <template v-slot:item.actions="{ item }">
        <v-row>
          <CustomerAllergyDelete
            :allergy="item"
            @updateAllergies="updateAllergies"
          />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CustomerAllergyDelete from "./CustomerAllergyDelete.vue";
import CustomerAddAllergy from "./CustomerAddAllergy.vue";
export default {
  components: { CustomerAllergyDelete, CustomerAddAllergy },
  props: {
    allergyList: {
      require: [],
    },
  },
  methods: {
    updateAllergies(allergies) {
      this.allergyItems = allergies;
    },
  },
  created() {
    this.allergyItems = this.allergyList;
  },
  watch: {
    allergyList: {
      handler(newVal) {
        this.allergyItems = newVal;
      },
      deep: true,
    },
  },
  data() {
    return {
      allergyItems: [],
      headers: [
        { text: "Item ID", value: "itemId" },
        { text: "Name", value: "nameEn" },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
};
</script>
