
<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="red">mdi-delete</v-icon>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text"
          >Do you really want to delete this Ingredient?</v-card-text
        >

        <v-card-text v-if="error" class="error--text font-weight-bold">{{
          error
        }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn class="mx-4 white--text" color="red" depressed @click="close"
              >No</v-btn
            >
            <v-btn
              class="mx-4 white--text"
              color="green"
              depressed
              :loading="button_loading"
              @click="confirmDelete()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>
<script>
export default {
  props: {
    dislike: {
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      snackbar: false,
      text: "",
    };
  },
  methods: {
    confirmDelete() {
      this.button_loading = true;
      this.$apollo
        .mutate({
          mutation: require("../../api/deleteCustomerDislike.graphql"),
          variables: {
            ingredientId: this.dislike._id,
            customerId: this.$route.query.customerId,
          },
        })
        .then((data) => {
          this.$emit( "updateDislikes", data.data.removeCustomerDislike.dislikes);
          this.$root.$emit('updateGetCustomerById')
          this.button_loading = false;
          this.error = null;
          this.dialog = false;
          this.snackbar = true;
          this.text = "Dislike removed";
        })
        .catch((error) => {
          // Error
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.button_loading = false;
          this.dialog = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    },
  },
};
</script>