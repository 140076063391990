import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../user/pages/UserSignIn.vue'
import i18n from '../i18n.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: SignIn,
    meta: { display: false, redirectAuthUser: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../user/pages/Dashboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/menuitems',
    name: 'MenuItems',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../menu-packages/pages/MenuItems.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/meal-details',
    name: 'MealDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../menu-packages/pages/MealDetails.vue'),
    meta: { requiresAuth: true }
  },


  {
    path: '/inactive-customers',
    name: 'InActiveCustomers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/InActiveCustomers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/menucategory',
    name: 'MenuCategory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../menu-packages/pages/MenuCategory.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/plans',
    name: 'Packages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../menu-packages/pages/Packages.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/managers',
    name: 'ManagersMain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../managers/pages/ManagersMain.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    name: 'NotificationMain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../notifications/pages/NotificationMain.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/CustomerDietitianAppt',
    name: 'CustomerDietitianAppointment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/components/dietitian/CustomerDietitianAppointment.vue'),
    meta: { requiresAuth: true }
  },

  // {
  //   path: '/settings',
  //   name: 'SettingsMain',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */'../settings/pages/SettingsMain.vue'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/faq',
    name: 'Faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../faq/pages/FaqMain.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contact-method',
    name: 'ContactMethod',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../contact-method/pages/ContactMethod.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customertags',
    name: 'CustomerTag',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/CustomerTag.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customers',
    name: 'CustomerMain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/CustomerMain.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customer_details', name: 'CustomerDetails',
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/CustomerDetails.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/customers-with-no-meals',
    name: 'CustomersWithNoMeals',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/CustomersWithNoMeals.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customers-birthday-report',
    name: 'BirthdayReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/BirthdayReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dietician_details', name: 'DietitianDetails',
    component: () => import(/* webpackChunkName: "about" */'../dietitian/pages/DietitianDetails.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/stock',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../stock/pages/StockMain.vue'),
    children: [
      {
        path: 'stock_details', name: 'StockDetails',
        component: () => import(/* webpackChunkName: "about" */'../stock/pages/StockDetails.vue'),
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: 'purchase', name: 'StockPurchase',
        component: () => import(/* webpackChunkName: "about" */'../stock/pages/StockPurchase.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'adjust_stock', name: 'StockAdjust',
        component: () => import(/* webpackChunkName: "about" */'../stock/pages/StockAdjust.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'category', name: 'StockCategory',
        component: () => import(/* webpackChunkName: "about" */'../stock/pages/StockCategory.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'master_data', name: 'StockMaster',
        component: () => import(/* webpackChunkName: "about" */'../stock/pages/StockMaster.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'monthlyUsage', name: 'MonthlyUsage',
        component: () => import(/* webpackChunkName: "about" */'../stock/pages/MonthlyUsage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'monthlyPurchase', name: 'MonthlyPurchase',
        component: () => import(/* webpackChunkName: "about" */'../stock/pages/MonthlyPurchase.vue'),
        meta: { requiresAuth: true }
      },

    ],
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/stock/master_data',
  //   name: 'StockMaster',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */'../stock/pages/StockMaster.vue'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/orders',
    name: 'OrdersMain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../orders/pages/OrdersMain.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/rating',
    name: 'Ratings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../orders/pages/MealRatings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/delivery',
    name: 'DeliveryMain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../delivery/pages/DeliveryMain.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/areas',
    name: 'CustomerArea',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/CustomerArea.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/salesorder',
    name: 'SalesOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../orders/pages/SalesOrder.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/inactivesalesorder',
    name: 'InactiveSalesOrder',
    // route level code-splittingsrc\orders\components\inactiveSalesOrderTable.vue
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../orders/pages/SalesOrder.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/inactivesalesordercount',
    name: 'InactiveSalesOrderCount',
    // route level code-splittingsrc\orders\components\inactiveSalesOrderTable.vue
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../orders/pages/SalesOrder.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/salesorderdaywise',
    name: 'SalesOrderDayWise',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../orders/pages/SalesOrder.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/sales-order-discount',
    name: 'SalesOrderDiscount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../orders/pages/SalesOrder.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/drivers',
    name: 'Drivers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../reports/pages/drivers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/referrals',
    name: 'Referrals',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../referral-transactions/pages/ReferralsPage'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dieticians',
    name: 'Dietitians',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../dietitian/pages/dietitianView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/celebrity',
    name: 'Celebrity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is s-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../celebrity/pages/CelebrityView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/celebrity_info', name: 'CelebrityInfo',
    component: () => import(/* webpackChunkName: "about" */'../celebrity/pages/CelebrityInfo.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/appointments',
    name: 'Appointments',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../appointments/pages/AppointmentsView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/sales',
    name: 'Sales',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../sales/pages/SalesMain.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/promocode-report',
    name: 'PromoCodeReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../reports/pages/PromoCodeReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/mealtags',
    name: 'MealsTag',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../menu-packages/pages/MealsTag.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report-status',
    name: 'ReportStatus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../reports/pages/ReportStatus.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/pausedmeals-report',
    name: 'PausedMealsReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../reports/pages/PausedMealsReport.vue'),
    meta: { requiresAuth: true }
  },


  {
    path: '/referral-report',
    name: 'ReferralReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../reports/pages/ReferralReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/otp-details',
    name: 'UserOTPDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/UserOTPDetails.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/promocodes',
    name: 'PromoCode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../promotion/pages/PromoCode.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/driver-report',
    name: 'DriverReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../reports/pages/DriverReport.vue'),

  },
  {
    name: 'PromoCodeDetails',
    path: '/promocode_details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../promotion/pages/PromoCodeDetails.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/shifts',
    name: 'CustomerShift',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/CustomerShift.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/zones',
    name: 'CustomerZone',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../customer/pages/CustomerZone.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/offers',
    name: 'Offers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../offer/pages/OfferView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/permission',
    name: 'Permission',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../permission/pages/Permission.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/add-permission',
    name: 'AddPermission',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../permission/pages/AddPermission.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/update-permission',
    name: 'UpdatePermission',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../permission/pages/AddPermission.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/staff-info',
    name: 'StaffInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../managers/pages/ManagerInfo.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dietplan',
    name: 'DietPlan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../menu-packages/diet-plan/pages/DietPlan.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/error-404',
    name: 'Error404',
    component: () => import(/* webpackChunkName: "about" */'../core/pages/Error404.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/end-date-reminder',
    name: 'EndDateReminder',
    component: () => import(/* webpackChunkName: "about" */'../orders/pages/EndDateReminder.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/accounts',
    name: 'AccountTransactions',
    component: () => import(/* webpackChunkName: "about" */'../accounts/pages/AccountTransactions.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/universal-pause',
    name: 'UniversalPause',
    component: () => import(/* webpackChunkName: "about" */'../universal-pause/pages/UniversalPause.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/plan-switch',
    name: 'PlanSwitch',
    component: () => import(/* webpackChunkName: "about" */'../plan-switch/pages/PlanSwitch.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/profile-settings',
    name: 'ProfileSettings',
    component: () => import(/* webpackChunkName: "about" */'../settings/pages/PreferencesForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/configurations-settings',
    name: 'ConfigurationsSettings',
    component: () => import(/* webpackChunkName: "about" */'../settings/pages/Configurations.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/terms-and-conditions-settings',
    name: 'TermsAndConditionsSettings',
    component: () => import(/* webpackChunkName: "about" */'../settings/pages/TermsForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tutorials-settings',
    name: 'TutorialsSettings',
    component: () => import(/* webpackChunkName: "about" */'../settings/pages/TutorialForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dietitian-appointment-list',
    name: 'DietitianAppointmentList',
    component: () => import(/* webpackChunkName: "about" */'../dietitianSlotBooking/pages/DietitanAppointmentList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monthly-customer-sales',
    name: 'MonthlyCustomerSalesReport',
    component: () => import(/* webpackChunkName: "about" */'../reports/pages/MonthlyCustomerSalesReport.vue'),
  },
  {
    path: '/dietician_slot_appointment',
    name: 'DietitianSlotAppointment',
    component: () => import(/* webpackChunkName: "about" */'../dietitian/dietitian_settings/pages/DietitianSlotAppointments.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/dietician-appointment-report',
    name: 'DietitianAppointmentReport',
    component: () => import(/* webpackChunkName: "about" */'../dietitian/dietitian_settings/pages/DietitianAppointmentReport.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/schedule-notification',
    name: 'ScheduleNotification',
    component: () => import(/* webpackChunkName: "about" */'../schedule-notification/pages/ScheduleNotificationMain.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/delete',
    name: 'AllUsers',
    component: () => import(/* webpackChunkName: "about" */'../core/pages/AllUsers.vue'),
    meta: { requiresAuth: true }
  },
]

// path: '/settings',
// name: 'SettingsMain',
// // route level code-splitting
// // this generates a separate chunk (about.[hash].js) for this route
// // which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */'../settings/pages/SettingsMain.vue'),
// meta: { requiresAuth: true }
// },
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const auth = localStorage.getItem('apollo-done-token');
  let language = to.params.lang;
  if (!language) {
    language = 'en';
  }
  i18n.locale = language;

  // redirect unauth users to Signin  page
  if (to.meta.requiresAuth && !auth) {
    next({ name: 'SignIn', params: { lang: i18n.locale } });
  } else if (to.meta.redirectAuthUser && auth) {
    // redirect Dashboard page for autheticated users
    next({ name: 'Dashboard', params: { lang: i18n.locale } });
  } else {
    next();
  }
});

export default router
