<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn class="white--text text-capitalize mb-5 ms-1" :disabled="hasUpcomingPackage" color="secondary" v-on="on">
          <v-icon class="me-1" small>mdi-plus</v-icon>Add
        </v-btn>
      </template>

      <v-spacer></v-spacer>
      <v-card class="pa-6" color="white">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2">Add Sales Order</v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2 mb-6"></v-divider>
        <v-container>
          <v-form @submit="createSalesOrder()" v-model="valid" ref="form">
            <v-row class="mx-2">
              <v-radio-group v-model="pacakageSelectionMethod" @change="onPackageModeChange" color="btncolor">
                <v-radio value="PACKAGE" label="Choose From Packages" color="btncolor">
                </v-radio>

                <!-- <v-radio
                  value="CELEBRITY"
                  label="Choose From Celebrity"
                  color="btncolor"
                >
                </v-radio> -->
              </v-radio-group>
            </v-row>
            <v-col class="mt-n2 mx-0">
              <!-- select celebrity  -->
              <!-- <v-select
                @change="choice = ''"
                v-model="selectedCelebrity"
                :items="celebrities"
                solo
                dense
                attach
                chips
                label="Choose celebrity"
                placeholder="Choose celebrity"
                return-object
                flat
                outlined
                v-if="pacakageSelectionMethod == 'CELEBRITY'"
              >
                <template slot="selection" slot-scope="data">
                  <span v-if="data.item.customer">
                    <span v-if="data.item.customer.user">
                      {{ data.item.customer.user.firstnameEn }}
                      {{ data.item.customer.user.lastnameEn }}
                    </span>
                  </span>
                </template>
                <template slot="item" slot-scope="data">
                  <span v-if="data.item.customer">
                    <span v-if="data.item.customer.user">
                      {{ data.item.customer.user.firstnameEn }}
                      {{ data.item.customer.user.lastnameEn }}
                    </span>
                  </span>
                </template>
              </v-select> -->
              <!-- {{ selectedCelebrity }} -->

              <!-- select package  -->
              <v-row class="mt-n4">
                <v-col v-if="selectedCelebrity.packages">
                  <v-select @change="updateCelebityId(selectedCelebrity, selectPackage)" v-model="selectPackage"
                    :items="selectedCelebrity.packages" solo dense attach chips label="Choose Package"
                    placeholder="Choose Package" return-object flat outlined>
                    <template slot="selection" slot-scope="data">
                      <span v-if="data.item">
                        {{ capitalizeFirstLetter(data.item.titleEn) }}
                      </span>
                    </template>
                    <template slot="item" slot-scope="data">
                      <span v-if="data.item">
                        {{ capitalizeFirstLetter(data.item.titleEn) }}
                      </span>
                    </template>
                  </v-select>
                </v-col>

                <v-col v-if="pacakageSelectionMethod == 'PACKAGE'">
                  <v-select @change="packageChange" v-model="selectPackage" :items="packagesList" solo dense attach
                    :rules="[requiredValidator('plan')]" item-value="_id" item-text="titleEn" chips label="Plan"
                    return-object flat outlined></v-select>
                </v-col>

                <!-- slect choice  -->
                <v-col v-if="selectPackage.availableChoices" class="mx-1">
                  <v-select v-model="choice" :items="selectPackage.availableChoices"
                    :rules="[requiredValidator('choices')]" item-value="_id" dense solo item-text="choiceNameEn" attach
                    chips return-object label="Choices" flat outlined></v-select>
                </v-col>
              </v-row>
            </v-col>

            <!-- choices  -->
            <v-row class="mx-2 mt-n4">
              <label class="text-body-2 black--text">Meal Choices</label>
            </v-row>

            <v-layout v-for="(item, index) in choice.mealChoice" :key="item._id" wrap class="mb-5 mx-2">
              <v-flex :offset-sm-2="index > 0">
                <v-card class="px-4 bg" flat>
                  <v-radio-group v-model="mealChoice" @change="onChoiceChange">
                    <v-row class="text-h5 ms-8 mt-4">
                      {{ item.variantNameEn }}
                    </v-row>
                    <v-row>
                      <v-col cols="2" sm="1" class="mt-1">
                        <v-radio :value="item"></v-radio>
                      </v-col>
                      <v-col cols="11">
                        <div v-for="(item, idxDayChoices) in item.dayChoices" :key="idxDayChoices">
                          <v-divider v-if="idxDayChoices > 0" class="my-1"></v-divider>
                          <div>
                            {{ item.day }}
                          </div>
                          <span v-for="meal in item.mealCount" :key="meal._id">
                            <span v-if="meal.category && meal.count > 0" class="caption mt-n2 mb-2">
                              {{ meal.count }} {{ meal.category.nameEn }}
                            </span>
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-card>
              </v-flex>
            </v-layout>
            <!-- <v-divider class="mb-6"></v-divider> -->

            <v-layout wrap v-if="choice">
              <v-flex md2>
                <label class="text-body-2 black--text mx-2">Available Days</label>
              </v-flex>
              <v-flex lg10>
                <v-select v-model="availableDays" :items="listOfDays" item-text="day" item-value="day" attach dense
                  return-object solo chips :rules="[requiredValidatorSelect('available days')]" label="Available Days"
                  multiple flat outlined :disabled="isAllowCustomerWeekDays(selectPackage)">

                </v-select>
              </v-flex>
            </v-layout>

            <v-layout>
              <!-- <v-flex xs8 md2 class="mt-md-0">
              </v-flex> -->
              <v-flex>
                <v-tabs class="pt-2" v-model="tab" center-active>
                  <div v-for="day in availableDays" :key="day.day" :value="day.day">
                    <v-tab><label class="
                          text-caption text-sm-body-2
                          black--text
                          text-capitalize
                          mt-3
                        ">{{ day.day }}</label></v-tab>
                  </div>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="(day, index) in availableDays" :key="index">
                    <div class="pop_bg pt-4">
                      <v-row>
                        <v-col cols="12" md="3" class="text-body-2 black--text ms-sm-5 my-0 py-0"
                          v-for="(item, dayIndex) in day.allCategories" :key="item._id">
                          {{ item.nameEn }}
                          <span v-show="false">{{ dayIndex }}</span>

                          <v-text-field :label="item.nameEn + '(No.)'" solo flat outlined type="number"
                            @keypress="isNumber" v-model="item.count" min="0" dense></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
                <!-- <v-select
                  v-model="choice.availableDays"
                  :items="choice.availableDays"
                  multiple
                  disabled
                  dense
                  attach
                  solo
                  label="Choices"
                  class="black--text"
                ></v-select> -->
              </v-flex>
            </v-layout>
            <!-- && selectPackage -->
            <v-layout wrap class="mt-3">
              <v-flex xs12 sm2 v-if="!hasActivePackage">
                <label class="black--text text-body-2 ms-2">Start date</label>
              </v-flex>
              <!-- <v-flex xs8 md2 class="mt-md-0">
              </v-flex> -->
              <v-flex xs12 sm3 lg4 pe-lg-6 v-if="!hasActivePackage">
                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field solo dense label="Start Date" :value="formatDate"
                      append-icon="fa-calendar-alt black--text" readonly :rules="[requiredValidator('start date')]"
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>

                  <v-date-picker color="secondary" v-model="start_date" @input="menu1 = false" :min="minDate"
                    :landscape="landscape" :reactive="reactive"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm2 v-if="choice">
                <label class="black--text text-body-2 pt-4">Package Days</label>
              </v-flex>

              <v-flex xs12 sm4 v-if="choice">
                <v-text-field :value="choice.availableNumOfDays" solo disabled dense flat outlined>
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout v-if="choice">
              <v-flex xs12 sm2>
                <label class="black--text text-body-2 pt-4">Additional Days</label>
              </v-flex>

              <v-flex xs12 sm4 lg4 pe-sm-6>
                <v-text-field v-model="additionalDays" solo :rules="positiveNoRule" type="number" min="0" dense flat
                  outlined>
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout v-if="choice" wrap>
              <v-flex xs12 sm2>
                <label class="text-body-2 black--text pt-4">Protein</label>
              </v-flex>
              <v-flex xs12 sm4 lg4 pe-sm-6>
                <h4 class="text-body-2">
                  <v-text-field v-model="protein" outlined dense type="number" min="0" :rules="[
                    requiredValidatorSelect('protein'),
                    positive('protein')
                  ]">
                    <template v-slot:append>
                      <!-- <h4 class="mt-1 grey--text">KD</h4> -->
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>

              <v-flex xs12 sm2 md2 v-if="choice">
                <h4 class="text-body-2 black--text pt-4">Carbs</h4>
              </v-flex>

              <v-flex xs12 sm4>
                <h4 class="text-body-2">
                  <v-text-field v-model="carbs" outlined dense type="number" min="0" :rules="[
                    requiredValidatorSelect('carbs'),
                    positive('carbs')
                  ]">
                    <template v-slot:append>
                      <!-- <h4 class="mt-1 grey--text">KD</h4> -->
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>
            </v-layout>
            <v-layout v-if="choice" wrap>
              <v-flex xs12 sm2>
                <label class="text-body-2 black--text pt-4">Package Price</label>
              </v-flex>

              <v-flex xs12 sm4 lg4 pe-sm-6>
                <h4 class="text-body-2">
                  <v-text-field :value="
                    parseFloat(
                      mealChoice.price ? mealChoice.price : 0
                    ).toFixed(3)
                  " solo disabled dense flat outlined>
                    <template v-slot:append>
                      <h4 class="mt-1 grey--text">KD</h4>
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>

              <v-flex xs12 sm2 md2 v-if="choice">
                <h4 class="text-body-2 black--text pt-4">Discount</h4>
              </v-flex>

              <v-flex xs12 sm4>
                <h4 class="text-body-2">
                  <v-text-field v-model="discount" @change="onChoiceChange" :rules="positiveNo" type="number" min="0"
                    solo dense flat outlined>
                    <template v-slot:append>
                      <h4 class="mt-1 grey--text">KD</h4>
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>
            </v-layout>

            <v-layout v-if="choice">
              <v-flex xs12 sm2>
                <label class="black--text text-body-2 pt-4">Additional Price</label>
              </v-flex>

              <v-flex xs12 sm4 lg4 pe-sm-6>
                <v-text-field v-model="additionalPrice" @change="onChoiceChange" solo :rules="positiveNoRule"
                  type="number" min="0" dense flat outlined>
                  <template v-slot:append>
                    <h4 class="mt-1 grey--text">KD</h4>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout v-if="choice" wrap>
              <v-flex xs12 sm2>
                <h4 class="text-body-2 black--text pt-4">Total Price</h4>
              </v-flex>

              <v-flex xs12 sm4 pe-sm-6>
                <h4 class="text-body-2">
                  <v-text-field :value="netPrice" type="number" disabled color="red" solo dense flat outlined>
                    <template v-slot:append>
                      <h4 class="mt-1 grey--text">KD</h4>
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>

              <v-flex xs12 sm2 v-if="parseFloat(netPrice) > 0">
                <h4 class="text-body-2 black--text pt-4">Payment Type</h4>
              </v-flex>

              <v-flex xs12 sm4 v-if="parseFloat(netPrice) > 0">
                <v-select solo dense :rules="[requiredValidatorSelect('payment method')]" attach item-text="text"
                  item-value="value" v-model="paymentMethod" :items="avalilablePaymentMethod" flat outlined>
                </v-select>
              </v-flex>
            </v-layout>

            <div v-if="pacakageSelectionMethod">
              <v-layout wrap v-if="isNotAlreadyReferred()">
                <v-flex xs12 sm2>
                  <h4 class="text-body-2 black--text mt-md-2">Referred By</h4>
                </v-flex>
                <v-flex xs12 sm4 pe-sm-6>
                  <v-text-field v-model="referredBy" solo dense flat outlined>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </div>
            <!-- <v-divider class="mb-6"></v-divider> -->

            <v-layout row class="mt-10" justify-end>
              <v-btn :width="$vuetify.breakpoint.xs ? 90 : 120" color="secondary" class="white--text text-capitalize"
                @click="createSalesOrder()" :loading="btnloading" :disabled="!valid" depressed>Save</v-btn>
            </v-layout>
          </v-form>
        </v-container>
        <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{ text }}</v-snackbar>
        <!-- </v-container> -->

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btncolor" text @click="close">CLOSE</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  requiredValidator,
  requiredValidatorSelect,
  positive
} from "../../../core/methods/validations";
import moment from "moment";
import { DATE_FORMAT, Utils } from "../../../utils/utils";

export default {
  components: {},
  props: {
    customer_id: {
      required: true
    },
    customer: {
      required: true
    },
    subscribedPackages: {
      required: true
    }
  },

  watch: {
    subscribedPackages: {
      handler() {
        this.getActivePackage();
      },
      deep: true
    },
    mealChoice: {
      handler() {
        this.packageCountListing();
      },
      deep: true
    },
    choice: {
      handler() {
        this.mealChoice = "";
        this.availableDays = [];
      },
      deep: true
    },

    selectPackage: {
      handler(item) {
        this.choice = "";
        this.mealChoice = "";
        this.availableDays = [];
        if (item.startDate) {
          let packageStartDate = moment(new Date(parseInt(item.startDate)));
          if (packageStartDate.isAfter(this.minDate)) {
            this.minDate = packageStartDate.format("YYYY-MM-DD");
          }
          else {
            this.minDate = this.packageSelectionDate.format("YYYY-MM-DD");
          }
        }
        else {
          this.minDate = this.packageSelectionDate.format("YYYY-MM-DD");
        }
      },
      deep: true
    }
  },
  created() {
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_ONLINE)) {
      const data = this.paymentMethods[0];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_OFFLINE)) {
      const data = this.paymentMethods[1];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_FREE)) {
      const data = this.paymentMethods[2];
      this.avalilablePaymentMethod.push(data);
    }
    this.getActivePackage();
  },
  data() {
    return {
      //   nowDate: new Date().toISOString().slice(0,10),
      nowDate: moment()
        .add(3, "d")
        .toDate()
        .toISOString(),
      avalilablePaymentMethod: [],
      allCategories: [],
      celebrityId: "",
      paymentMethods: [
        {
          value: "ONLINE",
          text: "Online"
        },
        {
          value: "OFFLINE",
          text: "Offline"
        },
        {
          value: "FREE",
          text: "Free"
        }
      ],
      paymentMethod: "",
      availableDays: "",
      listOfDays: [],
      tab: "",
      date: new Date(),
      netPrice: 0,
      landscape: false,
      discount: "",
      reactive: false,
      additionalPrice: "",
      additionalDays: "",
      choice: "",
      referredBy: "",
      mealChoice: "",
      start_date: "",
      menu1: false,
      activePackage: [],
      btnDisabled: false,
      snack: false,
      snackColor: "",
      snackText: "",
      hasActivePackage: false,
      hasUpcomingPackage: false,
      isValidIngredient: true,
      requiredValidator: requiredValidator,
      requiredValidatorSelect,
      positive,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      minDate: moment()
        .add(3, "day")
        .format("YYYY-MM-DD"),
      // .toDate()
      // .toISOString()
      text: "",
      pacakageSelectionMethod: "PACKAGE",
      celebrity: "",
      variables: {
        limit: 0,
        skip: 0
      },
      packagesList: [],
      celebrities: [],
      selectedCelebrity: "",
      selectPackage: "",
      rdoCeleb: "",
      positiveNo: [v => v >= 0 || "Enter a valid discount"],
      positiveNoRule: [v => v >= 0 || "Value should be greater than 0"],
      protein: "",
      carbs: "",
      packageSelectionDate: moment(new Date())
    };
  },
  //   created() {
  //     this.salesOrderTemp = JSON.parse(JSON.stringify(this.salesOrder));
  //   },
  apollo: {
    getAllPackages() {
      return {
        query: require("@/menu-packages/api/getAllPackagesWithChoice.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          // debugger;
          this.packagesList = data.getAllPackages.packages.filter(
            x => x.active
          );
          // console.log(data.getAllIngredients);
        }
      };
    },
    getAllCategories() {
      return {
        query: require("@/menu-packages/api/getAllCategories.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          let allCategories = data.getAllCategories.category.filter(
            x => x.active == true
          );
          this.allCategories = JSON.parse(JSON.stringify(allCategories));
        }
      };
    },
    getMealSelectionDate() {
      return {
        query: require("./api/getMealSelectionDate.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;

          let date = data.getMealSelectionDate.packageSelectionDate;
          this.packageSelectionDate = moment(new Date(parseInt(date)));
          if (this.packageSelectionDate.isAfter(this.minDate)) {
            this.minDate = this.packageSelectionDate.format("YYYY-MM-DD");
          }
        }
      };
    }
  },
  methods: {

    isAllowCustomerWeekDays(selectPackage) {

      if (selectPackage.allowedWeekDays) {
        if (selectPackage.allowedWeekDays.length > 0) {
          return true;
        }
      }
      return false;
    },
    packageCountListing() {
      // debugger;
      this.availableDays = [];
      if (this.choice && this.mealChoice) {
        this.allCategories.forEach(element => {
          element.count = 0;
        });
        this.listOfDays = [];

        let sunday = {
          day: "Sunday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let monday = {
          day: "Monday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let tuesday = {
          day: "Tuesday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let wednesday = {
          day: "Wednesday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let thursday = {
          day: "Thursday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let friday = {
          day: "Friday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let saturday = {
          day: "Saturday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };

        this.availableChoices = new Array();
        // let packageInfo = this.selectPackage;
        this.choice.availableDays.forEach(day => {
          switch (day) {
            case "Sunday": {
              sunday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(sunday);
              break;
            }
            case "Monday": {
              monday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(monday);
              break;
            }
            case "Tuesday": {
              tuesday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(tuesday);
              break;
            }
            case "Wednesday": {
              wednesday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(wednesday);
              break;
            }
            case "Thursday": {
              thursday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(thursday);
              break;
            }
            case "Friday": {
              friday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(friday);
              break;
            }
            case "Saturday": {
              saturday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(saturday);
              break;
            }
          }
        });
        this.listOfDays.push(sunday);
        this.listOfDays.push(monday);
        this.listOfDays.push(tuesday);
        this.listOfDays.push(wednesday);
        this.listOfDays.push(thursday);
        this.listOfDays.push(friday);
        this.listOfDays.push(saturday);
      }
    },
    packageChange() {
      (this.choice = ""),
        (this.protein = this.selectPackage.meanInfo.protein),
        (this.carbs = this.selectPackage.meanInfo.carbs);
    },
    onPackageModeChange() {
      this.celebrity = null;
      this.selectPackage = "";
      this.choice = "";
    },
    updateCelebityId(celebrity, packageItem) {
      this.celebrity = celebrity;
      this.selectPackage = packageItem;
    },
    isNotAlreadyReferred() {
      if (this.pacakageSelectionMethod == "CELEBRITY") {
        return false;
      }
      if (this.customer.referredByUser) {
        if (this.customer.isPackageSubscribed) {
          return true;
        }
        return false;
      }
      return true;
    },
    onChoiceChange() {
      if (this.mealChoice) {
        let price = parseFloat(this.mealChoice.price);
        let additionalPrice = parseFloat(
          this.additionalPrice ? this.additionalPrice : 0
        );
        let discount = this.discount ? parseFloat(this.discount) : 0;
        let netPrice = price - discount + additionalPrice;
        if (netPrice < 0) {
          discount = 0;
          this.discount = 0;
        }
        this.netPrice = (price - discount + additionalPrice).toFixed(3);
      }
    },

    getActivePackage() {
      let runningPackages = Utils.getActiveAndPendingCustomerPackages(
        this.subscribedPackages
      );
      this.currentPlanStatus(runningPackages);
    },

    currentPlanStatus(runningPackages) {
      this.hasActivePackage = false;
      this.hasUpcomingPackage = false;
      if (runningPackages.activePackage) {
        this.hasActivePackage = true;
      }
      if (runningPackages.upcomingPackage) {
        this.hasUpcomingPackage = true;
      }
    },
    close() {
      this.show_dialog_box = false;
      this.$refs.form.reset();
      this.selectPackage = "";
      this.start_date = "";
      this.choice = "";
    },
    createSalesOrder() {
      if (!this.valid) return false;

      let price = parseFloat(this.choice.price);
      let discount = this.discount ? parseFloat(this.discount) : 0;
      let netPrice = price - discount;
      if (this.pacakageSelectionMethod == "CELEBRITY") {
        if (this.celebrity) {
          this.referredBy = parseInt(
            this.celebrity.customer.user.userId
          ).toString(16);
        }
      }

      if (!this.selectPackage || !this.selectPackage._id) {
        this.snackbar = true;
        this.snackColor = "error";
        this.text = "Please select a package";
        return false;
      }

      if (!this.choice || !this.choice._id) {
        this.snackbar = true;
        this.snackColor = "error";
        this.text = "Please select a choice";
        return false;
      }

      if (!this.mealChoice || !this.mealChoice._id) {
        this.snackbar = true;
        this.snackColor = "error";
        this.text = "Please select meal choice";
        return false;
      }

      let mealData = JSON.parse(JSON.stringify(this.availableDays));

      let isValid = true;
      mealData.forEach(mealCount => {
        let hasMeal = false;
        mealCount.allCategories.forEach(category => {
          if (parseInt(category.count) > 0) {
            hasMeal = true;
          }
        });

        if (!hasMeal) {
          this.snackbar = true;
          this.snackColor = "error";
          this.text = "Please add atleast one meal on : " + mealCount.day;
          this.btnloading = false;
          isValid = false;
          return false;
        }
      });

      if (isValid) {
        let customerPackage = {};

        let dayChoices = new Array();
        this.availableDays.forEach(dayChoice => {
          let mealCounts = new Array();
          dayChoice.allCategories.forEach(element => {
            if (parseInt(element.count) > 0) {
              let mealCount = {
                count: parseInt(element.count),
                category: element._id
              };
              mealCounts.push(mealCount);
            }
          });
          let dayChoiceItem = {
            day: dayChoice.day,
            mealCount: mealCounts
          };
          dayChoices.push(dayChoiceItem);
        });

        customerPackage.dayChoices = dayChoices;
        let availableDays = this.availableDays.map(x => x.day);
        const subPackages = {
          package: this.selectPackage._id,
          start_date: this.start_date,
          selected_choice: this.choice._id,
          discount: discount,
          netPrice: netPrice,
          paymentMethod: this.paymentMethod,
          mealChoice: this.mealChoice._id,
          referralCode: this.referredBy,
          additionalDays: this.additionalDays
            ? parseInt(this.additionalDays)
            : 0,
          additionalPrice: this.additionalPrice
            ? parseFloat(this.additionalPrice)
            : 0,
          packageInfo: customerPackage,
          availableDays: availableDays,
          protein: this.protein,
          carbs: this.carbs
        };

        this.btnloading = true;
        this.$apollo
          .mutate({
            mutation: require("../../api/adminCheckoutCustomerPackage.graphql"),
            variables: {
              customerId: this.customer_id,
              input: subPackages
            }
          })
          .then(data => {
            console.log(data);
            this.snackbar = true;
            this.text = "Customer sales order added";
            this.$root.$emit("updateGetCustomerById");
            this.snackColor = "success";
            this.$refs.form.reset();
            this.btnloading = false;
            this.close();
          })
          .catch(error => {
            this.snackbar = true;
            this.snackColor = "error";
            this.text = "Something went wrong";
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    }
  },
  computed: {
    formatDate() {
      return this.start_date ? moment(this.start_date).format(DATE_FORMAT) : "";
    }
  }
};
</script>
