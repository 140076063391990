<template>
  <div>
    <v-row>
      <v-flex xs12 sm7 md6 lg9 xl8 class="pe-4 pe-sm-9 pe-md-15 pe-lg-9">
        <v-select :items="reportFilters" label="Report Type" v-model="reportFilter" item-text="text" item-value="value"
          dense filled solo @change="getReport" attach></v-select>
      </v-flex>
      <v-flex xs12 sm4 md3 lg3 class="ms-6 ms-sm-1 ms-md-n8 ms-lg-n4 mb-4 mb-sm-0">
        <v-btn :disabled="!hasData" v-if="reportFilter" @click="startDeliveryExport">DOWNLOAD</v-btn>
        <v-snackbar v-model="exportStarted" timeout="6000" right top color="success">Export Started
          <router-link class="black--text" :to="{ name: 'ReportStatus' }">click here to view report status</router-link>


        </v-snackbar>
      </v-flex>
    </v-row>
  </div>
</template>


<script>
import Vue from "vue";
import moment from "moment";
export default Vue.extend({
  props: {
    deliveryDate: { require: true },
    deliveryStatus: { require: true },
    deliveryShift: { require: true },
    headers: { require: true },
    hasData: { require: true }
  },
  created() { },
  watch: {
    deliveryDate: {
      handler() {
        this.isInitLoaded = false;
        this.reportFilter = "";
      },
    },
    deliveryStatus: {
      handler() {
        this.isInitLoaded = false;
        this.reportFilter = "";
      },
    },
    deliveryShift: {
      handler() {
        this.isInitLoaded = false;
        this.reportFilter = "";
      },
    },
  },
  data() {
    return {
      loading: false,
      btnloading: false,
      reportFilter: "",
      exportStarted: false,
      isInitLoaded: false,
      pendingList: [],
      deliveryWithoutDislike: [],
      deliveryWithDislike: [],
      reportFilters: [
        { text: "Delivey Sticker", value: "DELIVERY_STICKER" },
        { text: "Delivery Report", value: "DELIVERY_REPORT" },
        {
          text: "Delivery Report with Dislike",
          value: "DELIVERY_REPORT_WITH_DISLIKE",
        },
        {
          text: "Delivery Report without Dislike",
          value: "DELIVERY_REPORT_WITHOUT_DISLIKE",
        },
        {
          text: "Export",
          value: "EXPORT",
        },
      ],
    };
  },

  methods: {
    getReport() {
      if (!this.isInitLoaded) {
        this.isInitLoaded = true;
        //     this.getDeliveryDetailsByDate();
      }
    },
    startDeliveryExport() {
      const dateTime = moment(`${this.deliveryDate}`).format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      this.$apollo
        .watchQuery({
          query: require("@/delivery/api/excelDeliveryExportReport.graphql"),
          variables: {
            status: this.deliveryStatus,
            date: modifiedDate,
            type: this.reportFilter,
            shift: this.deliveryShift
          }
        })
        .refetch({
          variables: {
            status: this.deliveryStatus,
            date: modifiedDate,
            type: this.reportFilter,
            shift: this.deliveryShift
          },
        })
        .then(() => {
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
        });
      this.exportStarted = true
    },
    getDeliveryDetailsByDate() {
      const dateTime = moment(`${this.deliveryDate}`).format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getDeliveryDetailsByDate.graphql"),
          variables: {
            status: this.deliveryStatus,
            shift: this.deliveryShift,
            date: modifiedDate,
            skip: 0,
            limit: 10000,
          },
        })
        .refetch({
          variables: {
            status: this.deliveryStatus,
            shift: this.deliveryShift,
            date: modifiedDate,
            skip: 0,
            limit: 10000,
          },
        })
        .then((data) => {
          this.loading = false;
          if (data.data.getDeliveryDetailsByDate) {
            let responseData = data.data.getDeliveryDetailsByDate.orderDelivery;
            this.pendingList = responseData;

            let dislikedCustomers = new Array();
            let noDislikeCustomers = new Array();

            let responseDataJSON = JSON.parse(JSON.stringify(responseData));

            responseDataJSON.forEach((customer) => {
              let menuCategoryDislike = new Array();
              let menuCategoryNonDislike = new Array();

              customer.menu.forEach((menu) => {
                let menuItemsDislike = new Array();
                let menuItemsNonDislike = new Array();
                menu.menu.forEach((menuItem) => {
                  if (menuItem.dislikedIngrediants.length > 0) {
                    menuItemsDislike.push(menuItem);
                    if (menuItem.allergyIngredients) {
                      if (menuItem.allergyIngredients.length > 0) {
                        menuItemsNonDislike.push(menuItem);
                      }
                    }
                  } else {
                    menuItemsNonDislike.push(menuItem);
                    if (menuItem.allergyIngredients) {
                      if (menuItem.allergyIngredients.length > 0) {
                        menuItemsDislike.push(menuItem);
                      }
                    }
                  }
                });

                if (menuItemsDislike.length > 0) {
                  let menuInfo = {
                    menu: menuItemsDislike,
                    category: JSON.parse(JSON.stringify(menu.category)),
                    isAutoSelected: menu.isAutoSelected,
                  };
                  menuCategoryDislike.push(menuInfo);
                } else if (menuItemsNonDislike.length > 0) {
                  let menuInfo = {
                    menu: menuItemsNonDislike,
                    category: JSON.parse(JSON.stringify(menu.category)),
                    isAutoSelected: menu.isAutoSelected,
                  };
                  menuCategoryNonDislike.push(menuInfo);
                }
              });

              if (menuCategoryDislike.length > 0) {
                customer.menu = menuCategoryDislike;
                dislikedCustomers.push(JSON.parse(JSON.stringify(customer)));
              }

              if (menuCategoryNonDislike.length > 0) {
                customer.menu = menuCategoryNonDislike;
                noDislikeCustomers.push(JSON.parse(JSON.stringify(customer)));
              }
            });

            this.deliveryWithDislike = dislikedCustomers;
            this.deliveryWithoutDislike = noDislikeCustomers;
          }
        });
    },
  },
});
</script>
