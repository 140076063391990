<template>
  <div>
    <v-btn
      @click="showDialog"
      class="white--text text-capitalize"
      color="btncolor"
    >
      <v-icon small class="me-1"> mdi-file-document-multiple</v-icon>Log
    </v-btn>
    <v-dialog v-model="show_dialog_box1" max-width="900px">
      <v-card class="pa-3 pop_bg">
        <!-- {{ auditList }} -->
        <v-card-title class="justify-center">
          <span class="black--text text-h6"> Audit trail </span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="show_dialog_box1 = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-data-table
              :mobile-breakpoint="0"
              :headers="headers"
              :items="auditList"
              class="elevation-1 mt-4"
              :options.sync="options"
              :loading="loading"
              :server-items-length="totalItems"
              :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
            >
              <template v-slot:item.updatedBy="{ item }">
                <span v-if="item.updatedBy != null">
                  {{
                    item.updatedBy.firstnameEn[0].toUpperCase() +
                      item.updatedBy.firstnameEn.substring(1)
                  }}
                  {{
                    item.updatedBy.lastnameEn[0].toUpperCase() +
                      item.updatedBy.lastnameEn.substring(1)
                  }}
                </span>
              </template>
              <template v-slot:item.updatedTime="{ item }">
                {{
                  getDate(new Date(parseInt(item.updatedTime)))
                    | moment("MMM DD YYYY hh:mm:A")
                }}
              </template>
              <template v-slot:item.history="{ item }">
                <AuditAdditionalDetails :auditLog="item" />
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar> -->
  </div>
</template>
<script>
import AuditAdditionalDetails from "./AuditAdditionalDetails.vue";
import { tz } from "moment-timezone";
import moment from "moment";
import { TIME_ZONE } from "../../utils/utils";
export default {
  components: { AuditAdditionalDetails },
  props: {
    auditType: {
      required: true
    },
    id: {
      required: false
    }
  },
  methods: {
    getAllAuditReport() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllAuditTrialByAuditType.graphql"),
          variables: {
            type: this.auditType,
            limit: 25,
            skip: 0,
            id: this.id ? this.id : ""
          }
        })
        .refetch({
          type: this.auditType,
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 200,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage
        })
        .then(data => {
          this.loading = false;
          if (data.data.getAllAuditTrialByAuditType) {
            this.auditList = data.data.getAllAuditTrialByAuditType.auditTrail;
            this.totalItems = data.data.getAllAuditTrialByAuditType.totalCount;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    showDialog() {
      this.getAllAuditReport();
      this.show_dialog_box1 = true;
    },
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    }
  },
  watch: {
    options: {
      handler() {
        this.getAllAuditReport();
      },
      deep: true
    }
  },
  data() {
    return {
      valid: false,
      show_dialog_box1: false,
      auditList: [],
      btnloading: false,
      loading: true,
      totalItems: 25,
      tz: tz,
      text: "",
      options: {
        itemsPerPage: 25,
        page: 1
      },
      snackbar: false,
      headers: [
        { text: "Action", value: "action", sortable: false, width: 120 },
        {
          text: "Description",
          value: "description",
          sortable: false,
          width: 200
        },
        {
          text: "Updated Time",
          value: "updatedTime",
          sortable: false,
          width: 120
        },
        { text: "Updated By", value: "updatedBy", sortable: false, width: 150 },
        { text: "History", value: "history", sortable: false, width: 120 }
        // { text: "", value: "actions", sortable: false },
      ]
    };
  }
};
</script>
