<template>
  <v-container class="px-0" fluid>
    <v-layout>
      <h2 class="black--text text-h5 font-weight-bold">Zone</h2>
      <v-spacer></v-spacer>
      <ZoneCreateFrom
        v-if="hasRole(AdminUserPermissions.LOCATION_ADD)"
        :zone="zone"
      />
      <AuditTrialLog class="ms-2 ms-md-4" auditType="ZONE_LOG" />
    </v-layout>
    <app-zone-table :totalItemsCount="totalItemsCount"></app-zone-table>
  </v-container>
</template>

<script>
import ZoneTable from "../components/ZoneTable.vue";
import ZoneCreateFrom from "../components/ZoneCreateForm.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";

export default {
  components: {
    ZoneCreateFrom,
    "app-zone-table": ZoneTable,
    AuditTrialLog
  },
  data() {
    return {
      textFields: [],
      tags: [],
      loading: true,
      areaList: [],
      blockList: [],
      totalItemsCount: 0,
      area: {
        NameEn: "",
        NameAr: ""
      },
      shift: {
        NameEn: "",
        NameAr: ""
      },
      block: "",
      zone: "",
      search: ""
    };
  },
  created() {},
  apollo: {
    getAllAreas() {
      return {
        query: require("../api/getAllAreas.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.areaList = data.getAllAreas.areas;
          this.totalItemsCount = data.getAllAreas.totalCount;
        }
      };
    }
  },
  methods: {
    runSearch() {
      this.$apollo.queries.getAllAreas.refetch({
        searchKey: this.search
      });
    },
    clearSearch() {
      this.search = "";
      this.$apollo.queries.getAllAreas.refetch({
        searchKey: ""
      });
    }
  }
};
</script>
