<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <div>
      <v-card class="pa-5 ">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2"
            >Comments</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="commentList"
          class="elevation-1 mt-10"
          hide-default-footer
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ createdDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.message`]="{ item }">
            {{ item.message }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span v-if="item.actionStatusFrom" class="font-weight-bold">
              {{ item.actionStatusFrom ? item.actionStatusFrom : "  " }} =>
            </span>
            <span class="font-weight-bold">
              {{ item.actionStatusTo ? item.actionStatusTo : "  " }}</span
            >
            <!-- {{
              `${item.actionStatusFrom ? item.actionStatusFrom : "  "}    ${
                item.actionStatusTo
              }`
            }} -->
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    commentList: {
      require: true
    },
    openComment:{}
  },
  watch: {
    openComment: {
      handler(item) {
        if (item) {
          this.dialog = true;
        }
      }
    }
  },
  data: () => ({
    dialog: false,
    headers: [
      //   {
      //     text: "Sl No",
      //     value: "index",
      //     sortable: false,
      //     width: 70
      //   },
      { text: " Status changes", value: "status", sortable: false, width: 200 },
      //   { text: "Action Status To", value: "user.phone", sortable: false, width: 200 },
      { text: "Created At", value: "createdAt", sortable: false, width: 100 },
      { text: "Message", value: "message", sortable: false, width: 200 }
      //   { text: "Action", value: "actions", sortable: false, width: 10 }
    ]
  }),

  methods: {
    close() {
      
      this.dialog = false;
      this.$emit("closeComment") 
    },
    createdDate(date) {
      let data = "--";
      if (date) {
        data = moment(parseInt(date)).format("DD MMM YYYY  hh:mm:A");
      }
      return data;
    }
  }
};
</script>
