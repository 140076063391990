<template>
  <div class="bg">
    <v-data-table
      :headers="headers"
      :items="dietitianAppointmentList"
      :expanded.sync="expanded"
      item-key="_id"
      show-expand
      class="elevation-1"
      :options.sync="options"
    >
      <!-- <template v-slot:[`item.user`]="{ item }">
        <router-link
          v-if="item.customer"
          :to="{
            name: 'CustomerDetails',
            params: { customerId: item.customer._id },
            query: { customerId: item.customer._id }
          }"
          class="mx-auto"
        >
          {{
            item.customer.user.firstnameEn[0].toUpperCase() +
              item.customer.user.firstnameEn.substring(1)
          }}
          {{
            item.customer.user.lastnameEn[0].toUpperCase() +
              item.customer.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template> -->
      <template v-slot:[`item.customer`]="{ item }">
       <router-link
          v-if="item.customer"
          :to="{
            name: 'CustomerDetails',
            params: { customerId: item.customer._id },
            query: { customerId: item.customer._id }
          }"
          class="mx-auto"
        >
          {{
            item.customer.user.firstnameEn[0].toUpperCase() +
              item.customer.user.firstnameEn.substring(1)
          }}
          {{
            item.customer.user.lastnameEn[0].toUpperCase() +
              item.customer.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>
      <template v-slot:[`item.bookedDate`]="{ item }">
        {{ bookedDate(item) }}
      </template>
      <template v-slot:[`item.appointmentDate`]="{ item }">
        {{
          new Date(parseInt(item.firstBooking.appointmentDate))
            | moment("DD MMM YYYY")
        }}
        : {{ item.firstBooking.appointmentTime }}
        <!-- {{ item.firstBooking ? appointmentDate(item.firstBooking) : "--" }} -->
      </template>
      <template v-slot:[`item.mode`]="{ item }">
        {{ item.firstBooking ? mode(item.firstBooking) : "--" }}
      </template>
      <template v-slot:[`item.shift`]="{ item }">
        {{ item.firstBooking ? shift(item.firstBooking) : "--" }}
      </template>
      <template v-slot:[`item.followUpType`]="{ item }">
        {{ item.firstBooking ? followUpType(item.firstBooking) : "--" }}
      </template>
      <template v-slot:[`item.actionType`]="{ item }">
        {{ item.firstBooking ? item.firstBooking.actionType : "--" }}
      </template>
      <template v-slot:[`item.actionStatus`]="{ item }">
        {{ item.firstBooking ? item.firstBooking.actionStatus : "--" }}
      </template>
      <template v-slot:[`item.comment`]="{ item }">
        <!-- {{ item.followUp[0] ? (item.followUp[0].comment.length ): "--" }} -->
        <span v-if="item.firstBooking">
          <span v-if="item.firstBooking.comment.length">
            <ul
              v-for="comment in item.firstBooking.comment"
              :key="comment.message"
            >
              <li>
                {{ comment.message ? comment.message : "--" }}
              </li>
              <li style=" list-style-type: none;">
                {{
                  comment.actionStatusFrom || comment.actionStatusTo
                    ? " ( "
                    : ""
                }}
                {{ comment.actionStatusFrom ? comment.actionStatusFrom : "" }}
                {{ comment.actionStatusTo ? " - " + comment.actionStatusTo : ""
                }}{{
                  comment.actionStatusFrom || comment.actionStatusTo
                    ? " ) "
                    : ""
                }}
              </li>
              <!-- <li>sarath</li> -->
            </ul>
          </span>
        </span>
      </template>
      <template v-slot:[`item.bookingFee`]="{ item }">
        {{ bookingFee(item.firstBooking) }}
      </template>
      <template v-slot:[`item.additionalFee`]="{ item }">
        {{ additionalFee(item.firstBooking) }}
      </template>
      <template v-slot:[`item.totalFee`]="{ item }">
        {{ totalFee(item.firstBooking) }}
      </template>
      <template v-slot:[`item.paymentStatus`]="{ item }">
        <span
          :class="
            paymentStatus(item.firstBooking) == 'SUCCESS'
              ? 'green--text'
              : paymentStatus(item.firstBooking) == 'PENDING'
              ? 'orange--text'
              : paymentStatus(item.firstBooking) == 'UPCOMING'
              ? 'blue--text'
              : paymentStatus(item.firstBooking) == 'EXPIRED'
              ? 'red--text'
              : ''
          "
        >
          {{ paymentStatus(item.firstBooking) }}
          <!-- {{
            item.followUp.payment_status
              ? item.followUp.payment_status[0].toUpperCase() +
                item.followUp.payment_status.substring(1)
              : "--"
          }} -->
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="ma-0 pa-0"
          :colspan="headers.length"
          v-if="item.remainingFollowUp.length"
        >
          <v-data-table
            :headers="headers"
            :items="item.remainingFollowUp"
            hide-default-footer
          >
            <template v-slot:[`item.dietitian`]="{}">
              <router-link
                :to="{
                  name: 'DietitianDetails',
                  params: { dietitianId: item.dietitian._id },
                  query: { dietitianId: item.dietitian._id }
                }"
                class="mx-auto"
              >
                {{
                  item.dietitian.user.firstnameEn[0].toUpperCase() +
                    item.dietitian.user.firstnameEn.substring(1)
                }}
                {{
                  item.dietitian.user.lastnameEn[0].toUpperCase() +
                    item.dietitian.user.lastnameEn.substring(1)
                }}
              </router-link>
            </template>
            <template v-slot:[`item.bookedDate`]="{}">
              {{ bookedDate(item) }}
            </template>
            <template v-slot:[`item.appointmentDate`]="{ item }">
              {{
                new Date(parseInt(item.appointmentDate)) | moment("DD MMM YYYY")
              }}
              : {{ item.appointmentTime }}
              <!-- {{ appointmentDate(item.followUp) }} -->
            </template>
            <template v-slot:[`item.mode`]="{ item }">
              {{ mode(item) }}
            </template>
            <template v-slot:[`item.shift`]="{ item }">
              {{ shift(item) }}
            </template>
            <template v-slot:[`item.followUpType`]="{ item }">
              {{ followUpType(item) }}
            </template>
            <template v-slot:[`item.actionType`]="{ item }">
              {{ item.actionType }}
            </template>
            <template v-slot:[`item.actionStatus`]="{ item }">
              {{ item.actionStatus }}
            </template>
            <template v-slot:[`item.comment`]="{ item }">
              <span v-if="item.comment.length">
                <ul v-for="comment in item.comment" :key="comment.message">
                  <li>
                    {{ comment.message ? comment.message : "--" }}
                  </li>
                  <li style=" list-style-type: none;">
                    {{
                      comment.actionStatusFrom || comment.actionStatusTo
                        ? " ( "
                        : ""
                    }}
                    {{
                      comment.actionStatusFrom ? comment.actionStatusFrom : ""
                    }}
                    {{
                      comment.actionStatusTo
                        ? " - " + comment.actionStatusTo
                        : ""
                    }}{{
                      comment.actionStatusFrom || comment.actionStatusTo
                        ? " ) "
                        : ""
                    }}
                  </li>
                  <!-- <li>sarath</li> -->
                </ul>
              </span>
            </template>
            <template v-slot:[`item.bookingFee`]="{ item }">
              {{ bookingFee(item) }}
            </template>
            <template v-slot:[`item.additionalFee`]="{ item }">
              {{ additionalFee(item) }}
            </template>
            <template v-slot:[`item.totalFee`]="{ item }">
              {{ totalFee(item) }}
            </template>
            <template v-slot:[`item.paymentStatus`]="{ item }">
              <span
                :class="
                  paymentStatus(item) == 'SUCCESS'
                    ? 'green--text'
                    : paymentStatus(item) == 'PENDING'
                    ? 'orange--text'
                    : paymentStatus(item) == 'UPCOMING'
                    ? 'blue--text'
                    : paymentStatus(item) == 'EXPIRED'
                    ? 'red--text'
                    : ''
                "
              >
                {{ paymentStatus(item) }}
              </span>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dietitian_id: {
      required: true
    },
    type: {
      required: true
    }
  },
  // mounted() {
  //   this.$root.$on("updateDietitianAppointments", () => {
  //     this.loading = true;
  //     this.$apollo.queries.getAllAppoinments.refetch({
  //       variables: this.variables
  //     });
  //   });
  // },
  // components: {
  // },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      dietitianAppointmentList: [],
      search: "",
      headers: [
        { text: "", value: "data-table-expand", width: 20 },
        // {
        //   text: "Sl No",
        //   value: "index",
        //   sortable: false,
        //   width: 70
        // },
        // { text: "Name", value: "user", sortable: false },
        // {
        //   text: "Mobile Number",
        //   value: "customer.user.phone",
        //   sortable: false
        // },
        {
          text: "Appointment On",
          value: "appointmentDate",
          sortable: false,
          width: 200
        },
        {
          text: "Booked Date",
          value: "bookedDate",
          sortable: false,
          width: 120
        },
        { text: "Mode", value: "mode", sortable: false, width: 120 },
        { text: "Shift", value: "shift", sortable: false, width: 120 },
        { text: "Customer", value: "customer", sortable: false, width: 120 },
        { text: "Type", value: "followUpType", sortable: false, width: 120 },
        {
          text: "Action Type",
          value: "actionType",
          sortable: false,
          width: 180
        },
        {
          text: "Action Status",
          value: "actionStatus",
          sortable: false,
          width: 180
        },
        { text: "Comment", value: "comment", sortable: false, width: 250 },
        {
          text: "Booking Fee",
          value: "bookingFee",
          sortable: false,
          width: 120
        },
        {
          text: "Additional Fee",
          value: "additionalFee",
          sortable: false,
          width: 120
        },
        { text: "Total", value: "totalFee", sortable: false, width: 120 },
        {
          text: "Payment Status",
          value: "paymentStatus",
          sortable: false,
          width: 120
        }
        //   { text: "Action", value: "actions", sortable: false, width: 10 }
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 0,
      options: {
        itemsPerPage: 10,
        page: 1
      },

      input: {
        limit: 10,
        skip: 1
      },
      loading: true,
      customerId: this.$route.query.customerId,
      //
      expanded: [],
      followUpdata: []
      // singleExpand: false,
    };
  },
  watch: {
    options: {
      handler(item) {
        this.input.limit = item.itemsPerPage;
        this.input.skip = item.page;
        this.getSlotAppointmentDetails();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  // apollo: {
  //   getAllAppoinments() {
  //     return {
  //       query: require("../../api/getAllAppoinments.graphql"),
  //       variables: this.variables,
  //       result({ data, loading }) {
  //         this.loading = loading;
  //         this.appointmentList = data.getAllAppoinments.appointments;
  //         this.totalItemsCount = data.getAllAppoinments.totalCount;
  //       }
  //     };
  //   }
  // },

  methods: {
    updateDietitianAppointment() {
      this.getSlotAppointmentDetails();
    },
    // followUpData(item) {
    //   let result = new Array();
    //  this.followUpdata=item
    //   console.log(item);
    //   if (this.followUpdata.followUp.length) {
    //     // let data = followUpdata.followUp.filter((x, i) => i !== 0);
    //     // result = data;
    //     for (let [index, data] of this.followUpdata.followUp.entries()) {
    //       let newData = this.followUpdata;
    //       if (index) {
    //         newData._id = index;
    //         newData.followUp = data;
    //         result.push(newData);
    //       }
    //     }
    //   }
    //   console.log("result")
    //   console.log(result)
    //   return result;
    // },
    bookingFee(followUp) {
      let result = 0;
      if (followUp.bookingFee) {
        result = followUp.bookingFee;
      }
      return result;
    },
    additionalFee(followUp) {
      let result = 0;
      if (followUp.additionalFee) {
        result = followUp.additionalFee;
      }
      return result;
    },
    paymentStatus(followUp) {
      let result = "--";
      if (followUp.actionStatus == "FINISHED") {
        if (followUp.additional_payment_status) {
          // if (followUp.payment_status == "SUCCESS") {
          //   result = "Success";
          // }
          result = followUp.additional_payment_status;
        }
      } else {
        if (followUp.payment_status) {
          // if (followUp.payment_status == "SUCCESS") {
          //   result = "Success";
          // }
          result = followUp.payment_status;
        }
      }

      return result;
    },
    totalFee(followUp) {
      let result = 0;
      if (followUp.modeOfAppointment == "ON_VISIT")
        result =
          parseFloat(this.additionalFee(followUp)) +
          parseFloat(this.bookingFee(followUp));
      else result = followUp.onCallFee;
      return result;
    },
    bookedDate(followUp) {
      if (followUp.createdDate) {
        return moment(parseInt(followUp.createdDate)).format("DD MMM YYYY");
      }
    },
    appointmentDate(followUp) {
      if (followUp.appointmentDate) {
        let res1 = moment(parseInt(followUp.appointmentDate)).format(
          "DD MMM YYYY"
        );
        let res2 = followUp.appointmentTime;
        let result = res1 + " : " + res2;
        return result;
      }
    },
    mode(followUp) {
      if (followUp.modeOfAppointment) {
        let result = "--";
        if (followUp.modeOfAppointment == "ON_CALL") {
          result = "On Call";
        }
        if (followUp.modeOfAppointment == "ON_VISIT") {
          result = "Visit";
        }
        return result;
      }
    },
    shift(followUp) {
      let result = "--";
      if (followUp.shift) {
        if (followUp.shift == "MORNING_SHIFT") {
          result = "Morning Shift";
        }
        if (followUp.shift == "EVENING_SHIFT") {
          result = "Evening Shift";
        }
      }
      return result;
    },
    followUpType(followUp) {
      let result = "--";
      if (followUp.followType > 0) {
        result = `Follow Up - ${followUp.followType}`;
      } else if (followUp.followType == 0) {
        result = "First";
      }
      return result;
    },
    getSlotAppointmentDetails() {
      this.loading = true;

      this.$apollo
        .watchQuery({
          query: require("@/dietitian/dietitian_settings/api/getDietitianSlotAppointment.graphql"),
          variables: {
            input: this.input,
            dietitianId: this.dietitian_id,
            type: this.type
          }
        })
        .refetch({})
        .then(data => {
          this.loading = false;
          console.log(data.data); 
          if (data.data) {
            // if(data.data.getAllSlotAppointments.slotAppointments.length){
            //   for(let x of )
            // }
            this.dietitianAppointmentList =
              data.data.getDietitianSlotAppointments.slotAppointments;
            for (let [
              i,
              appointment
            ] of this.dietitianAppointmentList.entries()) {
              appointment.index =
                i + (this.options.page - 1) * this.options.itemsPerPage + 1;
              // let shiftedArray = appointment.followUp.shift();
              // appointment.firstBooking = shiftedArray;
              appointment.firstBooking = appointment.followUp[0];
              appointment.remainingFollowUp = appointment.followUp.filter(
                (x, i) => i !== 0
              );
              console.log(appointment.remainingFollowUp.length);
            }
          
            // this.oldValue = data.data.getCustomerSlotAppointments.slotAppointments;
            this.totalItemsCount =
              data.data.getDietitianSlotAppointments.totalCount; 
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    isPaymentPending(appt) {
      if (appt.payment_status == "PENDING" && appt.paymentUrl) {
        return true;
      }
      return false;
    },
    numberWithCommas(x) {
      if (!x) {
        return 0;
      }
      return parseFloat(x)
        .toFixed(3)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>

<style scoped></style>
