<template>
  <div class="secondary black--text" style="height: 270px">
    <v-flex class="mt-4 mx-2 mb-2">
      <h4 class="black--text text-h6 font-weight-bold">Meal Details
      </h4>
    </v-flex>

    <v-row class="mb-n6">
      <v-col md="5" class="ms-2">Fat</v-col>
      <v-col md="6" class="font-weight-medium">
        {{ mealInfo.meanInfo.carbs }}
      </v-col>
    </v-row>
    <v-row class="mb-n6 mt-n6">
      <v-col md="5" class="ms-2">Protein</v-col>
      <v-col md="6" class="font-weight-medium">{{ mealInfo.meanInfo.protein }}</v-col>
    </v-row>
    <v-row class="mb-n6 mt-n6">
      <v-col md="5" class="ms-2">Carbs</v-col>
      <v-col md="6" class="font-weight-medium">{{ mealInfo.meanInfo.carbs }}</v-col>
    </v-row>
    <v-row class="mb-n6 mt-n6">
      <v-col md="5" class="ms-2">Calorie</v-col>
      <v-col md="6" class="font-weight-medium">{{ mealInfo.meanInfo.calories }}</v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    mealInfo: {
      required: true
    }
  },
  methods: {

  },

};
</script>
