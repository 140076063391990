<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Edit Ingredient </span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-8">
            <v-form
              class="pa-sm-8"
              @submit="updateIngredient()"
              v-model="valid"
              ref="form"
            >
              <v-layout wrap class="mt-n6">
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Item ID</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    solo
                    :disabled="true"
                    dense
                    v-model="ingredient.itemId"
                    :rules="[requiredValidator('item id')]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="ms-md-16">
                  <h4 class="black--text text-body-2">Brand Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    :rules="[requiredValidator('brand name')]"
                    v-model="ingredient.brand"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <!-- <v-flex xs12 sm4 md2>
                  <h4>Label</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    label="Label"
                    v-model="ingredient.label"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex> -->

                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Item Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    v-model="ingredient.nameEn"
                    solo
                    onkeydown="return /[a-z\-]/i.test(event.key)"
                    :rules="[requiredValidator('item name')]"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="ms-md-16">
                  <h4 class="black--text text-body-2">Item Name Arabic</h4>
                </v-flex>
                <v-flex md3>
                  <v-text-field
                    v-model="ingredient.nameAr"
                    solo
                    onkeydown="return /[a-z\-]/i.test(event.key)"
                    :rules="[requiredValidator('item name arabic')]"
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 md2>
                  <h4 class="black--text text-body-2">Unit In</h4>
                </v-flex>
                <v-flex xs6 md4>
                  <v-radio-group
                    v-model="ingredient.quantityUnit"
                    :mandatory="false"
                    row
                    class="mt-n1 ml-n2"
                  >
                    <v-radio label="Grams" value="GRAMS"></v-radio>
                    <v-radio label="mL" value="ML"></v-radio>
                    <v-radio label="Piece" value="PIECE"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12 sm4 md2 class="mt-2" v-if="ingredient.quantityUnit">
                  <h4 class="black--text text-body-2">Price(/1{{ priceUnit() }})</h4>
                </v-flex>
                <v-flex md3 v-if="ingredient.quantityUnit">
                  <v-text-field
                    v-model="ingredient.quantityPrice"
                    type="number"
                    min="0"
                    @keypress="formatDecimal"
                    :rules="[requiredValidator('price')]"
                    solo
                    dense
                    label="Price"
                  >
                    <template v-slot:append>
                      <h5 class="mt-1 grey--text">KD</h5>
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs4 md2>
                  <h4 class="black--text text-body-2">Is Ingredient</h4>
                </v-flex>
                <v-flex md3 align-self-center class="mt-n3">
                  <v-radio-group v-model="ingredient.isIngredient" :mandatory="false" row>
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout wrap v-show="ingredient.isIngredient">
                <v-flex xs4 md2 class="pe-md-2 mb-3">
                  <h4 class="black--text text-body-2">Common Dislikable</h4>
                </v-flex>
                <v-flex md3 align-self-center class="mt-n5">
                  <v-radio-group
                    v-model="ingredient.isCommonDislikable"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="mb-2 mb-sm-0 black--text text-body-2 mt-1">
                    Ingredient Category
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-select
                    :items="ingredientCategories"
                    :rules="[requiredValidator('ingredient category')]"
                    v-model="ingredientCategory"
                    item-text="nameEn"
                    item-value="_id"
                    dense
                    attach
                    label="ingredient category"
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout>
                <h3 class="black--text font-weight-bold">Add Per Gram</h3>
              </v-layout>
              <v-layout wrap class="mt-4">
                <v-flex xs5 sm4 md3>
                  <h4 class="black--text text-body-2">Fat</h4>
                </v-flex>

                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field
                    v-model="fat"
                    solo
                    type="number"
                    min="0"
                    @keypress="formatDecimal"
                    suffix="gm"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3 class="ms-md-16">
                  <h4 class="black--text text-body-2">Protein</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field
                    v-model="protein"
                    solo
                    type="number"
                    min="0"
                    @keypress="formatDecimal"
                    suffix="gm"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3>
                  <h4 class="black--text text-body-2">Carb</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field
                    v-model="carbs"
                    solo
                    type="number"
                    min="0"
                    @keypress="formatDecimal"
                    suffix="gm"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3 class="ms-md-16">
                  <h4 class="black--text text-body-2">Calories</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field
                    v-model="calories"
                    solo
                    type="number"
                    min="0"
                    @keypress="formatDecimal"
                    suffix="gm"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <h3 class="black--text font-weight-bold">Alert</h3>
              <v-layout class="mt-2" wrap>
                <v-flex xs12 sm4 md6>
                  <h4 class="red--text font-weight-light">
                    Notify Admin when the quantity goes below*
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-4">
                <v-flex xs12 sm4 md2 class="pe-md-2">
                  <h4 class="black--text text-body-2">Minimum Quantity Level</h4>
                </v-flex>
                <v-flex xs12 sm5 md3>
                  <v-text-field
                    label="Qty"
                    solo
                    dense
                    type="number"
                    v-model="ingredient.alertQuantity"
                    @keypress="formatDecimal"
                    :rules="[requiredValidator('minimum quatity level')]"
                  >
                    <template v-if="ingredient.quantityUnit" v-slot:append>
                      <h5 class="mt-1 grey--text">
                        {{ ingredient.quantityUnit }}
                      </h5>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex class="ms-sm-16" md3> </v-flex>
              </v-layout>
            </v-form>

            <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
              text
            }}</v-snackbar>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n16">
          <v-spacer></v-spacer>
          <v-btn
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            color="secondary"
            @click="updateIngredient()"
            :loading="btnloading"
            :disabled="!valid"
            class="mt-3 white--text text-capitalize"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  props: {
    ingredientInfo: {
      required: true,
    },
  },
  created() {
    this.ingredient = JSON.parse(JSON.stringify(this.ingredientInfo));
  },
  data() {
    return {
      ingredient: [],
      ingredientCategories: [],
      ingredientCategory: "",
      show_dialog_box: false,
      quantityUnitRule() {
        return [
          (this.quantityUnit && this.quantityUnit.length) > 0 ||
            "At least one item should be selected",
        ];
      },
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      requiredValidator: requiredValidator,
      fat: "",
      protein: "",
      carbs: "",
      calories: "",
    };
  },
  methods: {
    getAllStockCategory() {
      this.$apollo
        .watchQuery({
          query: require("../api/getAllStockCategory.graphql"),
          variables: this.variables,
        })
        .refetch({})
        .then((data) => {
          if (data.data.getAllStockCategory) {
            this.ingredientCategories = data.data.getAllStockCategory.stockCategory;
          }
        });
    },
    priceUnit() {
      let unit = "";
      if (this.ingredient.quantityUnit == "ML") unit = "L";
      else if (this.ingredient.quantityUnit == "GRAMS") unit = "KG";
      else if (this.ingredient.quantityUnit == "PIECE") unit = "PCS";
      return unit;
    },
    onShowDialog() {
      this.ingredient = JSON.parse(JSON.stringify(this.ingredientInfo));
      this.getAllStockCategory();
      if (this.ingredient.quantityUnit == "PIECE") {
        this.ingredient.quantityPrice = parseFloat(this.ingredient.quantityPrice).toFixed(
          0
        );
      } else {
        this.ingredient.quantityPrice = JSON.parse(
          JSON.stringify(parseFloat(this.ingredient.quantityPrice * 1000).toFixed(3))
        );
      }
      if (this.ingredient.ingredientCategory) {
        this.ingredientCategory = this.ingredient.ingredientCategory._id;
      }
      this.fat = this.ingredient.meanInfo.fat;
      this.protein = this.ingredient.meanInfo.protein;
      this.carbs = this.ingredient.meanInfo.carbs;
      this.calories = this.ingredient.meanInfo.calories;
    },
    formatDecimal($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
      if (
        this.ingredient.alertQuantity != null &&
        this.ingredient.alertQuantity.indexOf(".") > -1 &&
        this.ingredient.alertQuantity.split(".")[1].length > 2
      ) {
        $event.preventDefault();
      }
    },
    updateIngredient() {
      this.ingredientInfo.itemId = this.ingredient.itemId;
      this.ingredientInfo.nameEn = this.ingredient.nameEn;
      this.ingredientInfo.nameAr = this.ingredient.nameAr;
      this.ingredientInfo.quantityUnit = this.ingredient.quantityUnit;
      this.ingredientInfo.brand = this.ingredient.brand;
      this.ingredientInfo.label = this.ingredient.label;
      this.ingredientInfo.alertQuantity = this.ingredient.alertQuantity;
      this.ingredientInfo.isIngredient = this.ingredient.isIngredient;
      this.ingredientInfo.quantityPrice = this.ingredient.quantityPrice / 1000;
      if (this.ingredient.quantityUnit == "PIECE") {
        this.ingredientInfo.quantityPrice = this.ingredient.quantityPrice;
      }

      this.ingredientInfo.ingredientCategory = {
        _id: this.ingredientCategory,
      };

      this.ingredientInfo.meanInfo.fat = this.fat;
      this.ingredientInfo.meanInfo.protein = this.protein;
      this.ingredientInfo.meanInfo.carbs = this.carbs;
      this.ingredientInfo.meanInfo.calories = this.calories;

      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/stock/api/updateIngredient.graphql"),
          variables: {
            id: this.ingredient._id,
            itemId: this.ingredient.itemId,
            nameEn: this.ingredient.nameEn,
            nameAr: this.ingredient.nameAr,
            quantityUnit: this.ingredient.quantityUnit,
            brand: this.ingredient.brand,
            quantityPrice:
              this.ingredient.quantityUnit == "PIECE"
                ? parseFloat(this.ingredient.quantityPrice)
                : parseFloat(this.ingredient.quantityPrice / 1000),
            isIngredient: this.ingredient.isIngredient,
            isCommonDislikable:
              this.ingredient.isIngredient == false
                ? false
                : this.ingredient.isCommonDislikable,
            label: this.ingredient.itemLabel,
            alertQuantity: parseFloat(this.ingredient.alertQuantity).toFixed(3),
            ingredientCategory: this.ingredientCategory,
            meanInfo: {
              fat: (this.fat / 100).toString(),
              protein: (this.protein / 100).toString(),
              carbs: (this.carbs / 100).toString(),
              calories: (this.calories / 100).toString(),
            },
          },
          update: () => {
            // this.$refs.form.reset();
            this.btnloading = false;
            this.show_dialog_box = false;
          },
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },

    close() {
      this.show_dialog_box = false;
      // this.$refs.form.reset();
    },
  },
};
</script>
<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: black !important;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
