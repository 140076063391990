<template>
  <div>
    <!-- {{ pendingAmount }}-->
    <v-dialog v-model="dialog" max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="white--text ma-2 text-capitalize"
          color="btncolor"
        >
          <v-icon small class="white--text">mdi-wallet</v-icon>
          Pay
        </v-btn>
      </template>

      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Celebrity Payment</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8"
          @submit="addReferralTransaction()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <h4 class="text-body-2 black--text">Payable Amount</h4>
                </v-flex>
                <v-flex xs12 sm8 md6>
                  <v-text-field
                    placeholder="Payable Amount"
                    v-model="payableAmount"
                    @keypress="isFloatAmount"
                    suffix="KD"
                    :rules="[requiredValidator('payable amount')]"
                    name="input-10-1"
                    type="number"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <h4 class="text-body-2 black--text">Pending Amount</h4>
                </v-flex>
                <v-flex xs12 sm8 md6>
                  <v-text-field
                    placeholder="Pending Amount"
                    :value="pendingAmount"
                    name="input-10-1"
                    type="number"
                    suffix="KD"
                    min="0"
                    solo
                    dense
                    disabled
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <h4 class="text-body-2 black--text">Received Amount</h4>
                </v-flex>
                <v-flex xs12 sm8 md6>
                  <v-text-field
                    placeholder="Received Amount"
                    v-model="totalDebit"
                    name="input-10-1"
                    type="number"
                    suffix="KD"
                    min="0"
                    solo
                    dense
                    disabled
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n6 mt-sm-0 mb-sm-n5">
            <!-- <v-spacer></v-spacer> -->
            <v-layout justify-center>
              <v-btn
                color="secondary"
                class="white--text text-capitalize"
                :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                :loading="btnloading"
                @click="addReferralTransaction"
                :disabled="!valid"
                >Update</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-form>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">{{
          snackText
        }}</v-snackbar>
        <!-- <v-layout wrap class="mt-5">
          <v-flex xs12 sm4 md3>
            <h4 class="text-h6 primary--text">Payment History</h4>
          </v-flex>
        </v-layout>
        <v-divider class="mb-6 mt-2"></v-divider>
        <CelebrityPaymentHistoryTable
          :celebrityTransactionList="celebrityTransactionList"
          :loading="loading"
          class="mt-n5"
        />

        <v-layout wrap class="mt-10">
          <v-flex xs12 sm4 md3>
            <h4 class="text-h6 primary--text">Plan Taken</h4>
          </v-flex>
        </v-layout>
        <v-divider class="mb-6 mt-2"></v-divider>
        <CelebrityPlanTakenTable
          :celebrityTransactionList="celebrityTransactionList"
          :loading="loading"
          class="mt-n5 mb-6"
        /> -->
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
// import CelebrityPaymentHistoryTable from "../components/CelebrityPaymentHistoryTable.vue";
// import CelebrityPlanTakenTable from "./CelebrityPlanTakenTable.vue";
export default {
  // components: { CelebrityPaymentHistoryTable, CelebrityPlanTakenTable },
  data() {
    return {
      image: null,
      imageUrl: process.env.VUE_APP_BASE_URL,
      myCroppa: null,
      requiredValidator: requiredValidator,
      btnloading: false,
      dialog: false,
      snackbar: false,
      snackbarColor: "",
      text: "",
      valid: true,
      loading: true,
      totalCredit: 0,
      totalDebit: 0,
      payableAmount: "",
      pendingAmount: 0,
      snack: false,
      snackColor: "",
      snackText: ""
    };
  },
  props: {
    celebrity: {
      required: true
    }
  },
  mounted() {
    this.$root.$on("updateReferralTransactions", () => {
      this.totalReferralTransactionAmountByUser();
    });
  },
  created() {
    this.totalReferralTransactionAmountByUser();
  },
  methods: {
    totalReferralTransactionAmountByUser() {
      this.$apollo
        .watchQuery({
          query: require("../api/totalReferralTransactionAmountByUser.graphql"),
          variables: { id: this.celebrity.customer.user._id }
        })
        .refetch({})
        .then(data => {
          // console.log(data.data);
          if (data.data.totalReferralTransactionAmountByUser) {
            this.totalCredit =
              data.data.totalReferralTransactionAmountByUser.totalCredit;
            this.totalDebit =
              data.data.totalReferralTransactionAmountByUser.totalDebit;
            this.pendingAmount = this.totalCredit - this.totalDebit;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },

    addReferralTransaction() {
      // console.log(input);
      if (parseFloat(this.payableAmount) > parseFloat(this.pendingAmount)) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Payable amount is higher than pending amount";
        return;
      }
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/addReferralTransaction.graphql"),
          variables: {
            user: this.celebrity.customer.user._id,
            userRole: "CELEBRITY",
            debit: parseFloat(this.payableAmount)
          },
          update: () => {
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .then(() => {
          this.$root.$emit("updateReferralTransactions");
          this.$root.$emit("updategetCelebrityById");
          this.payableAmount = "";
          this.text = "Celebrity Payment updated";
          this.snackbar = true;
          this.snackbarColor = "success";
          this.btnloading = false;
          this.dialog = false;
        })
        .catch(error => {
          this.btnloading = false;
          this.text = error.graphQLErrors[0].message;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },
    close() {
      this.dialog = false;
    },
    isFloatAmount: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode != 46 &&
        charCode != 45
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
