<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="secondary" class="white--text text-capitalize font-weight-bold">
          <v-icon>mdi-cog-outline</v-icon>&nbsp; Settings
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text"> Customer Referral Settings</span>
          <v-spacer></v-spacer>
          <v-btn color="btncolor" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-sm-8 mt-n6" @submit="updatePushNotification()" v-model="valid" ref="form">
          <v-card-text>
            <v-container>
              <v-layout class="mt-4 mt-sm-0" wrap>
                <v-flex xs12 sm2 md5>
                  <h4 class="text-body-2 black--text">Referral Percentage</h4>
                </v-flex>
                <v-flex xs12 sm8 md6 class="mt-5 mt-sm-0 mt-md-0">
                  <v-text-field label="Percentage" v-model="percentage" type="number" dense min="0"
                    append-icon="mdi-percent" :rules="[requiredValidator('customer referral percentage')]" solo>
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="mt-5 mt-sm-0 mt-md-0" wrap>
                <v-flex xs12 sm2 md5>
                  <h4 class="text-body-2 black--text">Max. Referral Amount</h4>
                </v-flex>
                <v-flex xs12 sm8 md6 class="mt-5 mt-sm-0 mt-md-0">
                  <v-text-field label="Max. Referral Amount" v-model="maxReferralAmount"
                    :rules="[requiredValidator('max. referral amount')]" type="number" dense min="0" solo>
                    <template v-slot:append>
                      <h4 class="mt-1 grey--text">KD</h4>
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-divider class="mb-4"> </v-divider>

              <v-layout class="mt-4 mt-sm-0" wrap>
                <v-flex xs12 sm2 md5>
                  <h4 class="text-body-2 black--text">
                    Maximum Redeem percent
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md6 class="mt-5 mt-sm-0 mt-md-0">
                  <v-text-field label="Maximum Redeem percent" v-model="maxRedeemPercent" type="number" dense min="0"
                    append-icon="mdi-percent" :rules="[requiredValidator('Maximum redeem percent')]" solo>
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout class="mt-4 mt-sm-0" wrap>
                <v-flex xs12 sm2 md5>
                  <h4 class="text-body-2 black--text">Maximum Redeem amount</h4>
                </v-flex>
                <v-flex xs12 sm8 md6 class="mt-5 mt-sm-0 mt-md-0">
                  <v-text-field label="Maximum Redeem amount" v-model="maxRedeemAmount" type="number" dense min="0"
                    append-icon="mdi-percent" :rules="[requiredValidator('Maximum redeem amount')]" solo>
                    <template v-slot:append>
                      <h4 class="mt-1 grey--text">KD</h4>
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n6">
            <v-flex xs12 sm12 md10 lg9 class="ms-md-16 mb-2 pe-2">
              <v-row justify="center">
                <v-btn color="secondary" :width="$vuetify.breakpoint.smAndUp ? 150 : 100"
                  class="white--text text-capitalize font-weight-bold" :loading="btnConfigloading" :disabled="!valid"
                  @click="updateConfigurations">Update</v-btn>
              </v-row>
            </v-flex>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">{{ text }}</v-snackbar>
  </div>
</template>


<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {},
  data() {
    return {
      requiredValidator: requiredValidator,
      maxRedeemAmount: "",
      maxRedeemPercent: "",
      btnConfigloading: false,
      maxReferralAmount: 0,
      percentage: 0,
      snackbarColor: "success",
      successAlert: false,
      snackbar: false,
      text: "",
      valid: true,
      dialog: false,
      configs: {},
    };
  },
  apollo: {
    getAppConfigurations() {
      return {
        query: require("@/settings/api/getAppConfigurations.graphql"),
        result({ data }) {
          if (data.getAppConfigurations) {
            this.configs = data.getAppConfigurations;
            this.maxReferralAmount =
              data.getAppConfigurations.customerReferralPercent.maxReferralAmount;
            this.percentage =
              data.getAppConfigurations.customerReferralPercent.percentage;
            this.maxRedeemPercent =
              data.getAppConfigurations.customerReferralPercent.maxRedeemPercent;
            this.maxRedeemAmount =
              data.getAppConfigurations.customerReferralPercent.maxRedeemAmount;
          }
        },
      };
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    updateConfigurations() {
      this.btnConfigloading = true;
      let customerReferralPercent = {
        maxReferralAmount: parseFloat(this.maxReferralAmount),
        percentage: parseFloat(this.percentage),
        maxRedeemPercent: parseFloat(this.maxRedeemPercent),
        maxRedeemAmount: parseFloat(this.maxRedeemAmount),
      }
      this.$apollo
        .mutate({
          mutation: require("@/settings/api/updateConfigurations.graphql"),
          variables: {
            whatsappNumber: this.configs.whatsappNumber,
            whatsappMessage: this.configs.whatsappMessage,
            customerReferralPercent: customerReferralPercent
          },
        })
        .then(() => {
          this.text = "Referral Settings successfully updated";
          this.snackbar = true;
          this.btnConfigloading = false;
          this.snackbarColor = "success";
          this.dialog = false;
        })
        .catch((error) => {
          if (error.graphQLErrors[0] != null) {
            this.text = error.graphQLErrors[0].message;
            this.snackbarColor = "error";
            this.snackbar = true;
          }
          this.btnConfigloading = false;
          console.log(error);
        });
    },
  },
};
</script>


<style>
.update_btn {
  border: 2px solid #f8166c;
}
</style>