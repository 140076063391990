
<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="red">mdi-delete</v-icon>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text"
          >Do you really want to delete this Celebrity?</v-card-text
        >

        <v-card-text v-if="error" class="error--text font-weight-bold">{{
          error
        }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 success"
              depressed
              @click="deleteCelebrity(celebrity._id)"
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>
<script>
export default {
  props: {
    celebrity: {
      required: true,
    },
    skip: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 25,
    },
  },
  data() {
    return {
      valid: true,
      dialog: false,
      show_alert: false,
      error: null,
      snackbar: false,
      text: "",
    };
  },
  methods: {
    deleteCelebrity(id) {
      this.$apollo
        .mutate({
          mutation: require("../api/deleteCelebrity.graphql"),
          variables: {
            id,
          },
        })
        .then(() => {
          this.$root.$emit("updateCelebrity");
          this.error = null;
          this.dialog = false;
          this.snackbar = true;
          this.text = "Celebrity deleted";
        })
        .catch((error) => {
          // Error
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    },
  },
};
</script>