<template>
  <div>
    <v-navigation-drawer app fixed v-model="drawerState" dense color="primary">
      <v-row justify="end" v-if="$vuetify.breakpoint.mdAndDown" class="pe-5">
        <v-icon class="mt-5" @click="drawerState = false" color="black">mdi-close</v-icon>
      </v-row>
      <v-img
        :src="require('../../assets/logo_svg.png')"
        contain
        position="left"
        height="120"
        class="mx-4 mt-6"
      />
      <!-- <v-list v-if="showDieticianItems" class="black--text mt-lg-10 pb-lg-16">
        <div class="mt-10 mt-lg-0">
          <v-list-item
            v-for="(dietitian, index) in dieticianItem" 
            :key="index"
            router
            :to="dietitian.route"
            color="primary"
            dense
          >
            <v-list-item-icon>
              <font-awesome-icon
                class="secondary--text font-weight-bold mt-1"
                :icon="['fas', dietitian.icon]"
              ></font-awesome-icon>
            </v-list-item-icon>

            <v-list-item-content class="ms-n5">
              <v-list-item-title class="secondary--text font-weight-bold">{{
                dietitian.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>   -->
      <!-- <v-list v-if="showDieticianItems" class="black--text mt-lg-10 pb-lg-16">
        <div class="mt-10 mt-lg-0">
          <v-list-item
            :key="dieticianItem.title" 
            router
            :to="dieticianItem.route"
            color="primary"
            dense
          >
            <v-list-item-icon>
              <font-awesome-icon
                class="secondary--text font-weight-bold mt-1"
                :icon="['fas', dieticianItem.icon]"
              ></font-awesome-icon>
            </v-list-item-icon>

            <v-list-item-content class="ms-n5">
              <v-list-item-title class="secondary--text font-weight-bold">{{
                dieticianItem.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list> -->
      <!-- <v-list v-else class="mt-lg-10 pb-lg-16 pe-5" dense> -->

      <v-list class="mt-lg-10 pb-lg-16 pe-5" dense>
        <div class="mt-10 mt-lg-0">
          <div v-for="item in accessableItems[tab]" :key="item.title">
            <v-list-group
              class="mt-n1"
              v-if="
                item.text == 'Customers' ||
                item.text == 'Users' ||
                item.text == 'Tags' ||
                item.text == 'E-Clinic' ||
                item.text == 'Sales Order'
              "
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <!-- <v-icon class="black--text font-weight-bold">{{
                    item.icon
                  }}</v-icon> -->
                  <font-awesome-icon
                    class="white--text font-weight-bold mt-1"
                    v-bind:class="{
                      'black--text': $route.path === item.route,
                    }"
                    :icon="['fas', item.icon]"
                  ></font-awesome-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="white--text font-weight-bold"
                    v-bind:class="{
                      'black--text': $route.path === item.route,
                    }"
                    >{{ item.text }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="obj in item.elem"
                :key="obj.text"
                :to="obj.route"
                dense
                color="white"
              >
                <v-list-item-icon>
                  <font-awesome-icon
                    class="ms-4 white--text font-weight-bold mt-1"
                    v-bind:class="{
                      'black--text': $route.path === obj.route,
                    }"
                    :icon="['fas', obj.icon]"
                  ></font-awesome-icon>
                </v-list-item-icon>
                <v-list-item-title
                  class="white--text font-weight-bold ms-n2"
                  v-bind:class="{
                    'black--text': $route.path === obj.route,
                  }"
                  >{{ obj.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              router
              :to="item.route"
              color="white "
              class="rounded-xl"
              dense
            >
              <v-list-item-icon>
                <!-- <v-icon
                  class="black--text font-weight-bold" 
                  v-bind:class="{
                    'secondary--text': $route.path === item.route,
                  }"
                  >{{ item.icon }}</v-icon
                > -->
                <v-img
                  v-if="item.text == 'Dashboard'"
                  :src="require('../../assets/dashboard-icon-white.svg')"
                  contain
                  class="mt-1 ms-n1"
                  height="18"
                />
                <v-img
                  v-if="$route.path === '/dashboard' && item.text == 'Dashboard'"
                  :src="require('../../assets/dashboard-icon.svg')"
                  contain
                  class="mt-1 ms-n6"
                  height="18"
                />
                <v-img
                  v-if="item.text == 'Offers'"
                  :src="require('../../assets/offers-icon.svg')"
                  contain
                  class="mt-1 ms-n1"
                  height="18"
                />
                <v-img
                  v-if="$route.path === '/offers' && item.text == 'Offers'"
                  :src="require('../../assets/offers-icon-change.svg')"
                  contain
                  class="mt-1 ms-n6"
                  height="18"
                />
                <font-awesome-icon
                  v-if="item.text != 'Dashboard' && item.text != 'Offers'"
                  class="white--text font-weight-bold mt-1"
                  v-bind:class="{
                    'black--text': $route.path === item.route,
                  }"
                  :icon="['fas', item.icon]"
                ></font-awesome-icon>
              </v-list-item-icon>

              <v-list-item-content class="ms-n4v">
                <v-list-item-title
                  class="white--text font-weight-bold"
                  v-bind:class="{
                    'black--text ': $route.path === item.route,
                  }"
                  >{{ item.text }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
      <div class="mt-8 mt-lg-16">
        <signout-button class="pt-lg-16 mb-lg-16" />
      </div>
    </v-navigation-drawer>
    <v-app-bar app class="white">
      <v-app-bar-nav-icon
        @click="toggleDrawer()"
        class="secondary--text"
      ></v-app-bar-nav-icon>

      <!-- <v-img
        :src="require('../../assets/logo.jpg')"
        contain
        height="28"
        :position="!$vuetify.rtl ? 'left' : 'right'"
      /> -->
      <!-- <v-tabs
        v-model="tab"
        align-with-title
        class="white"
        v-show="$vuetify.breakpoint.smAndUp"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-if="showSalesItems"
          :key="tabItems[0].text"
          class="font-weight-bold text-body-2"
          v-bind:class="{
            'secondary--text': $route.path === tabItems[0].route,
            'primary--text': $route.path !== tabItems[0].route,
          }"
          @click="tab = tabItems[0].tabindex"
          >{{ showDieticianItems ? "Dietitian" : tabItems[0].text }}</v-tab
        >
        <v-tab
          v-if="showOperationItems"
          :key="tabItems[1].text"
          class="font-weight-bold text-body-2"
          v-bind:class="{
            'secondary--text': $route.path === tabItems[1].route,
            'primary--text': $route.path !== tabItems[1].route,
          }"
          @click="tab = tabItems[1].tabindex"
          >{{ tabItems[1].text }}</v-tab
        >
        <v-tab
          v-if="showReportItems"
          :key="tabItems[2].text"
          class="font-weight-bold text-body-2"
          v-bind:class="{
            'secondary--text': $route.path === tabItems[2].route,
            'primary--text': $route.path !== tabItems[2].route,
          }"
          @click="tab = tabItems[2].tabindex"
          >{{ tabItems[2].text }}</v-tab
        >
      </v-tabs> -->

      <v-btn-toggle
        group
        color="white"
        v-model="tab"
        mandatory
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-btn
          :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
          v-if="showSalesItems"
          :key="tabItems[0].text"
          class="font-weight-bold text-body-2 text-capitalize rounded-lg"
          @click="tab = tabItems[0].tabindex"
          >{{ tabItems[0].text }}</v-btn
        >
        <v-btn
          :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
          v-if="showOperationItems"
          :key="tabItems[1].text"
          class="font-weight-bold text-body-2 text-capitalize rounded-lg"
          @click="tab = tabItems[1].tabindex"
          >{{ tabItems[1].text }}</v-btn
        >
        <v-btn
          :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
          v-if="showReportItems"
          :key="tabItems[2].text"
          class="font-weight-bold text-body-2 text-capitalize rounded-lg"
          @click="tab = tabItems[2].tabindex"
          >{{ tabItems[2].text }}</v-btn
        >
        <v-btn
          :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
          v-if="showSettingsItems"
          :key="tabItems[3].text"
          class="font-weight-bold text-body-2 text-capitalize rounded-lg"
          @click="tab = tabItems[3].tabindex"
          >{{ tabItems[3].text }}</v-btn
        >
        <v-btn
          :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
          v-if="showDieticianItems"
          :key="tabItems[4].text"
          class="font-weight-bold text-body-2 text-capitalize rounded-lg"
          @click="tab = tabItems[4].tabindex"
          >{{ tabItems[4].text }}</v-btn
        >
      </v-btn-toggle>
      <v-spacer></v-spacer>

      <UserAvatar />
      <div v-if="role" class="ms-3">
        <span class="me-lg-4 font-weight-medium black--text">{{ role }} </span>
      </div>
      <div v-if="firstName || lastName" class="ms-3">
        <span class="me-lg-4 font-weight-medium black--text text-capitalize"
          >{{ firstName }} {{ lastName }}
        </span>
      </div>

      <template v-slot:extension v-if="$vuetify.breakpoint.xs">
        <v-row justify="center">
          <v-btn-toggle tile group color="dashboard" v-model="tab" mandatory>
            <v-btn
              :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
              v-if="showSalesItems"
              :key="tabItems[0].text"
              class="font-weight-bold text-body-2 text-capitalize"
              @click="tab = tabItems[0].tabindex"
              >{{ tabItems[0].text }}</v-btn
            >
            <v-btn
              :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
              v-if="showOperationItems"
              :key="tabItems[1].text"
              class="font-weight-bold text-body-2 text-capitalize"
              @click="tab = tabItems[1].tabindex"
              >{{ tabItems[1].text }}</v-btn
            >
            <v-btn
              :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
              v-if="showReportItems"
              :key="tabItems[2].text"
              class="font-weight-bold text-body-2 text-capitalize"
              @click="tab = tabItems[2].tabindex"
              >{{ tabItems[2].text }}</v-btn
            >
            <v-btn
              :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
              v-if="showSettingsItems"
              :key="tabItems[3].text"
              class="font-weight-bold text-body-2 text-capitalize"
              @click="tab = tabItems[3].tabindex"
              >{{ tabItems[3].text }}</v-btn
            >
            <v-btn
              :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
              v-if="showDieticianItems"
              :key="tabItems[4].text"
              class="font-weight-bold text-body-2 text-capitalize"
              @click="tab = tabItems[4].tabindex"
              >{{ tabItems[4].text }}</v-btn
            >
          </v-btn-toggle>
        </v-row>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import SignOutButton from "../../user/components/SignOutButton";
import UserAvatar from "../../user/components/UserAvatar";
import { isAdmin, isManager, isDietitian } from "../../user/methods/helper.js";
export default {
  components: {
    "signout-button": SignOutButton,
    UserAvatar,
  },

  created() {
    this.tab = this.items.findIndex((item) => item.route === this.$route.path);
    if (isAdmin()) {
      this.role = "Admin";
      this.showSalesItems = true;
      this.showOperationItems = true;
      this.showReportItems = true;
      this.showDieticianItems = false;
      this.showSettingsItems = true;
      this.accessableItems = this.items;
    } else if (isManager() || isDietitian()) {
      this.showDieticianItems = false;
      let data = JSON.parse(localStorage.getItem("DONE_USER"));
      this.firstName = data.firstnameEn;
      this.lastName = data.lastnameEn;
      //#region Sales
      if (this.hasRole(this.AdminUserPermissions.DASHBOARD_VIEW)) {
        this.showSalesItems = true;
        const data = this.items[0].find((x) => x.text == "Dashboard");
        this.accessableItems[0].push(data);
      }

      // if (this.hasRole(this.AdminUserPermissions.CUSTOMER_VIEW)) {
      //   this.showSalesItems = true;
      //   const data = this.items[0].find(x => x.text == "Customers");
      //   this.accessableItems[0].push(data);
      // }
      if (
        this.hasRole(this.AdminUserPermissions.CUSTOMER_VIEW) ||
        this.hasRole(this.AdminUserPermissions.IN_ACTIVE_CUSTOMER_VIEW)
      ) {
        this.showSalesItems = true;
        if (this.hasRole(this.AdminUserPermissions.CUSTOMER_VIEW)) {
          var activeCustomerOrderVal = this.items[0]
            .filter((x) => x.text == "Customers")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "All Customers")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.IN_ACTIVE_CUSTOMER_VIEW)) {
          var inActiveCustomerOrderVal = this.items[0]
            .filter((x) => x.text == "Customers")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Inactive Customers")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.CUSTOMER_WITHOUT_MEAL_VIEW)) {
          var customerWithoutMeal = this.items[0]
            .filter((x) => x.text == "Customers")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Customers Without Meal")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.CUSTOMER_BIRTHDAY_REPORT_VIEW)) {
          var customerBirthdayReport = this.items[0]
            .filter((x) => x.text == "Customers")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Customers Birthday Report")[0];
        }

        const data = this.items[0].filter((x) => x.text == "Customers")[0];
        data.elem = [];
        if (this.hasRole(this.AdminUserPermissions.CUSTOMER_VIEW)) {
          data.elem.push(activeCustomerOrderVal);
        }
        if (this.hasRole(this.AdminUserPermissions.IN_ACTIVE_CUSTOMER_VIEW)) {
          data.elem.push(inActiveCustomerOrderVal);
        }
        if (this.hasRole(this.AdminUserPermissions.CUSTOMER_WITHOUT_MEAL_VIEW)) {
          data.elem.push(customerWithoutMeal);
        }
        if (this.hasRole(this.AdminUserPermissions.CUSTOMER_BIRTHDAY_REPORT_VIEW)) {
          data.elem.push(customerBirthdayReport);
        }
        this.accessableItems[0].push(data);
      }

      // if (this.hasRole(this.AdminUserPermissions.SALES_ORDER_VIEW)) {
      //   this.showSalesItems = true;
      //   const data = this.items[0].find((x) => x.text == "Sales Order");
      //   this.accessableItems[0].push(data);
      // }
      if (
        this.hasRole(this.AdminUserPermissions.SALES_ORDER_VIEW) ||
        this.hasRole(this.AdminUserPermissions.SALES_ORDER_DISCOUNT) ||
        this.hasRole(this.AdminUserPermissions.SALES_ORDER_DAY_WISE_VIEW) ||
        this.hasRole(this.AdminUserPermissions.INACTIVE_SALES_ORDER_VIEW) ||
        this.hasRole(this.AdminUserPermissions.INACTIVE_SALES_ORDER_COUNT_VIEW) ||
        this.hasRole(this.AdminUserPermissions.END_DATE_REMINDER_VIEW)
      ) {
        this.showSalesItems = true;
        if (this.hasRole(this.AdminUserPermissions.SALES_ORDER_VIEW)) {
          var salesOrderVal = this.items[0]
            .filter((x) => x.text == "Sales Order")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Sales Order")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.INACTIVE_SALES_ORDER_VIEW)) {
          var inactiveSalesOrderVal = this.items[0]
            .filter((x) => x.text == "Sales Order")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Inactive Sales Order")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.INACTIVE_SALES_ORDER_COUNT_VIEW)) {
          var inactiveSalesOrderValCount = this.items[0]
            .filter((x) => x.text == "Sales Order")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Inactive Sales Order Count")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.SALES_ORDER_DAY_WISE_VIEW)) {
          var salesOrderDayVal = this.items[0]
            .filter((x) => x.text == "Sales Order")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Sales Order Day Wise")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.SALES_ORDER_DISCOUNT)) {
          var salesOrderDiscount = this.items[0]
            .filter((x) => x.text == "Sales Order")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Sales Order Discount")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.END_DATE_REMINDER_VIEW)) {
          var endDateReminder = this.items[0]
            .filter((x) => x.text == "Sales Order")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "End Date Reminder")[0];
        }

        const data = this.items[0].filter((x) => x.text == "Sales Order")[0];
        data.elem = [];
        if (this.hasRole(this.AdminUserPermissions.SALES_ORDER_VIEW)) {
          data.elem.push(salesOrderVal);
        }
        if (this.hasRole(this.AdminUserPermissions.SALES_ORDER_DAY_WISE_VIEW)) {
          data.elem.push(salesOrderDayVal);
        }
        if (this.hasRole(this.AdminUserPermissions.SALES_ORDER_DISCOUNT)) {
          data.elem.push(salesOrderDiscount);
        }
        if (this.hasRole(this.AdminUserPermissions.END_DATE_REMINDER_VIEW)) {
          data.elem.push(endDateReminder);
        }
        if (this.hasRole(this.AdminUserPermissions.INACTIVE_SALES_ORDER_VIEW)) {
          data.elem.push(inactiveSalesOrderVal);
        }
        if (this.hasRole(this.AdminUserPermissions.INACTIVE_SALES_ORDER_COUNT_VIEW)) {
          data.elem.push(inactiveSalesOrderValCount);
        }

        this.accessableItems[0].push(data);
      }

      if (this.hasRole(this.AdminUserPermissions.MEALS_RATING_VIEW)) {
        this.showSalesItems = true;
        const data = this.items[0].find((x) => x.text == "Meals Rating");
        this.accessableItems[0].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.PAYMENT_VIEW)) {
        this.showSalesItems = true;
        const data = this.items[0].find((x) => x.text == "Payment");
        this.accessableItems[0].push(data);
      }

      if (this.hasRole(this.AdminUserPermissions.PUSH_NOTIFICATION_VIEW)) {
        this.showSalesItems = true;
        const data = this.items[0].find((x) => x.text == "Push Notification");
        this.accessableItems[0].push(data);
      }

      // if (this.hasRole(this.AdminUserPermissions.DIETITIAN_APPOINTMENT_VIEW)) {
      //   this.showSalesItems = true;
      //   const data = this.items[0].find(x => x.text == "Dietitian Appointment List");
      //   this.accessableItems[0].push(data);
      // }
      //#endregion
      //#region Operations
      let x = null;
      this.showSalesItems ? (x = 1) : (x = 0);

      if (
        this.hasRole(this.AdminUserPermissions.STOCK_VIEW) ||
        this.hasRole(this.AdminUserPermissions.PURCHASE_VIEW) ||
        this.hasRole(this.AdminUserPermissions.ADJUST_STOCK_VIEW) ||
        this.hasRole(this.AdminUserPermissions.MASTER_DATA_VIEW) ||
        this.hasRole(this.AdminUserPermissions.MONTHLY_USAGE_VIEW) ||
        this.hasRole(this.AdminUserPermissions.MONTHLY_PURCHASE_VIEW)
      ) {
        this.showOperationItems = true;

        const data = this.items[1].find((x) => x.text == "Stocks");
        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.PLAN_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Plans");
        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.MEAL_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Meals");
        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.MEALS_CATEGORY_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Meals Category");
        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.DIET_PLAN_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Diet Plan");
        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.DIETITIAN_APPOINTMENT_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Dietician Appointments");
        this.accessableItems[x].push(data);
      }
      // if (this.hasRole(this.AdminUserPermissions.UNIVERSAL_PAUSE_VIEW)) {
      //   this.showOperationItems = true;
      //   const data = this.items[1].find((x) => x.text == "Universal Pause");
      //   this.accessableItems[x].push(data);
      // }
      // if (this.hasRole(this.AdminUserPermissions.PLAN_SWITCH_VIEW)) {
      //   this.showOperationItems = true;
      //   const data = this.items[1].find((x) => x.text == "Plan Switch");
      //   this.accessableItems[x].push(data);
      // }

      if (
        this.hasRole(this.AdminUserPermissions.MANAGER_VIEW) ||
        this.hasRole(this.AdminUserPermissions.DRIVER_VIEW) ||
        this.hasRole(this.AdminUserPermissions.DIETITIAN_VIEW)
      ) {
        this.showOperationItems = true;
        if (this.hasRole(this.AdminUserPermissions.MANAGER_VIEW)) {
          var managerVal = this.items[1]
            .filter((x) => x.text == "Users")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Staff")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.DRIVER_VIEW)) {
          var driverVal = this.items[1]
            .filter((x) => x.text == "Users")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Drivers")[0];
          console.log(driverVal);
        }
        if (this.hasRole(this.AdminUserPermissions.DIETITIAN_VIEW)) {
          var dietitianVal = this.items[1]
            .filter((x) => x.text == "Users")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Dietitian")[0];
        }

        const data = this.items[1].filter((x) => x.text == "Users")[0];
        data.elem = [];
        if (this.hasRole(this.AdminUserPermissions.MANAGER_VIEW)) {
          data.elem.push(managerVal);
        }

        if (this.hasRole(this.AdminUserPermissions.DRIVER_VIEW)) {
          data.elem.push(driverVal);
        }
        if (this.hasRole(this.AdminUserPermissions.DIETITIAN_VIEW)) {
          data.elem.push(dietitianVal);
        }

        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.LOCATION_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Location");
        this.accessableItems[x].push(data);
      }
      if (
        this.hasRole(this.AdminUserPermissions.CUSTOMER_TAG_VIEW) ||
        this.hasRole(this.AdminUserPermissions.MEAL_TAG_VIEW)
      ) {
        this.showOperationItems = true;
        if (this.hasRole(this.AdminUserPermissions.CUSTOMER_TAG_VIEW)) {
          var cutomerTagVal = this.items[1]
            .filter((x) => x.text == "Tags")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Customer Tags")[0];
        }
        if (this.hasRole(this.AdminUserPermissions.MEAL_TAG_VIEW)) {
          var mealTagVal = this.items[1]
            .filter((x) => x.text == "Tags")
            .flatMap((x) => x.elem)
            .filter((x) => x.text == "Meal Tags")[0];
        }

        const data = this.items[1].filter((x) => x.text == "Tags")[0];
        data.elem = [];
        if (this.hasRole(this.AdminUserPermissions.CUSTOMER_TAG_VIEW)) {
          data.elem.push(cutomerTagVal);
        }
        if (this.hasRole(this.AdminUserPermissions.MEAL_TAG_VIEW)) {
          data.elem.push(mealTagVal);
        }

        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.REFERRAL_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Referrals");
        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.FAQ_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Faq");
        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.CONTACT_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Contact Method");
        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.PROMO_CODE_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Promo Code");
        this.accessableItems[x].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.OFFER_VIEW)) {
        this.showOperationItems = true;
        const data = this.items[1].find((x) => x.text == "Offers");
        this.accessableItems[x].push(data);
      }

      //#endregion
      //#region Report
      debugger;
      let y = null;
      if (x != null && this.showOperationItems) {
        y = x + 1;
      }
      if (x != null && !this.showOperationItems) {
        y = x;
      }
      if (
        this.hasRole(this.AdminUserPermissions.ORDER_ITEM_VIEW) ||
        this.hasRole(this.AdminUserPermissions.INGREDIENT_VIEW)
      ) {
        this.showReportItems = true;
        const data = this.items[2].find((x) => x.text == "Orders");
        this.accessableItems[y].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.DELIVERY_REPORT_VIEW)) {
        this.showReportItems = true;
        const data = this.items[2].find((x) => x.text == "Delivery");
        this.accessableItems[y].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.SALES_REPORT_VIEW)) {
        this.showReportItems = true;
        const data = this.items[2].find((x) => x.text == "Sales");
        this.accessableItems[y].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.DRIVER_REPORT_VIEW)) {
        this.showReportItems = true;
        const data = this.items[2].find((x) => x.text == "Driver");
        this.accessableItems[y].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.PROMO_CODE_REPORT_VIEW)) {
        this.showReportItems = true;
        const data = this.items[2].find((x) => x.text == "Promo Code");
        this.accessableItems[y].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.PAUSED_MEALS_REPORT_VIEW)) {
        this.showReportItems = true;
        const data = this.items[2].find((x) => x.text == "Paused Meals");
        this.accessableItems[y].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.MONTHLY_SALES_VIEW)) {
        this.showReportItems = true;
        const data = this.items[2].find((x) => x.text == "Monthly Sales");
        this.accessableItems[y].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.DIETITIAN_APPOINTMENTS_REPORT)) {
        this.showReportItems = true;
        const data = this.items[2].find((x) => x.text == "Dietitian Report");
        this.accessableItems[y].push(data);
      }
      if (this.hasRole(this.AdminUserPermissions.EXPORT_STATUS_REPORT)) {
        this.showReportItems = true;
        const data = this.items[2].find((x) => x.text == "Report Status");
        this.accessableItems[y].push(data);
      }
      let z = null;
      if (y != null && this.showReportItems) {
        z = y + 1;
      }
      if (y != null && !this.showReportItems) {
        z = y;
      }

      if (this.hasRole(this.AdminUserPermissions.SETTINGS_VIEW)) {
        this.showSettingsItems = true;
        const data = this.items[3];
        this.accessableItems[z].push(...data);
      }
      let d = null;
      if (z != null && this.showSettingsItems) {
        d = z + 1;
      }
      if (z != null && !this.showSettingsItems) {
        d = z;
      }
      if (isDietitian()) {
        this.showDieticianItems = true;
        const data = this.items[4];
        this.accessableItems[d].push(...data);
      }
    }
    // else if (isDietitian()) {
    //   this.role = "Dietitian";

    //   this.showSalesItems = true;
    //   this.showOperationItems = false;
    //   this.showReportItems = false;
    //   this.showDieticianItems = true;
    //   this.showSettingsItems = false;
    // }
    else {
      this.showSalesItems = false;
      this.showOperationItems = false;
      this.showReportItems = false;
      this.showDieticianItems = false;
      this.showSettingsItems = false;
    }
    console.log(this.accessableItems);
  },
  methods: {
    toggleDrawer: function () {
      this.drawerState = !this.drawerState;
    },
  },
  data() {
    return {
      drawerState: null,
      role: "",
      showSalesItems: false,
      showOperationItems: false,
      showReportItems: false,
      showDieticianItems: false,
      showSettingsItems: false,
      tab: 0,
      firstName: "",
      lastName: "",
      tabItems: [
        { text: "SALES", icon: "mdi-view-dashboard", tabindex: 0 },
        { text: "OPERATION", icon: "mdi-scooter", tabindex: 1 },
        { text: "REPORTS", icon: "mdi-account-circle", tabindex: 2 },
        { text: "SETTINGS", icon: "mdi-settings", tabindex: 3 },
        { text: "DIETITIAN", icon: "mdi-settings", tabindex: 4 },
      ],
      dieticianItem: {
        text: "Appointments",
        icon: "calendar-alt",
        route: "/appointments",
      },
      accessableItems: [[], [], [], [], []],
      items: [
        [
          {
            text: "Dashboard",
            icon: "chart-bar",
            route: "/dashboard",
          },

          // {
          //   text: "Customers",
          //   icon: "user",
          //   route: "/customers"
          // },
          {
            text: "Customers",
            icon: "user",
            elem: [
              {
                text: "All Customers",
                icon: "user",
                route: "/customers",
              },
              {
                text: "Inactive Customers",
                icon: "user",
                route: "/inactive-customers",
              },
              {
                text: "Customers Without Meal",
                icon: "user",
                route: "/customers-with-no-meals",
              },
              {
                text: "Customers Birthday Report",
                icon: "user",
                route: "/customers-birthday-report",
              },
            ],
          },
          // {
          //   text: "Sales Order",
          //   icon: "file-alt",
          //   route: "/salesorder",
          // },
          {
            text: "Sales Order",
            icon: "file-alt",
            elem: [
              {
                text: "Sales Order",
                icon: "file-alt",
                route: "/salesorder",
              },
              {
                text: "Inactive Sales Order",
                icon: "file-alt",
                route: "/inactivesalesorder",
              },
              {
                text: "Inactive Sales Order Count",
                icon: "file-alt",
                route: "/inactivesalesordercount",
              },
              {
                text: "Sales Order Day Wise",
                icon: "tags",
                route: "/salesorderdaywise",
              },
              {
                text: "Sales Order Discount",
                icon: "tags",
                route: "/sales-order-discount",
              },
              {
                text: "End Date Reminder",
                icon: "tags",
                route: "/end-date-reminder",
              },
            ],
          },
          {
            text: "Payment",
            icon: "credit-card",
            route: "/accounts",
          },
          {
            text: "Meals Rating",
            icon: "star-half-alt",
            route: "/rating",
          },
          {
            text: "Push Notification",
            icon: "bell",
            route: "/notifications",
          },
          {
            text: "Schedule Notification",
            icon: "bell",
            route: "/schedule-notification",
          },
          // {
          //   text: "Dietitian Appointment",
          //   icon: "user-md",
          //   route: "/CustomerDietitianAppt"
          // },
          // {
          //   text: "Dietitian Appointment List",
          //   icon: "user-md",
          //   route: "/dietitian-appointment-list"
          // }
        ],
        //operation
        [
          {
            text: "Stocks",
            icon: "archive",
            route: "/stock/master_data",
          },
          { text: "Plans", icon: "box", route: "/plans" },
          {
            text: "Meals",
            icon: "utensils",
            route: "/menuitems",
          },
          {
            text: "Meals Category",
            icon: "boxes",
            route: "/menucategory",
          },
          {
            text: "Diet Plan",
            icon: "shopping-basket",
            route: "/dietplan",
          },
          {
            text: "Dietician Appointments",
            icon: "user-md",
            route: "/dietician_slot_appointment",
          },
          // {
          //   text: "Universal Pause",
          //   icon: "pause",
          //   route: "/universal-pause"
          // },
          // {
          //   text: "Plan Switch",
          //   icon: "retweet",
          //   route: "/plan-switch",
          // },
          {
            text: "Users",
            icon: "users",
            route: "/user",
            elem: [
              {
                text: "Staff",
                icon: "briefcase",
                route: "/managers",
              },
              {
                text: "Drivers",
                icon: "car",
                route: "/drivers",
              },
              {
                text: "Dietitian",
                icon: "user-md",
                route: "/dieticians",
              },
            ],
          },

          {
            text: "Location",
            icon: "map-marker-alt",
            route: "/shifts",
          },

          {
            text: "Tags",
            icon: "tags",
            elem: [
              {
                text: "Customer Tags",
                icon: "user-tag",
                route: "/customertags",
              },
              {
                text: "Meal Tags",
                icon: "tags",
                route: "/mealtags",
              },
            ],
          },
          {
            text: "Referrals",
            icon: "wallet",
            route: "/referrals",
          },
          {
            text: "Faq",
            icon: "question",
            route: "/faq",
          },

          {
            text: "Contact Method",
            icon: "user-cog",
            route: "/contact-method",
          },
          {
            text: "Promo Code",
            icon: "percentage",
            route: "/promocodes",
          },
          {
            text: "Offers",
            icon: "box-open",
            route: "/offers",
          },
        ],
        //sales
        [
          { text: "Orders", icon: "shopping-bag", route: "/orders" },
          { text: "Delivery", icon: "truck", route: "/delivery" },
          { text: "Sales", icon: "chart-line", route: "/sales" },
          { text: "Driver", icon: "car", route: "/driver-report" },
          {
            text: "Promo Code",
            icon: "percentage",
            route: "/promocode-report",
          },
          {
            text: "Paused Meals",
            icon: "user",
            route: "/pausedmeals-report",
          },
          {
            text: "Monthly Sales",
            icon: "file-alt",
            route: "/monthly-customer-sales",
          },
          {
            text: "Dietitian Report",
            icon: "user-md",
            route: "/dietician-appointment-report",
          },
          {
            text: "Report Status",
            icon: "file-excel",
            route: "/report-status",
          },
        ],
        // settings
        [
          { text: "Profile", icon: "user-cog", route: "/profile-settings" },
          {
            text: "Configurations",
            icon: "cog",
            route: "/configurations-settings",
          },
          {
            text: "Terms and conditions",
            icon: "file-signature",
            route: "/terms-and-conditions-settings",
          },
          {
            text: "Tutorials",
            icon: "puzzle-piece",
            route: "/tutorials-settings",
          },
        ],
        [
          {
            text: "Appointments",
            icon: "calendar-alt",
            route: "/appointments",
          },
        ],
      ],
    };
  },
};
</script>
<style>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 1 !important;
  color: #ff5800 !important;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 1 !important;
  border-radius: 15px;
}

.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  z-index: 1 !important;
}

svg:not(:root).svg-inline--fa {
  z-index: 1 !important;
}
</style>
<style scoped>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 1 !important;
}

.pp {
  font-size: 0.8125rem !important;
  font-weight: 900 !important;
}
</style>
