<template>
  <v-container class="bg">
    <v-card class="pa-3" v-if="selectedPackage" color="pop_bg">
      <!-- <v-container> -->
      <!-- <h3 class="primary--text">Enter Meal Details</h3> -->
      <v-layout wrap>
        <v-flex xs12 sm3 md6>
          <label v-bind:class="getPackageTypeClass()">
            {{ getPackageStatus() }}</label
          >
        </v-flex>
        <!-- <v-flex xs6 md1> </v-flex> -->

        <v-flex xs6 sm3 md2 class="mt-md-0 ">
          <router-link
            :to="{
              name: 'CustomerDetails',
              params: { customerId: customer._id },
              query: { customerId: customer._id }
            }"
            class="mx-auto"
          >
            <v-btn
              color="btncolor"
              icon
              class="mb-2 mb-sm-4 text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            >
              <v-icon>mdi-arrow-left-bold</v-icon>Back
            </v-btn>
          </router-link>
        </v-flex>
      </v-layout>
      <v-divider class="mb-6"></v-divider>
      <v-layout wrap>
        <v-flex xs12 sm3 md2>
          <label class="black--text text-body-2">Available Days</label>
        </v-flex>
        <!-- <v-flex xs6 md1> </v-flex> -->
        <v-flex xs12 sm9 md10>
          {{ getAvailableDays() }}
        </v-flex>
      </v-layout>
      <v-divider class="mb-6"></v-divider>

      <v-layout wrap>
        <v-flex xs5 sm2 md2>
          <label class="black--text text-body-2">Start date</label>
        </v-flex>
        <v-flex xs6 sm3 md2 class="mt-md-0">
          {{
            new Date(parseInt(selectedPackage.start_date))
              | moment("MMM DD YYYY")
          }}
        </v-flex>
        <v-flex xs5 sm2 md2 class="ms-sm-16">
          <label class="text-body-2 black--text">End date</label>
        </v-flex>
        <v-flex xs6 sm3 md4 class="mt-md-0">
          {{
            new Date(parseInt(selectedPackage.end_date)) | moment("MMM DD YYYY")
          }}
        </v-flex>

        <v-flex xs5 sm2 md2 class="mt-3 mt-sm-0">
          <label class="text-body-2 black--text">Protien</label>
        </v-flex>
        <v-flex xs6 sm3 md2 class="mt-3 mt-sm-0">
          {{ selectedPackage.package.meanInfo.protein }}
        </v-flex>

        <v-flex xs5 sm2 md2 class="ms-sm-16">
          <label class="text-body-2 black--text">Carb</label>
        </v-flex>
        <v-flex xs6 sm3 md2>
          {{ selectedPackage.package.meanInfo.carbs }}
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex xs5 sm2 md2 class="mt-3 mt-sm-0">
          <label class="text-body-2 black--text">Subscribed Days</label>
        </v-flex>
        <v-flex xs6 sm3 md2>
          <h4 class="text-body-2">
            {{ getSubscribedDays() }}
          </h4>
        </v-flex>

        <v-flex xs5 sm2 md2 class="ms-sm-16">
          <h4 class="text-body-2 black--text">Added Days</h4>
        </v-flex>
        <v-flex xs6 sm3 md2>
          <h4 class="text-body-2">
            {{ additionalDaysIncluded }}
          </h4>
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex xs5 sm2 md2 class="mt-3 mt-sm-0">
          <label class="text-body-2 black--text">Available Days</label>
        </v-flex>
        <v-flex xs6 sm3 md2>
          <h4 class="text-body-2">
            {{ getAvailableNumOfDays() }}
          </h4>
        </v-flex>
      </v-layout>

      <v-divider class="mb-6"></v-divider>

      <v-layout wrap>
        <v-flex xs5 sm2 md2>
          <label class="text-body-2 black--text">Package Price </label>
        </v-flex>
        <v-flex xs6 sm3 md2 class="mt-md-0">
          <label class="text-body-2 black--text ">
            {{ formatCurrency(selectedPackage.package_price) }}
          </label>
        </v-flex>

        <v-flex xs5 sm2 md2 class="ms-sm-16">
          <h4 class="text-body-2 black--text">Discount</h4>
        </v-flex>
        <v-flex xs6 sm3 md2>
          <h4 class="text-body-2">
            {{ formatCurrency(selectedPackage.discount) }}
          </h4>
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex xs5 sm2 md2>
          <label class="text-body-2 black--text">Total</label>
        </v-flex>
        <v-flex xs6 sm3 md2 class="mt-md-0">
          <label class="text-body-2 black--text font-weight-bold">
            {{ formatCurrency(selectedPackage.net_price) }}
          </label>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>
<script>
import { TIME_ZONE } from "../../utils/utils";
import moment from "moment";
export default {
  props: {
    selectedPackage: {
      required: true
    },
    customer: {
      required: true
    }
  },
  methods: {
    getPackageTypeClass() {
      let packageStatus = this.getPackageStatus();
      let color = "black--text";
      if (packageStatus == "ACTIVE") {
        color = "green--text";
      }
      if (packageStatus == "UPCOMING") {
        color = "blue--text";
      }
      if (packageStatus == "EXPIRED") {
        color = "red--text";
      }
      return `${color} text-body-2 font-weight-bold`;
    },
    getPackageStatus() {
      if (this.selectedPackage.payment_status == "PENDING") {
        return "PAYMENT PENDING";
      }
      let today = moment().tz(TIME_ZONE);
      if (
        moment(new Date(parseInt(this.selectedPackage.end_date)))
          .tz(TIME_ZONE)
          .endOf("day") >= moment(today) &&
        moment(new Date(parseInt(this.selectedPackage.start_date)))
          .tz(TIME_ZONE)
          .startOf("day") <= moment(today) &&
        this.selectedPackage.payment_status == "SUCCESS" &&
        this.selectedPackage.is_expired != true
      ) {
        return "ACTIVE";
      }

      if (
        moment(new Date(parseInt(this.selectedPackage.end_date)))
          .tz(TIME_ZONE)
          .endOf("day") > moment(today) &&
        moment(new Date(parseInt(this.selectedPackage.start_date)))
          .tz(TIME_ZONE)
          .startOf("day") > moment(today) &&
        this.selectedPackage.payment_status == "SUCCESS" &&
        this.selectedPackage.is_expired != true
      ) {
        return "UPCOMING";
      }
      return "EXPIRED";
    },
    getAvailableDays() {
      if (this.selectedPackage) {
        if (this.selectedPackage.package) {
          return this.selectedPackage.package.availableChoices[0].availableDays.join(
            ","
          );
        }
      }
      return "";
    },
    getSubscribedDays() {
      let additionalDays = this.selectedPackage.package.availableChoices[0]
        .additionalDays;
      if (!additionalDays) {
        additionalDays = 0;
      }

      let available = this.getAvailableNumOfDays() - additionalDays;
      return available;
    },
    getAvailableNumOfDays() {
      let availableNumOfDays = this.selectedPackage.package.availableChoices[0]
        .availableNumOfDays;
      return parseInt(availableNumOfDays);
    }
  },
  created() {
    let additionalDays = "";
    if (this.selectedPackage.package) {
      if (this.selectedPackage.package.availableChoices.length > 0) {
        additionalDays = this.selectedPackage.package.availableChoices[0]
          .additionalDays;
      }
    }

    this.additionalDaysIncluded = additionalDays;
  },
  data: () => ({
    loading: true,
    additionalDaysIncluded: 0
  })
};
</script>
