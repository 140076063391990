<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="red" class="ms-2">mdi-delete</v-icon>
      </template>
      <v-card class="pa-6">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text"
          >Do you really want to delete this permission?</v-card-text
        >
        <!-- 
        <v-card-text v-if="error" class="error--text font-weight-bold">{{
          error
        }}</v-card-text> -->
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 error" depressed @click="dialog = false"
              >No</v-btn
            >
            <v-btn color=" mx-2 success" depressed @click="deletePermission"
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
export default {
  name: "deletePermission",
  props: {
    permission: {
      required: true
    }
  },
  data() {
    return {
      text: "",
      snackbar: false,
      dialog: false
    };
  },
  methods: {
    deletePermission() {
      console.log(this.permission._id);

      this.$apollo
        .mutate({
          mutation: require("../api/deleteUserRoleById.graphql"),
          variables: {
            id: this.permission._id
          }
        })
        .then(() => {
          this.$emit("updatePermission");
          //   this.error = null;
          this.dialog = false;
          this.snackbar = true;
          this.text = "Permission deleted";
        })
        .catch(error => {
          // Error
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
        });
    }
  }
};
</script>

<style scoped></style>
