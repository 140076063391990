<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="mt-n3   white--text text-capitalize btn_fnt"
        color="btncolor"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="exportdrivers"
        hide-default-footer
        :items-per-page="-1"
        :headers="head"
        :items="driverList"
        class="elevation-1 "
      >
        <template v-slot:[`item.user`]="{ item }">
          <v-row v-if="item.user">
            {{ item.user.firstnameEn }} {{ item.user.lastnameEn }}
          </v-row>
        </template>

        <template v-slot:[`item.morningShift`]="{ item }">
          <div v-if="item.morningShift.zone.length > 0">
            <span v-for="zone in item.morningShift.zone" :key="zone._id">
              {{ zone.zoneName }}
            </span>
          </div>
          <div v-if="item.eveningShift.zone.length > 0">
            <span v-for="zone in item.eveningShift.zone" :key="zone._id">
              {{ zone.zoneName }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.eveningShift`]="{ item }">
          <div v-if="item.morningShift.zone.length > 0">Morning</div>
          <div v-if="item.eveningShift.zone.length > 0">Evening</div>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    },
    driverList: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      head: []
    };
  },

  created() {
    this.head = this.headers.filter(
      x => x.value != "actions" && x.value != "index"
    );
  },

  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportdrivers", "Drivers List", false);
      this.loading = false;
    }
  }
};
</script>
