<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <h2 class="black--text text-h5 font-weight-bold mt-3 mt-sm-0">
      Profile Settings
    </h2>
    <app-preference-form></app-preference-form>
  </v-container>
</template>
<script>
import PreferencesForm from "../components/PreferencesForm.vue";
export default {
  components: {
    "app-preference-form": PreferencesForm
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.SETTINGS_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
