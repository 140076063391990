<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="black--text">mdi-square-edit-outline</v-icon>
      </template>
      <v-card class="pa-3 pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text"></span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="subtitle-1 mb-0 mt-6">
          <v-layout>
            <v-flex
              v-if="salesOrder.paymentType != 'CUSTOMER'"
              class="black--text"
            >
              Payment Url:
              <strong class="ml-3" v-if="salesOrder.paymentUrl">
                <a :href="salesOrder.paymentUrl">
                  {{ salesOrder.paymentUrl }}
                </a></strong
              >
              <strong v-else> Payment Url not generated </strong>
              <v-btn
                v-if="salesOrder.paymentUrl"
                class="ma-2"
                text
                icon
                color="red lighten-2"
                @click="copyUrl"
              >
                <v-icon class="gray1--text">mdi-content-copy</v-icon>
              </v-btn>
            </v-flex>
            <v-flex v-else class="black--text text-body-1">
              Generated through payment gateway.
            </v-flex>
          </v-layout>

          <v-layout justify-end class="mt-8">
            <v-btn
              color="secondary"
              class="me-5 text-capitalize black--text"
              @click="checkPaymentStatus()"
              :loading="btnPaymentStatusLoading"
            >
              <v-icon>mdi-refresh</v-icon> Payment Status
            </v-btn>
            <v-btn
              v-if="salesOrder.paymentType != 'CUSTOMER'"
              color="error"
              class="me-5 text-capitalize"
              @click="regenerateInvoice"
              :loading="btnRegenerateLoading"
              >Regenerate Invoice</v-btn
            >
          </v-layout>
        </v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center"> </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    salesOrder: {
      required: true
    }
  },
  data() {
    return {
      valid: true,
      btnPaymentStatusLoading: false,
      btnRegenerateLoading: false,
      isPermanentlyDelete: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      alertData: {
        value: true,
        msg: "Member deleted !",
        msg_ar: "تم حذف العضو!",
        type: "success"
      },
      snackbar: false,
      snackbarColor: "",
      text: ""
    };
  },
  methods: {
    checkPaymentStatus() {
      this.btnPaymentStatusLoading = true;
      this.$apollo
        .mutate({
          mutation: require("./api/recheckPaymentStatus.graphql"),
          variables: {
            customerId: this.$route.query.customerId,
            subscribedPackageId: this.salesOrder._id
          }
        })
        .then(data => {
          this.btnPaymentStatusLoading = false;
          if (data.data.recheckPaymentStatus) {
            this.$root.$emit("updateGetCustomerById");
            this.button_loading = false;
            this.dialog = false;
            this.text = "Sales order payment received.";
            this.snackbarColor = "success";
            this.snackbar = true;
          } else {
            this.text = "No payment received";
            this.snackbarColor = "error";
            this.snackbar = true;
            this.dialog = false;
            this.button_loading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.btnPaymentStatusLoading = false;
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.dialog = false;
          this.button_loading = false;
        });
    },
    regenerateInvoice() {
      this.btnRegenerateLoading = true;
      this.$apollo
        .mutate({
          mutation: require("./api/regenerateSalesOrderInvoiceUrl.graphql"),
          variables: {
            customerId: this.$route.query.customerId,
            subscribedPackageId: this.salesOrder._id
          }
        })
        .then(data => {
          this.btnRegenerateLoading = false;
          if (data.data.regenerateSalesOrderInvoiceUrl) {
            this.$root.$emit("updateGetCustomerById");
            this.button_loading = false;
            this.dialog = false;
            this.text = "New sales order link generated.";
            this.snackbarColor = "success";
            this.snackbar = true;
          } else {
            this.text = "Something went wrong";
            this.snackbarColor = "error";
            this.snackbar = true;
            this.dialog = false;
            this.button_loading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.btnRegnerateLoading = false;
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.dialog = false;
          this.button_loading = false;
        });
    },
    copyUrl() {
      navigator.clipboard.writeText(this.salesOrder.paymentUrl);
    },
    expireSalesOrder() {
      this.$apollo
        .mutate({
          mutation: require("./api/updateSalesOrderToExpire.graphql"),
          variables: {
            customerId: this.$route.query.customerId,
            subscribedPackageId: this.salesOrder._id,
            isPermanentlyDelete: this.isPermanentlyDelete
          }
        })
        .then(() => {
          this.$root.$emit("updateGetCustomerById");
          this.button_loading = false;
          this.dialog = false;
          this.text = "Sales order updated as Expired";
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch(error => {
          console.log(error);
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.dialog = false;
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    }
  }
};
</script>
