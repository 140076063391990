<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.dietPlan="{ item }">
        <span v-if="item.dietPlan">
          {{ item.dietPlan.nameEn }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <RemovePackage :celebrity="celebrity" :package="item" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import RemovePackage from "../components/RemovePackage.vue";
export default {
  components: { RemovePackage },
  props: {
    celebrity: {
      require: true,
    },
  },

  data: () => ({
    dialog: false,
    plansTaken: [],
    headers: [
      {
        text: "Sl No",
        value: "slNo",
        sortable: false,
        width: 70,
      },
      {
        text: "Title (English)",
        value: "titleEn",
        sortable: false,
        width: 120,
      },
      {
        text: "Title (Arabic)",
        value: "titleAr",
        sortable: false,
        width: 120,
      },

      { text: "Diet Plan", value: "dietPlan", sortable: false, width: 70 },
      { text: "Action", value: "action", sortable: false, width: 70 },
    ],
  }),
  computed: {
    itemsWithSno() {
      if (this.celebrity.packages == null) return [];
      return this.celebrity.packages
        .filter((x) => x.active)
        .map((d, index) => ({
          ...d,
          slNo: index + 1,
        }));
    },
  },
};
</script>

