<template>
  <v-container>
    <div v-if="hasPlanPurchase">
      <div v-for="item in item.data.purchasedPlans" :key="item._id">
        <div style="">
          <div>
            {{ item.name }}
          </div>
          <div>
            {{ new Date(parseInt(item.date)) | moment("DD MMM YYYY") }}
          </div>
          <div>
            {{ item.range }}
          </div>
          <div>{{ item.amount }} KD</div>
          <div v-if="hasActivePlan"><strong>ACTIVE </strong></div>
        </div>
        <v-divider class="mt-3 mb-3"></v-divider>
      </div>
    </div>
    <div v-else-if="hasActivePlan"><strong>ACTIVE </strong></div>
  </v-container>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
    },
  },
  created() {
    this.hasActivePlan = this.item.data.hasActivePlan;
    this.hasPlanPurchase =
      this.item.data.purchasedPlans.length > 0 ? true : false;
  },
  watch: {
    item(newValue) {
      this.hasActivePlan = newValue.data.hasActivePlan;
      this.hasPlanPurchase =
        newValue.data.purchasedPlans.length > 0 ? true : false;
    },

    deep: true,
  },

  data: () => ({
    totalItemsCount: 25,
    hasActivePlan: false,
    hasPlanPurchase: false,
  }),

  methods: {},
};
</script>
