<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="accountList"
      class="elevation-1 mt-10 "
      :mobile-breakpoint="0"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.siNo`]="{ item }">
        {{
          accountList
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) + 1
        }}
      </template>

      <template v-slot:[`item.date`]="{ item }">
        {{ dateFormat(new Date(parseInt(item.createdAt))) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ numberWithCommas(item.amount) }}
      </template>
      <template v-slot:[`item.total`]="{ item }">
        {{ numberWithCommas(item.total) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data: () => ({
    accountList: [],
    loading: true,
    headers: [
      {
        text: "Si No",
        value: "siNo"
      },

      { text: "Date and Time", value: "date" },
      { text: "Amount", value: "amount" },
      { text: "Total ", value: "total" }
    ]
  }),
  created() {
    this.$apollo
      .query({
        query: require("@/accounts/api/getAllCreditNoteTransaction.graphql"),
        variables: {
          skip: 0,
          limit: 0,
          customerId: this.$route.query.customerId
        }
      })
      .then(data => {
        if (data.data.getAllCreditNoteTransactions) {
          this.accountList =
            data.data.getAllCreditNoteTransactions.transactionList;
        }
        this.loading = false;
      });
  },
  methods: {
    dateFormat(date) {
      return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY, h:mm:ss a");
    }
  }
};
</script>
