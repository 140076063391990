<template>
  <div>
    <v-dialog v-model="dialog" max-width="400px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" class="ms-2">mdi-printer</v-icon>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text"
          >Please select the format</v-card-text
        >

        <v-card-text v-if="error" class="error--text font-weight-bold">{{
          error
        }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn
              color=" mx-4 primary"
              width="100"
              :loading="button_loading"
              @click="exportExcel(menuItemInfo._id)"
              >Excel</v-btn
            >
            <v-btn
              color=" mx-4 secondary white--text  font-weight-bold"
              width="100"
              :loading="pdf_button_loading"
              @click="printReport(menuItemInfo._id)"
              >Pdf</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-layout wrap>
      <v-flex xs12 sm6 lg6 class="mt-5 mt-sm-0 ps-sm-1">
        <v-icon
          color="black"
          @click="printReport(menuItemInfo._id)"
          class="ms-2"
          >mdi-printer</v-icon
        >
      </v-flex>
    </v-layout> -->
    <div v-show="false">
      <div :id="`printIngredient_${menuItemInfo._id}`">
        <table id="printPage" border="1" style="border-collapse: collapse; width: 250mm">
          <h1 align="center">{{ menuItemInfo.itemCode }} - {{ menuItemInfo.nameEn }}</h1>
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header.value"
                :style="`width:${header.width};vertical-align: middle;`"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in menuItemInfo.ingredients" :key="index">
              <div v-if="item.ingredient">
                <td style="text-align: center">{{ item.ingredient.itemId }}</td>
                <td>
                  {{ item.ingredient.nameEn }}
                </td>
                <td style="text-align: center; vertical-align: middle">
                  {{ parseFloat(item.quantity).toFixed(3) }}
                </td>
                <td style="text-align: center; vertical-align: middle">
                  {{ item.ingredient.quantityUnit }}
                </td>
                <td style="text-align: center; vertical-align: right">
                  <!-- <input
                    class="mx-2"
                    v-if="item.isDislikable"
                    type="checkbox"
                    checked="true"
                  />
                  <input v-else class="ms-2" type="checkbox" /> -->
                  <span v-if="item.isDislikable"><b> Yes</b></span>
                  <span v-else>No</span>
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Utils } from "../../utils/utils";

export default Vue.extend({
  props: {
    menuItemInfo: { require: true },
  },

  data() {
    return {
      dialog: false,
      pdf_button_loading: false,
      button_loading: false,
      menu: false,
      error: "",
      headers: [
        {
          text: "Item ID",
          value: "itemCode",
          width: 110,
        },
        {
          text: "Item Name",
          value: "nameEn",
          width: 180,
        },
        {
          text: "Quantity",
          width: 180,
        },
        { text: "Unit", value: "active", width: 90 },
        {
          text: "Dislikable",
          value: "availableWeekdays",
          sortable: false,
          width: 250,
        },
      ],
    };
  },

  methods: {
    printReport(id) {
      this.pdf_button_loading = true;
      const divToPrint = document.getElementById("printIngredient_" + id);
      const newWin = window.open("", "Print-Window");
      newWin.document.open();
      newWin.document.write(
        '<html><body onload="window.print()">' + divToPrint.innerHTML + "</body></html>"
      );

      newWin.document.close();
      newWin.focus();
      newWin.print();
      setTimeout(function () {
        newWin.close();
      }, 10);
      this.close();
      this.pdf_button_loading = false;
    },
    exportExcel(id) {
      // this.getAllMenuItems();
      this.button_loading = false;
      Utils.htmlToExcel("printIngredient_" + id, "Recipies", false);
      this.close();
      this.button_loading = false;
    },
    close() {
      this.error = null;
      this.dialog = false;
    },
  },
});
</script>
