var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"justify-end":""}},[_c('purchase-report',{attrs:{"fromDate":_vm.fromDate,"toDate":_vm.toDate,"purchaseList":_vm.purchaseList,"headers":_vm.headers}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-1  ms-2",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.itemsWithSno,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.slNo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.itemsWithSno.indexOf(item) + (_vm.options.page - 1) * _vm.options.itemsPerPage + 1))]}},{key:"item.nameEn",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.nameEn))]}},{key:"item.nameAr",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.nameAr))]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.itemId))]}},{key:"item.brandName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.brand))]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.quantityUnit))]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }