var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 mx-0"},[_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"mt-n3 white--text text-capitalize btn_fnt",attrs:{"width":"150","loading":_vm.loading,"color":"btncolor"},on:{"click":_vm.exportExcel}},[_c('v-icon',{staticClass:"me-2",attrs:{"small":""}},[_vm._v("mdi-export")]),_vm._v("Export ")],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-data-table',{staticClass:"elevation-1 mt-1",attrs:{"id":"DietitianAppoinments","hide-default-footer":"","mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.dietitianAppointmentList},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]}},{key:"item.dietitian",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dietitian.user.firstnameEn[0].toUpperCase() + item.dietitian.user.firstnameEn.substring(1))+" "+_vm._s(item.dietitian.user.lastnameEn[0].toUpperCase() + item.dietitian.user.lastnameEn.substring(1))+" ")]}},{key:"item.bookedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdDate)),"DD MMM YYYY"))+" ")]}},{key:"item.appointmentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.followUp.appointmentDate)),"DD MMM YYYY"))+" : "+_vm._s(item.followUp.appointmentTime)+" ")]}},{key:"item.mode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mode(item.followUp))+" ")]}},{key:"item.shift",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shift(item.followUp))+" ")]}},{key:"item.followUpType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.followUpType(item.followUp))+" ")]}},{key:"item.actionType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.followUp.actionType)+" ")]}},{key:"item.actionStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.followUp.actionStatus)+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.followUp.comment.length)?_c('span',_vm._l((item.followUp.comment),function(comment){return _c('ul',{key:comment.message},[_c('li',[_vm._v(" "+_vm._s(comment.message ? comment.message : "--")+" ")]),_c('li',{staticStyle:{"list-style-type":"none"}},[_vm._v(" "+_vm._s(comment.actionStatusFrom || comment.actionStatusTo ? " ( " : "")+" "+_vm._s(comment.actionStatusFrom ? comment.actionStatusFrom : "")+" "+_vm._s(comment.actionStatusTo ? " - " + comment.actionStatusTo : "")+_vm._s(comment.actionStatusFrom || comment.actionStatusTo ? " ) " : "")+" ")])])}),0):_vm._e()]}},{key:"item.bookingFee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookingFee(item.followUp))+" ")]}},{key:"item.additionalFee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.additionalFee(item.followUp))+" ")]}},{key:"item.totalFee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totalFee(item.followUp))+" ")]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" BOOKING FEE: "),_c('span',{class:item.followUp.payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE'
                ? 'green--text'
                : item.followUp.payment_status == 'PENDING'
                  ? 'orange--text'
                  : item.followUp.payment_status == 'CANCELLED'
                    ? 'blue--text'
                    : item.followUp.payment_status == 'FAILED'
                      ? 'red--text'
                      : ''},[_vm._v(" "+_vm._s(item.followUp.payment_status)+" ")])]),(item.followUp.actionStatus == 'FINISHED' && item.followUp.modeOfAppointment == 'ON_VISIT' && item.followUp.additional_payment_status)?_c('div',[_vm._v(" ADDITIONAL FEE : "),_c('span',{class:item.followUp.additional_payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE'
                ? 'green--text'
                : item.followUp.additional_payment_status == 'PENDING'
                  ? 'orange--text'
                  : item.followUp.additional_payment_status == 'CANCELLED'
                    ? 'blue--text'
                    : item.followUp.additional_payment_status == 'FAILED'
                      ? 'red--text'
                      : ''},[_vm._v(" "+_vm._s(item.followUp.additional_payment_status)+" ")])]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }