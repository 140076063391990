<template>
  <v-container>
    <v-layout justify-end>
      <v-btn
        class="white--text text-capitalize"
        color="secondary"
        @click="showIngrediantDialog()"
        ><v-icon small class="me-1">mdi-plus</v-icon> Add</v-btn
      >
    </v-layout>

    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Stock Category</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="show_dialog_box = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="createCategory()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Name En</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    solo
                    dense
                    label="Name En"
                    :rules="[requiredValidator('Name En')]"
                    v-model="nameEn"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="ms-md-16">
                  <h4 class="black--text text-body-2">Name Ar</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    v-model="nameAr"
                    :rules="[requiredValidator('Name Ar')]"
                    solo
                    dense
                    label="Name Ar"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n16">
          <v-spacer></v-spacer>
          <v-btn
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            color="secondary "
            @click="createCategory()"
            :loading="btnloading"
            :disabled="!valid"
            class="mt-8 white--text text-capitalize"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  data() {
    return {
      btnloading: false,
      isIngredient: true,
      isCommonDislikable: true,
      loading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      requiredValidator: requiredValidator,
      nameEn: "",
      nameAr: "",
    };
  },

  methods: {
    createCategory() {
      console.log(this.nameEn + this.nameAr);
      this.$apollo
        .mutate({
          mutation: require("@/stock/api/createStockCategory.graphql"),
          variables: {
            nameEn: this.nameEn,
            nameAr: this.nameAr,
          },
        })
        .then((data) => {
          console.log(data);
          this.$emit("updatedStockCategory");
          this.show_dialog_box = false;
        });
    },
    showIngrediantDialog() {
      this.show_dialog_box = true;
    },
  },
};
</script>
