<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row class="mt-3 mt-sm-0">
      <h2 class="black--text text-h5 font-weight-bold ms-2 mb-6">
        Dietitians
      </h2>
    </v-row>
    <v-row align="center" class="mt-n4 mt-md-0">
      <v-col cols="12" sm="6" lg="4">
        <v-text-field
          placeholder="Name or phone.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col class="mb-4 mb-sm-0">
        <!--   :dietitian="dietitian" -->
        <DietitianCreateForm
          v-if="hasRole(AdminUserPermissions.DIETITIAN_ADD)"
          :userTypes="userTypes"
        />
      </v-col>
      <v-col>
        <ExportDietitian :dietitianList="dietitianList" />
      </v-col>
    </v-row>

    <app-dietitian-table
      class="mt-n12"
      :dietitianList="dietitianList"
      :totalItemsCount="totalItemsCount"
      :loading="loading"
      
    ></app-dietitian-table>
  </v-container>
</template>

<script>
import DietitianTable from "../components/DietitianTable.vue";
import DietitianCreateForm from "../components/DietitianCreateForm.vue";
import ExportDietitian from "../components/ExportDietitian.vue";
export default {
  components: {
    "app-dietitian-table": DietitianTable,
    DietitianCreateForm,
    ExportDietitian
  },
  apollo: {
    getAllUserRole() {
      return {
        query: require("../../permission/api/getAllUserRole.graphql"),
        result({ data }) {
          this.userTypes = data.getAllUserRole;
        }
      };
    }
  },
  mounted() {
    this.$root.$on("updateDietitian", () => {
      this.getAllDietitians();
    });
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DIETITIAN_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllDietitians();
  },
  data() {
    return {
      userTypes: [],
      loading: true,
      totalItemsCount: 25,
      dietitianList: [],
      dietitian: {
        firstnameEn: "",
        lastnameEn: "",
        password: "",
        phone: ""
      },
      search: "",
      variables: {
        limit: 25,
        skip: 0
      }
    };
  },
  methods: {
    runSearch() {
      this.getAllDietitians();
    },
    clearSearch() {
      this.search = "";
      this.getAllDietitians();
    },
    getAllDietitians() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllDietitians.graphql"),
          variables: {
            skip: this.variables.skip,
            limit: this.variables.limit,
            searchKey: this.search
          }
        })
        .refetch({
          variables: {
            skip: this.variables.skip,
            limit: this.variables.limit,
            searchKey: this.search
          }
        })
        .then(data => {
          this.loading = false;
          this.dietitianList = data.data.getAllDietitians.dietitians;
          this.dietitianList = this.dietitianList.map((items, index) => ({
            ...items,
            index: index + 1
          }));
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  }
};
</script>
