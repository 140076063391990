<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="red" class="ms-2">mdi-delete</v-icon>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center"
          >Do you really want to delete this?</v-card-text
        >

        <v-card-text v-if="error" class="error--text font-weight-bold">{{
          error
        }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 success"
              depressed
              :loading="button_loading"
              @click="
                button_loading = true;
                deletePlanSwitch(planSwitchId);
              "
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>
<script>
export default {
  props: {
    planSwitchId: {
      required: true
    }
  },
  data() {
    return {
      snackbar: false,
      text: "",
      snackbarColor: "",
      valid: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      alertData: {
        value: true,
        msg: "plan switch deleted !",
        type: "success"
      }
    };
  },
  methods: {
    deletePlanSwitch(id) {
      this.$apollo
        .mutate({
          mutation: require("../api/deletePlanSwitch.graphql"),
          variables: {
            id
          }
        })
        .then(() => {
          this.button_loading = false;
          this.error = null;
          this.snackbarColor = "green";
          this.snackbar = true;
          this.text = "plan switch deleted";
          this.dialog = false;
          this.$emit("updateplanSwitch");
        })
        .catch(error => {
          // Error
          if (error.graphQLErrors != null) {
            this.button_loading = false;
            this.error = null;
            this.dialog = false;
            this.text = error.graphQLErrors;
            this.snackbarColor = "error";
            this.snackbar = true;
          }
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    }
  }
};
</script>
