
<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="red">mdi-delete</v-icon>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text"
          >Do you really want to Resume this?</v-card-text
        >

        <v-card-text v-if="error" class="error--text font-weight-bold">{{
          error
        }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 success"
              depressed
              :loading="button_loading"
              @click="
                button_loading = true;
                deleteMethod();
              "
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    pausedDay: {
      required: true,
    },
    activePackage: {
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      snackbar: false,
      snackbarColor: "",
      text: "",
    };
  },
  methods: {
    deleteMethod() {
      //   console.log(id);
      this.$apollo
        .mutate({
          mutation: require("../../api/unpauseCustomerMenu.graphql"),
          variables: {
            id: this.pausedDay._id,
            customerId: this.$route.query.customerId,
            subscribed_package_id: this.activePackage._id,
          },
        })
        .then((data) => {
          this.button_loading = false;
          this.dialog = false;
          this.$emit(
            "updatePausedDates",
            data.data.unpauseCustomerMenu.subscribed_packages
          );
          this.$root.$emit("updateGetCustomerById");
        })
        .catch((error) => {
          // Error
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
            this.text = error.graphQLErrors[0].message;
            if(error.graphQLErrors[0].message=="UNIVERSAL_PAUSED"){
              this.text="Can't unpause this date due to universal pause"
            }
            this.snackbarColor = "error";
            this.snackbar = true;
            this.dialog = false;
          }
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    },
  },
};
</script>