<template>
  <div>
    <div class="d-flex">
      <v-btn
        :disabled="disabled"
        @click="printReport"
        :width="$vuetify.breakpoint.xs ? 100 : 120"
        color="btncolor"
        class="white--text text-capitalize text-end me-2 me-sm-3"
        :small="$vuetify.breakpoint.xs"
      >
        <v-icon class="me-1" :small="$vuetify.breakpoint.xs"
          >mdi-file-document</v-icon
        >Print
      </v-btn>

      <v-btn
        :disabled="disabled"
        @click="exportExcel"
        :width="$vuetify.breakpoint.xs ? 100 : 120"
        color="btncolor"
        :small="$vuetify.breakpoint.xs"
        class="white--text text-capitalize text-end me-2 me-sm-3"
      >
        <v-icon size="20" class="me-1" :small="$vuetify.breakpoint.xs"
          >mdi-export</v-icon
        >Export
      </v-btn>
    </div>
    <!-- <v-flex xs12 sm6 lg6 class="mt-3 mt-sm-0">
        <v-btn
          outlined
          :disabled="disabled"
          @click="exportExcel"
          width="150"
          color="btncolor"
        >
          <v-icon>mdi-export</v-icon>Export
        </v-btn>
      </v-flex> -->
    <!-- </v-layout> -->
    <div v-show="false">
      <div id="printMe">
        <div v-for="(item, index) in reportList" :key="index">
          <h2>{{ item.categoryName }}</h2>
          <table border="1" style="border-collapse: collapse; width: 250mm">
            <tr>
              <th
                v-for="header in headers"
                :key="header.value"
                :style="`width:${header.width};vertical-align: middle;`"
              >
                <span v-if="header.value == 'slNo'">{{ header.text }}</span>
                <span v-if="header.value == 'itemName'">{{ header.text }}</span>
                <span v-if="header.value == 'total'">{{ header.text }}</span>
                <span> {{ header.protein }}</span>
                <span> {{ header.carbs }}</span>
              </th>
            </tr>

            <div v-for="(order, indexOrder) in item.orders" :key="indexOrder">
              <tr>
                <td>{{ order.count }}</td>
                <td>{{ order.itemName }}</td>
                <td
                  v-for="(meanInfo, idx) in meanInfos"
                  :key="idx"
                  style="text-align: center; vertical-align: middle"
                >
                  {{ getMeanInfo(meanInfo, order.meanInfo) }}
                </td>
                <td>{{ getTotalValue(order) }}</td>
              </tr>

              <tr
                v-for="(verity, verityIndex) in order.orderVerities"
                :key="verityIndex"
              >
                <td></td>
                <td>
                  <ul>
                    <li style="display: inline">
                      {{ order.itemName }}
                      {{ getDislikes(verity.dislikedIngredients) }}
                    </li>
                  </ul>
                </td>

                <td
                  v-for="(meanInfo, idx) in meanInfos"
                  :key="idx"
                  style="text-align: center; vertical-align: middle"
                >
                  {{ getMeanInfo(meanInfo, verity.meanInfo) }}
                </td>
                <td>{{ getTotalValue(verity) }}</td>
              </tr>
            </div>
          </table>

          <p style="page-break-after: always">&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import VueLodash from "vue-lodash";
import Vue from "vue";
import lodash from "lodash";
Vue.use(VueLodash, { name: "custom", lodash: lodash });
import { Utils } from "../../utils/utils";
export default Vue.extend({
  props: {
    date: { require: true },
    reportList: { require: true },
    disabled: { require: true },
    headers: { require: true },
    meanInfos: { require: true }
  },
  watch: {
    date: {
      handler() {
        this.getOrderMealDetailsByDate();
      },
      deep: true
    }
  },
  data() {
    return {
      menu: false,
      loading: false,
      lodash: lodash,
      errorLog: [],
      showErrorDialog: false,
      btnloading: false,

      reportGeneratedDate: []
    };
  },
  created() {
    // this.getOrderMealDetailsByDate();
  },
  methods: {
    exportExcel() {
      Utils.htmlToExcel("printMe", "Order");
    },
    getTotalValue(item) {
      let total = 0;

      if (item.isParent && !item.isCatefory) {
        if (item.meanInfo) {
          item.meanInfo.forEach(element => {
            element.forEach(item => {
              total = total + item.count;
            });
          });
        }
      } else {
        total = item.meanInfo.map(x => x.count).reduce((a, b) => a + b, 0);
      }

      return total;
    },
    printReport() {
      const divToPrint = document.getElementById("printMe");
      const newWin = window.open("", "Print-Window");
      newWin.document.open();

      newWin.document.write(
        '<html><body onload="window.print()">' +
          divToPrint.innerHTML +
          "</body></html>"
      );

      newWin.document.close();
      newWin.focus();
      newWin.print();
      setTimeout(function() {
        newWin.close();
      }, 10);
    },

    updateHeaders() {
      let header = [
        { text: "Sl.No", value: "slNo", width: "10mm", textAlign: "left" },
        {
          text: "Item Name",
          value: "itemName",
          width: "100mm",
          textAlign: "left"
        }
      ];
      if (this.meanInfos)
        this.meanInfos.forEach(element => {
          let item = {
            text: `P${element.protein}/C${element.carbs}`,
            value: `${element.protein}/${element.carbs}`,
            width: "70px",
            textAlign: "right"
          };
          header.push(item);
        });
      header.push({
        text: "Total",
        value: "total",
        width: "50px",
        textAlign: "right"
      });
      this.headers = header;
    },

    getMeanInfo(type, meanInfoList) {
      meanInfoList = this.lodash.flatten(meanInfoList, true);
      meanInfoList = meanInfoList.filter(
        x => x.protein == type.protein && x.carbs == type.carbs
      );

      if (meanInfoList.length > 0) {
        if (meanInfoList.length > 1) {
          let count = 0;
          meanInfoList.forEach(element => {
            count = count + element.count;
          });
          return count;
        }
        return meanInfoList[0].count;
      }
      return 0;
    },

    getDislikes(dislikes) {
      let dislikeNames = new Array();
      dislikes.forEach(element => {
        dislikeNames.push(`No ${element.nameEn}`);
      });
      let joined = dislikeNames.join(",");
      let response = "";
      if (dislikes.length > 0) {
        response = `( ${joined} )`;
      }
      return response;
    },
    searchDelivery() {
      this.getOrderMealDetailsByDate();
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    reportWithSno() {
      if (this.reportList == null) return [];
      return this.reportList.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  }
});
</script>

<style scoped>
@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}
</style>
