var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","sm3":"","md3":"","lg2":""}},[_c('span'),_c('v-select',{attrs:{"items":_vm.packageCountList,"item-text":"text","item-value":"value","dense":"","attach":"","label":"Package Count","solo":""},on:{"change":_vm.packageCountChange},model:{value:(_vm.noOfPackage),callback:function ($$v) {_vm.noOfPackage=$$v},expression:"noOfPackage"}})],1),_c('v-flex',{attrs:{"xs12":"","sm3":"","md3":"","lg2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ms-sm-2",attrs:{"value":_vm.toDate,"label":"To","solo":"","append-icon":"fa-calendar-alt black--text","dense":""},on:{"click:clear":function($event){_vm.tDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"secondary","max":_vm.toMaxDate,"min":_vm.toMinDate},on:{"change":_vm.selectToDate},model:{value:(_vm.tDate),callback:function ($$v) {_vm.tDate=$$v},expression:"tDate"}})],1)],1)],1),_c('v-layout',{staticClass:"text-end",attrs:{"justify-end":""}},[(_vm.hasRole(_vm.AdminUserPermissions.SALES_ORDER_EXPORT))?_c('ExportSalesOrder',{attrs:{"headers":_vm.headers,"noOfPackage":_vm.noOfPackage,"tDate":_vm.tDate}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.salesOrderList,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.created_At)),"MMM DD YYYY")))]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.start_date)),"MMM DD YYYY")))]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.end_date)),"MMM DD YYYY")))]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.user ? item.user.phone : "--")+" ")]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.payment_status == 'SUCCESS'
            ? 'green--text'
            : item.payment_status == 'PENDING'
            ? 'orange--text'
            : item.payment_status == 'UPCOMING'
            ? 'blue--text'
            : item.payment_status == 'EXPIRED'
            ? 'red--text'
            : ''},[_vm._v(" "+_vm._s(item.payment_status))])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [(item.user)?_c('span',[(!item.is_deleted)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
            name: 'CustomerDetails',
            params: { customerId: item.customer },
            query: { customerId: item.customer }
          }}},[_vm._v(" "+_vm._s(_vm.toTitleCase(item.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.user.lastnameEn))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.toTitleCase(item.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.user.lastnameEn))+" ")])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }