var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg"},[_c('comment-view',{attrs:{"commentList":_vm.commentList,"openComment":_vm.openComment},on:{"closeComment":_vm.closeComment}}),_c('v-layout',[_c('AddCustomerDietitianAppoinment',{attrs:{"customer_id":_vm.customer_id},on:{"updateDietitianAppointment":_vm.updateDietitianAppointment}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dietitianAppointmentList,"expanded":_vm.expanded,"item-key":"_id","show-expand":"","options":_vm.options},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dietitian",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
        name: 'DietitianDetails',
        params: { dietitianId: item.dietitian._id },
        query: { dietitianId: item.dietitian._id }
      }}},[_vm._v(" "+_vm._s(item.dietitian.user.firstnameEn[0].toUpperCase() + item.dietitian.user.firstnameEn.substring(1))+" "+_vm._s(item.dietitian.user.lastnameEn[0].toUpperCase() + item.dietitian.user.lastnameEn.substring(1))+" ")])]}},{key:"item.bookedDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookedDate(item))+" ")]}},{key:"item.appointmentDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.firstBooking.appointmentDate)),"DD MMM YYYY"))+" : "+_vm._s(item.firstBooking.appointmentTime)+" ")]}},{key:"item.mode",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? _vm.mode(item.firstBooking) : "--")+" ")]}},{key:"item.shift",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? _vm.shift(item.firstBooking) : "--")+" ")]}},{key:"item.followUpType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? _vm.followUpType(item.firstBooking) : "--")+" ")]}},{key:"item.actionType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? item.firstBooking.actionType : "--")+" ")]}},{key:"item.actionStatus",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? item.firstBooking.actionStatus : "--")+" ")]}},{key:"item.comment",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"elevation":"0","color":"transparent "},on:{"click":function($event){return _vm.showComments(item.firstBooking.comment)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.bookingFee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookingFee(item.firstBooking))+" ")]}},{key:"item.additionalFee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.additionalFee(item.firstBooking))+" ")]}},{key:"item.totalFee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totalFee(item.firstBooking))+" ")]}},{key:"item.paymentStatus",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('span',{class:item.followUp[0].payment_status == 'SUCCESS' || item.followUp[0].additional_payment_status == 'FREE'
            ? 'green--text'
            : item.followUp[0].payment_status == 'PENDING'
              ? 'orange--text'
              : item.followUp[0].payment_status == 'CANCELLED'
                ? 'blue--text'
                : item.followUp[0].payment_status == 'FAILED'
                  ? 'red--text'
                  : ''},[_vm._v(" "+_vm._s(item.followUp[0].payment_status)+" ")])]),(item.followUp[0].actionStatus == 'FINISHED' && item.followUp[0].modeOfAppointment == 'ON_VISIT' && item.followUp[0].additional_payment_status)?_c('div',[_vm._v(" ADDITIONAL FEE : "),_c('span',{class:item.followUp[0].additional_payment_status == 'SUCCESS' || item.followUp[0].additional_payment_status == 'FREE'
            ? 'green--text'
            : item.followUp[0].additional_payment_status == 'PENDING'
              ? 'orange--text'
              : item.followUp[0].additional_payment_status == 'CANCELLED'
                ? 'blue--text'
                : item.followUp[0].additional_payment_status == 'FAILED'
                  ? 'red--text'
                  : ''},[_vm._v(" "+_vm._s(item.followUp[0].additional_payment_status)+" ")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(_vm.isPaymentPending(item.firstBooking))?_c('span',[_c('RegenerateApptPayment',{attrs:{"appointmentId":item._id,"appointment":item.firstBooking}})],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
return [(item.remainingFollowUp.length)?_c('td',{staticClass:"ma-0 pa-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":headers,"items":item.remainingFollowUp,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.dietitian",fn:function(ref){return [_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
              name: 'DietitianDetails',
              params: { dietitianId: item.dietitian._id },
              query: { dietitianId: item.dietitian._id }
            }}},[_vm._v(" "+_vm._s(item.dietitian.user.firstnameEn[0].toUpperCase() + item.dietitian.user.firstnameEn.substring(1))+" "+_vm._s(item.dietitian.user.lastnameEn[0].toUpperCase() + item.dietitian.user.lastnameEn.substring(1))+" ")])]}},{key:"item.bookedDate",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.bookedDate(item))+" ")]}},{key:"item.appointmentDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.appointmentDate)),"DD MMM YYYY"))+" : "+_vm._s(item.appointmentTime)+" ")]}},{key:"item.mode",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mode(item))+" ")]}},{key:"item.shift",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shift(item))+" ")]}},{key:"item.followUpType",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.followUpType(item))+" ")]}},{key:"item.actionType",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.actionType)+" ")]}},{key:"item.actionStatus",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.actionStatus)+" ")]}},{key:"item.comment",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"elevation":"0","color":"transparent "},on:{"click":function($event){return _vm.showComments(item.comment)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.bookingFee",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookingFee(item))+" ")]}},{key:"item.additionalFee",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.additionalFee(item))+" ")]}},{key:"item.totalFee",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totalFee(item))+" ")]}},{key:"item.paymentStatus",fn:function(ref){
            var item = ref.item;
return [_c('span',{class:item.payment_status == 'SUCCESS' || item.payment_status == 'FREE'
                ? 'green--text'
                : item.payment_status == 'PENDING'
                  ? 'orange--text'
                  : item.payment_status == 'CANCELLED'
                    ? 'blue--text'
                    : item.payment_status == 'FAILED'
                      ? 'red--text'
                      : ''},[_vm._v(" "+_vm._s(item.payment_status)+" ")]),(item.actionStatus == 'FINISHED' && item.modeOfAppointment == 'ON_VISIT' && item.additional_payment_status)?_c('div',[_vm._v(" ADDITIONAL FEE : "),_c('span',{class:item.additional_payment_status == 'SUCCESS' || item.additional_payment_status == 'FREE'
                  ? 'green--text'
                  : item.additional_payment_status == 'PENDING'
                    ? 'orange--text'
                    : item.additional_payment_status == 'CANCELLED'
                      ? 'blue--text'
                      : item.additional_payment_status == 'FAILED'
                        ? 'red--text'
                        : ''},[_vm._v(" "+_vm._s(item.additional_payment_status)+" ")])]):_vm._e()]}},{key:"item.actions",fn:function(subitem){return [(_vm.isPaymentPending(subitem.item))?_c('span',[_c('RegenerateApptPayment',{attrs:{"appointmentId":item._id,"appointment":subitem.item}})],1):_vm._e()]}}],null,true)})],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }