<template>
  <div class="secondary black--text" style="height: 270px">
    <v-row class="mt-4 mx-2 ">
      <h4 class="black--text text-h6 font-weight-bold">Meal Details</h4>
    </v-row>

    <v-row class="mb-n6">
      <v-col md="5" class="ms-2">Item Code</v-col>
      <v-col md="6" class="font-weight-medium">
        M {{ mealInfo.itemCode }}
      </v-col>
    </v-row>

    <v-row class="mb-n6 mt-n6">
      <v-col md="5" class="ms-2">Name English</v-col>
      <v-col md="6" class="font-weight-medium">
        {{ toPascalCase(mealInfo.nameEn) }}
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col md="5" class="ms-2">Name Arabic</v-col>
      <v-col md="6" class="font-weight-medium">
        {{ (mealInfo.nameAr) }}
      </v-col>
    </v-row>

    <v-row class="mt-n6">
      <v-col md="5" class="ms-2">Rating</v-col>
      <v-col md="6" class="font-weight-medium">{{ mealInfo.averageRating ? mealInfo.averageRating.rating : "" }}</v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  props: {
    mealInfo: {
      required: true
    }
  },

  methods: {
    toPascalCase(string) {
      return `${string}`
        .replace(new RegExp(/[-_]+/, "g"), " ")
        .replace(new RegExp(/[^\w\s]/, "g"), "")
        .replace(
          new RegExp(/\s+(.)(\w+)/, "g"),
          ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\s/, "g"), "")
        .replace(new RegExp(/\w/), s => s.toUpperCase());
    }
  },

};
</script>
