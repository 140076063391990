<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="mealRatings"
      :items-per-page="10"
      value=""
      :mobile-breakpoint="0"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
    >
      <template v-slot:[`item.rating`]="{ item }">
        <v-rating
          small
          color="secondary"
          length="5"
          size="64"
          :value="item.rating"
          :readonly="true"
        ></v-rating>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ new Date(parseInt(item.date)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ new Date(parseInt(item.createdAt)) | moment("MMM DD YYYY") }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    subscribedPackages: {
      require: true,
    },
  },

  watch: {
    options: {
      handler() {
        this.getMealRatings();
      },
      deep: true,
    },
  },
  methods: {
    getMealRatings() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../api/getAllCustomerRating.graphql"),
          variables: {
            customerId: this.$route.query.customerId,
            limit: 0,
            skip: 0,
          },
        })
        .refetch({
          customerId: this.$route.query.customerId,
          limit: this.options.itemsPerPage,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage,
        })
        .then((data) => {
          this.loading = false;

          if (data.data.getAllCustomerRating) {
            this.mealRatings =
              data.data.getAllCustomerRating.customerMealRating;
            this.totalItems = data.data.getAllCustomerRating.totalCount;
          }
        })
        .catch((error) => {
          this.loading = false;

          console.log(error);
        });
    },
  },
  created() {
    this.getMealRatings();
  },
  data() {
    return {
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      mealRatings: [],
      totalItems: 0,
      loading: true,
      headers: [
        { text: "Date", value: "date", width: 120, sortable: false },
        { text: "Rating", value: "rating", sortable: false },
        { text: "Meal", value: "meal.nameEn", sortable: false },
        { text: "Comment", value: "comment", sortable: false },
        {
          text: "Created Date",
          value: "createdAt",

          sortable: false,
        },
      ],
    };
  },
};
</script>
