
<template>
  <v-container v-if="address">
    <v-row v-if="type == 'area'">
      <span v-if="address.area">{{ address.area.nameEn }}</span>
    </v-row>
    <v-row v-if="type == 'block'">
      <span v-if="address.block">{{ address.block.block }}</span>
      <span v-if="address.street">, {{ address.street }}</span>
      <span v-if="address.jedha">, {{ address.jedha }}</span>
    </v-row>
    <v-row v-if="type == 'building'">
      <span v-if="address.building_number">{{ address.building_number }}</span>
      <span v-if="address.house_number">, {{ address.house_number }}</span>
      <span v-if="address.apartment_number">, {{ address.apartment_number }}</span>
    </v-row>
    <v-row></v-row>
    <v-row></v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    address: {
      required: true
    },
    type: {
      required: true
    }
  },

  data() {
    return {};
  }
};
</script>