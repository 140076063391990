<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-layout wrap  class="mt-3 mt-sm-0 mb-2">
      <v-flex>
        <h2 class="black--text font-weight-bold">FAQs</h2>
      </v-flex>
      <v-spacer></v-spacer>
      <ExportFaq class="mt-n3"  :faqList="faqList"/>  
     <app-faq-form v-if="hasRole(AdminUserPermissions.FAQ_ADD)"></app-faq-form>
    </v-layout>
    <!-- {{ faqList }} -->
    <app-faq-table
      class="mt-8"
      :faqList="faqList"
      :totalItemsCount="totalItemsCount"
      :loading="loading"
    ></app-faq-table>
  </v-container>
</template>

<script>
import FaqForm from "../components/FaqForm";
import FaqTable from "../components/FaqTable";
import ExportFaq from "../components/ExportFaq.vue";


export default {
  components: {
    "app-faq-table": FaqTable,
    "app-faq-form": FaqForm,
    ExportFaq 
  },
  methods: {},
  data: () => ({
    loading: true,
    faqList: [],
    totalItemsCount: 25,
    variables: {
      limit: 25,
      skip: 0
    }
  }),
  apollo: {
    getAllFaqs() {
      // console.log("djfd");
      return {
        query: require("@/faq/api/getAllFaqs.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.faqList = data.getAllFaqs.faqs;
          this.totalItemsCount = data.getAllFaqs.totalCount;
        }
      };
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.FAQ_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
