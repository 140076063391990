<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="black--text">mdi-square-edit-outline</v-icon>
      </template>
      <v-card class="pa-3 pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text"></span>
          <v-spacer></v-spacer>
          <v-btn color="btncolor" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- {{ appointment }} -->
        <v-card-text class="subtitle-1 mb-0 mt-6">
          <v-layout>
            <v-flex class="black--text">
              Payment Url:
              <strong class="ml-3" v-if="paymentInfo.paymentUrl">
                <a :href="paymentInfo.paymentUrl">
                  {{ paymentInfo.paymentUrl }}
                </a></strong>
              <strong v-else> Payment Url not generated </strong>
              <v-btn v-if="paymentInfo.paymentUrl" class="ma-2" text icon color="red lighten-2" @click="copyUrl">
                <v-icon class="gray1--text">mdi-content-copy</v-icon>
              </v-btn>
            </v-flex>

          </v-layout>

          <v-layout justify-end class="mt-8">
            <v-btn color="secondary" class="me-5 text-capitalize" @click="checkPaymentStatus()"
              :loading="btnPaymentStatusLoading">
              <v-icon>mdi-refresh</v-icon> Payment Status
            </v-btn>
            <v-btn v-if="appointment.paymentType != 'CUSTOMER'" color="error" class="me-5 text-capitalize"
              @click="regenerateAppointmentInvoiceUrl" :loading="btnRegenerateLoading">Regenerate Invoice</v-btn>
          </v-layout>
        </v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center"> </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    appointment: {
      required: true
    },
    appointmentId: {
      required: true
    }
  },
  data() {
    return {
      valid: true,
      btnPaymentStatusLoading: false,
      btnRegenerateLoading: false,
      isPermanentlyDelete: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      alertData: {
        value: true,
        msg: "Member deleted !",
        msg_ar: "تم حذف العضو!",
        type: "success"
      },
      snackbar: false,
      snackbarColor: "",
      text: "",
      paymentInfo: null
    };
  },
  created() {
    this.getDietitianPendingPaymentDetails()
  },
  methods: {
    getDietitianPendingPaymentDetails() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/customer/api/getDietitianPendingPaymentDetails.graphql"),
          variables: {
            id: this.appointmentId,
            followUpId: this.appointment._id
          }
        })
        .refetch({
          variables: {
            id: this.appointmentId,
            followUpId: this.appointment._id
          }
        })
        .then(data => {
          this.paymentInfo = data.data.getDietitianPendingPaymentDetails;
        });
    },
    paymentUrlValue(appointment) {
      let data = false;
      if (appointment) {
        if (appointment.paymentUrl && appointment.payment_status == "PENDING") {
          data = appointment.paymentUrl;
        } else if (
          appointment.additionalPaymentUrl &&
          appointment.additional_payment_status == "PENDING"
        ) {
          data = appointment.additional_payment_status;
        }
        return data;
      }
    },
    checkPaymentStatus() {
      this.btnPaymentStatusLoading = true;
      this.$apollo
        .mutate({
          mutation: require("@/dietitian/api/checkDietitianPaymentStatus.graphql"),
          variables: {
            appointmentId: this.appointmentId,
            followupId: this.appointment._id
          }
        })
        .then(data => {
          this.btnPaymentStatusLoading = false;
          if (data.data.checkDietitianPaymentStatus) {
            this.$root.$emit("updateDietitianAppointments");
            this.button_loading = false;
            this.dialog = false;
            this.text = "Appointment payment received.";
            this.snackbarColor = "success";
            this.snackbar = true;
          } else {
            this.text = "No payment received";
            this.snackbarColor = "error";
            this.snackbar = true;
            this.dialog = false;
            this.button_loading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.btnPaymentStatusLoading = false;
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.dialog = false;
          this.button_loading = false;
        });
    },
    regenerateAppointmentInvoiceUrl() {
      this.btnRegenerateLoading = true;
      this.$apollo
        .mutate({
          mutation: require("../../api/regenerateAppointmentInvoiceUrl.graphql"),
          variables: {
            paymentId: this.paymentInfo._id
          }
        })
        .then(data => {
          this.btnRegenerateLoading = false;
          this.getDietitianPendingPaymentDetails();
          if (data.data.regenerateAppointmentInvoiceUrl) {
            this.$root.$emit("updateDietitianAppointments");
            this.button_loading = false;
            this.dialog = false;
            this.text = "New Appointment Payment link generated.";
            this.snackbarColor = "success";
            this.snackbar = true;
          } else {
            this.text = "Something went wrong";
            this.btnRegnerateLoading = false;
            this.snackbarColor = "error";
            this.snackbar = true;
            this.dialog = false;
            this.button_loading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.btnRegnerateLoading = false;
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.dialog = false;
          this.button_loading = false;
        });
    },
    copyUrl() {
      navigator.clipboard.writeText(this.appointment.paymentUrl);
    },

    close() {
      this.error = null;
      this.dialog = false;
    }
  }
};
</script>
