<template>
  <div class="secondary black--text" style="height: 270px">
    <v-flex class="mt-4 mx-2 mb-4">
      <h4 class="black--text text-h6 font-weight-bold">Promo Code Info</h4>
    </v-flex>
    <v-row class="mb-n8">
      <v-col md="6" class="ms-2">Start Date</v-col>
      <v-col md="5" class="font-weight-medium" v-if="promocode.startDate">
        {{ new Date(parseInt(promocode.startDate)) | moment("MMM DD YYYY") }}
      </v-col>
    </v-row>
    <v-row class="mb-n8">
      <v-col md="6" class="ms-2">End Date</v-col>
      <v-col md="5" class="font-weight-medium" v-if="promocode.endDate">
        {{ new Date(parseInt(promocode.endDate)) | moment("MMM DD YYYY") }}
      </v-col>
    </v-row>
    <v-row class="mb-n8">
      <v-col md="6" class="ms-2">Usage Count</v-col>
      <v-col md="5" class="font-weight-medium" v-if="promocode.usageCount">
        {{ promocode.usageCount }}
      </v-col>
    </v-row>
    <v-row class="mb-n8">
      <v-col md="6" class="ms-2">Discount</v-col>
      <v-col md="5" class="font-weight-medium" v-if="promocode.discount">
        {{ promocode.discount }} KD
      </v-col>
    </v-row>
    <v-row class="mb-n8">
      <v-col md="6" class="ms-2">Max. Discount Amount</v-col>
      <v-col
        md="6"
        class="font-weight-medium"
        v-if="promocode.maxDiscountAmount"
      >
        {{ promocode.maxDiscountAmount }} KD
      </v-col>
    </v-row>
    <v-row class="mb-n8">
      <v-col md="6" class="ms-2">Is Discount In Percent</v-col>
      <v-col md="5" class="font-weight-medium" v-if="promocode.inPercentage">
        <v-icon v-show="promocode.inPercentage" color="btncolor"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!promocode.inPercentage" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    promocode: {
      required: true
    }
  },
  methods: {},
  computed: {}
};
</script>
