var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.plansTaken,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('v-row',[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]):_c('v-row',[_vm._v(" -- ")])]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [(item.package)?_c('span',[_vm._v(" "+_vm._s(item.package.titleEn)+" ")]):_c('span',[_vm._v(" -- ")])]}},{key:"item.noOfDays",fn:function(ref){
var item = ref.item;
return [(
          item.package &&
          _vm.getChoice(item.package.availableChoices, item.choice)
        )?_c('span',[_vm._v(" "+_vm._s(_vm.getChoice(item.package.availableChoices, item.choice) .availableNumOfDays)+" ")]):_c('span',[_vm._v(" -- ")])]}},{key:"item.credit",fn:function(ref){
        var item = ref.item;
return [(item.credit)?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.credit).toFixed(3))+" KD ")]):_c('span',[_vm._v(" -- ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }