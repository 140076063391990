<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="80"
        :loading="loading"
        class="white--text text-capitalize btn_fnt"
        color="btncolor"
      >
        <v-icon small class="me-1">mdi-export</v-icon>Export
      </v-btn>
    </v-layout> 
    <div v-show="false">
      <v-data-table
        id="exportShift" 
        hide-default-footer
        :items-per-page="-1"
        :headers="head"
        :items="shiftList"
        class="elevation-1 "
      >
     <template v-slot:[`item.zone`]="{ item }">
        <span v-if="item.zone"> {{ item.zone.zoneName }}</span>
      </template>
      <template v-slot:[`item.mngDriver`]="{ item }">
        <span v-if="item.mngDriver">
          {{ item.mngDriver.user.firstnameEn }}
          {{ item.mngDriver.user.lastnameEn }}
        </span>
      </template>
      <template v-slot:[`item.evngDriver`]="{ item }">
        <span v-if="item.evngDriver">
          {{ item.evngDriver.user.firstnameEn }}
          {{ item.evngDriver.user.lastnameEn }}
        </span>
      </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils"; 
export default {
  props: {
    headers: {
      required: true
    },
    shiftList: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      head: []
    };
  },

  created() {
    this.head = this.headers.filter(
      x => x.value != "actions" && x.value != "index"
    );
  },

  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportShift", "Shift List", false);
      this.loading = false;
    }
  }
};
</script>
