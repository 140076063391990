<template>
  <div>
    <v-btn
      @click="showDialog"
      color="btncolor"
      class="white--text ma-2 pa-3 text-capitalize"
    >
      Reset Password
      <v-icon>mdi-textbox</v-icon>
    </v-btn>
    <v-dialog v-model="show_dialog_box1" max-width="600px">
      <v-card class="pa-3" color="pop_bg">
        <v-card-title class="justify-center">
          <span class="black--text text-h6">Reset Password</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="closeResetForm"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-form class="pa-sm-4" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg3 mt-2>
                  <h4 class="text-body-2 black--text">New Password</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg6 pe-lg-4>
                  <v-text-field
                    :rules="[
                      requiredValidator('new password'),
                      minLengthValidator('new password', 8)
                    ]"
                    solo
                    dense
                    v-model="password"
                    :append-icon="
                      show1 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
                    "
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-flex>

                <v-flex>
                  <v-btn
                    color="btncolor"
                    class="mt-1 white--text text-capitalize"
                    small
                    depressed
                    @click="generatePassword()"
                    >Generate</v-btn
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg3 mt-2> </v-flex>

                <v-flex xs12 sm8 md9 lg6 pe-lg-4>
                  <v-checkbox
                    color="btncolor"
                    label="Send password as SMS"
                    v-model="isSMSEnabled"
                  ></v-checkbox>
                </v-flex>
              </v-layout>

              <v-layout justify-end class="mb-n5 mb-sm-n10">
                <v-btn
                  :width="$vuetify.breakpoint.xs ? 90 : 120"
                  color="secondary"
                  class="white--text text-capitalize"
                  depressed
                  :disabled="!valid"
                  @click="resetPassword()"
                  :loading="btnloading"
                  >Save</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>
<script>
import {
  requiredValidator,
  minLengthValidator
} from "../../core/methods/validations";
export default {
  methods: {
    closeResetForm() {
      this.show_dialog_box1 = false;
    },
    generatePassword() {
      var randomstring = Math.random()
        .toString(36)
        .slice(-8);
      this.password = randomstring;
      this.show1 = true;
    },
    showDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.show1 = false;
      }
      this.show_dialog_box1 = true;
    },
    resetPassword() {
      if (!this.valid) return false;

      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/resetPassword.graphql"),
          variables: {
            customerId: this.$route.query.customerId,
            password: this.password,
            isSMSEnabled: this.isSMSEnabled
          }
        })
        .then(() => {
          //  this.$root.$emit("updateGetCustomerById");
          this.snackbar = true;
          this.snackbarColor = "success";
          this.text = "Password updated successfully";
          this.btnloading = false;
          this.show_dialog_box1 = false;
          this.close();
        })
        .catch(error => {
          console.log(error);
          this.btnloading = false;
        });
    }
  },
  data() {
    return {
      valid: false,
      show_dialog_box1: false,
      requiredValidator,
      password: "",
      snackbarColor: "",
      minLengthValidator,
      isSMSEnabled: false,
      show1: false,
      btnloading: false,
      text: "",
      snackbar: false
    };
  }
};
</script>
<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: black !important;
}
</style>
