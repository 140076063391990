var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('v-layout',{attrs:{"justify-end":""}},[_c('ExportAccount',{attrs:{"headers":_vm.headers,"accountType":'invoice'}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"headers":_vm.headers,"items":_vm.accountList,"options":_vm.options,"mobile-breakpoint":0,"items-per-page":25,"server-items-length":_vm.totalItemsCount,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.siNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.accountList .map(function(x) { return x._id; }) .indexOf(item._id) + 1 + _vm.options.itemsPerPage * (_vm.options.page - 1))+" ")]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'CustomerDetails',
          query: { customerId: item.customer }
        }}},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.user.firstnameEn))+" "+_vm._s(_vm.capitalizeFirstLetter(item.user.lastnameEn))+" ")]):_vm._e()]}},{key:"item.contact",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.phone)+" ")]}},{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(new Date(parseInt(item.createdAt))))+" ")]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.amount))+" ")]}},{key:"item.discount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.discount))+" ")]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total))+" ")]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('InvoiceDownload',{attrs:{"invoice":item}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }