<template>
  <div>
    <v-container fluid>

      <v-card-text>
        <v-row>
          <h3 class="black--text text-h5 text-sm-h6 ms-2 mb-4">
            Meal Rating
          </h3>

        </v-row>
        <v-divider></v-divider>

        <v-layout class="mt-6 mt-md-4 ms-md-n4 mb-n5"  wrap>


          <v-flex xs8 sm3 md3 lg2>
            <v-menu v-model="menu1" :close-on-content-click="true" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <span class="black--text me-3">From</span>
                <v-text-field :value="fromDate" label="From" solo class="ms-sm-2"
                  append-icon="fa-calendar-alt black--text" v-bind="attrs" v-on="on" dense>
                </v-text-field>
              </template>
              <v-date-picker color="secondary" v-model="fDate" @change="menu1 = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs8 sm3 md3 lg2>
            <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <span class="black--text me-3">To</span>
                <v-text-field :value="toDate" label="To" solo class="ms-sm-2" append-icon="fa-calendar-alt black--text"
                  v-bind="attrs" v-on="on" dense></v-text-field>
              </template>
              <v-date-picker color="secondary" v-model="tDate" @change="menu2 = false"></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>



        <meal-rating-table :fDate="fDate" :tDate="tDate" :mealId="this.mealId"></meal-rating-table>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import MealRatingTable from "../components/MealRatingTable.vue";
// import MealDetails from "../../pages/MealDetails"
import { format, parseISO } from "date-fns";
import moment from "moment";
import { DATE_FORMAT } from "../../../utils/utils";
export default {

  components: {
    "meal-rating-table": MealRatingTable
  },
  props:{
    mealId:{
      default:true
    }

  },


  data() {
    return {
      
      fDate: format(parseISO(moment().subtract(30, "days").toDate().toISOString()), "yyyy-MM-dd"),
      tDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      menu2: false,
      menu1: false,


    }
  },


  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format(DATE_FORMAT) : "";
    },
    toDate() {
      return this.tDate ? moment(this.tDate).format(DATE_FORMAT) : "";
    }
  }

}


</script>