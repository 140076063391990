<template>
  <div>
    <v-btn
      @click="showDialog"
      color="btncolor"
      class="white--text ma-2 pa-3 text-capitalize"
    >
      Send Notification
    </v-btn>
    <v-dialog v-model="show_dialog_box1" max-width="800px">
      <v-card class="pa-3" color="pop_bg">
        <v-card-title class="justify-center">
          <span class="black--text text-h6"> Send Notification </span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="closeResetForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-form class="pa-sm-4" v-model="valid" ref="form">
              <v-layout class="mb-5">
                <v-flex xs12 sm4 md3 lg3 mt-2>
                  <h4 class="text-body-2 black--text">Placeholder</h4>
                </v-flex>
                <v-flex xs12 sm4 md4 lg6 pe-lg-4 class="mt-2">
                  <strong> EN_FIRSTNAME / AR_FIRSTNAME</strong>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap>
                <v-flex xs12 sm4 md3 lg3 mt-2 pe-md-3
                  ><label class="text-body-2 black--text">
                    Include customer first name</label
                  >
                </v-flex>
                <v-flex xs12 sm8 md6>
                  <v-checkbox
                    label="Yes"
                    v-model="isIncludeFirstName"
                  ></v-checkbox>
                </v-flex>
              </v-layout> -->
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg3 mt-2>
                  <h4 class="text-body-2 black--text">Title</h4>
                </v-flex>
                <v-flex xs12 sm4 md4 lg6 pe-lg-4>
                  <v-text-field
                    :rules="[requiredValidator('title')]"
                    solo
                    dense
                    v-model="title"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg3 mt-2>
                  <h4 class="text-body-2 black--text">Image</h4>
                </v-flex>

                <v-flex xs12 sm6 md6 lg8 pe-lg-8>
                  <croppa
                    v-model="myCroppa"
                    :width="225"
                    :height="150"
                    canvas-color="black"
                    :placeholder="'Choose an image'"
                    :placeholder-font-size="16"
                    placeholder-color="yellow"
                    :accept="'image/*'"
                    :file-size-limit="0"
                    :quality="2"
                    :zoom-speed="3"
                    :disabled="false"
                    :disable-drag-and-drop="false"
                    :disable-click-to-choose="false"
                    :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false"
                    :disable-rotation="false"
                    :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false"
                    :show-remove-button="true"
                    :remove-button-color="'red'"
                    :remove-button-size="20"
                  ></croppa>

                  <v-layout class="mb-3">
                    <span style="color: red"> *Use mouse to crop image* </span>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg3 mt-2>
                  <h4 class="text-body-2 black--text">Description</h4>
                </v-flex>

                <v-flex xs12 sm6 md6 lg8 pe-lg-8>
                  <v-textarea
                    rows="5"
                    v-model="description"
                    solo
                    dense
                    :rules="[requiredValidator('description')]"
                  >
                  </v-textarea>
                </v-flex>
              </v-layout>

              <v-layout justify-end class="mb-n5 mb-sm-n10">
                <v-btn
                  :width="$vuetify.breakpoint.xs ? 90 : 120"
                  color="secondary"
                  class="black--text text-capitalize"
                  depressed
                  :disabled="!valid"
                  @click="SendNotification()"
                  :loading="btnloading"
                  >Send</v-btn
                >
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg3 mt-2>
                  <h4 class="text-body-2 black--text"></h4>
                </v-flex>
                <!-- {{notificationModes}}
                <v-flex xs12 sm6 md6 lg8 pe-lg-8>
                  <v-checkbox v-model="notificationModes" value="WHATSAPP" label="Sent through watsapp."></v-checkbox>
                  <v-checkbox v-model="notificationModes" value="PUSH" label="Push Notification"></v-checkbox>
                </v-flex> -->
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">
      {{ text }}
    </v-snackbar>
  </div>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  methods: {
    closeResetForm() {
      this.show_dialog_box1 = false;
    },

    showDialog() {
      this.show_dialog_box1 = true;
    },
    SendNotification() {
      if (!this.valid) return false;

      this.btnloading = true;

      let customers = new Array();

      customers.push(this.$route.query.customerId);
      this.isIncludeFirstName = this.isIncludeFirstName ? this.isIncludeFirstName : false;
      if (this.myCroppa.hasImage()) {
        this.myCroppa.generateBlob((blob) => {
          this.$apollo
            .mutate({
              mutation: require("../../notifications/api/createNotification.graphql"),
              variables: {
                title: this.title,
                description: this.description,
                reciverType: "",
                is_IncludeFirstName: this.isIncludeFirstName,
                customer: customers,
                image: blob,
              },
            })
            .then((data) => {
              this.snackbar = true;
              this.snackbarColor = "success";
              this.text = "Notification send successfully";
              this.btnloading = false;
              this.$refs.form.reset();
              this.myCroppa.remove();
              this.show_dialog_box1 = false;
              this.close();
              console.log(data);
            })
            .catch((error) => {
              this.btnloading = false;
              console.log(error);
            });
        });
      } else {
        this.$apollo
          .mutate({
            mutation: require("../../notifications/api/createNotification.graphql"),
            variables: {
              title: this.title,
              description: this.description,
              reciverType: "",
              is_IncludeFirstName: this.isIncludeFirstName,
              customer: customers,
              notificationModes: this.notificationModes,
            },
          })
          .then((data) => {
            this.snackbar = true;
            this.snackbarColor = "success";
            this.text = "Notification send successfully";
            this.btnloading = false;
            this.$refs.form.reset();
            this.show_dialog_box1 = false;
            this.close();
            console.log(data);
          })
          .catch((error) => {
            this.btnloading = false;
            console.log(error);
          });
      }
    },
  },
  data() {
    return {
      valid: false,
      notificationModes: ["PUSH"],
      show_dialog_box1: false,
      requiredValidator,
      title: "",
      description: "",
      password: "",
      snackbarColor: "",
      btnloading: false,
      text: "",
      snackbar: false,
      isIncludeFirstName: false,
      myCroppa: null,
    };
  },
};
</script>
