<template>
  <div class="mt-md-n6">
    <v-btn
      @click="exportExcel"
      width="150"
      color="btncolor"
      class="white--text text-capitalize"
      :loading="loading"
    >
      <v-icon class="me-1" size="17">fa-file-export</v-icon>Export
    </v-btn>
    <div v-show="false">
      <v-data-table
        id="printMenuItems"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="exportMenuList"
        class="elevation-1 mt-6"
      >
        <template v-slot:[`item.dietPlans`]="{ item }">
          <div v-for="plan in item.dietPlans" :key="plan._id">
            {{ plan.nameEn }}
          </div></template
        >
        <template v-slot:[`item.active`]="{ item }">
          <span v-if="item.active == true"> Active </span>
          <span v-if="item.active == false"> Inactive </span></template
        >
        <template v-slot:[`item.rating`]="{ item }">
          <span v-if="item.averageRating.rating == null"> - </span>
          <span v-else> {{ item.averageRating.rating }} </span></template
        >
        <template v-slot:[`item.availableWeekdays`]="{ item }">
          {{ item.availableWeekdays.join(",") }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    dayFilter: {
      required: true,
    },
    typeFilter: {
      required: true,
    },
    searchKey: {
      required: true,
    },
    searchCounter: {
      required: true,
    },
    statusFilter: {
      required: true,
    },
  },
  data() {
    return {
      exportMenuList: [],
      dayData: "",
      typeData: "",
      loading: false,
      variables: {
        limit: 0,
        skip: 0,
        category: "",
        sortDesc: false,
        sortBy: " ",
        dayFilter: this.dayFilter,
        typeFilter: this.typeFilter,
        statusFilter: this.statusFilter,
      },
      headers: [
        {
          text: "Menu Code",
          value: "itemCode",
          width: 100,
        },
        {
          text: "Meal(English)",
          value: "nameEn",
          width: 180,
        },
        {
          text: "Meal(Arabic)",
          value: "nameAr",
          width: 180,
        },
        {
          text: "Type",
          value: "dietPlans",
          width: 100,
          sortable: false,
        },
        {
          text: "Customer Rating",
          value: "rating",
          sortable: false,
          width: 100,
        },
        { text: "Status", value: "active", width: 90, sortable: false },
        // { text: "Price", value: "price" },
        {
          text: "Days",
          value: "availableWeekdays",
          sortable: false,
          width: 150,
        },
      ],
    };
  },
  // watch: {
  //   dayFilter: {
  //     handler() {
  //       this.getAllMenuItems();
  //     },
  //     deep: true
  //   },
  //   typeFilter: {
  //     handler() {
  //       this.getAllMenuItems();
  //     },
  //     deep: true
  //   },
  //   searchCounter: {
  //     handler() {
  //       this.getAllMenuItems();
  //     },
  //     deep: true
  //   },
  //   statusFilter: {
  //     handler() {
  //       this.getAllMenuItems();
  //     },
  //     deep: true
  //   }
  // },
  created() {
    // this.getAllMenuItems();
  },
  methods: {
    getAllMenuItems() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllMenuItems.graphql"),
          variables: {
            limit: 0,
            skip: 0,
            category: "",
            sortDesc: false,
            searchKey: this.searchKey,
            sortBy: " ",
            dayFilter: this.dayFilter,
            typeFilter: this.typeFilter,
            statusFilter: this.statusFilter,
          },
        })
        .refetch({
          variables: {
            limit: 0,
            skip: 0,
            category: "",
            sortDesc: false,
            searchKey: this.searchKey,
            sortBy: " ",
            dayFilter: this.dayFilter,
            typeFilter: this.typeFilter,
            statusFilter: this.statusFilter,
          },
        })
        .then((data) => {
          this.exportMenuList = data.data.getAllMenuItems.menuItem;
          setTimeout(() => {
            Utils.htmlToExcel("printMenuItems", "Meals", false);
          }, 100);
          this.loading = false;
        });
    },
    exportExcel() {
      this.getAllMenuItems();
    },
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
  },
};
</script>
