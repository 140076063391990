var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.offerList != null)?_c('div',[_c('v-layout',{staticClass:"mt-4 ",attrs:{"justify-end":""}},[_c('ExportOffer',{attrs:{"headers":_vm.headers,"offerList":_vm.offerList}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"headers":_vm.headers,"items":_vm.offerList,"mobile-breakpoint":0,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.active),expression:"item.active"}],attrs:{"color":"btncolor"}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!item.active),expression:"!item.active"}],attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.startDate)),"DD/MM/YYYY"))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.endDate)),"DD/MM/YYYY"))+" ")]}},{key:"item.promoCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.promoCode.promoCode)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(_vm.hasRole(_vm.AdminUserPermissions.OFFER_EDIT))?_c('OfferUpdateForm',{attrs:{"offer":item}}):_vm._e(),(_vm.hasRole(_vm.AdminUserPermissions.OFFER_DELETE))?_c('deleteOffer',{attrs:{"offer":item}}):_vm._e()],1)]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }