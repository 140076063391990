<template>
  <v-container class="px-0" fluid>
    <v-layout wrap class="mt-6">
      <h3 class="black--text text-h6 font-weight-bold">Configurations</h3>
      <v-spacer></v-spacer>
      <SplashScreenContent class="mt-2 mt-md-0" />
      <NotififcationConfiguration class="ms-md-5 mt-4 mt-md-0" />
      <DieititianBookingDescription class="ms-md-5 mt-4 mt-md-0" />
    </v-layout>
    <v-form v-model="valid" ref="profileform">
      <v-container class="px-0" fluid>
        <v-layout class="mt-5 mt-md-0" wrap>
          <v-flex xs12 sm4 md3>
            <v-text-field label="Whatsapp Number" v-model="whatsappNumber" :rules="[
              requiredValidator('Whatsapp Number'),
              minLengthValidator('phone', 8)
            ]" solo dense></v-text-field>
          </v-flex>
          <v-flex xs12 sm4 md1 class="ml-5"> Default Shift </v-flex>
          <v-flex xs12 sm4 md3>
            <v-select :items="['MORNING', 'EVENING']" :rules="[requiredValidator('shift')]" v-model="defaultShift" dense
              attach solo></v-select>
          </v-flex>
        </v-layout>
        <v-layout class="mt-5 mt-md-0" wrap>
          <v-flex xs12 sm4 md5>
            <v-text-field label="Default Whatsapp Message" v-model="whatsappMessage"
              :rules="[requiredValidator('Whatsapp Message')]" solo dense></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <h3 class="black--text text-h6 font-weight-bold">
              Default Meal Selection
            </h3>
          </v-flex>
          <v-flex xs12 sm4 md3 class="mt-4">
            <v-text-field label="Default Meal Selection" v-model="defaultMealSelectionDay" :rules="[positive]" solo
              dense type="number" suffix="days"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <h3 class="black--text text-h6 font-weight-bold">
              Default Package Selection
            </h3>
          </v-flex>
          <v-flex xs12 sm4 md3 class="mt-2">
            <v-text-field label="Default Meal Selection" v-model="defaultPackageSelectionDay" :rules="[positive]" solo
              dense type="number" suffix="days"></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>

      <v-flex xs12 sm12 md10 lg9 class="pe-2">
        <v-row justify="end">
          <v-btn color="btncolor" :width="$vuetify.breakpoint.smAndUp ? 150 : 100"
            class="white--text text-capitalize font-weight-bold" :loading="btnConfigloading" :disabled="!valid"
            @click="updateConfigurations">Update</v-btn>
        </v-row>
      </v-flex>
    </v-form>

    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">{{ text }}</v-snackbar>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import { minLengthValidator } from "../../core/methods/validations";
import NotififcationConfiguration from "../components/NotififcationConfiguration";
import DieititianBookingDescription from "../components/DieititianBookingDescription";
import SplashScreenContent from "../components/SplashScreenContent.vue";

export default {
  components: { NotififcationConfiguration, SplashScreenContent, DieititianBookingDescription },
  data() {
    return {
      minLengthValidator: minLengthValidator,
      requiredValidator: requiredValidator,
      btnConfigloading: false,
      whatsappNumber: "",
      whatsappMessage: "",
      snackbarColor: "success",
      successAlert: false,
      snackbar: false,
      text: "",
      valid: true,
      defaultMealSelectionDay: "",
      defaultPackageSelectionDay: ""
    };
  },
  apollo: {
    getAppConfigurations() {
      return {
        query: require("../api/getAppConfigurations.graphql"),
        result({ data }) {
          this.whatsappNumber = data.getAppConfigurations.whatsappNumber;
          this.whatsappMessage = data.getAppConfigurations.whatsappMessage;
          this.defaultShift = data.getAppConfigurations.defaultShift;
          this.defaultMealSelectionDay =
            data.getAppConfigurations.defaultMealSelectionDay;
          this.defaultPackageSelectionDay =
            data.getAppConfigurations.defaultPackageSelectionDay;
        }
      };
    }
  },
  methods: {
    positive(x) {
      if (x) {
        if (parseInt(x) < 1) {
          return "Default meal selection should be greater than 1 day";
        }
        if (!Number.isInteger(parseFloat(x))) {
          return "Default meal selection should be integer";
        }
        return true;
      } else return "Default meal selection is required";
    },
    updateConfigurations() {
      this.btnConfigloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateConfigurations.graphql"),
          variables: {
            whatsappNumber: this.whatsappNumber,
            whatsappMessage: this.whatsappMessage,
            defaultShift: this.defaultShift,
            defaultMealSelectionDay: parseInt(this.defaultMealSelectionDay),
            defaultPackageSelectionDay: parseInt(
              this.defaultPackageSelectionDay
            )
          }
        })
        .then(() => {
          this.text = "Configuration updated";
          this.snackbar = true;
          this.btnConfigloading = false;
          this.snackbarColor = "success";
          this.dialog = false;
        })
        .catch(error => {
          this.btnConfigloading = false;
          console.log(error);
        });
    }
  }
};
</script>

<style>
.update_btn {
  border: 2px solid #f8166c;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
