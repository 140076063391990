var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dietitianAppointmentList,"expanded":_vm.expanded,"item-key":"_id","show-expand":"","options":_vm.options},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'CustomerDetails',
          params: { customerId: item.customer._id },
          query: { customerId: item.customer._id }
        }}},[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]):_vm._e()]}},{key:"item.bookedDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookedDate(item))+" ")]}},{key:"item.appointmentDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.firstBooking.appointmentDate)),"DD MMM YYYY"))+" : "+_vm._s(item.firstBooking.appointmentTime)+" ")]}},{key:"item.mode",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? _vm.mode(item.firstBooking) : "--")+" ")]}},{key:"item.shift",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? _vm.shift(item.firstBooking) : "--")+" ")]}},{key:"item.followUpType",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? _vm.followUpType(item.firstBooking) : "--")+" ")]}},{key:"item.actionType",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? item.firstBooking.actionType : "--")+" ")]}},{key:"item.actionStatus",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstBooking ? item.firstBooking.actionStatus : "--")+" ")]}},{key:"item.comment",fn:function(ref){
        var item = ref.item;
return [(item.firstBooking)?_c('span',[(item.firstBooking.comment.length)?_c('span',_vm._l((item.firstBooking.comment),function(comment){return _c('ul',{key:comment.message},[_c('li',[_vm._v(" "+_vm._s(comment.message ? comment.message : "--")+" ")]),_c('li',{staticStyle:{"list-style-type":"none"}},[_vm._v(" "+_vm._s(comment.actionStatusFrom || comment.actionStatusTo ? " ( " : "")+" "+_vm._s(comment.actionStatusFrom ? comment.actionStatusFrom : "")+" "+_vm._s(comment.actionStatusTo ? " - " + comment.actionStatusTo : "")+_vm._s(comment.actionStatusFrom || comment.actionStatusTo ? " ) " : "")+" ")])])}),0):_vm._e()]):_vm._e()]}},{key:"item.bookingFee",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookingFee(item.firstBooking))+" ")]}},{key:"item.additionalFee",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.additionalFee(item.firstBooking))+" ")]}},{key:"item.totalFee",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totalFee(item.firstBooking))+" ")]}},{key:"item.paymentStatus",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:_vm.paymentStatus(item.firstBooking) == 'SUCCESS'
            ? 'green--text'
            : _vm.paymentStatus(item.firstBooking) == 'PENDING'
            ? 'orange--text'
            : _vm.paymentStatus(item.firstBooking) == 'UPCOMING'
            ? 'blue--text'
            : _vm.paymentStatus(item.firstBooking) == 'EXPIRED'
            ? 'red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.paymentStatus(item.firstBooking))+" ")])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [(item.remainingFollowUp.length)?_c('td',{staticClass:"ma-0 pa-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":headers,"items":item.remainingFollowUp,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.dietitian",fn:function(ref){return [_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
                name: 'DietitianDetails',
                params: { dietitianId: item.dietitian._id },
                query: { dietitianId: item.dietitian._id }
              }}},[_vm._v(" "+_vm._s(item.dietitian.user.firstnameEn[0].toUpperCase() + item.dietitian.user.firstnameEn.substring(1))+" "+_vm._s(item.dietitian.user.lastnameEn[0].toUpperCase() + item.dietitian.user.lastnameEn.substring(1))+" ")])]}},{key:"item.bookedDate",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.bookedDate(item))+" ")]}},{key:"item.appointmentDate",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.appointmentDate)),"DD MMM YYYY"))+" : "+_vm._s(item.appointmentTime)+" ")]}},{key:"item.mode",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mode(item))+" ")]}},{key:"item.shift",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shift(item))+" ")]}},{key:"item.followUpType",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.followUpType(item))+" ")]}},{key:"item.actionType",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.actionType)+" ")]}},{key:"item.actionStatus",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.actionStatus)+" ")]}},{key:"item.comment",fn:function(ref){
              var item = ref.item;
return [(item.comment.length)?_c('span',_vm._l((item.comment),function(comment){return _c('ul',{key:comment.message},[_c('li',[_vm._v(" "+_vm._s(comment.message ? comment.message : "--")+" ")]),_c('li',{staticStyle:{"list-style-type":"none"}},[_vm._v(" "+_vm._s(comment.actionStatusFrom || comment.actionStatusTo ? " ( " : "")+" "+_vm._s(comment.actionStatusFrom ? comment.actionStatusFrom : "")+" "+_vm._s(comment.actionStatusTo ? " - " + comment.actionStatusTo : "")+_vm._s(comment.actionStatusFrom || comment.actionStatusTo ? " ) " : "")+" ")])])}),0):_vm._e()]}},{key:"item.bookingFee",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookingFee(item))+" ")]}},{key:"item.additionalFee",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.additionalFee(item))+" ")]}},{key:"item.totalFee",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totalFee(item))+" ")]}},{key:"item.paymentStatus",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:_vm.paymentStatus(item) == 'SUCCESS'
                  ? 'green--text'
                  : _vm.paymentStatus(item) == 'PENDING'
                  ? 'orange--text'
                  : _vm.paymentStatus(item) == 'UPCOMING'
                  ? 'blue--text'
                  : _vm.paymentStatus(item) == 'EXPIRED'
                  ? 'red--text'
                  : ''},[_vm._v(" "+_vm._s(_vm.paymentStatus(item))+" ")])]}}],null,true)})],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }