<template>
  <div>
    <v-container class="pa-4 pa-sm-8" fluid>
      <v-row class="mt-3 mt-sm-0 mb-4">
        <h2 class="black--text text-h5 font-weight-bold ms-3">
          Monthly Utilization Report
        </h2>
      </v-row>
      <v-layout wrap class="me-3">
        <v-flex xs8 sm4 md3 class="mr-5">
          <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="formattedMonth" readonly label="Month" solo
                append-icon="fa-calendar-alt black--text" v-bind="attrs" v-on="on" dense></v-text-field>
            </template>
            <v-date-picker color="secondary" v-model="month" type="month" :max="maxDate" @change="menu = false">
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-flex>
        <!-- <v-spacer></v-spacer> -->
        <v-flex xs8 sm4 md3>
          <!-- <span class="black--text ms-3"></span>   -->
          <v-text-field placeholder="Name.." v-model="search" solo dense @keydown.enter="runSearch()">
            <template v-slot:append>
              <v-icon color="black" v-if="search.length > 0" @click="clearSearch()">mdi-close</v-icon>
              <v-icon color="black" v-if="search.length == 0" @click="runSearch()">mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-flex>
      </v-layout>

      <monthly-usage-table :month="month" :search="search"></monthly-usage-table>
    </v-container>
  </div>
</template>

<script>
import MonthlyUsageTable from "../components/MonthlyUsageTable.vue";

import moment from "moment";

export default {
  components: {
    "monthly-usage-table": MonthlyUsageTable
  },
  computed: {

    formattedMonth() {
      return this.month
        ? moment()
          .set({
            year: this.month.split("-")[0],
            month: this.month.split("-")[1] - 1,
            day: 2
          })
          .format("MMMM - YYYY")
        : "";
    }
  },
  data() {
    return {
      search: "",
      searchKey: "",
      menu: false,
      month: new Date().toISOString().substr(0, 7),
      maxDate: moment(new Date()).format("YYYY-MM"),
    };
  },

  methods: {
    clearSearch() {
      this.search = "";
      this.searchKey = "";
    },
    runSearch() {
      this.searchKey = this.search;
    }
  }

};
</script>
