<template>
    <div>
      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="red" class="ms-2">mdi-delete</v-icon>
        </template>
        <v-card class="pa-6" color="pop_bg">
          <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text"
            >Do you really want to delete this?</v-card-text
          >
  
          <v-card-text v-if="error" class="error--text font-weight-bold">{{
            error
          }}</v-card-text>
          <v-card-actions justify="center">
            <v-row justify="center">
              <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
              <v-btn
                color=" mx-2 success"
                depressed
                :loading="button_loading"
                @click="
                  button_loading = true;
                  deleteScheduledNotification(notification._id);
                "
                >Yes</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        timeout="3000"
        right
        top
        :color="snackbarColor"
      >
        {{ text }}
      </v-snackbar>
    </div>
  </template>
  <script>
  export default {
    props: {
      notification: {
        required: true,
      },
    },
    data() {
      return {
        valid: true,
        dialog: false,
        button_loading: false,
        show_alert: false,
        error: null,
        alertData: {
          value: true,
          msg: "Member deleted !",
          msg_ar: "تم حذف العضو!",
          type: "success",
        },
        snackbar: false,
        snackbarColor: "",
        text: "",
      };
    },
    methods: {
      deleteScheduledNotification(id) {
        // console.log(id);
        this.$apollo
          .mutate({
            mutation: require("../api/deleteScheduledNotification.graphql"),
            variables: {
              id,
            },
            update: (store) => {
              const query = {
                query: require("../api/getAllScheduleNotification.graphql"),
                variables: {
                  limit: 25,
                  skip: 0,
                },
              };
              // Read the query from cache
              const data = store.readQuery(query);
              // console.log(data);
              // Look for the deleted item
              const index = data.getAllScheduleNotification.notifications.findIndex(
                (m) => m._id === id
              );
              // console.log(index);
              if (index !== -1) {
                // Mutate cache result
                data.getAllScheduleNotification.notifications.splice(index, 1);
                // Write back to the cache
                store.writeQuery({
                  ...query,
                  data,
                });
              }
            },
          })
          .then(() => {
            this.button_loading = false;
            this.error = null;
            this.dialog = false;
            this.text = "Notification Deleted";
            this.snackbarColor = "success";
            this.snackbar = true;
          })
          .catch((error) => {
            // Error
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
              this.text = error.graphQLErrors[0].message;
              this.snackbarColor = "error";
              this.snackbar = true;
              this.dialog = false;
            }
            this.button_loading = false;
          });
      },
      close() {
        this.error = null;
        this.dialog = false;
      },
    },
  };
  </script>