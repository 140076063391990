<template>
  <div class="mt-6">

    <v-sheet elevation="1" height="64" :width="
      $vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.sm ? 600 : 340
    ">
      <v-toolbar flat>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar" class="text-caption text-sm-body-2">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="white--text" color="secondary" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet :height="
      $vuetify.breakpoint.mdAndUp ? 500 : $vuetify.breakpoint.sm ? 400 : 300
    " :width="
  $vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.sm ? 600 : 340
">
      <v-calendar ref="calendar" v-model="focus" color="white--text btncolor" :events="events"
        :event-color="getEventColor" :type="type" @click:event="showEvent" @click:more="viewDay" @click:date="viewDay"
        @change="updateRange">
      </v-calendar>
    </v-sheet>
    <div class="mt-10"></div>
  </div>
</template>

<script>
import moment from "moment";
export default {


  data: () => ({
    focus: "",
    type: "month",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days"
    },
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1"
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party"
    ],
    // appoinments: []
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  watch: {
    // appoinments: {
    //   handler() {
    //     this.updateRange();
    //   },
    //   deep: true
    // }
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getSlotAppointmentDetails(filterData) {
      console.log("data");
      let input = { skip: 1, limit: 1000 };
      let dietitanId = "";
      if (this.$route.name === "Appointments") {
        let data = JSON.parse(localStorage.getItem("DONE_USER"));
        if (data) {
          dietitanId = data._id;
        }
      }
      return new Promise((resolve, reject) => {
        this.$apollo
          .watchQuery({
            query: require("../api/getAllSlotAppointments.graphql"),
            variables: {
              input,
              filterData,
              dietitanId
            }
          })
          .refetch({})
          .then(data => {
            this.loading = false;

            if (data.data) {
              let appoinments =
                data.data.getAllSlotAppointments.slotAppointments;
              resolve(appoinments);
            }
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
            reject(error);
          });
      });
    },
    updateRange() {
      let filterData = new Object();
      filterData.startDate = this.$refs.calendar.lastStart.date;
      filterData.endDate = this.$refs.calendar.lastEnd.date;
      this.getSlotAppointmentDetails(filterData)
        .then(data => {
          console.log(data);
          const events = [];
          for (let element of data) {
            try {
              if (element.followUp) {
                {

                  let appointmentDate = moment(
                    new Date(parseInt(element.followUp.appointmentDate))
                  ).format("YYYY-MM-DD");

                  let hours = element.followUp.appointmentTime.split(":")[0];
                  let minutes = element.followUp.appointmentTime.split(":")[1].split(' ')[0];
                  let shift = element.followUp.appointmentTime.split(":")[1].split(' ')[1];
                  if (shift == 'PM') {
                    hours = parseInt(hours) + 12;
                  }

                  var timeString = hours + ':' + minutes + ':00';
                  var appointmentStartTime = new Date(appointmentDate + ' ' + timeString);
                  let appointmentEndTime = moment(appointmentStartTime).add(element.dietitian.slotDuration, "minutes").toDate()

                  const allDay = this.rnd(0, 3) === 0;
                  let paymentStatus =
                    element.followUp.actionStatus == "FINISHED"
                      ? element.followUp.additional_payment_status
                      : element.followUp.payment_status;
                  if (element.customer) {

                    events.push({
                      name: `${element.customer.user
                        ? element.customer.user.firstnameEn
                        : ""
                        } ${element.customer.user
                          ? element.customer.user.lastnameEn
                          : ""
                        } `,
                      start: appointmentStartTime,
                      end: appointmentEndTime,
                      color:
                        paymentStatus == "SUCCESS"
                          ? "green"
                          : paymentStatus == "PENDING"
                            ? "orange"
                            : paymentStatus == "CANCELLED"
                              ? "blue"
                              : paymentStatus == "FAILED"
                                ? "red"
                                : "",
                      // color: "green",
                      timed: !allDay
                    });
                  }
                }
              }
            } catch (error) {
              console.log(error);
            }
          }


          this.events = events;
          console.log("this.events");
          console.log(this.events);
        })
        .catch(e => console.log(e));

    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    }
  }
};
</script>
