<template>
  <v-container class="px-0 mx-0">
      <v-layout justify-end >
    <v-btn
      @click="exportExcel"
      width="150"
      :loading="loading"
      class="mt-n3 white--text text-capitalize btn_fnt"
      color="btncolor"
    >
      <v-icon small class="me-2">mdi-export</v-icon>Export
    </v-btn>
    </v-layout>
    <div  v-show="false"> 
      <v-data-table
        :id="exportTable"
        hide-default-footer
        :items-per-page="-1"
        :headers="head"
        :items="accountList"
        class="elevation-1 mt-6"
      >
        <template v-slot:[`item.customerName`]="{ item }">
          {{ toTitleCase(item.user.firstnameEn) }}
          {{ toTitleCase(item.user.lastnameEn) }} 
        </template>
        <template v-slot:[`item.contact`]="{ item }">
          {{ item.user.phone }}
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ dateFormat(new Date(parseInt(item.createdAt))) }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ numberWithCommas(item.amount) }}
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          {{ numberWithCommas(item.discount) }}
        </template>
        <!-- <template v-slot:[`item.taxes`]="{ item }">
        {{ numberWithCommas(item.tax) }}
      </template> -->
        <template v-slot:[`item.total`]="{ item }">
          {{ numberWithCommas(item.total) }}
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment' 
import { Utils } from "../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    },
    accountType: {
      required: true
    }
  },
  data() {
    return {
      accountList: [],
      loading: false,
      variables:{
          limit:0,
          skip:0
      } ,
      head:[],
      exportTable:''
    };
  },

  created() {
       this.head=this.headers.filter(x=>x.value!='action' && x.value!='siNo') 
      if(this.accountType=='invoice'){
         this.exportTable='invoice'
      }else{
          this.exportTable='creditNote' 
      }
    // this.subscribePackagesEndDateReminder();
  },

  methods: {
    getAllInvoiceTransaction() {
      this.$apollo
        .query({
          query: require("../api/getAllInvoiceTransaction.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data.getAllInvoiceTransactions) {
            this.accountList =
              data.data.getAllInvoiceTransactions.transactionList;
            console.log(this.accountList.length);
             setTimeout(() => {
              Utils.htmlToExcel(this.exportTable, "Invoice", false);
              this.loading = false;
            }, 100);
          }
          this.loading = false;
        });
    },
      getAllCreditNoteTransactions() {
      this.$apollo
        .query({
          query: require("../api/getAllCreditNoteTransaction.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data.getAllCreditNoteTransactions) {
            this.accountList =
              data.data.getAllCreditNoteTransactions.transactionList;
            console.log(this.accountList.length);
             setTimeout(() => {
              Utils.htmlToExcel(this.exportTable, "Credit Note", false);
              this.loading = false;
            }, 100);
          }
          this.loading = false;
        });
    },
    exportExcel() {
      this.loading = true;
         if(this.accountType=='invoice'){
         console.log("one")
         this.getAllInvoiceTransaction() 
      }else if(this.accountType=='creditNote'){
          console.log("two")
          this.getAllCreditNoteTransactions()
      }
    },
    toTitleCase(str) { 
      if (!str) return "";
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    numberWithCommas(x) {
      if (x)
        return x
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    },
     dateFormat(date) {
      return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY, h:mm:ss A");
    }
  }
};
</script>
