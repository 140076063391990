

<template>
  <v-container fluid class="px-0">
    <v-layout wrap>
      <v-flex xs8 sm4 md3>
        <v-select :items="packageStatuses" label="Status" v-model="packageStatus" @change="onStatusChange"
          item-text="text" item-value="value" attach solo dense></v-select>
      </v-flex>
      <v-flex xs12 sm5 md2 class="me-2 ml-3" >
        <v-autocomplete :items="adminUsers" label="User" v-model="createdBy" @change="onStatusChange" item-text="name"
          item-value="_id" attach dense solo clearable></v-autocomplete>
      </v-flex>

      <v-flex xs8 sm3 md3 lg2>
        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDate" label="From" solo class="ms-sm-2" append-icon="fa-calendar-alt black--text"
              v-bind="attrs" v-on="on" @click:clear="fDate = null" dense></v-text-field>
          </template>
          <v-date-picker color="secondary" v-model="fDate" @change="selectFromDate"></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs8 sm3 md3 lg2>
        <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDate" label="To" class="ms-sm-2" solo append-icon="fa-calendar-alt black--text"
              v-bind="attrs" v-on="on" @click:clear="tDate = null" dense></v-text-field>
          </template>
          <v-date-picker color="secondary" v-model="tDate" @change="selectToDate"></v-date-picker>
        </v-menu>
      </v-flex>
      <!-- <v-btn
        @click="exportExcel"
        width="150"
        class="text-capitalize black--text"
        color="secondary"
      >
        <v-icon small class="me-2">fa-file-export</v-icon>Export
      </v-btn> -->

      <v-flex sm12 md2 lg2>
        <v-layout class="text-end">
          <ExportSalesOrder v-if="hasRole(AdminUserPermissions.SALES_ORDER_EXPORT)" :headers="headers"
            :status="variables.status" :createdBy="createdBy" :search="search" :packageStatus="packageStatus" :fDate="fDate" :tDate="tDate" />
        </v-layout>
      </v-flex>
    </v-layout>

    <v-data-table :mobile-breakpoint="0" :headers="headers" :items="salesOrderList" class="elevation-1 mt-4"
      :loading="loading" :options.sync="options" :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">
      <template v-slot:[`item.date`]="{ item }">{{
      getDate(new Date(parseInt(item.date))) | moment("MMM DD YYYY hh:mm")
      }}
      </template>
      <template v-slot:[`item.startDate`]="{ item }">{{
      new Date(parseInt(item.startDate)) | moment("MMM DD YYYY")
      }}</template>
      <template v-slot:[`item.endDate`]="{ item }">{{
      new Date(parseInt(item.endDate)) | moment("MMM DD YYYY")
      }}</template>
      <template v-slot:[`item.package_price`]="{ item }">{{
      numberWithCommas(item.package_price)
      }}</template>
      <template v-slot:[`item.discount`]="{ item }">{{
      numberWithCommas(item.discount)
      }}</template>
      <template v-slot:[`item.net_price`]="{ item }">{{ numberWithCommas(item.net_price) }}
      </template>
      <template v-slot:[`item.phone`]="{ item }">{{ item.customer.phone ? item.customer.phone : "--" }}
      </template>
      <template v-slot:[`item.total`]="{ item }">
        <PriceSplitup :subscribedPackage="item" />
      </template>
      <template v-slot:[`item.createdBy`]="{ item }">
        <span v-if="item.createdBy">
          {{ capitalizeFirstLetter(item.createdBy.firstnameEn) }}
          {{ capitalizeFirstLetter(item.createdBy.lastnameEn) }}
        </span>
      </template>
      <template v-slot:[`item.paymentStatus`]="{ item }">
        <span :class="
          item.paymentStatus == 'ACTIVE'
            ? 'green--text'
            : item.paymentStatus == 'Payment Pending'
              ? 'orange--text'
              : item.paymentStatus == 'UPCOMING'
                ? 'blue--text'
                : item.paymentStatus == 'EXPIRED'
                  ? 'red--text'
                  : ''
        ">
          {{ item.paymentStatus }}</span>
      </template>
      <template v-slot:[`item.referredUser`]="{ item }">
        <span v-if="item.referredByUser">
          {{ toTitleCase(item.referredByUser.firstnameEn) }}
          {{ toTitleCase(item.referredByUser.lastnameEn) }}
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:[`item.referredUserRole`]="{ item }">
        <span v-if="item.referredByUser">
          {{ item.referredByUser.role }}
        </span>
        <span v-else>--</span>
      </template>
      <!-- :item-class="itemRowBackground" -->
      <template v-slot:[`item.customer`]="{ item }">
        <router-link v-if="!item.is_deleted" :to="{
          name: 'CustomerDetails',
          params: { customerId: item.customerId },
          query: { customerId: item.customerId },
        }" class="mx-auto">
          {{ toTitleCase(item.customer.firstnameEn) }}
          {{ toTitleCase(item.customer.lastnameEn) }}
          <!-- <div class="mx-1" /> -->
        </router-link>

        <span v-else>
          <!-- <div class="mx-1" /> -->
          {{ toTitleCase(item.customer.firstnameEn) }}
          {{ toTitleCase(item.customer.lastnameEn) }}
        </span>
        <!-- </v-row> -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import { TIME_ZONE } from "../../utils/utils";
import ExportSalesOrder from "../components/ExportSalesOrder.vue";
export default {
  components: { ExportSalesOrder },
  apollo: {
    getAdminUsers() {
      debugger
      return {
        query: require("../api/getAdminUsers.graphql"),
        result({ data }) {
          this.adminUsers = data.getAdminUsers;
          if (this.adminUsers) {
            this.adminUsers.forEach(element => {
              element.name = `${this.capitalizeFirstLetter(
                element.firstnameEn
              )} ${this.capitalizeFirstLetter(element.lastnameEn)}`;
            });
          }
        }
      };
    }
  },


  data: () => ({
    fDate: format(
      parseISO(moment().subtract(7, "days").toDate().toISOString()),
      "yyyy-MM-dd"
    ),
    tDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    createdBy: "",
    adminUsers: [],
    menu1: false,
    menu2: false,
    moment: moment,
    options: {
      itemsPerPage: 25,
      page: 1,
    },
    loading: false,
    totalItemsCount: 0,
    variables: {
      limit: 25,
      skip: 0,
      status: "",
      startDate: "",
      endDate: "",
      discountOnly: false,
      createdBy: "",
    },
    packageStatuses: [
      { text: "All", value: "" },
      { text: "Active", value: "ACTIVE" },
      { text: "Upcoming", value: "UPCOMING" },
      { text: "Pending", value: "PENDING" },
      { text: "Expired", value: "EXPIRED" },
    ],
    packageStatus: "",
    search: "",
    headers: [
      {
        text: "Order ID",
        value: "order_number",
        width: 120,
      },
      { text: "Date", value: "date", width: 120, sortable: false },
      { text: "Customer Name", value: "customer", width: 180, sortable: false },
      { text: "Phone", value: "phone", width: 180, sortable: false },
      { text: "Created By", value: "createdBy", width: 120, sortable: false },
      {
        text: "Referred User",
        value: "referredUser",
        width: 180,
        sortable: false,
      },
      {
        text: "Referred User Role",
        value: "referredUserRole",
        width: 180,
        sortable: false,
      },
      { text: "Start Date", value: "startDate", width: 120, sortable: false },
      { text: "End Date", value: "endDate", width: 120, sortable: false },
      { text: "Plan Name", value: "plan", width: 150, sortable: false },
      {
        text: "Days",
        value: "availableNumOfDays",
        width: 75,
        sortable: false,
      },
      {
        text: "Plan Status",
        value: "paymentStatus",
        width: 110,
        sortable: false,
      },
      {
        text: "Package Price",
        value: "package_price",
        width: 90,
        sortable: false,
      },
      { text: "Discount", value: "discount", width: 100, sortable: false },
      // { text: "Refund", value: "refund", width: 100, sortable: false },
      // { text: "Payment Status", value: "", width: 120, sortable: false },
      { text: "Total", value: "net_price", width: 100, sortable: false },
    ],
    salesOrderList: [],
    menuList: [],
  }),

  watch: {
    options: {
      handler() {
        this.variables.limit =
          this.options.itemsPerPage == -1 ? 100000 : this.options.itemsPerPage;
        this.variables.skip =
          this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage;
        this.getAllSubscribedPackages();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.variables.startDate = this.fDate;
    this.variables.endDate = this.tDate;
    this.variables.searchKey = this.search;
    this.getAllSubscribedPackages();
  },

  methods: {
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },
    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    onStatusChange() {
      this.getAllSubscribedPackages();
    },
    selectFromDate() {
      this.menu1 = false;
      this.getAllSubscribedPackages();
    },
    selectToDate() {
      this.menu2 = false;
      this.getAllSubscribedPackages();
    },
    getAllSubscribedPackages() {
      this.loading = true;
      this.variables.searchKey = this.search;
      this.variables.status = this.packageStatus;
      this.variables.startDate = this.fDate;
      this.variables.endDate = this.tDate;
      this.variables.createdBy = this.createdBy;
      console.log("Variables ", this.variables);
      this.$apollo
        .watchQuery({
          query: require("../api/getAllSubscribedPackagesWithDate.graphql"),
          variables: this.variables,
        })
        .refetch({ variables: this.variables })
        .then((data) => {
          this.salesOrderList = [];
          this.loading = false;
          this.totalItemsCount =
            data.data.getAllSubscribedPackagesWithDate.total_count;
          data.data.getAllSubscribedPackagesWithDate.sales_orders.forEach(
            (element) => {
              if (element.package_price === null) {
                element.package_price = 0;
              }

              var packageStatus = element.status;
              if (element.is_deleted === true) {
                packageStatus = "Customer Deleted";
              } else if (element.status === "PENDING") {
                packageStatus = "Payment Pending";
              }
              const salesOrderData = {
                id: element._id,
                order_number: element.order_number,
                customerId: element.customer,
                customer: element.user,
                date: element.created_At,
                startDate: element.start_date,
                endDate: element.end_date,
                plan: element.package.titleEn,
                paymentStatus: packageStatus,
                total: element.net_price,
                net_price: element.net_price,
                package_price: element.package_price,
                discount: element.discount,
                is_deleted: element.is_deleted,
                referredByUser: element.referredByUser,
                availableNumOfDays:
                  element.package.availableChoices.length > 0
                    ? element.package.availableChoices[0].availableNumOfDays
                    : "",
                createdBy: element.createdBy ? element.createdBy : ""

              };
              this.salesOrderList.push(salesOrderData);
            }
          );
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    itemRowBackground: function (item) {
      if (item.paymentStatus == "Payment Pending") {
        return "black--text";
      } else if (item.paymentStatus == "ACTIVE") {
        return "green--text";
      } else return "blue--text";
    },
    numberWithCommas(x) {
      if (x)
        return x
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    },

    runSearch() {
      this.variables.status = this.packageStatus;
      this.variables.startDate = this.fDate;
      this.variables.endDate = this.tDate;
      this.getAllSubscribedPackages();
    },
    clearSearch() {
      this.variables.status = this.packageStatus;
      this.variables.startDate = this.fDate;
      this.variables.endDate = this.tDate;
      this.search = "";
      this.getAllSubscribedPackages();
    },
  },
  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format("DD/MM/YYYY") : "";
    },
    toDate() {
      return this.tDate ? moment(this.tDate).format("DD/MM/YYYY") : "";
    },
  },
};
</script>


<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
