<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      :items-per-page="5"
      class="elevation-1"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.purchaseDate="{ item }">
        {{ new Date(parseInt(item.purchaseDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.purchasePrice="{ item }">
        {{ parseFloat(item.purchasePrice).toFixed(3) }} KD
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <PurchaseView :purchase="item" class="me-1" />
          <PurchaseUpdateForm
            :purchase="item"
            class="me-1"
          ></PurchaseUpdateForm>
          <PurchaseDelete :purchase="item" />
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import PurchaseDelete from "../components/PurchaseDelete";
import PurchaseUpdateForm from "../components/PurchaseUpdateForm";
import PurchaseView from "../components/PurchaseView.vue";

export default {
  components: {
    PurchaseDelete,
    PurchaseUpdateForm,
    PurchaseView,
  },
  props: {
    purchaseList: {
      require: true,
    },
    loading: { require: true },
    totalItemsCount: { default: 25 },
  },
  computed: {
    itemsWithSno() {
      if (this.purchaseList == null) return [];
      return this.purchaseList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Sl No.",
          value: "slNo",
          width: 70,
        },
        {
          text: "Invoice No.",
          value: "invoiceNumber",
          sortable: false,
          width: 120,
        },
        { text: "Date", value: "purchaseDate", width: 120, sortable: false },
        {
          text: "Total Price",
          value: "purchasePrice",
          sortable: false,
          width: 120,
        },
        { text: "Actions", value: "actions", sortable: false, width: 90 },
      ],
    };
  },
};
</script>
