var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-6"},[(!_vm.proceedView)?_c('div',[_c('v-row',[_c('v-card-title',{staticClass:"text-h6 black--text mt-n2"},[_vm._v(_vm._s(_vm.finishView && _vm.isFollowUp ? "Follow Up" : "Update Appointment"))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider',{staticClass:"mt-n2 mb-6"})],1):_vm._e(),(_vm.proceedView)?_c('div',[_c('v-card-text',{staticClass:"subtitle-1 mb-0 mt-6 text-center"},[_vm._v("Do you really want to change the action status from "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getFromActionType(_vm.otherDatas.from))+" ")]),_vm._v(" to "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.slotAppointment ? _vm.getFromActionType(_vm.slotAppointment.followUp.actionStatus) : "--")+" ")]),_vm._v(" ? ")]),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs10":""}},[_c('v-textarea',{staticClass:"mx-2",attrs:{"append-icon":"mdi-comment","label":"Write any comment...","rows":"1"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1),(this.slotAppointment)?_c('div',{staticClass:"mb-5 "},[(this.slotAppointment.followUp.actionStatus == 'FINISHED')?_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"ml-4",attrs:{"xs4":""}},[_vm._v("Follow Up Needed ?")]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{staticClass:"mt-n5 mr-4   "},[_c('v-checkbox',{model:{value:(_vm.isFollowUp),callback:function ($$v) {_vm.isFollowUp=$$v},expression:"isFollowUp"}})],1)])],1):_vm._e(),(
          this.slotAppointment.followUp.modeOfAppointment == 'ON_VISIT' &&
          this.slotAppointment.followUp.actionStatus == 'FINISHED'
        )?_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"ml-4 mt-2 ",attrs:{"xs4":""}},[_vm._v("Additional Fee")]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{attrs:{"dense":"","attach":"","solo":"","rules":[_vm.positive('Fee')]},model:{value:(_vm.additionalFee),callback:function ($$v) {_vm.additionalFee=$$v},expression:"additionalFee"}})],1)],1):_vm._e(),(
          this.slotAppointment.followUp.modeOfAppointment == 'ON_VISIT' &&
          !_vm.isFollowUp &&
          this.slotAppointment.followUp.actionStatus == 'FINISHED'
        )?_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"ml-4 mt-2 ",attrs:{"xs4":""}},[_vm._v(" Payment Method ")]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-select',{attrs:{"items":_vm.avalilablePaymentMethod,"dense":"","solo":"","item-text":"text","item-value":"value"},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}})],1)],1):_vm._e()],1):_vm._e(),(_vm.error)?_c('v-card-text',{staticClass:"error--text font-weight-bold"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-actions',{attrs:{"justify":"center"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":" mx-2 error","depressed":""},on:{"click":_vm.close}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":" mx-2 success","depressed":"","loading":_vm.button_loading},on:{"click":function($event){return _vm.proceedDietitianAppointment();}}},[_vm._v("Yes")])],1)],1)],1):_c('div',[(_vm.rescheduleView)?_c('div',[_c('update-appointment-popup',{attrs:{"appointment":_vm.slotAppointment},on:{"updateSlotAppointments":_vm.updateSlotAppointments}})],1):_vm._e(),(_vm.finishView && _vm.isFollowUp)?_c('div',[_c('FinishFollowUp',{attrs:{"appointment":_vm.slotAppointment,"loadingBtn":_vm.loadingBtn},on:{"updateSlotAppointmentFollowUp":_vm.updateSlotAppointmentFollowUp}})],1):_vm._e()]),_c('v-snackbar',{attrs:{"timeout":"3000","right":"","top":"","color":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.text))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }