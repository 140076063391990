<template>
  <v-container fluid class="px-0">
    <v-layout wrap justify-end>
      <!-- <v-flex xs12 sm4 md3> </v-flex> -->
      <v-flex xs12 sm3 md3 lg2>
        <span></span>
        <v-select
          :items="packageCountList"
          v-model="noOfPackage"
          item-text="text"
          item-value="value"
          dense
          attach
          label="Package Count"
          solo
          @change="packageCountChange"
        ></v-select>
      </v-flex>

      <v-flex xs12 sm3 md3 lg2>
        <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="toDate"
              label="To"
              class="ms-sm-2"
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              @click:clear="tDate = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="secondary"
            v-model="tDate"
            @change="selectToDate"
            :max="toMaxDate"
            :min="toMinDate"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout class="text-end" justify-end>
      <ExportSalesOrder
        v-if="hasRole(AdminUserPermissions.SALES_ORDER_EXPORT)"
        :headers="headers"
        :noOfPackage="noOfPackage"
        :tDate="tDate"
      />
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="salesOrderList"
      class="elevation-1 mt-4"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.date`]="{ item }">{{
        new Date(parseInt(item.created_At)) | moment("MMM DD YYYY")
      }}</template>
      <template v-slot:[`item.startDate`]="{ item }">{{
        new Date(parseInt(item.start_date)) | moment("MMM DD YYYY")
      }}</template>
      <template v-slot:[`item.endDate`]="{ item }">{{
        new Date(parseInt(item.end_date)) | moment("MMM DD YYYY")
      }}</template>
      <!-- <template v-slot:[`item.package_price`]="{ item }">{{
        numberWithCommas(item.package_price)
      }}</template>
      <template v-slot:[`item.discount`]="{ item }">{{
        numberWithCommas(item.discount)
      }}</template>
      <template v-slot:[`item.net_price`]="{ item }"
        >{{ numberWithCommas(item.net_price) }}
      </template> -->
      <template v-slot:[`item.phone`]="{ item }"
        >{{ item.user ? item.user.phone : "--" }}
      </template>
      <!-- <template v-slot:[`item.plan`]="{ item }"
        >{{ item.package ? item.package.titleEn : "--" }}
      </template> -->
      <!-- <template v-slot:[`item.days`]="{ item }">
        <span v-if="item.package"
          >{{
            item.package.availableChoices.length
              ? item.package.availableChoices[0].availableNumOfDays
              : ""
          }}
        </span>
      </template>
      <template v-slot:[`item.total`]="{ item }">
        <PriceSplitup :subscribedPackage="item" />
      </template> -->
      <template v-slot:[`item.paymentStatus`]="{ item }">
        <span
          :class="
            item.payment_status == 'SUCCESS'
              ? 'green--text'
              : item.payment_status == 'PENDING'
              ? 'orange--text'
              : item.payment_status == 'UPCOMING'
              ? 'blue--text'
              : item.payment_status == 'EXPIRED'
              ? 'red--text'
              : ''
          "
        >
          {{ item.payment_status }}</span
        >
      </template>
      <!-- <template v-slot:[`item.referredUser`]="{ item }">
        <span v-if="item.referredByUser">
          {{ toTitleCase(item.referredByUser.firstnameEn) }}
          {{ toTitleCase(item.referredByUser.lastnameEn) }}
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:[`item.referredUserRole`]="{ item }">
        <span v-if="item.referredByUser">
          {{ item.referredByUser.role }}
        </span>
        <span v-else>--</span>
      </template> -->
      <!-- :item-class="itemRowBackground" -->
      <template v-slot:[`item.customer`]="{ item }">
        <span v-if="item.user">
          <router-link
            v-if="!item.is_deleted"
            :to="{
              name: 'CustomerDetails',
              params: { customerId: item.customer },
              query: { customerId: item.customer }
            }"
            class="mx-auto"
          >
            {{ toTitleCase(item.user.firstnameEn) }}
            {{ toTitleCase(item.user.lastnameEn) }}
            <!-- <div class="mx-1" /> -->
          </router-link>

          <span v-else>
            <!-- <div class="mx-1" /> -->
            {{ toTitleCase(item.user.firstnameEn) }}
            {{ toTitleCase(item.user.lastnameEn) }}
          </span>
        </span>
        <!-- </v-row> -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import ExportSalesOrder from "./ExportInactiveSalesOrderCount.vue";
export default {
  components: { ExportSalesOrder },

  data: () => ({
    noOfPackage: "",
    packageCountList: [
      { text: "One", value: 1 },
      { text: "Two", value: 2 },
      { text: "Three", value: 3 }
    ],
    fDate: format(
      parseISO(
        moment()
          .subtract(2, "month")
          .toDate()
          .toISOString()
      ),
      "yyyy-MM-dd"
    ),
    tDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    menu1: false,
    menu2: false,
    moment: moment,
    options: {
      itemsPerPage: 25,
      page: 1
    },
    loading: false,
    totalItemsCount: 0,
    variables: {
      limit: 25,
      skip: 0,
      startDate: "",
      endDate: ""
    },
    packageStatuses: [
      { text: "All", value: "" },
      { text: "Active", value: "ACTIVE" },
      { text: "Upcoming", value: "UPCOMING" },
      { text: "Pending", value: "PENDING" },
      { text: "Expired", value: "EXPIRED" }
    ],
    packageStatus: "",
    search: "",
    headers: [
      {
        text: "Last Active Package Order ID",
        value: "order_number",
        width: 120
      },
      // { text: "Created Date", value: "date", width: 120, sortable: false },
      { text: "Customer Name", value: "customer", width: 180, sortable: false },
      { text: "Phone", value: "phone", width: 180, sortable: false },
      {
        text: "Previously Active Packages",
        value: "subscribePackageCount",
        width: 90,
        sortable: false
      },
      {
        text: "Last Active Package Start Date",
        value: "startDate",
        width: 120,
        sortable: false
      },
      {
        text: "Last Active Package End Date",
        value: "endDate",
        width: 120,
        sortable: false
      },

    ],
    salesOrderList: [],
    menuList: []
  }),

  watch: {
    options: {
      handler() {
        this.variables.limit =
          this.options.itemsPerPage == -1
            ? 100000
            : this.options.itemsPerPage * this.options.page;
        this.variables.skip =
          (this.options.page - 1) * this.options.itemsPerPage;

        this.getAllSubscribedPackages();
      },
      deep: true
    },

    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.variables.startDate = this.fDate;
    this.variables.endDate = this.tDate;
    // this.getAllSubscribedPackages();
  },

  methods: {
    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    // selectFromDate() {
    //   this.menu1 = false;

    //   this.getAllSubscribedPackages();
    // },
    packageCountChange() {
      this.getAllSubscribedPackages();
    },
    selectToDate() {
      this.menu2 = false;
      this.fDate = moment(this.tDate)
        .subtract(2, "month")
        .format("yyyy-MM-DD");
      this.getAllSubscribedPackages();
    },
    getAllSubscribedPackages() {
      this.loading = true;
      this.variables.endDate = this.tDate;
      // let noOfPackage = "";
      if (this.noOfPackage) {
        this.variables.noOfPackage = this.noOfPackage;
      }
      this.$apollo
        .watchQuery({
          query: require("../api/getInactiveSubscribePackageCount.graphql"),
          variables: this.variables
        })
        .refetch({ variables: this.variables })
        .then(data => {
          console.log(data);
          this.salesOrderList =
            data.data.getAllInactiveSubscribedPackagesCount.sales_orders;
          this.loading = false;
          if (this.variables.skip == 0)
            this.totalItemsCount =
              data.data.getAllInactiveSubscribedPackagesCount.total_count;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },

    itemRowBackground: function(item) {
      if (item.paymentStatus == "Payment Pending") {
        return "black--text";
      } else if (item.paymentStatus == "ACTIVE") {
        return "green--text";
      } else return "blue--text";
    },
    numberWithCommas(x) {
      if (x)
        return x
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    }

    // runSearch() {
    //   this.variables.status = this.packageStatus;
    //   this.variables.startDate = this.fDate;
    //   this.variables.endDate = this.tDate;
    //   this.getAllSubscribedPackages();
    // },
    // clearSearch() {
    //   this.variables.status = this.packageStatus;
    //   this.variables.startDate = this.fDate;
    //   this.variables.endDate = this.tDate;
    //   this.search = "";
    //   this.getAllSubscribedPackages();
    // },
  },
  computed: {
    // fromDate() {
    //   return this.fDate ? moment(this.fDate).format("DD/MM/YYYY") : "";
    // },
    toDate() {
      return this.tDate ? moment(this.tDate).format("DD/MM/YYYY") : "";
    },
    toMaxDate() {
      return this.fDate ? moment(new Date()).format("YYYY-MM-DD") : "";
    },
    toMinDate() {
      return "";
      // this.fDate
      //   ? moment(this.fDate)
      //       .format("YYYY-MM-DD")
      //   : "";
    },
    fromMaxDate() {
      return this.tDate
        ? moment(this.tDate)
            // .add(2, "month")
            .format("YYYY-MM-DD")
        : "";
    },
    fromMinDate() {
      return this.tDate
        ? moment(this.tDate)
            .subtract(2, "month")
            .format("YYYY-MM-DD")
        : "";
    }
  }
};
</script>
