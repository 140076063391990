<template>
  <v-container class="pt-8 px-0" fluid>
    <h3 class="black--text text-body-1 text-sm-h6 font-weight-bold">
      Purchase Details
    </h3>
    <v-form v-model="valid" ref="form" class="mt-8">
      <v-layout wrap>
        <v-flex xs12 sm4 md2 lg2>
          <h5
            class="
              mt-2
              text-start text-body-2 text-sm-body-1
              font-weight-medium
              black--text
            "
          >
            Invoice No.
          </h5>
        </v-flex>
        <v-flex xs10 sm5 md4>
          <v-text-field
            v-model="invoiceNumber"
            solo
            dense
            :rules="[requiredValidator('invoice number')]"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm4 md2 lg2>
          <h5
            class="
              text-body-2 text-sm-body-1
              mt-2
              text-start
              font-weight-medium
              black--text
            "
          >
            Purchase Date
          </h5>
        </v-flex>
        <v-flex xs6 sm3 lg2>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate"
                placeholder="Date"
                solo
                readonly
                :rules="[requiredValidator('purchase date')]"
                append-icon="fa-calendar-alt black--text"
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              color="secondary"
              v-model="date"
              @change="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>

      <!-- {{ date }} -->
      <v-form ref="form2" v-model="valid2">
        <v-layout wrap>
          <v-flex xs4 sm4 md2 lg2>
            <h5
              class="
                mt-2
                text-start text-body-2 text-sm-body-1
                font-weight-medium
                black--text
              "
            >
              Item Id
            </h5>
          </v-flex>
          <v-flex xs10 sm5 md4>
            <v-autocomplete
              v-model="newPurchaseDetails.ingredient"
              solo
              dense
              attach
              :rules="[requiredValidator('item id')]"
              :items="allIngredients"
              item-text="itemId"
              item-value="_id"
              clearable
              deletable-chips
              :search-input.sync="ingredientsIdSearchKey"
              :loading="itemsLoading"
              return-object
            >
              <template slot="no-data">
                <v-flex xs12 md12 class="mt-2 mb-2">
                  <h5 class="font-weight-medium ml-4">
                    Search ingredient id...
                  </h5>
                </v-flex>
              </template>
              <template slot="item" slot-scope="data">{{
                data.item.itemId
              }}</template>
            </v-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs4 sm4 md2 lg2>
            <h5
              class="
                mt-2
                text-start text-body-2 text-sm-body-1
                font-weight-medium
                black--text
              "
            >
              Item Name
            </h5>
          </v-flex>
          <v-flex xs10 sm5 md4>
            <v-autocomplete
              v-model="newPurchaseDetails.ingredient"
              :items="allIngredients"
              item-text="nameEn"
              item-value="_id"
              solo
              chips
              dense
              attach
              small-chips
              :rules="[requiredValidator('item name')]"
              clearable
              deletable-chips
              :search-input.sync="ingredientsNameSearchKey"
              :loading="itemsLoading"
              return-object
            >
              <template slot="no-data">
                <v-flex xs12 md12 class="mt-2 mb-2">
                  <h5 class="font-weight-medium ml-4">
                    Search ingredient name...
                  </h5>
                </v-flex>
              </template>
              <template slot="item" slot-scope="data"
                >{{ data.item.nameEn }}, {{ data.item.nameAr }}</template
              >
            </v-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs4 sm4 md2 lg2>
            <h5
              class="
                mt-2
                text-start text-body-2 text-sm-body-1
                font-weight-medium
                black--text
              "
            >
              Supplier
            </h5>
          </v-flex>
          <v-flex xs10 sm5 md4>
            <v-text-field
              solo
              dense
              v-model="newPurchaseDetails.description"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 sm4 md2 lg2>
            <h5
              class="
                mt-2
                text-start text-body-2 text-sm-body-1
                font-weight-medium
                black--text
              "
            >
              Quantity
            </h5>
          </v-flex>
          <v-flex xs10 sm5 md4>
            <v-text-field
              v-model="newPurchaseDetails.quantity"
              :rules="[requiredValidator('quantity')]"
              max="9999999"
              oninput="if(value>9999999) value=9999999;"
              onblur="if(value>9999999) value=9999999;"
              solo
              @keypress="isFloatNumber"
              dense
              min="0"
              type="number"
            >
              <template v-if="newPurchaseDetails.ingredient" v-slot:append>
                <h4 class="mt-1 grey--text">
                  {{ itemQuantityUnit() }}
                  <!-- {{ newPurchaseDetails.ingredient.quantityUnit }} -->
                </h4>
              </template>
            </v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 sm4 md2 lg2>
            <h5
              class="
                mt-2
                text-start text-body-2 text-sm-body-1
                font-weight-medium
                black--text
              "
            >
              Unit Price
            </h5>
          </v-flex>
          <v-flex xs10 sm5 md4>
            <v-text-field
              v-model="newPurchaseDetails.unitPrice"
              :rules="[requiredValidator('unit price')]"
              solo
              @keypress="isFloatNumber"
              min="0"
              suffix="KD"
              dense
              type="number"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <!-- <v-flex xs12 sm6 md2 class="ms-4">
          <v-text-field
            label="Total Price"
            outlined
            dense
            type="number"
          ></v-text-field>
        </v-flex> -->
        <!-- <v-layout wrap>
          <v-flex xs3 sm4 md2 lg2>
            <h5
              class="
                mt-2
                text-start text-body-2 text-sm-body-1
                font-weight-medium
                black--text
              "
            >
              Total Price
            </h5>
          </v-flex>

          <v-flex xs6 sm5 md4>
            <h4 class="font-weight-bolder mt-2">
              {{ totalPurchasePrice() }} KD
            </h4>
          </v-flex>
        </v-layout> -->
        <v-layout justify-center class="mt-5 mt-sm-8 mb-5">
          <v-btn
            color="secondary"
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            :disabled="!valid2"
            @click="addDetail()"
            class="white--text text-capitalize"
            v-if="hasRole(AdminUserPermissions.PURCHASE_ADD)"
            >Add</v-btn
          >
        </v-layout>

        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">{{
          snackText
        }}</v-snackbar>
      </v-form>
      <PurchaseDetailTable
        :purchaseDetails="purchaseDetails"
        class="mt-8 mt-sm-0"
      />
      <v-layout justify-center wrap>
        <v-flex xs12 sm6 md2 class="mt-6">
          <v-layout justify-center>
            <v-btn
              color="secondary"
              class="white--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 200 : 140"
              :disabled="!valid"
              :loading="btnloading"
              large
              @click="createPurchase()"
              v-if="hasRole(AdminUserPermissions.PURCHASE_SUBMIT)"
              >Submit</v-btn
            >
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
    <v-system-bar class="mt-6" height="1"></v-system-bar>
    <v-layout>
      <h3 class="black--text mt-6 mb-5 text-body-1 text-sm-h6 font-weight-bold">
        Purchased Items
      </h3>
      <v-spacer></v-spacer>
      <AuditTrialLog class="ms-2 ms-md-4 mt-6" auditType="PURCHASE_LOG" />
    </v-layout>
    <app-purchase-table
      :purchaseList="purchaseList"
      :loading="loading"
      :totalItemsCount="totalItemsCount"
    ></app-purchase-table>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
import { format, parseISO } from "date-fns";
import PurchaseTable from "../components/PurchaseTable.vue";
import PurchaseDetailTable from "../components/PurchaseDetailTable.vue";
import { requiredValidator } from "../../core/methods/validations";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  components: {
    "app-purchase-table": PurchaseTable,
    PurchaseDetailTable,
    AuditTrialLog
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.PURCHASE_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.resetNewPurchase();
  },
  data() {
    return {
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      requiredValidator: requiredValidator,
      menu: false,
      invoiceNumber: "",
      purchasePrice: 0.0,
      purchaseDetails: [],
      newPurchaseDetails: "",
      purchaseList: [],
      loading: true,
      totalItemsCount: 25,
      variables: {
        limit: 25,
        skip: 0
      },
      valid: true,
      valid2: true,
      snack: false,
      snackColor: "",
      snackText: "",
      snackbar: false,
      text: "",
      allIngredients: [],
      awaitingSearchId: false,
      awaitingSearchName: false,
      itemsLoading: false,
      btnloading: false,
      ingredientsNameSearchKey: "",
      ingredientsIdSearchKey: ""
    };
  },
  mounted() {
    this.$root.$on("updatePurchase", () => {
      // this.date = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
      this.loading = true;
      this.$apollo.queries.getAllPurchases.refetch({
        variables: this.variables
      });
    });
  },
  apollo: {
    getAllPurchases() {
      return {
        query: require("../api/getAllPurchases.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.purchaseList = data.getAllPurchases.purchases;
          this.totalItemsCount = data.getAllPurchases.totalCount;
          // console.log(data.getAllIngredients);
        }
      };
    }
  },
  watch: {
    ingredientsIdSearchKey: function(val) {
      if (val == null || val == undefined) return;
      if (val.length < 1) return;
      if (!this.awaitingSearchId) {
        setTimeout(() => {
          this.awaitingSearchId = false;
          this.itemsLoading = true;
          this.$apollo
            .query({
              query: require("@/stock/api/getAllIngredients.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val
              }
            })
            .then(data => {
              this.allIngredients = data.data.getAllIngredients.ingredients;
              this.itemsLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearchId = true;
    },
    ingredientsNameSearchKey: function(val) {
      if (val == null || val == undefined) return;
      if (val.length <= 0) return;
      if (!this.awaitingSearchName) {
        setTimeout(() => {
          this.awaitingSearchName = false;
          this.itemsLoading = true;
          this.$apollo
            .query({
              query: require("@/stock/api/getAllIngredients.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val
              }
            })
            .then(data => {
              this.allIngredients = data.data.getAllIngredients.ingredients;
              this.itemsLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearchName = true;
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    }
  },
  methods: {
    itemQuantityUnit() {
      let unit = "";
      if (this.newPurchaseDetails.ingredient) {
        if (this.newPurchaseDetails.ingredient.quantityUnit === "GRAMS")
          unit = "KG";
        else if (this.newPurchaseDetails.ingredient.quantityUnit === "ML")
          unit = "L";
        else if (this.newPurchaseDetails.ingredient.quantityUnit === "PIECE")
          unit = "PCS";
      }
      return unit;
    },

    addDetail() {
      debugger;
      this.newPurchaseDetails.unitPrice = parseFloat(
        this.newPurchaseDetails.unitPrice
      );
      this.newPurchaseDetails.quantity = parseFloat(
        this.newPurchaseDetails.quantity
      );
      this.newPurchaseDetails.totalPrice =
        this.newPurchaseDetails.unitPrice * this.newPurchaseDetails.quantity;
      this.purchaseDetails.push(this.newPurchaseDetails);

      this.purchasePrice =
        this.purchasePrice + this.newPurchaseDetails.totalPrice;
      // console.log(this.purchasePrice);
      this.resetNewPurchase();
      this.$refs.form2.reset();
    },
    totalPurchasePrice() {
      let price = 0.0;
      if (this.purchaseDetails) {
        this.purchaseDetails.map(function(elt) {
          price = price + elt.totalPrice;
        });
      }
      this.purchasePrice = price.toFixed(3);
      return this.purchasePrice;
    },
    createPurchase() {
      if (this.purchaseDetails[0] == null) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please add item details";
        return;
      }
      let purchaseDetails = JSON.parse(JSON.stringify(this.purchaseDetails));
      // console.log(purchaseDetails);
      purchaseDetails.map(function(element) {
        let ingredientId = element.ingredient._id;
        if (element.ingredient.quantityUnit != "PIECE") {
          element.quantity = element.quantity * 1000;
        }
        element.ingredient = ingredientId;
      });
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/stock/api/createPurchase.graphql"),
          variables: {
            invoiceNumber: this.invoiceNumber,
            purchaseDate: this.date,
            purchasePrice: parseFloat(this.purchasePrice),
            purchaseDetails: purchaseDetails
          },
          update: (store, { data: { createPurchase } }) => {
            try {
              const query = {
                query: require("@/stock/api/getAllPurchases.graphql"),
                variables: {
                  limit: 25,
                  skip: 0
                }
              };

              let allPurchases = store.readQuery(query);

              allPurchases.getAllPurchases.purchases.unshift(createPurchase); //push to top
              store.writeQuery({
                ...query,
                data: allPurchases
              });

              this.text = "Purchase Details Added";
              this.snackbar = true;
              this.$root.$emit("updatePurchase");
              this.$refs.form2.reset();
              this.$refs.form.reset();
              this.purchaseDetails = [];
              this.btnloading = false;
            } catch (e) {
              console.log("Something bad happend- StockPurchase.vue");
              this.btnloading = false;
            }
            this.btnloading = false;
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    resetNewPurchase() {
      this.newPurchaseDetails = {
        ingredient: "",
        unitPrice: "",
        quantity: "",
        totalPrice: "",
        discount: 0.0,
        netPrice: 0.0,
        description: ""
      };
    },
    isFloatNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
