<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Update Block</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-sm-8 mt-n6" @submit="updateBlock()" ref="form">
          <v-card-text>
            <v-container>
              <v-layout class="mt-3 mt-sm-0" wrap>
                <v-flex xs12 sm2 md2 class="text-body-2 black--text">
                  Select Area
                </v-flex>
                <v-flex xs12 sm7 md5>
                  <v-autocomplete
                    item-text="nameEn"
                    v-model="block.area"
                    item-value="_id"
                    :items="areaList"
                    :rules="[requiredValidator('area ')]"
                    dense
                    attach
                    solo
                    return-object
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for area...
                        </h5>
                      </v-flex>
                    </template></v-autocomplete
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2 class="text-body-2 black--text">
                  Block
                </v-flex>
                <v-flex xs12 sm7 md5>
                  <v-text-field
                    v-model="block.block"
                    :rules="[requiredValidator('block')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout justify-center>
                <v-flex xs8 md2 class="mt-8"> </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n16">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="white--text text-capitalize"
              :loading="btnloading"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              @click="updateBlock"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      dialog: false,
      addedArea: [],
      nameEn: "",
      nameAr: "",
      snackbar: false,
      text: "",
      areaList: []
    };
  },
  props: {
    blockInfo: {
      required: true
    }
  },
  apollo: {
    getAllAreas() {
      return {
        query: require("../api/getAllAreas.graphql"),
        result({ data, loading }) {
          // console.log(data.getAllAreas.areas);
          this.loading = loading;
          this.areaList = data.getAllAreas.areas;
        }
      };
    }
  },
  created() {
    this.block = JSON.parse(JSON.stringify(this.blockInfo));
  },
  methods: {
    onShowDialog() {
      this.block = JSON.parse(JSON.stringify(this.blockInfo));
    },
    updateBlock() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateBlock.graphql"),
          variables: {
            id: this.block._id,
            area: this.block.area._id,
            block: this.block.block
          },
          update: () => {
            this.$root.$emit("updateBlock");
            this.dialog = false;
            this.btnloading = false;
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
