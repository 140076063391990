<template>
  <v-container fluid class="px-0">
    <v-layout class="black--text mt-6 ms-3 mb-4">
      <h3>Stock Category</h3>
      <v-spacer></v-spacer>
      <StockCategoryForm @updatedStockCategory="stockCategoryUpdated" />
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="stockCategoryList"
      class="elevation-1 mt-10 ms-2"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.id`]="{ item }">{{
        stockCategoryList.indexOf(item) + 1
      }}</template>
      <template v-slot:[`item.nameEn`]="{ item }">{{ item.nameEn }}</template>
      <template v-slot:[`item.nameAr`]="{ item }">{{ item.nameAr }}</template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-col>
            <StockCategoryUpdate
              v-if="hasRole(AdminUserPermissions.MASTER_DATA_EDIT)"
              :stockCategory="item"
              @updatedStockCategory="stockCategoryUpdated"
            ></StockCategoryUpdate>
          </v-col>
          <v-col>
            <StockCategoryDelete
              v-if="hasRole(AdminUserPermissions.MASTER_DATA_DELETE)"
              :stockCategory="item"
              @updatedStockCategory="stockCategoryUpdated"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import StockCategoryForm from "../components/StockCategoryForm.vue";
import StockCategoryDelete from "../components/StockCategoryDelete.vue";
import StockCategoryUpdate from "../components/StockCategoryUpdateForm.vue";

export default {
  components: {
    StockCategoryForm,
    StockCategoryDelete,
    StockCategoryUpdate
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.searchKey = "";
    },
    runSearch() {
      this.searchKey = this.search;
    },
    stockCategoryUpdated() {
      this.$apollo.queries.getAllStockCategory.refetch({});
    }
  },
  data: () => ({
    loading: false,
    headers: [
      { text: "Si No", value: "id", width: 100 },
      { text: "Stock category name (English)", value: "nameEn" },
      { text: "Stock category name (Arabic)", value: "nameAr" },
      { text: "Actions", value: "actions", width: 110 }
    ],
    totalItemsCount: 25,
    stockCategoryList: [],
    options: {
      itemsPerPage: 25,
      page: 1
    }
  }),
  apollo: {
    getAllStockCategory() {
      return {
        query: require("../api/getAllStockCategory.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          if (data.getAllStockCategory) {
            this.stockCategoryList = data.getAllStockCategory.stockCategory;
            this.totalItemsCount = data.getAllStockCategory.totalCount;
          }
        }
      };
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.MASTER_DATA_VIEW)) {
      if (
        this.hasRole(this.AdminUserPermissions.STOCK_VIEW) ||
        this.AdminUserPermissions.PURCHASE_VIEW ||
        this.AdminUserPermissions.ADJUST_STOCK_VIEW
      ) {
        this.$router.push("/stock");
      } else
        this.$router.push({
          name: "Error404"
        });
    }
  }
};
</script>
