<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props:["month"],
  data(){
    return{
     monthVal:"",
    }
  },
 methods:{
 getTrendingMeals() {
    this.$apollo
      .query({
        query: require("../api/getTrendingMealCount.graphql"),
        variables: {
           month:this.monthVal
          },
      })
      .then(data => {
       
        let trendingMealList = data.data.getTrendingMealCount;
        let graphLabels = new Array();
        let graphCount = new Array();
        trendingMealList.forEach(element => {
          graphLabels.push(element.meal);
          graphCount.push(element.count);
        });

        this.renderChart(
          {
            labels: graphLabels,
            datasets: [
              {
                label: "Trending Meal",
                backgroundColor: [
                  "#000080",
                  "#FFE500",
                  "#BC8F8F",
                  "#00BFFF",
                  "#FFA07A"
                ],
                data: graphCount
              }
            ]
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              //hides the legend
              display: false
            }
          }
        );
      });
  }
 },
 created(){
   let date=new Date()
   let currentMonth=date.getMonth()
   this.monthVal=currentMonth+1
 this.getTrendingMeals()
 },
 watch:{
  month(newValue){
    this.monthVal=newValue
    this.getTrendingMeals()
  },
  immediate:true,
  deep:true,
 },
};
</script>
