<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Update Shift</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n6"
          @submit="updateShift()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout class="mt-3 mt-sm-0" wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Zone</h4>
                </v-flex>

                <v-flex xs12 sm7 md5>
                  <v-autocomplete
                    item-text="zoneName"
                    v-model="shift.zone"
                    item-value="_id"
                    :items="zoneList"
                    :rules="[requiredValidator('zone ')]"
                    placeholder="Select zone"
                    dense
                    attach
                    solo
                    return-object
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for zone...
                        </h5>
                      </v-flex>
                    </template></v-autocomplete
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Morning Driver</h4>
                </v-flex>
                <v-flex xs12 sm7 md5 class="mt-2 mt-sm-0">
                  <v-autocomplete
                    item-text="user.firstnameEn"
                    v-model="shift.mngDriver"
                    item-value="_id"
                    :items="driverList"
                    label="Select Driver"
                    dense
                    attach
                    solo
                    return-object
                  >
                    <template slot="item" slot-scope="data"
                      >{{ data.item.user.firstnameEn }}
                      {{ data.item.user.lastnameEn }}</template
                    >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">No driver found</h5>
                      </v-flex>
                    </template></v-autocomplete
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="pe-md-2">
                  <h4 class="text-body-2 black--text">Evening Driver</h4>
                </v-flex>
                <v-flex xs12 sm7 md5 class="mt-2 mt-sm-0">
                  <v-autocomplete
                    item-text="user.firstnameEn"
                    item-value="_id"
                    v-model="shift.evngDriver"
                    :items="driverList"
                    label="Select Driver"
                    dense
                    attach
                    return-object
                    solo
                  >
                    <template slot="item" slot-scope="data"
                      >{{ data.item.user.firstnameEn }}
                      {{ data.item.user.lastnameEn }}</template
                    >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">No driver found</h5>
                      </v-flex>
                    </template></v-autocomplete
                  >
                </v-flex>
                <v-snackbar
                  v-model="snack"
                  :timeout="3000"
                  :color="snackColor"
                  >{{ snackText }}</v-snackbar
                >
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n4 mt-sm-0 mb-sm-n5">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="white--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              @click="updateShift"
              :disabled="!valid"
              :loading="btnloading"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          color="success"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      evngDriver: "",
      mngDriver: "",
      requiredValidator: requiredValidator,
      dialog: false,
      text: "",
      loading: false,
      zoneList: [],
      driverList: [],
      snackbar: false,
      btnloading: false,
      valid: true,
      shift: [],
      variables: {
        limit: 25,
        skip: 0
      }
    };
  },
  props: {
    shiftInfo: {
      required: true
    }
  },
  apollo: {
    getAllDrivers() {
      return {
        query: require("@/reports/api/getAllDrivers.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.driverList = data.getAllDrivers.drivers;
        }
      };
    }
  },
  created() {
    this.getAllZones();
    this.shift = JSON.parse(JSON.stringify(this.shiftInfo));
  },

  methods: {
    onShowDialog() {
      this.shift = JSON.parse(JSON.stringify(this.shiftInfo));
    },
    getAllZones() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllZones.graphql"),
          variables: {
            limit: 0,
            skip: 0
          }
        })
        .refetch({
          variables: {
            limit: 0,
            skip: 0
          }
        })
        .then(data => {
          this.loading = false;
          if (data.data.getAllZones) {
            this.zoneList = data.data.getAllZones.zones;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    updateShift() {
      if (this.shift.mngDriver === null && this.shift.evngDriver === null) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please Select any shift";
        return;
      } else {
        this.btnloading = true;
        if (this.shift.mngDriver === null) {
          this.mngDriver = null;
        } else {
          this.mngDriver = this.shift.mngDriver._id;
        }
        if (this.shift.evngDriver === null) {
          this.evngDriver = null;
        } else {
          this.evngDriver = this.shift.evngDriver._id;
        }
        this.$apollo
          .mutate({
            mutation: require("../api/updateShift.graphql"),
            variables: {
              id: this.shift._id,
              zone: this.shift.zone._id,
              mngDriver: this.mngDriver,
              evngDriver: this.evngDriver
            }
          })
          .then(() => {
            this.$root.$emit("updateShift");
            this.btnloading = false;
            this.dialog = false;
          })
          .catch(error => {
            this.btnloading = false;

            console.log(error);
          });
      }
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
