<template>
  <v-container>
    <v-dialog v-model="statusDialogBox" max-width="700px">
      <!-- <template v-slot:activator=false"{ on }">
      <v-btn v-on="on" small class="mx-2 my-1" outlined color="red"
        >Confirm Deactivation</v-btn
      >
    </template> -->
      <v-card class="pa-6">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center"
          >Do you really want to deactivate meal?</v-card-text
        >
        <div v-if="customersUsingMeal[0]">
          <v-card-text class="subtitle-1 mb-0 mt-n6 text-center"
            >Customers using this meal</v-card-text
          >
          <v-data-table
            :mobile-breakpoint="0"
            :headers="headers"
            :items="customersUsingMeal"
            :items-per-page="5"
          >
            <template v-slot:item.customerName="{ item }">
              {{
                item.user.firstnameEn[0].toUpperCase() +
                item.user.firstnameEn.substring(1)
              }}
              {{
                item.user.lastnameEn[0].toUpperCase() +
                item.user.lastnameEn.substring(1)
              }}
            </template>
            <template v-slot:item.date="{ item }">
              {{
                new Date(parseInt(item.subscribed_packages.selected_menu.date))
                  | moment("DD MMM YYYY")
              }}</template
            >
            <!-- <template v-slot:item.shift="{ item }">
              <span v-if="item.shift">{{ item.shift }}</span>
              <span v-else>--</span>
            </template>
            <template v-slot:item.endDate="{ item }">
              {{
                new Date(parseInt(item.subscribed_packages.end_date))
                  | moment("DD MMM YYYY")
              }}</template
            > -->
          </v-data-table>
        </div>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 success" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 primary"
              depressed
              :loading="button_loading"
              @click="
                button_loading = true;
                confirmChange();
              "
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  props: {
    menuItem: {
      required: true,
    },
    customersUsingMeal: {
      required: true,
    },
    statusDialogBox: {
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      button_loading: false,
      error: null,
      headers: [
        {
          text: "Customer Name",
          value: "customerName",
          width: 150,
          sortable: false,
        },
        // {
        //   text: "Package",
        //   value: "subscribed_packages.package.titleEn",
        //   width: 150,
        //   sortable: false,
        // },
        {
          text: "Phone",
          value: "user.phone",
          width: 110,
          sortable: false,
        },
        {
          text: "Date",
          value: "date",
          width: 110,
          sortable: false,
        },
        // { text: "Shift", value: "shift", width: 100, sortable: false },
        // {
        //   text: "Area",
        //   value: "addressDetails.area.nameEn",
        //   width: 150,
        //   sortable: false,
        // },
        // {
        //   text: "Block",
        //   value: "addressDetails.block.block",
        //   width: 100,
        //   sortable: false,
        // },
      ],
    };
  },
  methods: {
    confirmChange() {
      this.button_loading = false;
      this.error = null;
      this.menuItem.active = false;
      this.$emit("updateStatusDialogBox", this.menuItem, true);
    },
    close() {
      this.error = null;
      this.$emit("updateStatusDialogBox", this.menuItem, false);
    },
  },
};
</script>
