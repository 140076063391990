
<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" color="red" class="ms-2">mdi-delete</v-icon>
    </template>
    <v-card class="pa-6">
      <v-card-text class="subtitle-1 mb-0 mt-6 text-center text-black"
        >Do you really want to delete this?</v-card-text
      >

      <v-card-text v-if="error" class="error--text font-weight-bold">{{
        error
      }}</v-card-text>
      <v-card-actions justify="center">
        <v-row justify="center">
          <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
          <v-btn
            color=" mx-2 success"
            depressed
            :loading="button_loading"
            @click="
              button_loading = true;
              deleteFaq(faq._id);
            "
            >Yes</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    faq: {
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      alertData: {
        value: true,
        msg: "Member deleted !",
        msg_ar: "تم حذف العضو!",
        type: "success",
      },
    };
  },
  methods: {
    deleteFaq(id) {
      // console.log(id);
      this.$apollo
        .mutate({
          mutation: require("@/faq/api/deleteFaq.graphql"),
          variables: {
            id,
          },
          update: (store) => {
            const query = {
              query: require("@/faq/api/getAllFaqs.graphql"),
              variables: {
                limit: 25,
                skip: 0,
              },
            };
            // Read the query from cache
            const data = store.readQuery(query);
            // console.log(data);
            // Look for the deleted item
            const index = data.getAllFaqs.faqs.findIndex((m) => m._id === id);
            // console.log(index);
            if (index !== -1) {
              // Mutate cache result
              data.getAllFaqs.faqs.splice(index, 1);
              // Write back to the cache
              store.writeQuery({
                ...query,
                data,
              });
            }
          },
        })
        .then(() => {
          this.button_loading = false;
          this.error = null;
          this.dialog = false;
        })
        .catch((error) => {
          // Error
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    },
  },
};
</script>