<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  mounted() {
    this.$apollo
      .query({
        query: require("../api/getDeliveryStatusCount.graphql")
      })
      .then(data => {
        // console.log(data);
        let deliveryStatusList = data.data.getDeliveryStatusCount;
        let graphLabels = new Array();
        let graphCount = new Array();
        if(!deliveryStatusList.length) this.$emit('hasData',false)
        deliveryStatusList.forEach(element => {
          graphLabels.push(element.status);
          graphCount.push(element.count);
        });

        this.renderChart(
          {
            labels: graphLabels,
            datasets: [
              {
                label: "DELIVERY STATUS",
                backgroundColor: [
                  // "#000080",
                  // "#90EE90",
                  "#BC8F8F",
                  "#00BFFF",
                  "#FFA07A"
                ],
                data: graphCount
              }
            ]
          },
          {
            circumference: 1 * Math.PI,
            rotation: 1 * Math.PI,
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              //hides the legend
              display: false
            }
          }
        );
      });
  }
};
</script>
