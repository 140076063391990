<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Update Area</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-sm-8 mt-n6" @submit="updateArea()" ref="form">
          <v-card-text>
            <v-container>
              <v-layout class="mt-3 mt-sm-0" wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Area Name</h4>
                </v-flex>
                <v-flex xs12 sm7 md5 class="mt-5 mt-md-0">
                  <v-text-field
                    placeholder="Area Name"
                    v-model="area.nameEn"
                    :rules="[requiredValidator('Area Name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Area Name (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm7 md5>
                  <v-text-field
                    label="Area Name Arabic"
                    v-model="area.nameAr"
                    :rules="[requiredValidator('Area Name Arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout justify-center>
                <v-flex xs8 md2 class="mt-8"> </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n16">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="white--text text-capitalize"
              :loading="btnloading"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              @click="updateArea"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          color="success"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      dialog: false,
      addedArea: [],
      nameEn: "",
      nameAr: "",
      snackbar: false,
      text: "",
      area: []
    };
  },
  props: {
    areaInfo: {
      required: true
    }
  },
  created() {
    this.area = JSON.parse(JSON.stringify(this.areaInfo));
  },

  methods: {
    onShowDialog() {
      this.area = JSON.parse(JSON.stringify(this.areaInfo));
    },
    updateArea() {
      if (this.$refs.form.validate()) {
        this.btnloading = true;
        this.areaInfo.nameEn = this.area.nameEn;
        this.areaInfo.nameAr = this.area.nameAr;
        this.$apollo
          .mutate({
            mutation: require("../api/updateArea.graphql"),
            variables: {
              id: this.area._id,
              nameEn: this.area.nameEn,
              nameAr: this.area.nameAr
            },
            update: () => {
              this.btnloading = false;
              this.dialog = false;
            }
          })
          .catch(error => {
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
