<template>
  <div>
    <v-layout justify-end > 
      <ExportRating  :headers="headers" :fDate="fDate" :tDate="tDate" :mealId="mealId" />
    </v-layout>

      <v-data-table :mobile-breakpoint="0" :headers="headers" :items="itemsWithSno" :server-items-length="totalCount"
        class="elevation-1 mt-1" :loading="loading" :options.sync="options"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">

        <template v-slot:[`item.createdAt`]="{ item }">{{
            new Date(parseInt(item.createdAt)) | moment("MMM DD YYYY")
        }}</template>
      </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import ExportRating from "./ExportRating"
export default {
  components: {
    "ExportRating":ExportRating
  },
  props: {
    fDate: {
      require: true
    },
    tDate: {
      require: true
    },
    mealId:{
      require: true
    }
  },
  watch: {
    options: {
      handler() {
        const fromDate = moment(`${this.fDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
        const toDate = moment(`${this.tDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
        const mealId = this.mealId

        this.$apollo.queries.getRatingByMealId.refetch({
          limit: this.options.itemsPerPage,
          skip: this.options.page * this.options.itemsPerPage - this.options.itemsPerPage,
          fromDate: fromDate,
          toDate: toDate,
          mealId:mealId

        });
      }
    },
    fDate: {
      handler() {
        const fromDate = moment(`${this.fDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
        const toDate = moment(`${this.tDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
        const mealId = this.mealId

        this.$apollo.queries.getRatingByMealId.refetch({
          limit: this.options.itemsPerPage,
          skip: 0,
          fromDate: fromDate,
          toDate: toDate,
          mealId:mealId

        });
      }
    },
    tDate: {
      handler() {
        const fromDate = moment(`${this.fDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
        const toDate = moment(`${this.tDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
        const mealId = this.mealId

        this.$apollo.queries.getRatingByMealId.refetch({
          limit: this.options.itemsPerPage,
          skip: 0,
          fromDate: fromDate,
          toDate: toDate,
          mealId:mealId

        });
      }
    },
    mealId: {
      handler(){

        const fromDate = moment(`${this.fDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
        const toDate = moment(`${this.tDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
        const mealId = this.mealId

        this.$apollo.queries.getRatingByMealId.refetch({
          limit: this.options.itemsPerPage,
          skip: 0,
          fromDate: fromDate,
          toDate: toDate,
          mealId:mealId
        })
      } 
    }
  },


  data() {
    return {
      fromDate: "",
      toDate: "",
      totalCount: 0,
      variables: {
        skip: 0,
        limit: 25,
      },
      loading: false,
      ratings: [],
      options: {
        itemsPerPage: 25,
        page: 1
      },
      headers: [
        { text: "firstnameEn", value: "customer.user.firstnameEn", width: 230 },
        { text: "lastnameEn", value: "customer.user.lastnameEn", width: 230 },
        { text: "Rating", value: "rating", width: 230 },
        { text: "comment", value: "comment", width: 230 },
        { text: "Date", value: "createdAt", width: 230 },

      ]


    }
  },
  apollo: {
    getRatingByMealId() {
      const fromDate = moment(`${this.fDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
      const toDate = moment(`${this.tDate}`, "YYYY-MM-DD").format("YYYY-MM-DD");
      return {
        query: require("../api/getRatingByMealId.graphql"),
        variables: {
          limit: this.variables.limit,
          skip: 0,
          fromDate: fromDate,
          toDate: toDate,
          mealId:this.mealId
        },
        result({ data, loading }) {

          this.ratings = data.getRatingByMealId.ratings;
          this.loading = loading;
          this.totalCount = data.getRatingByMealId.totalCount;
        }
      };

    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DIET_PLAN_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  },
  computed: {
    itemsWithSno() {
      return this.ratings.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  }
}



</script>