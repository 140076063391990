<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="me-1" small color="black" @click="onShowDialog"
          >click</v-icon
        >
      </template>
      <v-card class="pt-1" color="pop_bg">
        <v-card-title class="ps-5 pe-1">
          <span class="text-h6 black--text">Notification Details</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="px-1 px-sm-3">
          <v-container>
            <!-- <v-layout class="mt-3 mt-sm-0" wrap>
              <v-flex xs12 sm4 md4>
                <h4 class="black--text text-body-2">Zone</h4>
              </v-flex>
            </v-layout> -->
            <v-row class="mt-n4 ">
              <v-col md="5" class="ms-2">Title</v-col>
              <v-col md="6" class="font-weight-medium" v-if="info.title">
                {{ info.notificationType }}
              </v-col>
            </v-row>
            <v-row class="mt-n4 ">
              <v-col md="5" class="ms-2">Description</v-col>
              <v-col md="6" class="font-weight-medium" v-if="info.description">
                {{ info.description}}
              </v-col>
            </v-row>
            <v-row class="mt-n4 ">
              <v-col md="5" class="ms-2">Notification Type</v-col>
              <v-col md="6" class="font-weight-medium" v-if="info.notificationType">
                {{ info.notificationType}}
              </v-col>
            </v-row>
            <v-row class="mt-n4 " v-if="info.notificationDate">
              <v-col md="5" class="ms-2">Notification Date</v-col>
              <v-col md="6" class="font-weight-medium" v-if="info.notificationDate">
                {{ info.notificationDate}}
              </v-col>
            </v-row>
            <v-row class="mt-n4 ">
              <v-col md="5" class="ms-2">Notification Time</v-col>
              <v-col md="6" class="font-weight-medium" v-if="info.notificationTime">
                {{ info.notificationTime}}
              </v-col>
            </v-row>
            <v-row class="mt-n4 " v-if="info.weeklyNotificationDays.length !=0">
              <v-col md="5" class="ms-2">Weekly Notification Days</v-col>
              <v-col md="6" class="font-weight-medium" v-if="info.weeklyNotificationDays.length !=0">
                {{ info.weeklyNotificationDays}}
              </v-col>
            </v-row>
            <v-row class="mt-n4" v-if="info.repeatEveryWeek">
              <v-col md="5" class="ms-2">Repeat Every Week</v-col>
              <v-col md="6" class="font-weight-medium" v-if="info.repeatEveryWeek">
                {{ info.repeatEveryWeek}}
              </v-col>
            </v-row>
            <v-row class="mt-n4" v-if="info.montlyNotificationDay">
              <v-col md="5" class="ms-2"> Montly Notification Day</v-col>
              <v-col md="6" class="font-weight-medium" v-if="info.montlyNotificationDay">
                {{ info. montlyNotificationDay}}
              </v-col>
            </v-row>


          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      evngDriver: "",
      mngDriver: "",
      requiredValidator: requiredValidator,
      dialog: false,
      text: "",
      loading: false,
      zoneList: [],
      driverList: [],
      snackbar: false,
      btnloading: false,
      valid: true,
      shift: [],
      variables: {
        limit: 25,
        skip: 0
      },
      info: []
    };
  },
  props: {
    notificationList: {
      required: true
    },
    notification: {
      required: true
    }
  },
  apollo: {},
  created() {
    this.findNotification();
    console.log("notificationList", this.notificationList);
    console.log("notificationList", this.notification);
  },

  methods: {
    close() {
      this.dialog = false;
    },
    findNotification() {
      console.log("notificationList", this.notificationList);
      console.log("notificationList", this.notification);
      this.info = this.notificationList.find(
        x => x._id == this.notification._id
      );
      console.log("infooo", this.info);
    }
  }
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
