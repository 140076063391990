<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <!-- <v-overlay :value="btnloading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <h2 class="header--text text-h5 font-weight-bold">
      Customer Registration OTP
    </h2>

    <v-layout class="mt-6" wrap>
      <v-flex xs10 sm4 lg4>
        <v-text-field
          placeholder="Search.."
          v-model="searchKey"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:prepend-inner>
            <v-icon
              color="black"
              v-if="searchKey.length > 0"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="searchKey.length == 0"
              color="black"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-flex>
    </v-layout>

    <!-- {{ phoneVerifications }} -->
    <v-data-table
      :mobile-breakpoint="0"
      id="myTable"
      :headers="headers"
      :items="phoneVerifications"
      :loading="loading"
      item-key="slNo"
      class="elevation-1 mt-4 mt-sm-6"
      :options.sync="options"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.slNo="{ item }">{{ item.slNo }}</template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  created() {
    // if (!this.hasRole(this.AdminUserPermissions.REFERRAL_VIEW)) {
    //   this.$router.push({
    //     name: "Error404",
    //   });
    // }
    this.getAllPhoneVerifications();
  },
  watch: {
    options: {
      handler() {
        this.getAllPhoneVerifications();
        this.tableIndex =
          this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage;
      }
    }
  },
  methods: {
    runSearch() {
      this.getAllPhoneVerifications();
    },
    clearSearch() {
      this.searchKey = "";
      this.getAllPhoneVerifications();
    },
    getAllPhoneVerifications() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/customer/api/getAllPhoneVerifications.graphql"),
          variables: {
            searchKey: this.searchKey,
            limit: 25,
            skip: 0
          }
        })
        .refetch({
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 200,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage
        })
        .then(data => {
          this.loading = false;
          if (data.data.getAllPhoneVerifications) {
            this.phoneVerifications =
              data.data.getAllPhoneVerifications.phoneVerifications;
            this.totalItemsCount =
              data.data.getAllPhoneVerifications.totalCount;
            this.phoneVerifications = this.phoneVerifications.map(
              (d, index) => ({
                ...d,
                slNo: index + this.tableIndex + 1
              })
            );
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  data() {
    return {
      phoneVerifications: [],
      loading: true,
      totalItemsCount: 25,
      btnloading: true,
      tableIndex: 0,
      searchKey: "",
      headers: [
        { text: "Sl.No", value: "slNo", width: 50, sortable: false },
        {
          text: "Number",
          value: "phone",
          width: 150,
          sortable: false
        },
        { text: "OTP", value: "otp", width: 150, sortable: false }
        // {
        //   text: "Is Verified",
        //   value: "isVerified",
        //   width: 150,
        //   sortable: false,
        // },
      ],
      options: {
        itemsPerPage: 25,
        page: 1
      },
      variables: {
        skip: 0,
        limit: 25
      }
    };
  }
};
</script>
