import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        // primary: "#EEDA84",
        primary: "#FF5800",
        header: "#ff7933",
        secondary: "#ff7933",
        background: "#FFFFFF",
        black: "#000000",
        bg: "#e5e5e5",
        // btncolor: "#004438",
        btncolor: "#ff7933",
        dasboard:"#fcfcfd"
      },
      light: {
        primary: "#FF5800",
        header: "#ff7933",
        secondary: "#ff7933",
        background: "#FFFFFF",
        black: "#000000",
        // greentext: "#004438",
        greentext: "#ff7933",
        bg: "#e5e5e5",
        // btncolor: "#004438",
        btncolor:"#ff7933",
        dasboard:"#fcfcfd"
      }
    }
  }
});
