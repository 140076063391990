<template>
  <div>
    <v-btn
      :disabled="promocodeReport.length == 0"
      @click="exportExcel"
      width="150"
      class="mt-2 white--text text-capitalize"
      color="btncolor"
    >
      <v-icon class="white--text me-1" size="17">mdi-export</v-icon>Export
    </v-btn>
    <div v-show="false">
      <v-data-table
        id="printPromoCode"
        hide-default-footer
        :headers="headers"
        :items-per-page="-1"
        :items="promocodeReport"
        class="elevation-1 mt-6"
      >
        <template v-slot:item.slNo="{ item }">{{ item.slNo }}</template>
        <template v-slot:item.customerName="{ item }">
          {{ item.customer.user.firstnameEn }}
          {{ item.customer.user.lastnameEn }}
        </template>
        <template v-slot:item.discount="{ item }">
          <span>{{ item.discountedAmount.toFixed(3) }} KD</span>
        </template>
        <template v-slot:item.date="{ item }">
          {{ new Date(item.dateTime) | moment("DD MMM YYYY") }}</template
        >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { Utils } from "../../utils/utils";
// import moment from "moment";
export default {
  components: {},
  props: {
    promocodeReport: {
      required: true
    },
    headers: {
      required: true
    }
  },
  methods: {
    exportExcel() {
      Utils.htmlToExcel("printPromoCode", "PromoCode", false);
    }
  }
};
</script>
