export const requiredValidatorNumber = propertyType => {
  return v =>
    (v && v.length > 0) ||
    v.length == undefined ||
    `Please input  ${propertyType}`;
};

export const requiredValidator = propertyType => {
  return v => !!v || `${propertyType}  is required`
};

export const requiredValidatorSelect = propertyType => {
  return v =>
    (v && v.length >= 1) ||
    `${propertyType}  is required`
};

export const minLengthValidator = (propertyType, minLength) => {
  return v =>
    (v && v.length >= minLength) ||
    `${propertyType} must be more than   ${minLength} characters `;
};

export const emailValidator = () => {
  return v => (v && /.+@.+\..+/.test(v)) || `Please input a valid email`;
};

export const confirmPasswordValidator = password => {
  return v => (v && v && v == password) || `Passwords doesnt match`;
};

export const confirmnumberValidator = propertyType => {
  return v => (v && v && v == propertyType) || `Passwords doesnt match`;
};
export const maxLengthValidator = (propertyType, maxLength) => {
  return v =>
    (v && v.length <= maxLength) ||
    `${propertyType} must not be more than ${maxLength} characters `;
};

 export const percentageValidator = propertyType => {
   return v => (v && parseFloat(v) < 100) || `${propertyType} Should be less than 100`;
 };
 export const positive = propertyType => {
   return v => (parseFloat(v)  >= 0) || `${propertyType} must be a positive number`; 
 };
 export const integer = propertyType => {
  return v => (parseFloat(v) == Math.floor(v) && parseFloat(v) > 0) || `${propertyType} must be a integer`;
};
 