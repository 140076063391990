var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-10 ",attrs:{"headers":_vm.headers,"items":_vm.accountList,"mobile-breakpoint":0,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.siNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.accountList .map(function(x) { return x._id; }) .indexOf(item._id) + 1)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(new Date(parseInt(item.createdAt))))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.amount))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }