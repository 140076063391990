var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"black--text mt-6 ms-3 mb-4"},[_c('h3',[_vm._v("Stock Category")]),_c('v-spacer'),_c('StockCategoryForm',{on:{"updatedStockCategory":_vm.stockCategoryUpdated}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-10 ms-2",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.stockCategoryList,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stockCategoryList.indexOf(item) + 1))]}},{key:"item.nameEn",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.nameEn))]}},{key:"item.nameAr",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.nameAr))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[(_vm.hasRole(_vm.AdminUserPermissions.MASTER_DATA_EDIT))?_c('StockCategoryUpdate',{attrs:{"stockCategory":item},on:{"updatedStockCategory":_vm.stockCategoryUpdated}}):_vm._e()],1),_c('v-col',[(_vm.hasRole(_vm.AdminUserPermissions.MASTER_DATA_DELETE))?_c('StockCategoryDelete',{attrs:{"stockCategory":item},on:{"updatedStockCategory":_vm.stockCategoryUpdated}}):_vm._e()],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }