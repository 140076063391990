var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"d-flex",attrs:{"wrap":""}},[_c('v-flex',[_c('h2',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v(" Customers Without Meal ")])])],1),_c('v-layout',{staticClass:"mt-8"},[_c('v-flex',{attrs:{"xs8":"","sm3":"","md3":"","lg2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.fromDate,"label":"From","solo":"","append-icon":"fa-calendar-alt black--text","dense":""},on:{"click:clear":function($event){_vm.fDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":_vm.selectFromDate},model:{value:(_vm.fDate),callback:function ($$v) {_vm.fDate=$$v},expression:"fDate"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsWithSno,"items-per-page":25,"options":_vm.options,"loading":_vm.loading,"mobile-breakpoint":0,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.padLeft(item.customerId, 4))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
        name: 'CustomerDetails',
        params: { customerId: item._id },
        query: { customerId: item._id }
      }}},[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "),_vm._v(" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")]):_vm._e()]}},{key:"item.tag",fn:function(ref){
      var item = ref.item;
return _vm._l((item.tags),function(tag){return _c('span',{key:tag._id},[_vm._v(" "+_vm._s(tag.name)),_c('br')])})}},{key:"item.area",fn:function(ref){
      var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"address":_vm.defaultAddress(item.addressDetails),"type":"area"}})]}},{key:"item.block",fn:function(ref){
      var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"address":_vm.defaultAddress(item.addressDetails),"type":"block"}})]}},{key:"item.building",fn:function(ref){
      var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"address":_vm.defaultAddress(item.addressDetails),"type":"building"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }