<template>
  <div class="primary black--text" style="height: 270px">
    <v-flex class="mt-4 mx-2 mb-2">
      <h4 class="black--text text-h6 font-weight-bold">Personal Info</h4>
    </v-flex>
    <v-row class="mb-n6">
      <v-col md="5" class="ms-2">Gender</v-col>
      <v-col md="6" class="font-weight-medium" v-if="customer.user.gender">
        {{ toPascalCase(customer.user.gender) }}
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col md="5" class="ms-2">DOB</v-col>
      <v-col md="6" class="font-weight-medium" v-if="customer.user.dob">
        {{ new Date(parseInt(customer.user.dob)) | moment("MMM DD YYYY") }}
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col md="5" class="ms-2">Age</v-col>
      <v-col md="6" class="font-weight-medium">{{ age }}</v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col md="5" class="ms-2">Mobile</v-col>
      <v-col md="6" class="font-weight-medium">{{ customer.user.phone }}</v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col md="5" class="ms-2">Email</v-col>
      <v-col md="6" class="font-weight-medium">{{ customer.user.email }}</v-col>
    </v-row>
    <v-row class="mt-n6" v-if="customer.height">
      <v-col md="5" class="ms-2">Height</v-col>
      <v-col md="6" class="font-weight-medium">{{ customer.height }}</v-col>
    </v-row>

    <v-row class="mt-n6" v-if="customer.weight">
      <v-col md="5" class="ms-2">Weight</v-col>
      <v-col md="6" class="font-weight-medium">{{ customer.weight }}</v-col>
    </v-row>
    <v-row class="mt-n6" v-if="customer.user.userId">
      <v-col md="5" class="ms-2">Referral Code</v-col>
      <v-col md="6" class="font-weight-medium">
        {{
          parseInt(customer.user.userId)
            .toString(16)
            .toUpperCase()
        }}</v-col
      >
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      required: true
    }
  },
  methods: {
    toPascalCase(string) {
      return `${string}`
        .replace(new RegExp(/[-_]+/, "g"), " ")
        .replace(new RegExp(/[^\w\s]/, "g"), "")
        .replace(
          new RegExp(/\s+(.)(\w+)/, "g"),
          ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\s/, "g"), "")
        .replace(new RegExp(/\w/), s => s.toUpperCase());
    }
  },
  computed: {
    age: {
      get: function() {
        if (this.customer.user.dob) {
          let dateofbirth = new Date(parseInt(this.customer.user.dob));
          var ageDifMs = Date.now() - dateofbirth.getTime();
          var ageDate = new Date(ageDifMs);
          return Math.abs(ageDate.getUTCFullYear() - 1970);
        } else return "";
      },
      set: () => null
    }
  }
};
</script>
