<template>
  <div class="">
    <div class="d-flex  px-5  py-2">
      <span class="black--text text-h5 font-weight-bold">
        <!-- <v-icon size="32" class="primary--text me-2 pb-1">mdi-account</v-icon
          > -->
        Health History
      </span>
      <v-spacer></v-spacer>
      <CreateHealthInfo :customer="customer" v-if="!hasTodaysHistory" />
    </div>
    <v-spacer></v-spacer>

    <v-data-table :items="itemsWithSno">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="header in headers" :key="header.value">
            <td :style="`width:${header.width};vertical-align: middle;`">
              {{ header.text }}
            </td>
            <td v-for="item in items" :key="item">
              <!-- {{item}} -->
              <span v-if="header.value == 'weight'">
                {{ item.muscleFatAnalysis.weight }}
              </span>
              <span v-else-if="header.value == 'smm'">
                {{ item.muscleFatAnalysis.smm }}
              </span>
              <span v-else-if="header.value == 'pbf'">
                {{ item.obesityAnalysis.pbf }}
              </span>
              <span v-else-if="header.value == 'bmi'">
                {{ item.obesityAnalysis.bmi }}
              </span>
              <span v-else-if="header.value == 'date'">
                {{ new Date(parseInt(item.date)) | moment("MMM DD YYYY") }}
              </span>
              <span v-else-if="header.value == 'notes'">
                <v-dialog v-model="item.show_dialog_box" max-width="400px">
                  <template v-slot:activator="{ on }">
                    <!-- <v-btn
                      v-on="on"
                      class="font-weight-bold text-capitalize"
                      color="btncolor"
                      text

                    >
                      Click
                    </v-btn> -->
                    <v-icon v-on="on" class="me-1" small>Click</v-icon>
                  </template>
                  <v-card color="pop_bg">
                    <v-card-title>
                      <span class="text-h6 primary--text">Note</span>
                      <v-spacer></v-spacer>
                      <AuditTrialLog auditType="TAX_UPDATE" />
                      <v-btn color="btncolor" text @click="item.show_dialog_box = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-container class="mt-2">
                        {{ item.notes }}
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </span>
              <span v-else-if="header.value == 'update'">
                <div v-if="disableHealthInfo(item)">
                  <update-health-info :healthInfo="item" />
                </div>
              </span>
              <span v-else>
                {{ item[header.value] }}
              </span>

              <!-- {{items}} -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CreateHealthInfo from "./CreateHealthInfo.vue";
import UpdateHealthInfo from "./UpdateHealthInfo.vue";
import moment from "moment";
export default {
  components: { CreateHealthInfo, UpdateHealthInfo },
  props: {
    customer: {
      required: true
    }
  },
  data() {
    return {
      healthInfo: [],
      moment: moment,
      headers: [
        { text: "Date", value: "date", width: 90 },
        { text: "Weight (Kg)", value: "weight", width: 90 },
        { text: "Height (cm)", value: "height", width: 90 },
        { text: "SMM (Kg)", value: "smm", width: 90 },
        { text: "PBF (%)", value: "pbf", width: 90 },
        { text: "BMI (Kg/m2)", value: "bmi", width: 90 },
        { text: "Notes", value: "notes", width: 90, align: "left" },
        { text: "Update", value: "update", width: 90 }
      ],
      loading: true,
      hasTodaysHistory: false,
    };
  },
  mounted() {
    this.$root.$on("updateHelthInfo", () => {
      this.loading = true;
      this.$apollo.queries.getHealthInfoByCustomerId.refetch({});
    });
  },
  methods: {
    dateFormat(date) {
      return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY");
    },
    disableHealthInfo(item) {
      if (item.date) {
        let date = item.date;
        let startDate = moment(new Date(parseInt(date))).startOf("day");
        let todayStartDate = moment(new Date()).startOf("day");
        let diff = startDate.diff(todayStartDate, "day");
        if (diff == 0) return true;
        else return false;
      } else {
        return false;
      }
    }
  },

  apollo: {
    getHealthInfoByCustomerId() {
      this.loading = true;
      return {
        query: require("../../api/getHealthInfoByCustomerId.graphql"),
        variables: {
          customerId: this.customer._id,
          input: {
            skip: 0,
            limit: 0
          }
        },
        result({ data, loading }) {
          this.loading = loading;
          if (data.getHealthInfoByCustomerId) {
            this.healthInfo = data.getHealthInfoByCustomerId.healthInfo;
            if (this.healthInfo) {
              let todaysRecord = this.healthInfo.find(x => moment(new Date(parseInt(x.date))).format('DD-MM-YYYY') == moment().format('DD-MM-YYYY'))
              if (todaysRecord) {
                this.hasTodaysHistory = true;
              }
            }
          }

        }
      };
    }
  },
  computed: {
    itemsWithSno() {
      if (this.healthInfo == null) return [];

      return this.healthInfo.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  }
};
</script>
