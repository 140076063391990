<template>
  <v-container class="mt-n6 pa-0">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="white--text text-capitalize" color="secondary">
          + Add
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Celebrity</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8"
          @submit="createCelebrity()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="text-body-2 black--text">Celebrity Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-autocomplete
                    v-model="customer"
                    :items="customerList"
                    item-text="user.firstnameEn"
                    item-value="_id"
                    solo
                    chips
                    dense
                    attach
                    small-chips
                    label="Search customers"
                    clearable
                    deletable-chips
                    :search-input.sync="customersSearchKey"
                    :loading="customerLoading"
                    return-object
                    :rules="[requiredValidator('Celebrity Name')]"
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for customer...
                        </h5>
                      </v-flex>
                    </template>

                    <template slot="selection" slot-scope="data">
                      {{ data.item.user.firstnameEn }}
                      {{ data.item.user.lastnameEn }}
                      - {{ data.item.customerId }}
                    </template>
                    <template slot="item" slot-scope="data"
                      >{{ data.item.user.firstnameEn }}
                      {{ data.item.user.lastnameEn }}
                      - {{ data.item.customerId }}
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-4 mt-sm-0">
                <v-flex xs12 sm4 md3>
                  <h5 class="text-body-2 black--text">Image</h5>
                </v-flex>

                <v-flex xs12 sm7 class="mt-3 mt-sm-0">
                  <croppa
                    class="ml-2 mt-2"
                    v-model="myCroppa"
                    :width="170"
                    :height="120"
                    canvas-color="black"
                    :placeholder="'Choose an image'"
                    :placeholder-font-size="16"
                    placeholder-color="yellow"
                    :accept="'image/*'"
                    :file-size-limit="0"
                    :quality="5"
                    :zoom-speed="3"
                    :disabled="false"
                    :disable-drag-and-drop="false"
                    :disable-click-to-choose="false"
                    :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false"
                    :disable-rotation="false"
                    :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false"
                    :show-remove-button="true"
                    :remove-button-color="'red'"
                    :remove-button-size="10"
                  ></croppa>

                  <v-layout>
                    <span style="color: red">
                      ****Use mouse scroll wheel to crop image****
                    </span>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mt-4">
                <v-flex xs12 sm4 md3>
                  <h4 class="text-body-2 black--text">Status</h4>
                </v-flex>
                <v-flex xs12 sm8 md7 class="mt-n1">
                  <v-checkbox label="Active" v-model="status"></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="text-body-2 black--text">
                    Description in English
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-textarea
                    :rules="[requiredValidator('description')]"
                    rows="4"
                    label="About Celebrity"
                    v-model="descriptionEn"
                    solo
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="text-body-2 black--text">Description in Arabic</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-textarea
                    :rules="[requiredValidator('description in AR')]"
                    rows="4"
                    label="About Celebrity "
                    v-model="descriptionAr"
                    solo
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="text-body-2 black--text">Commission Percentage</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    :rules="[
                      requiredValidator('commission percentage'),
                      percentageValidator('commission percentage ')
                    ]"
                    placeholder="Commission Percentage"
                    append-icon="mdi-percent"
                    v-model="commissionPercentage"
                    @keypress="isFloatNumber"
                    name="input-10-1"
                    type="number"
                    solo
                    dense
                    min="0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n6 mt-sm-0 mb-sm-n5">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="white--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="createCelebrity"
              :disabled="!valid"
              >Add</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackbarColor"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  requiredValidator,
  percentageValidator
} from "../../core/methods/validations";
export default {
  data() {
    return {
      image: null,
      awaitingSearch: false,
      myCroppa: null,
      customer: "",
      descriptionEn: "",
      descriptionAr: "",
      commissionPercentage: "",
      customerLoading: false,
      customersSearchKey: "",
      customerList: [],
      status: false,
      requiredValidator,
      percentageValidator,
      dialog: false,
      btnloading: false,
      snackbar: false,
      snackbarColor: "",
      text: "",
      valid: true,
      errors: [],
      variables: {
        limit: 25,
        skip: 0
      }
    };
  },
  props: {
    Celebrity: {
      required: true
    }
  },
  watch: {
    customersSearchKey: function(val) {
      if (val == null || val == undefined) return;
      if (val.length < 3) return;

      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.awaitingSearch = true;
          this.customerLoading = true;
          this.$apollo
            .query({
              query: require("@/customer/api/getAllCustomerBasicInfo.graphql"),
              variables: {
                limit: 100,
                skip: 0,
                searchKey: val
              }
            })
            .then(data => {
              this.customerList = [];
              if (data.data.getAllCustomerBasicInfo) {
                let customerList = data.data.getAllCustomerBasicInfo.customers;
                this.customerList = customerList;
              }
              this.awaitingSearch = false;
              this.customerLoading = false;
            });
        }, 1000);
      }
    },
    reciverType: {
      handler() {
        if (!this.reciverType) {
          this.reciverType = " ";
        }
      }
    }
  },
  methods: {
    createCelebrity() {
      this.myCroppa.generateBlob(blob => {
        this.image = blob;
        this.btnloading = true;
        this.$apollo
          .mutate({
            mutation: require("../api/createCelebrity.graphql"),
            variables: {
              customer: this.customer._id,
              status: this.status,
              descriptionEn: this.descriptionEn,
              descriptionAr: this.descriptionAr,
              commissionPercentage: parseFloat(this.commissionPercentage),
              image: this.image
            },
            update: () => {
              try {
                this.$root.$emit("updateCelebrity");
                this.text = "Celebrity Added";
                this.snackbarColor = "success";
                this.snackbar = true;
              } catch (e) {
                console.log(e);
                console.log("Something bad happend");
              }
              this.$refs.form.reset();
              this.btnloading = false;
            }
          })
          .then(() => {
            this.dialog = false;
          })
          .catch(error => {
            this.btnloading = false;
            if (error.graphQLErrors != null) {
              if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
                this.doesPhoneExist = true;
              } else if (error.graphQLErrors[0].message === "ALREADY_EXIST") {
                this.text = "Customer already exist";
                this.snackbarColor = "error";
                this.snackbar = true;
              } else {
                this.text = error.graphQLErrors[0].message;
                this.snackbarColor = "error";
                this.snackbar = true;
              }
            }
            console.log(error);
          });
      });
    },
    isFloatNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
