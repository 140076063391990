<template>
  <div>
    <v-container class="pa-4 pa-sm-8" fluid>
      <v-row>
        <span class="black--text text-h5 font-weight-bold ms-3 mb-6 mt-3">
          Permission
        </span>
        <v-spacer></v-spacer>
        <span>
          <router-link
            style="text-decoration: none"
            :to="{
              name: 'AddPermission'
            }"
            class="mx-auto"
          >
            <v-btn
              color="secondary"
              class="mt-3 text-capitalize white--text me-3"
            >
              <v-icon small class="me-1">mdi-plus</v-icon>Add
            </v-btn>
          </router-link>
        </span>
        <AuditTrialLog class="ms-2 ms-md-4 mt-3" auditType="ROLES_LOG" />
      </v-row>
      <!-- <v-row class="mb-5 ml-1">
        
      </v-row> -->
      <template>
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="usersRole"
          class="elevation-1 mt-6"
        >
          <template v-slot:item.name="{ item }">
            {{ usersRole.indexOf(item) + 1 }}
          </template>
          <template v-slot:item.userType="{ item }">
            {{ item.role.toUpperCase() }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-row>
              <DeletePermission
                v-if="hasRole(AdminUserPermissions.PERMISSION_DELETE)"
                @updatePermission="updatePermission"
                :permission="item"
              />
              <router-link
                class="mx-5"
                style="text-decoration: none"
                :to="{
                  name: 'UpdatePermission',
                  params: { id: item._id },
                  query: { id: item._id }
                }"
              >
                <v-icon color="black">mdi-square-edit-outline</v-icon>
              </router-link>
            </v-row>
          </template>
        </v-data-table>
      </template>
    </v-container>
  </div>
</template>

<script>
import DeletePermission from "../components/DeletePermission.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";

export default {
  name: "Permission",
  components: {
    AuditTrialLog,
    DeletePermission
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "name",
        width: "10%",
        sortable: false
      },

      { text: "User Type", value: "userType", width: "60%", sortable: false },
      { text: "Actions", value: "action", width: "30%", sortable: false }
    ],
    usersRole: []
  }),
  methods: {
    getAllUserRole() {
      this.$apollo
        .watchQuery({
          query: require("../api/getAllUserRole.graphql")
        })
        .refetch({})
        .then(data => {
          this.usersRole = data.data.getAllUserRole;
        });
    },
    updatePermission() {
      this.getAllUserRole();
    }
  },
  mounted() {
    this.getAllUserRole();
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.PERMISSION_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>

<style scoped></style>
