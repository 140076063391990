<template>
  <div class="mx-5">
    <updateSlotAppointment :showDialogue="showDialogue" :slotAppointment="selectedArrayValue" :otherDatas="otherDatas"
      @closeDialogue="closeDialogue" @updateDietitianSlotAppointments="updateDietitianSlotAppointments" />
    <comment-view :commentList="commentList" :openComment="openComment" @closeComment="closeComment" />
    <v-flex>
      <h2 class="black--text text-h5  mt-5 font-weight-bold" v-if="isAdmin">
        Appointment Details
      </h2>
      <h2 v-else class="primary--text">Requests</h2>
    </v-flex>
    <v-layout class="mt-5">
      <v-flex xs12 sm6 lg4>
        <v-text-field placeholder="Search.." v-model="searchKey.searchKey" solo dense @keydown.enter="runSearch()">
          <template v-slot:prepend-inner>
            <v-icon color="black" v-if="search.length > 0" @click="clearSearch()">mdi-close</v-icon>
            <v-icon v-if="search.length == 0" color="black" @click="runSearch()">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-flex>
      <v-flex xs4 sm3 md2>
        <v-select class="ml-2" :items="searchList" v-model="searchKey.searchType" item-text="text" item-value="value"
          label="Search Type" dense attach solo></v-select>
      </v-flex>
      <v-spacer> </v-spacer>
    </v-layout>
    <v-layout wrap justify-end>
      <v-flex xs5 sm3 md2 class="mr-4 mx-sm-2" v-if="isAdmin">
        <v-autocomplete v-model="filterData.filterDietitian" :items="dietitianList" item-text="user.firstnameEn"
          item-value="_id" solo dense attach label="Search dietitian" clearable @change="filterSearch" return-object>
          <template slot="no-data">
            <v-flex xs12 md12 class="mt-2 mb-2">
              <h5 class="font-weight-medium ml-4">
                Search for dietitian...
              </h5>
            </v-flex>
          </template>

          <template slot="selection" slot-scope="data">
            {{ data.item.user.firstnameEn }}
            {{ data.item.user.lastnameEn }}
          </template>
          <template slot="item" slot-scope="data">{{ data.item.user.firstnameEn }}
            {{ data.item.user.lastnameEn }}
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs5 sm3 md2 class="ml-4 mx-sm-2">
        <v-select :items="actionTypeList" v-model="filterData.filterActionType" item-text="text" item-value="value"
          label="Type" dense attach solo @change="filterSearch"></v-select>
      </v-flex>
      <v-flex xs5 sm3 md2 class="mr-4 mx-sm-2">
        <v-select :items="getActionStatusList('filter')" v-model="filterData.filterActionStatus" item-text="text"
          item-value="value" label="Status" dense attach solo @change="filterSearch"></v-select>
      </v-flex>
      <v-flex xs5 sm3 md2 class="ml-4 mx-sm-2">
        <v-select :items="shiftList" v-model="filterData.filterShift" item-text="text" item-value="value" label="Shift"
          dense attach solo @change="filterSearch"></v-select>
      </v-flex>
      <v-flex xs5 sm3 md2 class="ml-4 mx-sm-2">
        <v-layout>
          <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="searchDate" label="Appointment Date" solo class=""
                append-icon="fa-calendar-alt black--text" v-bind="attrs" v-on="on" @click:clear="appDate = null" dense>
              </v-text-field>
            </template>
            <v-date-picker color="secondary" v-model="appDate" @change="selectFromDate"></v-date-picker>
          </v-menu>
        </v-layout>
        <v-layout justify-end class=" mt-n3  mb-3">
          <export-dietitian-appointment v-if="isAdmin" :headers="headers" :input="{
            input: { limit: totalItemsCount, skip: 1 },
            filterData,
            searchKey
          }" />
          <v-btn class="white--text ml-5 " color="btncolor" dense @click="clearSearch()">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-data-table :mobile-breakpoint="0" :headers="headers" :items="dietitianAppointmentList" class="elevation-1 mt-1"
      :loading="loading" :server-items-length="totalItemsCount" :options.sync="options">
      <template v-slot:[`item.user`]="{ item }">
        <router-link v-if="item.customer" :to="{
          name: 'CustomerDetails',
          params: { customerId: item.customer._id },
          query: { customerId: item.customer._id }
        }" class="mx-auto">
          {{
              item.customer.user.firstnameEn[0].toUpperCase() +
              item.customer.user.firstnameEn.substring(1)
          }}
          {{
              item.customer.user.lastnameEn[0].toUpperCase() +
              item.customer.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>
      <template v-slot:[`item.dietitian`]="{ item }">
        <router-link :to="{
          name: 'DietitianDetails',
          params: { dietitianId: item.dietitian._id },
          query: { dietitianId: item.dietitian._id }
        }" class="mx-auto">
          {{
              item.dietitian.user.firstnameEn[0].toUpperCase() +
              item.dietitian.user.firstnameEn.substring(1)
          }}
          {{
              item.dietitian.user.lastnameEn[0].toUpperCase() +
              item.dietitian.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>
      <template v-slot:[`item.bookedDate`]="{ item }">
        {{ bookedDate(item) }}
      </template>
      <template v-slot:[`item.appointmentDate`]="{ item }">
        {{ appointmentDate(item.followUp) }}
      </template>
      <template v-slot:[`item.mode`]="{ item }">
        {{ mode(item.followUp) }}
      </template>
      <template v-slot:[`item.shift`]="{ item }">
        {{ shift(item.followUp) }}
      </template>
      <!-- followUpType -->
      <template v-slot:[`item.followUpType`]="{ item }">
        {{ followUpType(item.followUp) }}

        <!-- {{  (item.followUp.updatedDate)}} -->
      </template>
      <template v-slot:[`item.actionType`]="{ item }">
        <v-select class="my-3 white rounded-lg " :items="actionTypeList" v-model="item.followUp.actionType"
          item-text="text" item-value="value" solo-inverted hide-details disabled></v-select>
      </template>
      <template v-slot:[`item.actionStatus`]="{ item }">

        <div
          v-if="item.followUp.actionStatus == 'CANCELLED' || item.followUp.actionStatus == 'FINISHED' && (paymentStatus(item.followUp) !== 'SUCCESS' || paymentStatus(item.followUp) !== 'FREE')">
          <v-select class="my-3 white rounded-lg " :items="getActionStatusList(item.followUp.actionStatus)"
            v-model="item.followUp.actionStatus" item-text="text" item-value="value" solo-inverted disabled hide-details
            @click="clickActionStatus(item.followUp.actionStatus)" @change="changeActionStatus(item)"></v-select>
        </div>
        <div v-else>
          <v-select background-color="green" class="my-3 white rounded-lg "
            :items="getActionStatusList(item.followUp.actionStatus)" v-model="item.followUp.actionStatus"
            item-text="text" item-value="value" solo-inverted hide-details
            @click="clickActionStatus(item.followUp.actionStatus)" @change="changeActionStatus(item)">
            <!-- <option v-for="opt in actionStatusList" :key="opt.value" :disabled="opt.disabled">{{opt.display}}</option> -->
          </v-select>
        </div>
      </template>
      <template v-slot:[`item.comment`]="{ item }">
        <!-- <v-text-field
          readonly
          class="my-3 white rounded-lg "
          :value="comment(item.followUp)"
          label="Comments"
          solo-inverted
          hide-details
          :append-icon="'mdi-eye black--text'"
          @click:append="showComments(item.followUp.comment)"
        ></v-text-field> -->
        <v-btn elevation="0" color="transparent " @click="showComments(item.followUp.comment)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.bookingFee`]="{ item }">
        {{ bookingFee(item.followUp) }}
      </template>
      <template v-slot:[`item.additionalFee`]="{ item }">
        {{ additionalFee(item.followUp) }}
      </template>
      <template v-slot:[`item.callFee`]="{ item }">
        {{ callFee(item.followUp) }}
      </template>
      <template v-slot:[`item.totalFee`]="{ item }">
        {{ totalFee(item.followUp) }}
      </template>
      <template v-slot:[`item.paymentStatus`]="{ item }">


        <div>
          BOOKING FEE:
          <span :class="
            item.followUp.payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE' || item.followUp.payment_status == 'FREE'
              ? 'green--text'
              : item.followUp.payment_status == 'PENDING'
                ? 'orange--text'
                : item.followUp.payment_status == 'CANCELLED'
                  ? 'blue--text'
                  : item.followUp.payment_status == 'FAILED'
                    ? 'red--text'
                    : ''
          ">
            {{ item.followUp.payment_status }}

          </span>

        </div>

        <div
          v-if="item.followUp.actionStatus == 'FINISHED' && item.followUp.modeOfAppointment == 'ON_VISIT' && item.followUp.additional_payment_status">
          ADDITIONAL FEE :
          <span :class="
            item.followUp.additional_payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE'
              ? 'green--text'
              : item.followUp.additional_payment_status == 'PENDING'
                ? 'orange--text'
                : item.followUp.additional_payment_status == 'CANCELLED'
                  ? 'blue--text'
                  : item.followUp.additional_payment_status == 'FAILED'
                    ? 'red--text'
                    : ''
          ">
            {{ item.followUp.additional_payment_status }}

          </span>
        </div>
      </template>
    </v-data-table>

    <v-layout class="mt-10">
      <h2 v-if="isAdmin" class="black--text text-h5 font-weight-bold">
        Calendar View
      </h2>
      <h2 v-else class="primary--text text-h5 font-weight-bold">
        Calendar View
      </h2>
    </v-layout>
    <div>

      <DietitianBookingCalendar />
    </div>
    <v-spacer></v-spacer>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{ text }}</v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import updateSlotAppointment from "../components/UpdateDietittianSlotAppointment.vue";
import CommentView from "../components/CommentView.vue";
import ExportDietitianAppointment from "../components/ExportDietitianAppointment.vue";
import DietitianBookingCalendar from "../components/DietitianBookingCalendar.vue";
export default {
  components: {
    updateSlotAppointment,
    CommentView,
    ExportDietitianAppointment,
    DietitianBookingCalendar
  },
  //   props: {
  //     dietitianList: {
  //       require: true
  //     },
  //     userTypes: {
  //       require: true
  //     },
  //     loading: { require: true }
  //   },

  data: () => ({
    // appointmentSearchDate: "",
    appDate: "",
    menu: false,
    dialog: false,
    headers: [
      {
        text: "Sl No",
        value: "index",
        sortable: false,
        width: 70
      },
      { text: "Name", value: "user", sortable: false, width: 130 },
      {
        text: "Mobile Number",
        value: "customer.user.phone",
        sortable: false
      },
      {
        text: "Appointment On",
        value: "appointmentDate",
        sortable: false,
        width: 200
      },
      { text: "Booked Date", value: "bookedDate", sortable: false, width: 120 },
      {
        text: "Mode",
        value: "mode",
        sortable: false
        //  width: 120
      },
      { text: "Shift", value: "shift", sortable: false, width: 100 },
      { text: "Dietitian", value: "dietitian", sortable: false, width: 120 },
      { text: "Type", value: "followUpType", sortable: false, width: 120 },
      { text: "Action Type", value: "actionType", sortable: false, width: 180 },
      {
        text: "Action Status",
        value: "actionStatus",
        sortable: false,
        width: 180
      },
      {
        text: "Comment",
        value: "comment",
        sortable: false
        //  width: 250
      },
      {
        text: "Booking Fee / Call Fee",
        value: "bookingFee",
        sortable: false,
        width: 120
      },
      {
        text: "Additional Fee",
        value: "additionalFee",
        sortable: false,
        width: 120
      },
      { text: "Total", value: "totalFee", sortable: false, width: 120 },
      {
        text: "Payment Status",
        value: "paymentStatus",
        sortable: false,
        width: 240
      }
      //   { text: "Action", value: "actions", sortable: false, width: 10 }
    ],
    dietitianAppointmentList: [],
    totalCount: 0,
    actionTypeList: [
      { text: "First", value: "FIRST" },
      { text: "Follow Up", value: "FOLLOW_UP" }
    ],

    shiftList: [
      { text: "Morning", value: "MORNING_SHIFT" },
      { text: "Evening", value: "EVENING_SHIFT" }
    ],
    showDialogue: false,
    oldValue: [],
    oldActionStatus: "",
    selectedArrayValue: "",
    otherDatas: {},
    commentList: [],
    openComment: false,
    search: "",
    // filterDietitian: "",
    dietitianList: [],
    // filterActionType: "",
    // filterActionStatus: "",
    // filterShift: "",
    filterData: {
      filterActionStatus: "",
      filterActionType: "",
      filterDietitian: "",
      filterShift: "",
      appointmentSearchDate: ""
    },
    input: {
      limit: 10,
      skip: 0
    },
    searchKey: {
      searchKey: "",
      searchType: ""
    },
    searchList: [
      { text: "Name", value: "NAME" },
      { text: "Mobile Number", value: "PHONE" }
    ],
    options: {
      itemsPerPage: 10,
      page: 1,
      sortDesc: [false],
      sortBy: []
    },
    totalItemsCount: 10,
    loading: false,
    isAdmin: true,
    snackbar: false,
    snackColor: "green",
    text: ""
    // dietitanId: "",
  }),
  created() {

    if (!this.hasRole(this.AdminUserPermissions.DIETITIAN_APPOINTMENT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    if (this.$route.name === "Appointments") {
      this.isAdmin = false;
      // let data = localStorage.DONE_USER;
      // if (data) {
      //   this.dietitanId = data._id;
      // }
    }
    // this.getSlotAppointmentDetails();
  },
  watch: {
    options: {
      handler(item) {
        this.input.limit = item.itemsPerPage;
        this.input.skip = item.page;
        this.getSlotAppointmentDetails();
      }
    }
  },
  methods: {
    getActionStatusList(currentStatus) {

      let actionStatusList = [];
      if (currentStatus == 'filter') {
        actionStatusList = [
          { text: "Booked", value: "BOOKED" },
          { text: "Reshedule", value: "RESCHEDULE" },
          { text: "Confirmed", value: "CONFIRMED" },
          { text: "Cancelled", value: "CANCELLED", },
          { text: "Finished", value: "FINISHED" }
        ]
      }
      else if (currentStatus == 'BOOKED') {
        actionStatusList = [
          { text: "Booked", value: "BOOKED", disabled: true },
          { text: "Reshedule", value: "RESCHEDULE" },
          { text: "Confirmed", value: "CONFIRMED" },
          { text: "Cancelled", value: "CANCELLED" },
          { text: "Finished", value: "FINISHED", disabled: true }
        ]
      }
      else if (currentStatus == 'RESCHEDULE') {
        actionStatusList = [
          { text: "Booked", value: "BOOKED", disabled: true },
          { text: "Resheduled", value: "RESCHEDULE" },
          { text: "Next Reshedule", value: "NEXT_RESCHEDULE" },
          { text: "Confirmed", value: "CONFIRMED" },
          { text: "Cancelled", value: "CANCELLED" },
          { text: "Finished", value: "FINISHED", disabled: true }
        ]
      }
      else if (currentStatus == 'RESCHEDULE') {
        actionStatusList = [
          { text: "Booked", value: "BOOKED", disabled: true },
          { text: "Resheduled", value: "RESCHEDULE" },
          { text: "Next Reshedule", value: "NEXT_RESCHEDULE" },
          { text: "Confirmed", value: "CONFIRMED" },
          { text: "Cancelled", value: "CANCELLED" },
          { text: "Finished", value: "FINISHED" }
        ]
      }
      else {
        actionStatusList = [
          { text: "Booked", value: "BOOKED", disabled: true },
          { text: "Resheduled", value: "RESCHEDULE" },
          { text: "Confirmed", value: "CONFIRMED" },
          { text: "Cancelled", value: "CANCELLED" },
          { text: "Finished", value: "FINISHED" }
        ]
      }
      return actionStatusList;
    },
    filterSearch() {
      this.getSlotAppointmentDetails();
      // console.log(this.filterDietitian);
      // console.log("filterDietitian");
      // let filter = new Object();
      // filter.filterDietitian = this.filterDietitian._id;
      // filter.filterActionStatus = this.filterActionStatus;
      // filter.filterActionType = this.filterActionType;
      // filter.filterShift = this.filterShift;
      // console.log(filter)
    },
    selectFromDate() {
      this.menu = false;
      console.log(this.appDate);
      this.filterData.appointmentSearchDate = this.appDate;
      this.getSlotAppointmentDetails();
    },

    runSearch() {
      this.getSlotAppointmentDetails();
    },
    clearSearch() {
      this.filterData.filterActionStatus = this.filterData.appointmentSearchDate = this.filterData.filterActionType = this.filterData.filterDietitian = this.filterData.filterShift = this.searchKey.searchKey = this.searchKey.searchType =
        "";
      this.getSlotAppointmentDetails();
    },
    updateDietitianSlotAppointments() {
      this.showDialogue = false;
      this.getSlotAppointmentDetails();
    },
    closeDialogue(item) {
      item.followUp.actionStatus = this.oldActionStatus;
      // this.dietitianAppointmentList[
      //   this.selectedArrayValue
      // ].followUp.actionStatus = this.oldActionStatus;
      this.showDialogue = false;
    },
    changeActionStatus(item) {
      // console.log(item);
      // console.log(this.oldActionStatus);
      // if (item.followUp.actionStatus === "BOOKED") {
      //   this.snackColor = "red";
      //   this.text = "Can't change to booked status";
      //   this.snackbar = true;
      //   item.followUp.actionStatus = this.oldActionStatus;
      //   return;
      // }
      this.selectedArrayValue = item;
      // this.selectedArrayValue = this.dietitianAppointmentList
      //   .map(x => x._id)
      //   .indexOf(item._id);
      this.otherDatas.from = this.oldActionStatus;
      this.showDialogue = true;
    },
    clickActionStatus(actionStatus) {
      this.oldActionStatus = actionStatus;
    },
    bookedDate(followUp) {
      if (followUp.createdDate) {
        return moment(parseInt(followUp.createdDate)).format("DD MMM YYYY");
      }
    },
    appointmentDate(followUp) {
      if (followUp.appointmentDate) {
        let res1 = moment(parseInt(followUp.appointmentDate)).format(
          "DD MMM YYYY"
        );
        let res2 = followUp.appointmentTime;
        let result = res1 + " : " + res2;
        return result;
      }
    },
    mode(followUp) {
      if (followUp.modeOfAppointment) {
        let result = "--";
        if (followUp.modeOfAppointment == "ON_CALL") {
          result = "On Call";
        }
        if (followUp.modeOfAppointment == "ON_VISIT") {
          result = "Visit";
        }
        return result;
      }
    },
    shift(followUp) {
      let result = "--";
      if (followUp.shift) {
        if (followUp.shift == "MORNING_SHIFT") {
          result = "Morning";
        }
        if (followUp.shift == "EVENING_SHIFT") {
          result = "Evening";
        }
      }
      return result;
    },
    followUpType(followUp) {
      let result = "--";
      if (followUp.followType > 0) {
        result = `Follow Up - ${followUp.followType}`;
      } else if (followUp.followType == 0) {
        result = "First";
      }
      return result;
    },
    getActionStatus(data) {
      return data;
    },
    actionStatus(followUp) {
      let result = "--";
      if (followUp.actionStatus) {
        result = followUp.actionStatus;
      }
      return result;
    },
    comment(followUp) {
      let result = "--";
      if (followUp.comment)
        if (followUp.comment.length) {
          if (followUp.comment.length == 1)
            result = followUp.comment[0].message;
          else {
            let length = followUp.comment.length;
            result = followUp.comment[length - 1].message;
          }
        }
      return result;
    },
    bookingFee(followUp) {
      let result = "";
      if (followUp.modeOfAppointment == "ON_VISIT") {
        if (followUp.bookingFee) {
          result = followUp.bookingFee;
        }
      } else {
        if (followUp.onCallFee) {
          result = followUp.onCallFee;
        }
      }
      return result;
    },
    additionalFee(followUp) {
      let result = "";
      if (followUp.modeOfAppointment == "ON_VISIT") {
        if (followUp.additionalFee) {
          result = followUp.additionalFee;
        }
      }

      return result;
    },
    callFee(followUp) {
      let result = "";
      if (followUp.onCallFee) {
        result = followUp.onCallFee;
      }
      return result;
    },
    paymentStatus(followUp) {
      let result = "--";
      if (
        followUp.actionStatus == "FINISHED" &&
        followUp.modeOfAppointment == "ON_VISIT"
      ) {
        if (followUp.additional_payment_status) {
          // if (followUp.payment_status == "SUCCESS") {
          //   result = "Success";
          // }
          result = followUp.additional_payment_status;
        }
      } else {
        if (followUp.payment_status) {
          // if (followUp.payment_status == "SUCCESS") {
          //   result = "Success";
          // }
          result = followUp.payment_status;
        }
      }

      return result;
    },
    totalFee(followUp) {
      let result = 0;
      if (followUp.modeOfAppointment == "ON_VISIT") {
        if (followUp.bookingFee) {
          result += parseFloat(followUp.bookingFee);
        }
        if (followUp.additionalFee) {
          result += parseFloat(followUp.additionalFee);
        }
      } else result = followUp.onCallFee;
      return result;
    },
    showComments(comment) {
      this.commentList = comment;
      this.openComment = true;
    },
    closeComment() {
      this.openComment = false;
    },
    getAllDietitians() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../api/getAllDietitians.graphql"),
          variables: {
            skip: 0,
            limit: 1000
          }
        })
        .refetch({})
        .then(data => {
          this.loading = false;
          this.dietitianList = data.data.getAllDietitians.dietitians;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getSlotAppointmentDetails() {
      let dietitanId = "";
      if (this.$route.name === "Appointments") {
        let data = JSON.parse(localStorage.getItem("DONE_USER"));
        if (data) {
          dietitanId = data._id;
        }
      }
      this.loading = true;
      this.filterData.filterDietitian
        ? (this.filterData.filterDietitian = this.filterData.filterDietitian._id)
        : "";

      this.$apollo
        .watchQuery({
          query: require("../api/getAllSlotAppointments.graphql"),
          variables: {
            input: this.input,
            filterData: this.filterData,
            searchKey: this.searchKey,
            dietitanId
          }
        })
        .refetch({})
        .then(data => {
          this.loading = false;

          if (data.data) {
            // if(data.data.getAllSlotAppointments.slotAppointments.length){
            //   for(let x of )
            // }
            this.dietitianAppointmentList =
              data.data.getAllSlotAppointments.slotAppointments;
            if (this.dietitianAppointmentList.length) {
              for (let [i, x] of this.dietitianAppointmentList.entries()) {
                x.index =
                  i + (this.options.page - 1) * this.options.itemsPerPage + 1;
              }
            }
            // console.log(data.data.getAllSlotAppointments);
            this.oldValue = data.data.getAllSlotAppointments.slotAppointments;
            this.totalItemsCount = data.data.getAllSlotAppointments.totalCount;
            this.getAllDietitians();
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  computed: {
    searchDate() {
      return this.filterData.appointmentSearchDate
        ? moment(this.filterData.appointmentSearchDate).format("DD-MM-YYYY")
        : "";
    }
  }
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
