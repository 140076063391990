<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="mt-n3 white--text text-capitalize btn_fnt"
        color="secondary"
      >
        <v-icon small class="me-2">fa-file-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="exportMealRating"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="mealRatingExportList"
        class="elevation-1 mt-6"
      >
        <template v-slot:[`item.rating`]="{ item }">
          {{ item.rating }}
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ new Date(parseInt(item.date)) | moment("MMM DD YYYY") }}
        </template>

        <template v-slot:[`item.customer`]="{ item }">
          <div v-if="item.customer">
            <div v-if="item.customer.user">
              {{
                item.customer.user.firstnameEn[0].toUpperCase() +
                  item.customer.user.firstnameEn.substring(1)
              }}
              {{
                item.customer.user.lastnameEn[0].toUpperCase() +
                  item.customer.user.lastnameEn.substring(1)
              }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <div v-if="item.customer">
            {{ item.customer.user.phone }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ new Date(parseInt(item.createdAt)) | moment("MMM DD YYYY") }}
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    }
  },
  data() {
    return {
      mealRatingExportList: [],
      loading: false
    };
  },
  methods: {
    getMealRatings() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("../../customer/api/getAllCustomerRating.graphql"),
          variables: {
            customerId: "",
            limit: 0,
            skip: 0,
            sortDesc: false,
            sortBy: ""
          }
        })
        .then(data => {
          if (data.data) {
            this.mealRatingExportList =
              data.data.getAllCustomerRating.customerMealRating;
            this.loading = false;
            setTimeout(() => {
              Utils.htmlToExcel("exportMealRating", "Meal Rating", false);
            }, 100);
          }
        });
    },
    exportExcel() {
      this.getMealRatings();
    }
  }
};
</script>
