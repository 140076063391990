<template>
  <div class="secondary black--text" style="height: 270px">
    <v-row class="mt-4 mx-2 ">
      <h4 class="black--text text-h6 font-weight-bold">Dietitian Info</h4>
    </v-row>
    <v-row class="mb-n6">
      <v-col md="5" class="ms-2">Certification</v-col>
      <v-col md="6" class="font-weight-medium" v-if="dietitian.certification">
        {{ dietitian.certification ? dietitian.certification : "--" }}
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col md="5" class="ms-2">Designation</v-col>
      <v-col md="6" class="font-weight-medium" v-if="dietitian.designation">
        {{ dietitian.designation ? dietitian.designation : "--" }}
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col md="5" class="ms-2">Description</v-col>
      <v-col md="6" class="font-weight-medium" v-if="dietitian.descriptionEn">
        {{ dietitian.descriptionEn }}
      </v-col>
    </v-row>

    <v-row class="mt-n6" v-if="dietitian.consultationMode.includes('ON_CALL')">
      <v-col md="5" class="ms-2">Call Mode Fee</v-col>
      <v-col md="6" class="font-weight-medium">{{ dietitian.callModeFee.fee }} KD</v-col>
    </v-row>

    <v-row class="mt-n6" v-if="dietitian.consultationMode.includes('ON_VISIT')">
      <v-col md="5" class="ms-2">Visit Mode Fee</v-col>
      <v-col md="6" class="font-weight-medium">{{ dietitian.visitModeBookingFee.fee }} KD</v-col>
    </v-row>
  </div>
</template>




<script>
export default {
  props: {
    dietitian: {
      required: true
    }
  },
  methods: {
    toPascalCase(string) {
      return `${string}`
        .replace(new RegExp(/[-_]+/, "g"), " ")
        .replace(new RegExp(/[^\w\s]/, "g"), "")
        .replace(
          new RegExp(/\s+(.)(\w+)/, "g"),
          ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\s/, "g"), "")
        .replace(new RegExp(/\w/), s => s.toUpperCase());
    }
  },
  computed: {
    age: {
      get: function () {
        if (this.dietitian.user.dob) {
          let dateofbirth = new Date(parseInt(this.dietitian.user.dob));
          var ageDifMs = Date.now() - dateofbirth.getTime();
          var ageDate = new Date(ageDifMs);
          return Math.abs(ageDate.getUTCFullYear() - 1970);
        } else return "";
      },
      set: () => null
    }
  }
};
</script>
