var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('h2',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v("Promo Code")]),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mt-4",attrs:{"xs8":"","sm4":"","md3":""}},[_c('v-select',{attrs:{"items":_vm.allPromoCodes,"item-value":"_id","item-text":"promoCode","label":"PromoCode","dense":"","attach":"","solo":""},on:{"change":function($event){return _vm.getPromocodeReport()}},model:{value:(_vm.variables.promoCodeId),callback:function ($$v) {_vm.$set(_vm.variables, "promoCodeId", $$v)},expression:"variables.promoCodeId"}})],1),_c('v-flex',{staticClass:"px-0 mx-0",attrs:{"sm8":"","md9":""}},[_c('v-layout',{attrs:{"justify-end":""}},[_c('ExportPromocodeReport',{staticClass:"text-end",attrs:{"promocodeReport":_vm.promocodeReport,"headers":_vm.headers}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-4 mt-sm-6",attrs:{"mobile-breakpoint":0,"id":"myTable","headers":_vm.headers,"items":_vm.promocodeReport,"loading":_vm.loading,"item-key":"slNo","options":_vm.options,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.slNo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.slNo))]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.discountedAmount)+" KD")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(item.dateTime),"DD MMM YYYY")))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }