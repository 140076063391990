<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black">mdi-square-edit-outline</v-icon>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Update Zone </span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-sm-8 mt-n6" @submit="updateZone()" ref="form">
          <v-card-text>
            <v-container>
              <v-layout class="mt-3 mt-sm-0" wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="black--text text-body-2">Enter zone name</h4>
                </v-flex>
                <v-flex xs12 sm7 md5>
                  <v-text-field
                    label="Enter zone name"
                    v-model="zoneInfo.zoneName"
                    :rules="[requiredValidator('zone Name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-col
                  cols="8"
                  sm="10"
                  md="10"
                  lg="11"
                  class="font-weight-medium text-h6 ms-n3"
                >
                  Area
                </v-col>
                <v-col cols="6" sm="2" md="2" lg="1"
                  ><v-btn
                    small
                    color="btncolor"
                    class="px-6 text-capitalize mt-3 white--text"
                    @click="add"
                    >+ Add</v-btn
                  ></v-col
                >
              </v-layout>

              <v-layout
                class="mt-3 mt-sm-5 mt-md-0"
                v-for="(zoneArea, i) in zoneInfo.areaDetails"
                :key="i"
                wrap
              >
                <v-flex xs12 sm5 md4 class="mt-md-0 pe-6">
                  <v-autocomplete
                    item-text="nameEn"
                    v-model="zoneArea.area"
                    @change="getBlocksByArea(zoneArea)"
                    item-value="_id"
                    :items="areaList"
                    :rules="[requiredValidator('area ')]"
                    label="Select Area"
                    dense
                    attach
                    solo
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for area...
                        </h5>
                      </v-flex>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex xs9 sm4 md4 class="ms-sm-16">
                  <v-select
                    v-model="zoneArea.block"
                    :items="zoneArea.blockList"
                    solo
                    dense
                    item-value="_id"
                    item-text="block"
                    attach
                    chips
                    small-chips
                    label="Select Block"
                    multiple
                    deletable-chips
                  ></v-select>
                </v-flex>

                <template>
                  <v-icon
                    x-large
                    color="btncolor"
                    class="mt-n7 ms-2"
                    @click="remove(i)"
                    :disabled="zoneInfo.areaDetails.length == 1"
                    >mdi-close-box</v-icon
                  >
                </template>
              </v-layout>

              <v-layout justify-center>
                <v-flex xs8 md2 class="mt-8"> </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n16">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="white--text text-capitalize"
              :loading="btnloading"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              @click="updateZone"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      dialog: false,
      addedArea: [],
      nameEn: "",
      zoneInfo: [],
      nameAr: "",
      snackbar: false,
      text: "",
      areaList: [],
      areaDetails: [],
      blockList: []
    };
  },
  props: {
    zone: {
      required: true
    }
  },
  watch: {
    zone: {
      handler(newVal) {
        this.zoneInfo = JSON.parse(JSON.stringify(newVal));
        this.zoneInfo.areaDetails.forEach(element => {
          this.getBlocksByArea(element);
        });
      }
    }
  },

  apollo: {
    getAllAreas() {
      return {
        query: require("../api/getAllAreas.graphql"),
        result({ data, loading }) {
          // console.log(data.getAllAreas.areas);
          this.loading = loading;
          this.areaList = data.getAllAreas.areas;
        }
      };
    }
  },
  created() {
    this.zoneInfo = JSON.parse(JSON.stringify(this.zone));
    this.zoneInfo.areaDetails.forEach(element => {
      this.getBlocksByArea(element);
    });
  },
  methods: {
    updateZone() {
      this.btnloading = true;

      let blockValue;
      let areaData = new Array(0);
      this.zoneInfo.areaDetails.forEach(element => {
        console.log(element.block.length);
        if (element.block.length === 0) {
          blockValue = null;
        } else {
          let blocks = new Array();
          element.block.forEach(block => {
            block = block._id ? block._id : block;
            blocks.push(block);
          });
          blockValue = blocks;
        }
        let inf = {
          area: element.area._id ? element.area._id : element.area,
          block: blockValue
        };
        areaData.push(inf);
      });

      this.$apollo
        .mutate({
          mutation: require("../api/updateZone.graphql"),
          variables: {
            id: this.zoneInfo._id,
            zoneName: this.zoneInfo.zoneName,
            areaDetails: areaData
          },
          update: () => {
            this.$root.$emit("updateZone");
            this.dialog = false;
            this.btnloading = false;
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    getBlocksByArea(item) {
      let area = item.area;
      if (item.area._id) {
        area = item.area._id;
      }
      this.$apollo
        .query({
          query: require("../api/getBlocksByArea.graphql"),
          variables: {
            id: area
          }
        })
        .then(data => {
          item.blockList = data.data.getBlocksByArea.blocks;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },

    close() {
      this.dialog = false;
    },
    add() {
      const section = {
        area: " ",
        block: "",
        blockList: []
      };
      this.zoneInfo.areaDetails.push(section);
    },
    remove(index) {
      this.zoneInfo.areaDetails.splice(index, 1);
    }
  }
};
</script>
