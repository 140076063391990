import { apolloClient } from '@/vue-apollo.js';
import { updateClientCache } from '@/core/methods/update_cache.js';

const mutation = (_variables, _path, _readyQueryVariables, _isVariables, methodName, limitSkip) => {
	let getDataFromApi = [];
	let fullPath = getFullPath(_path);

	getDataFromApi = apolloClient.mutate({
		mutation: fullPath,
		variables: _variables,
		update: (store, { data }) => {
			if (_readyQueryVariables != null) {
				updateClientCache(store, data, _readyQueryVariables, _isVariables, methodName, _variables, limitSkip);
			}
		}
	});
	return getDataFromApi;
};

//concat full path
let getFullPath = (_path) => require('@/' + _path + '.graphql');

export { mutation };
