<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="availableChoices"
      :items-per-page="5"
    >
      <template v-slot:[`item.actions`]="props">
        <v-row>
          <EditPackageChoice
            :choiceInfo="props.item"
            :allCategories="allCategories"
            :allowedWeekDays="allowedWeekDays"
          />
          <v-icon color="red" @click="deleteItem(props.item)">
            mdi-delete
          </v-icon>
        </v-row>
      </template>

      <template v-slot:item.varients="{ item }">
        <div v-for="(varient, index) in item.mealChoice" :key="index">
          <PackageChoiceTableDetails :varient="varient" />
        </div>
      </template>
      <template v-slot:item.availableDays="{ item }">
        <span v-for="(day, idx) in item.availableDays" :key="idx">
          <span v-if="day.day">
            {{ day.day }}
          </span>
          <span v-else>
            {{ day }}
          </span>
        </span>
      </template>
    </v-data-table>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
    </v-snackbar>
  </div>
</template>
<script>
import PackageChoiceTableDetails from "./PackageChoiceTableDetails.vue";
import EditPackageChoice from "./EditPackageChoice.vue";

export default {
  components: { EditPackageChoice, PackageChoiceTableDetails },
  props: {
    allCategories: {
      default: []
    },
    availableChoices: {
      required: true
    },
allowedWeekDays:{
  type:Array
}
  },
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      pagination: {},
      headers: [
        { text: "Name English", value: "choiceNameEn", width: 150 },
        { text: "Name Arabic", value: "choiceNameAr", width: 150 },
        { text: "Day", value: "availableNumOfDays", width: 90 },
        // { text: "Price", value: "price" },
        { text: "Days", value: "availableDays", width: 160 },
        { text: "Variants", value: "varients", width: 160 },
        { text: "Actions", value: "actions", sortable: false, width: 90 }
      ]
    };
  },
  methods: {
    deleteItem(choice) {
      // console.log(item);
      const index = this.availableChoices.findIndex(
        item => item.choiceNameEn === choice.choiceNameEn
      );
      this.availableChoices.splice(index, 1);
      // console.log(index);
    },
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
    }
  }
};
</script>
