var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 mx-0"},[_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:" white--text text-capitalize btn_fnt",attrs:{"width":"180","loading":_vm.loading,"color":"btncolor"},on:{"click":_vm.exportExcel}},[_c('v-icon',{staticClass:"me-2",attrs:{"small":""}},[_vm._v("mdi-export")]),_vm._v("Export ")],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-data-table',{staticClass:"elevation-1 mt-6",attrs:{"id":"exportCustomerList","hide-default-footer":"","items-per-page":-1,"headers":_vm.headers,"items":_vm.customerList},scopedSlots:_vm._u([{key:"item.customerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.padLeft(item.customerId, 4))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "),_vm._v(" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")]}},{key:"item.tag",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag){return _c('span',{key:tag._id},[_vm._v(" "+_vm._s(tag.name)),_c('br')])})}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"address":_vm.defaultAddress(item.addressDetails),"type":"area"}})]}},{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"address":_vm.defaultAddress(item.addressDetails),"type":"block"}})]}},{key:"item.building",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"address":_vm.defaultAddress(item.addressDetails),"type":"building"}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }