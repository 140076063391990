<template>
  <div>
    <!-- <v-container class="pa-4 pa-sm-8" fluid> -->
    <v-layout justify-end>
      <!-- <v-flex class="mt-sm-n2 text-end"> -->
      <purchase-report
        :fromDate="fromDate"
        :toDate="toDate"
        :purchaseList="purchaseList"
        :headers="headers"
      />
      <!-- </v-flex> -->
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-1  ms-2"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template #[`item.slNo`]="{ item }">{{
        itemsWithSno.indexOf(item) +
          (options.page - 1) * options.itemsPerPage +
          1
      }}</template>
      <template #[`item.nameEn`]="{ item }">{{ item.nameEn }}</template>
      <template #[`item.nameAr`]="{ item }">{{ item.nameAr }}</template>
      <template #[`item.id`]="{ item }">{{ item.itemId }}</template>
      <template #[`item.brandName`]="{ item }">{{ item.brand }}</template>
      <template #[`item.unit`]="{ item }">{{ item.quantityUnit }}</template>
    </v-data-table>
    <!-- </v-container> -->
  </div>
</template>

<script>
import moment from "moment";
import PurchaseReport from "./export/PurchaseReport.vue";
export default {
  components: {
    "purchase-report": PurchaseReport
  },
  props: {
    month: {
      require: true
    },
    search: {
      require: true
    }
  },
  watch: {
    options: {
      handler() {
        let month = moment().set({
          year: this.month.split("-")[0],
          month: this.month.split("-")[1] - 1 ,
          day: 2
        });
        let fromDate = month.startOf("month").format("YYYY-MM-DD");
        let toDate = month.endOf("month").format("YYYY-MM-DD");
        this.$apollo.queries.monthlyPurchase.refetch({
          limit: this.options.itemsPerPage,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage,
          fromDate: fromDate,
          toDate: toDate,
          searchKey: this.search
        });
      },
      deep: true
    },
    month: {
      handler() {
        let month = moment().set({
          year: this.month.split("-")[0],
          month: this.month.split("-")[1] - 1,
          day: 2
        });
        let fromDate = month.startOf("month").format("YYYY-MM-DD");
        let toDate = month.endOf("month").format("YYYY-MM-DD");
        this.$apollo.queries.monthlyPurchase.refetch({
          limit: this.variables.limit,
          skip: 0,
          fromDate: fromDate,
          toDate: toDate,
          searchKey: this.search
        });
      },
      deep: true
    },
    search: {
      handler() {
        let month = moment().set({
          year: this.month.split("-")[0],
          month: this.month.split("-")[1] - 1,
          day: 2
        });
        let fromDate = month.startOf("month").format("YYYY-MM-DD");
        let toDate = month.endOf("month").format("YYYY-MM-DD");

        this.$apollo.queries.monthlyPurchase.refetch({
          limit: this.variables.limit,
          skip: 0,
          searchKey: this.search,
          fromDate: fromDate,
          toDate: toDate
        });
      }
    }
  },
  data() {
    return {
      loading: false,
      totalCount: 0,
      fromDate: "",
      toDate: "",
      variables: {
        limit: 25,
        skip: 0
      },
      options: {
        itemsPerPage: 25,
        page: 1
      },
      headers: [
        { text: "#", value: "slNo", width: 100 },
        { text: "Item ID", value: "id", width: 100 },
        { text: "Item Name (English)", value: "nameEn" },
        { text: "Item Name (Arabic)", value: "nameAr" },
        { text: "Brand Name", value: "brandName" },
        { text: "Unit", value: "unit" },
        { text: "Monthly Inward", value: "inwardTotal" }
      ],
      purchaseList: []
    };
  },
  apollo: {
    monthlyPurchase() {
      // debugger
      let month = moment().set({
        year: this.month.split("-")[0],
        month: this.month.split("-")[1] - 1 ,
        day: 2
      });
      let fromDate = month.startOf("month").format("YYYY-MM-DD");
      let toDate = month.endOf("month").format("YYYY-MM-DD");
      return {
        query: require("../api/monthlyPurchase.graphql"),
        variables: {
          limit: this.variables.limit,
          skip: this.variables.skip,
          fromDate: fromDate,
          toDate: toDate,
          searchKey: this.search
        },
        result({ data, loading }) {
          console.log("data", data);
          this.loading = loading;
          this.purchaseList = data.monthlyPurchase.ingredients;
          this.totalCount = data.monthlyPurchase.totalCount;
        }
      };
    }
  },
  computed: {
    itemsWithSno() {
      if (this.purchaseList)
        return this.purchaseList.map((d, index) => ({ ...d, slNo: index + 1 }));
      else return new Array();
    }
  }
};
</script>
