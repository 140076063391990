var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"headers":_vm.headers,"items":_vm.accountList,"mobile-breakpoint":0,"items-per-page":25,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.siNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.accountList .map(function(x) { return x._id; }) .indexOf(item._id) + 1 + _vm.options.itemsPerPage * (_vm.options.page - 1))+" ")]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.user.firstnameEn))+" "+_vm._s(_vm.capitalizeFirstLetter(item.user.lastnameEn))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(new Date(parseInt(item.createdAt))))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.amount))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.discount))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('InvoiceDownload',{attrs:{"invoice":item}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }