import { onLogin, apolloClient } from '@/vue-apollo.js';
import { mutation } from '@/core/methods/mutation.js';

const userSignIn = (phone, password) => {
	let auth;
	let variables = {
		phone: phone,
		password: password
	};

	let mutationPath = 'user/api/signin';
	auth = mutation(variables, mutationPath)
		.then((data) => {
			return setUserAndToken(apolloClient, data);
		})
		.catch((error) => {
			// console.log(error.message.toString());

			return JSON.stringify(error.message);

		});
	return auth;
};

// set role & user & token
function setUserAndToken(apolloClient, data) {
	onLogin(apolloClient, data.data.userSignIn.token);
	if (typeof localStorage !== 'undefined') {
		localStorage.setItem('DONE_USER', JSON.stringify(data.data.userSignIn.user));
		localStorage.setItem('DONE_USER_ROLE', data.data.userSignIn.user.role);
		localStorage.setItem('DONE_USER_PERMISSIONS', data.data.userSignIn.permissions);
		if (data.data.userSignIn.userRoles) {
			let permissions = JSON.stringify(data.data.userSignIn.userRoles);
			localStorage.setItem('DONE_USER_ROLE_PERMISSIONS', permissions);
		}
	}
	// location.reload();
	return true;
}

export { userSignIn };
