<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <div v-if="hasRole(AdminUserPermissions.PUSH_NOTIFICATION_SEND)">
      <h2 class="black--text">Schedule Notification</h2>
      <schedule-notification-form
        class="ms-n10 mt-sm-n10 mt-md-n3"
      ></schedule-notification-form>
    </div>
    <v-layout class="mt-2" wrap>
      <h2 class="black--text mb-6">Notification History</h2>
    </v-layout>
    <scheduled-notification-table></scheduled-notification-table>
  </v-container>
</template>
<script>
import ScheduleNotificationForm from "../components/ScheduleNotificationForm.vue";
import ScheduledNotificationTable from "../components/ScheduledNotificationTable.vue";
export default {
  components: {
    "schedule-notification-form": ScheduleNotificationForm,
    "scheduled-notification-table":ScheduledNotificationTable
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.PUSH_NOTIFICATION_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
