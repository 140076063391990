<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <!-- <div v-if="totalItemsCount == 0">
      <v-alert dense class="text-center secondary--text" color="btncolor" dark>
        No Active plans <strong> expires</strong> in nearby days
      </v-alert>
    </div> -->

    <div>
      <v-layout wrap>
        <v-flex xs8 sm3 md3 lg3>
          <h2 class="secondary--text text-h5 font-weight-bold">
            End Date Reminder
          </h2>
        </v-flex>
        <v-flex xs8 sm3 md3 lg2>
          <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="formatEndDate" label="End Date" solo class="ms-sm-2"
                append-icon="fa-calendar-alt black--text" v-bind="attrs" v-on="on" @click:clear="endDate = null" dense>
              </v-text-field>
            </template>
            <v-date-picker color="secondary" v-model="endDate" @change="selectEndDate"></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>

      <v-spacer></v-spacer>
      <v-layout class="text-end" justify-end>
        <!-- <div>
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                solo
                dense
                :value="formatEndDate"
                append-icon="fa-calendar-alt black--text"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="secondary"
              v-model="endDate"
              @input="menuEndDate = false"
              :max="endDateMaxDates"
              :min="endDateMinDates"
            ></v-date-picker>
          </v-menu>
        </div> -->
        <ExportEndDate :headers="headers" :endDate="endDate" />
      </v-layout>

      <v-data-table :mobile-breakpoint="0" :headers="headers" :options.sync="options" :items="salesOrderList"
        class="elevation-1 mt-4" :loading="loading" :items-per-page="25"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }" :server-items-length="totalItemsCount">
        <template v-slot:[`item.date`]="{ item }">{{
        new Date(parseInt(item.created_At)) | moment("MMM DD YYYY")
        }}</template>

        <template v-slot:[`item.plan`]="{ item }">{{
        item.package.titleEn
        }}</template>
        <template v-slot:[`item.package_price`]="{ item }">{{
        numberWithCommas(item.package_price)
        }}</template>
        <template v-slot:[`item.discount`]="{ item }">{{
        numberWithCommas(item.discount)
        }}</template>
        <template v-slot:[`item.net_price`]="{ item }">{{ numberWithCommas(item.net_price) }}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <PriceSplitup :subscribedPackage="item" />
        </template>

        <template v-slot:[`item.customer`]="{ item }">
          <router-link v-if="!item.is_deleted" :to="{
            name: 'CustomerDetails',
            params: { customerId: item.customer },
            query: { customerId: item.customer }
          }" class="mx-auto">
            {{ toTitleCase(item.user.firstnameEn) }}
            {{ toTitleCase(item.user.lastnameEn) }}
          </router-link>

          <span v-else>
            {{ toTitleCase(item.customer.firstnameEn) }}
            {{ toTitleCase(item.customer.lastnameEn) }}
          </span>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";
import ExportEndDate from "../components/ExportEndDate.vue";

export default {
  components: { ExportEndDate },

  data: () => ({
    loading: true,
    headers: [
      {
        text: "Order ID",
        value: "order_number",
        width: 120,
        sortable: false
      },
      { text: "Date", value: "date", width: 120, sortable: false },
      { text: "Customer Name", value: "customer", width: 180, sortable: false },
      { text: "Phone", value: "user.phone", width: 130, sortable: false },

      { text: "Start Date", value: "startDate", width: 120, sortable: true },
      { text: "End Date", value: "endDate", width: 120, sortable: true },
      { text: "Plan Name", value: "plan", width: 150, sortable: false },
      // {
      //   text: "Days",
      //   value: "availableNumOfDays",
      //   width: 75,
      //   sortable: false
      // },
      // {
      //   text: "Plan Status",
      //   value: "paymentStatus",
      //   width: 110,
      //   sortable: false
      // },
      {
        text: "Package Price",
        value: "package_price",
        width: 150,
        sortable: false
      },
      { text: "Discount", value: "discount", width: 130, sortable: false },
      // { text: "Refund", value: "refund", width: 100, sortable: false },
      // { text: "Payment Status", value: "", width: 120, sortable: false },
      { text: "Total", value: "net_price", width: 100, sortable: false }
    ],
    salesOrderList: [],
    menuList: [],
    menu1: false,
    totalItemsCount: 0,
    menuEndDate: false,
    endDate: "",
    options: {
      itemsPerPage: 25,
      page: 1
    },
    variables: {
      input: {
        limit: 25,
        skip: 0,
        sortBy: "",
        sortDesc: false
      }
    }
  }),
  watch: {
    options: {
      handler() {
        if (!this.loading) {
          this.variables.input.limit =
            this.options.itemsPerPage * this.options.page;
          this.variables.input.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage;
          this.variables.input.sortBy = this.options.sortBy
            ? this.options.sortBy[0]
            : "";
          this.variables.input.sortDesc = this.options.sortDesc ? this.options.sortDesc[0] : false,
            this.getSubscribePackagesEndDateReminder();
        }
      },
      deep: true
    }
  },

  created() {
    if (!this.hasRole(this.AdminUserPermissions.END_DATE_REMINDER_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getSubscribePackagesEndDateReminder();
  },
  methods: {
    selectEndDate() {
      this.menu1 = false;

      this.getSubscribePackagesEndDateReminder();
    },
    getSubscribePackagesEndDateReminder() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("../api/subscribedPackageEndDateReminder.graphql"),
          variables: {
            input: {
              skip: this.variables.input.skip,
              limit: this.variables.input.limit,
            },
            endDate: this.endDate
          }
        })
        .then(data => {
          this.loading = false;
          if (data.data) {
            console.log(this.variables.input.skip);
            if (this.variables.input.skip == 0) {
              this.totalItemsCount =
                data.data.subscribePackagesEndDateReminder.total_count;
            }
            this.salesOrderList =
              data.data.subscribePackagesEndDateReminder.sales_orders;

            this.salesOrderList.forEach(element => {
              element.startDate = moment(
                new Date(parseInt(element.start_date))
              ).format("MMM DD YYYY");
              element.endDate = moment(
                new Date(parseInt(element.end_date))
              ).format("MMM DD YYYY");
            });
          }
        });
    },

    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    numberWithCommas(x) {
      if (x)
        return x
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    }
  },
  computed: {
    formatEndDate() {
      return this.endDate ? moment(this.endDate).format(DATE_FORMAT) : "";
    }
  }
};
</script>
