var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"black"}},on),[_vm._v("mdi-square-edit-outline")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-3",attrs:{"color":"pop_bg"}},[_c('v-card-title',[_c('span',{staticClass:"text-h6 black--text"},[_vm._v("Update Appointment Time")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-form',{ref:"form",staticClass:"pa-sm-8 mt-n6",on:{"submit":function($event){return _vm.updateDietitianAppointment()}}},[_c('v-card-text',[_c('v-container',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('h5',{staticClass:"black--text text-body-2"},[_vm._v("Customer Name")])]),_c('v-flex',{staticClass:"mt-5 mt-md-0 text-body-2 ms-5",attrs:{"xs8":"","md3":""}},[(_vm.dietitianappointment.customer != null)?_c('span',[_vm._v(" "+_vm._s(_vm.dietitianappointment.customer.user.firstnameEn)+" "+_vm._s(_vm.dietitianappointment.customer.user.lastnameEn))]):_vm._e()]),_c('v-flex',{attrs:{"xs12":"","md2":"","offset-md-1":""}},[_c('h5',{staticClass:"black--text text-body-2"},[_vm._v("Phone")])]),_c('v-flex',{staticClass:"mt-5 mt-md-0 text-body-2",attrs:{"xs8":"","md3":""}},[(_vm.dietitianappointment.customer != null)?_c('span',[_vm._v(" "+_vm._s(_vm.dietitianappointment.customer.user.phone))]):_vm._e()])],1),_c('v-layout',{staticClass:"mt-5",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('h5',{staticClass:"black--text text-body-2"},[_vm._v("Date")])]),_c('v-flex',{staticClass:"mt-5 mt-md-0 ms-5",attrs:{"xs8":"","md3":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate,"readonly":"","label":"Date","rules":[_vm.requiredValidator('date')],"solo":"","append-icon":"mdi-calendar-month-outline black--text","dense":""},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md2":"","offset-md-1":""}},[_c('h5',{staticClass:"black--text text-body-2"},[_vm._v("Time")])]),_c('v-flex',{staticClass:"mt-5 mt-md-0",attrs:{"xs8":"","md3":""}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-clock-time-four-outline black--text","readonly":"","label":"Time","rules":[_vm.requiredValidator('time')],"dense":"","solo":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[(_vm.menu1)?_c('v-time-picker',{attrs:{"color":"secondary","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mb-n5"},[_c('v-spacer'),_c('v-btn',{staticClass:"black--text text-capitalize",attrs:{"color":"secondary","width":"120","loading":_vm.btnloading},on:{"click":_vm.updateDietitianAppointment}},[_vm._v("Update")])],1)],1),_c('v-snackbar',{attrs:{"timeout":"3000","right":"","top":"","color":"success"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.text))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }