var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"headers":_vm.headers,"mobile-breakpoint":0,"items":_vm.managerList,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'StaffInfo',
          params: { managerId: item._id },
          query: { managerId: item._id },
        }}},[(item.user && item.user.firstnameEn)?_c('span',[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")]):_vm._e()]):_vm._e()]}},{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.isActive),expression:"item.isActive"}],attrs:{"color":"btncolor"}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!item.isActive),expression:"!item.isActive"}],attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.user)?_c('ReferralTransations',{attrs:{"user":item.user,"isUserTable":"true"}}):_vm._e(),(_vm.hasRole(_vm.AdminUserPermissions.MANAGER_EDIT))?_c('ManagerUpdateForm',{staticClass:"ms-2",attrs:{"managerInfo":item}}):_vm._e(),(_vm.hasRole(_vm.AdminUserPermissions.MANAGER_DELETE))?_c('ManagerDelete',{attrs:{"manager":item}}):_vm._e()],1)]}},{key:"item.userRole",fn:function(ref){
        var item = ref.item;
return [(item.user &&  item.user.userRole)?_c('div',[(item.user.userRole.role)?_c('span',[_vm._v(" "+_vm._s(item.user.userRole.role.toUpperCase())+" ")]):_vm._e()]):_vm._e()]}},{key:"item.referralCode",fn:function(ref){
        var item = ref.item;
return [(item.user && item.user.userId)?_c('div',[_vm._v(" "+_vm._s(parseInt(item.user.userId).toString(16).toUpperCase())+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }