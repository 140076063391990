<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="secondary"
          class="white--text text-capitalize font-weight-bold"
        >
          Dieititian Description
        </v-btn>
      </template>
      <v-card class="pa-3">
        <v-card-title>
          <span class="text-h6 primary--text"> Dieititian Booking Description</span>
          <v-spacer></v-spacer>
          <v-btn color="btncolor" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n6"
          @submit="updateConfiguration()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="On Call Description"
                    v-model="onCallDescription"
                    :rules="[requiredValidator('on call description')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Visit Description"
                    v-model="visitDescription"
                    :rules="[requiredValidator('visit description')]"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n6">
            <!-- <v-spacer></v-spacer> -->
            <v-layout justify-center>
              <v-btn
                color="btncolor"
                class="white--text"
                :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                :loading="btnloading"
                @click="updateConfiguration"
                :disabled="!valid"
                >Save</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="color">{{
      text
    }}</v-snackbar>
    <v-snackbar v-model="snackbarError" timeout="3000" right top color="error">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      dialog: false,
      snackbarError: false,
      btnloading: false,
      addedArea: [],
      areaList: [],
      block: "",
      area: "",
      color: "success",
      snackbar: false,
      text: "",
      valid: true,
      onCallDescription: "",
      visitDescription: "",
    };
  },
  apollo: {
    getPushNotification() {
      return {
        query: require("../api/getAppConfigurations.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          if (data.getAppConfigurations)
            if (data.getAppConfigurations.dietitianBookingTypeDescription) {
              let onCall = data.getAppConfigurations.dietitianBookingTypeDescription.find(
                (x) => x.bookingType == "ON_CALL"
              );
              if (onCall) {
                this.onCallDescription = onCall.description;
              }
              let visit = data.getAppConfigurations.dietitianBookingTypeDescription.find(
                (x) => x.bookingType == "VISIT"
              );
              if (visit) {
                this.visitDescription = visit.description;
              }
            }
        },
      };
    },
  },
  methods: {
    updateConfiguration() {
      let newConfigs = new Array();
      let dietOnCallConfig = {
        bookingType: "ON_CALL",
        description: this.onCallDescription,
      };
      let dietVisitConfig = {
        bookingType: "VISIT",
        description: this.visitDescription,
      };
      newConfigs.push(dietOnCallConfig);
      newConfigs.push(dietVisitConfig);

      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateConfigurations.graphql"),
          variables: {
            dietitianBookingTypeDescription: newConfigs,
          },
          update: () => {
            try {
              this.color = "success";
              this.text = "Settings Updated";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.btnloading = false;
            this.dialog = false;
          },
        })
        .catch((error) => {
          this.btnloading = false;
          this.text = error;
          this.color = "error";
          this.snackbarError = true;

          console.log(error);
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
