<template>
  <v-container class="pa-4 pa-sm-8" fluid>
<h2 class=" text-h5 font-weight-bold mb-8">
  Report status
    </h2>
      <v-data-table
    :headers="headers"
    :loading="loading"
    :items="reports"
    :items-per-page="-1"
    class="elevation-1"
  >

<template v-slot:item.createdAt="{ item }">
  <span v-if="item.createdAt">

  {{moment(new Date(parseInt(item.createdAt))).format('DD MMMM YYYY hh:mm a')}}
  </span>
    
      </template>
  <template v-slot:item.url="{ item }">
    <a :href="excelUrl + item.url" class=" text-decoration-none">
    <v-btn   v-if="item.status === 'FINISHED'" small color="secondary"  class="black--text">
      <v-icon small>mdi-file-excel</v-icon>
      Download
      </v-btn>
      <span v-else-if="item.status === 'CANCELLED'" class="error--text">Cancelled</span>
    <v-btn v-else text  small   loading class=""> </v-btn>
    </a>
      </template>
  </v-data-table>
  </v-container>
</template>

<script>
import moment from 'moment' 
export default {
  data(){
    return{
      moment:moment,
      excelUrl: process.env.VUE_APP_BASE_URL,
      reports:[],
      loading:false,
      headers:[
        { text: 'Title', value: 'title' },
        { text: 'Created At', value: 'createdAt'},
        { text: 'Action', value: 'url',align:"center"},
      ]
    }
  },
  apollo: {
    excelReport() {
      return {
        query: require("../api/excelReports.graphql"),
        result({ data }) {
          if(data){
          this.reports = data.excelReport
          }
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: 15000,
        watchLoading(loading){
          this.loading = loading
        }
      };
    },
  }
}
</script>

<style>

</style>