<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-10"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-row>
          <!-- {{ item }} -->
          <ContactMethodUpdateForm
            v-if="hasRole(AdminUserPermissions.CONTACT_EDIT)"
            :contactMethod="item"
          />
          <DeleteContactMethod
            v-if="hasRole(AdminUserPermissions.CONTACT_DELETE)"
            :method="item"
          />
        </v-row>
      </template>
    </v-data-table>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import DeleteContactMethod from "../components/DeleteContactMethod";
import ContactMethodUpdateForm from "../components/ContactMethodUpdateForm";
export default {
  components: { DeleteContactMethod, ContactMethodUpdateForm },
  props: {
    methodList: {
      require: true,
    },
    loading: {
      require: true,
    },
    // options: {
    //   require: true,
    // },
    totalItemsCount: {
      require: true,
    },
  },
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      pagination: {},
      headers: [
        {
          text: "#",
          sortable: false,
          value: "slNo",
          width: 120,
        },
        { text: "Method En", value: "method", width: 230 },
        { text: "Method Ar", value: "methodAr", width: 230 },
        { text: "Action", value: "actions", sortable: false, width: 90 },
      ],
    };
  },
  computed: {
    itemsWithSno() {
      return this.methodList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  methods: {},
};
</script>