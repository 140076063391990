
<script>
import { Line } from "vue-chartjs";
import moment from "moment";
// import { DATE_FORMAT } from "../../utils/utils";

export default {
  extends: Line,

  mounted() {
    this.$apollo
      .query({
        query: require("../api/getSalesTrendGraph.graphql"),
      })
      .then((data) => {
        // console.log(data);
        let salesTrendList = data.data.getSalesTrendGraph;
        let graphLabels = new Array();
        let graphCount = new Array();
        salesTrendList.forEach((element) => {
          graphLabels.push(
            moment(`${element.date}`, "HH:mm").format("DD-MM-YYYY")
          );
          graphCount.push(element.price);
        });

        this.renderChart(
          {
            labels: graphLabels,
            datasets: [
              {
                label: "MONTHLY SALES TREND",
                // backgroundColor: '#f87979',
                data: graphCount,
              },
            ],
          },
          {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true,
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
            responsive: true,
            maintainAspectRatio: false,
          }
        );
      });
  },
};
</script>