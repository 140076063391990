<template>
  <div>
    <v-layout class="bg" wrap>
      <v-flex xs3 md1 class=" mt-n3">
        <CustomerAddPauseDate
          :pausedDates="pausedDates"
          :subscribedPackage="activePackage"
          @updatePausedDates="updatePausedDates"
        />
      </v-flex>
    </v-layout>

    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="pausedDates"
      :items-per-page="10"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:[`item.universalPause`]="{ item }">
        {{ item.isUniversalPause ? "Universal" : "Individual" }}
      </template>
      <template v-slot:[`item.last_modified_date`]="{ item }">
        {{
          new Date(parseInt(item.last_modified_date)) | moment("MMM DD YYYY")
        }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <Unpause
          v-if="checkActivePackage() && !item.isUniversalPause"
          :pausedDay="item"
          :activePackage="activePackage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CustomerAddPauseDate from "./CustomerAddPauseDate.vue";
import Unpause from "./Unpause.vue";
import { Utils } from "../../../utils/utils";
export default {
  components: { CustomerAddPauseDate, Unpause },
  props: {
    selectedPackage: {
      require: true
    }
  },
  watch: {
    selectedPackage: {
      handler(newVal) {
        this.activePackage = newVal;
        this.getPausedDated();
      },
      deep: true
    }
  },
  methods: {
    updatePausedDates(subscribedPackages) {
      this.getPausedDated(subscribedPackages);
    },
    checkActivePackage() {
      return Utils.checkIsActiveOrUpcomingPackage(this.activePackage);
    },

    getPausedDated() {
      let pausedDates = new Array();

      if (this.activePackage.selected_menu) {
        this.activePackage.selected_menu.forEach(menu => {
          if (menu.is_paused) {
            let pausedDate = {
              _id: menu._id,
              date: new Date(parseInt(menu.date)),
              isUniversalPause: menu.is_universal_paused,
              last_modified_by: menu.last_modified_by,
              last_modified_date: menu.last_modified_date
            };
            pausedDates.push(pausedDate);
          }
        });
        pausedDates.sort(function(a, b) {
          return new Date(a.date) - new Date(b.date);
        });
      }
      this.pausedDates = pausedDates;
    },
    onPackageChange() {
      this.getPausedDated();
    }
  },

  created() {
    this.activePackage = this.selectedPackage;
    this.getPausedDated();
  },
  data() {
    return {
      pausedDates: [],
      activePackage: [],
      runningPackages: [],

      headers: [
        { text: "Paused Date", value: "date" },
        { text: "Pause Type", value: "universalPause" },
        { text: "Modified Date", value: "last_modified_date" },
        { text: "Done By", value: "last_modified_by" },
        { text: "Action", value: "actions" }
      ]
    };
  }
};
</script>
