<template>
  <div>
    <v-btn class="white--text text-capitalize mb-4 ms-1" color="secondary" @click.stop="onAddClick()">
      <v-icon class="me-1" small>mdi-plus</v-icon>Add
    </v-btn>

    <v-dialog v-model="showDialog" max-width="600">
      <v-card class="pa-6" color="pop_bg">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2">Add a dislike item</v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2"></v-divider>
        <v-row class="mt-4" justify="center">
          <v-col cols="12" sm="3">
            <label class="black--text text-body-2">Select Ingredient</label>
          </v-col>
          <v-col cols="12" sm="8" class="mt-n3 mt-sm-0">
            <v-autocomplete v-model="ingrediant" :items="allIngredients" item-text="nameEn" item-value="_id" solo chips
              dense small-chips label="Select Ingredient" clearable deletable-chips
              :search-input.sync="dislikedItemsSearchKey" :loading="dislikedItemLoading">
              <template slot="no-data">
                <v-flex xs12 md12 class="mt-2 mb-2">
                  <h5 class="font-weight-medium ml-4">Search for ingredients...</h5>
                </v-flex>
              </template>
              <template slot="item" slot-scope="data">{{ data.item.nameEn }}, {{ data.item.nameAr }}</template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="end" class="mt-sm-4">
          <v-btn class="white--text me-3 text-capitalize" color="secondary"
            :disabled="ingrediant == '' || ingrediant == null" @click="addCustomerDislike()" :loading="btnloading">
            <v-icon left>mdi-plus</v-icon>Add
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{
    text
    }}</v-snackbar>
  </div>
</template>
<script>
import Vue from "vue";
export default Vue.extend({
  props: {
    pausedDates: {
      require: true,
    },
    subscribedPackage: {
      require: true,
    },
  },
  watch: {
    dislikedItemsSearchKey: function (val) {
      if (val == null || val == undefined) {
        return;
      }
      if (val.length <= 0) {
        return;
      }
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.awaitingSearch = false;
          this.dislikedItemLoading = true;
          this.$apollo
            .query({
              query: require("@/stock/api/getAllDislikableIngredients.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val,
              },
            })
            .then((data) => {
              this.allIngredients = data.data.getAllDislikableIngredients.ingredients;
              this.dislikedItemLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearch = true;
    },
  },
  methods: {
    onAddClick() {
      this.showDialog = true;
    },
    addCustomerDislike() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../../api/addCustomerDislike.graphql"),
          variables: {
            ingredientId: this.ingrediant,
            customerId: this.$route.query.customerId,
          },
          update: () => {
            this.showDialog = false;
          },
        })
        .then((data) => {
          this.ingrediant = "";
          this.snackbar = true;
          this.text = "New dislike added successfuly.";
          this.snackColor = "success";
          this.$emit("updateDislikes", data.data.addCustomerDislike.dislikes);
          this.$root.$emit("updateGetCustomerById");
          this.btnloading = false;
        })
        .catch((error) => {
          this.ingrediant = "";
          this.snackbar = true;
          this.text = "item already exist";
          this.snackColor = "error";
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
  },

  data() {
    return {
      loading: true,
      awaitingSearch: false,
      btnloading: false,
      dislikedItemsSearchKey: "",
      ingrediant: "",
      snackColor: "success",
      snackbar: false,
      text: "New dislike added successfuly.",
      dislikedItemLoading: false,
      allIngredients: [],
      showDialog: false,
    };
  },
});
</script>
