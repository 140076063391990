<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-layout class="mt-3 mt-sm-0">
      <h2 class="black--text text-h5 font-weight-bold">Offers</h2>
      <v-spacer></v-spacer>

      <OfferCreateForm v-if="hasRole(AdminUserPermissions.OFFER_ADD)" />
      <AuditTrialLog class="ms-2 ms-md-4" auditType="OFFER_LOG" />
    </v-layout>
    <app-offer-table :totalItemsCount="totalItemsCount"></app-offer-table>
  </v-container>
</template>

<script>
import OffersTable from "../components/OffersTable.vue";
import OfferCreateForm from "../components/OfferCreateForm.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";

export default {
  components: {
    "app-offer-table": OffersTable,
    OfferCreateForm,
    AuditTrialLog
  },
  data() {
    return {
      textFields: [],
      tags: [],
      loading: true,
      OfferList: [],
      totalItemsCount: 0,
      shift: {
        NameEn: "",
        NameAr: ""
      },
      Offer: "",
      zone: "",
      search: ""
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.OFFER_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
