<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn v-if="buttonMode" small class="primary" v-on="on">Edit</v-btn>
        <v-icon v-else v-on="on" color="red">mdi-pencil</v-icon>
      </template>
      <!-- <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="white--text ma-2 text-capitalize"
          color="btncolor"
        >
          <v-icon small class="white--text me-1"
            >mdi-square-edit-outline</v-icon
          >
          Edit
        </v-btn>
      </template> -->
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Update Dietitian</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          @submit="updateDietitian()"
          v-model="valid"
          ref="form"
          class="pa-sm-8"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="text-body-2 black--text">Dietitian Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md9>
                  <v-text-field
                    v-model="dietitianInfo.user.firstnameEn"
                    solo
                    dense
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap>
                    <v-flex xs12 sm3 md3>
                      <h5 class="text-body-2 black--text">Image</h5>
                    </v-flex>

                    <v-flex xs12 sm5 md9>
                      <croppa
                        class="ml-2 mt-2"
                        v-model="myCroppa"
                        :width="170"
                        :height="100"
                        canvas-color="black"
                        :placeholder="'Update image'"
                        :placeholder-font-size="16"
                        placeholder-color="yellow"
                        :accept="'image/*'"
                        :file-size-limit="0"
                        :quality="5"
                        :zoom-speed="3"
                        :disabled="false"
                        :disable-drag-and-drop="false"
                        :disable-click-to-choose="false"
                        :disable-drag-to-move="false"
                        :disable-scroll-to-zoom="false"
                        :disable-rotation="false"
                        :prevent-white-space="false"
                        :reverse-scroll-to-zoom="false"
                        :show-remove-button="true"
                        :remove-button-color="'red'"
                        :remove-button-size="20"
                      >
                        /></croppa
                      >
                      <v-layout>
                        <span style="color: red" class="text-center">
                          ****Use mouse scroll wheel to crop image****
                        </span>
                      </v-layout>
                    </v-flex>
                  </v-layout> -->

              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="black--text text-body-2">Last Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 class="mt-n1">
                  <v-text-field
                    placeholder="Last Name"
                    v-model="dietitianInfo.user.lastnameEn"
                    :rules="[requiredValidator('Last Name ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mb-3">
                <v-flex xs12 sm3 md3>
                  <h5 class="text-body-2 black--text">Image</h5>
                </v-flex>
                
                <v-flex xs12 sm8 md8>
                  <croppa
                    class=" mt-2"
                    v-model="myCroppa"
                    :width="170"
                    :height="100"
                    canvas-color="black"
                    :placeholder="'Update image'"
                    :placeholder-font-size="16"
                    placeholder-color="yellow"
                    :accept="'image/*'"
                    :file-size-limit="0"
                    :quality="5"
                    :zoom-speed="3"
                    :disabled="false"
                    :disable-drag-and-drop="false"
                    :disable-click-to-choose="false"
                    :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false"
                    :disable-rotation="false"
                    :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false"
                    :show-remove-button="true"
                    :remove-button-color="'red'"
                    :remove-button-size="20"
                  >
                    />
                    
                  <v-img :width="100" class="ms-md-8" :src="getImage()"  v-if="!myCroppa || !myCroppa.hasImage()"/>
                    </croppa
                  >
                  <v-layout class="mb-2 ">
                    <span style="color: red" class="text-center">
                      ****Use mouse scroll wheel to crop image****
                    </span>
                  </v-layout>
                </v-flex>
                
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="black--text text-body-2">Phone</h4>
                </v-flex>
                <v-flex xs12 sm8 md9>
                  <v-text-field
                    placeholder="Phone"
                    @keypress="isNumber"
                    v-model="dietitianInfo.user.phone"
                    @click="doesPhoneExist = false"
                    :rules="[
                      requiredValidator('phone'),
                      minLengthValidator('phone', 8),
                      maxLengthValidator('phone', 8),
                      this.doesPhoneExist != true || 'phone already exists.'
                    ]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="black--text text-body-2">Password</h4>
                </v-flex>
                <v-flex xs12 sm8 md9>
                  <v-text-field
                    placeholder="Password"
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    @click:append="show1 = !show1"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              
              <!-- 
                  
                   -->
              <!-- <v-layout wrap>
                    <v-flex xs12 sm4 md2>
                      <h4 class="black--text text-body-2">Referral Percent</h4>
                    </v-flex>
                    <v-flex xs12 sm8 md4>
                      <v-text-field
                        placeholder="Referral Percent"
                        v-model="dietitianInfo.referralPercent.percentage"
                        type="number"
                        min="0"
                        append-icon="mdi-percent"
                        :rules="[requiredValidator('referral percentage ')]"
                        solo
                        dense
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm4 md2>
                      <h4 class="black--text text-body-2">
                        Max. Referral Amount(KD)
                      </h4>
                    </v-flex>
                    <v-flex xs12 sm8 md4>
                      <v-text-field
                        placeholder="Max. Referral Amount"
                        v-model="
                          dietitianInfo.referralPercent.maxReferralAmount
                        "
                        type="number"
                        min="0"
                        :rules="[
                          requiredValidator('max. referral percentage '),
                        ]"
                        solo
                        dense
                      ></v-text-field>
                    </v-flex>
                  </v-layout> -->
              <v-layout wrap>
                    <v-flex xs12 sm4 md3>
                      <h4 class="black--text text-body-2">Description</h4>
                    </v-flex>
                    <v-flex xs12 sm8 md9 class="mt-5 mt-md-0">
                      <v-textarea
                        solo
                        name="input-7-4"
                        placeholder="Description"
                        value=""
                        v-model="dietitianInfo.descriptionEn"
                        :rules="[requiredValidator('description ')]"
                      ></v-textarea>
                    </v-flex>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md3>
                        <h4 class="black--text text-body-2">
                          Description Arabic
                        </h4>
                      </v-flex>
                      <v-flex xs12 sm8 md9 class="mt-5 mt-md-0">
                        <v-textarea
                          solo
                          name="input-7-4"
                          value=""
                          label="Description in Arabic"
                          v-model="dietitianInfo.descriptionAr"
                          :rules="[requiredValidator('description in arabic')]"
                        ></v-textarea>
                      </v-flex>
                    </v-layout>
                  </v-layout>
              <!-- <v-layout class="mt-6" wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="text-body-2 black--text">Role</h4>
                </v-flex>
                <v-flex xs12 sm8 md6> -->
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="black--text text-body-2">Role</h4>
                </v-flex>
                <v-flex xs12 sm8 md9>
                  <v-autocomplete
                    :items="userTypes"
                    v-model="userRoleId"
                    item-text="role"
                    item-value="_id"
                    solo
                    chips
                    dense
                    attach
                    small-chips
                    label="User Type"
                    clearable
                    deletable-chips
                    :rules="[requiredValidator('User Type')]"
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for user types...
                        </h5>
                      </v-flex>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
                            <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="black--text text-body-2">Designation</h4>
                </v-flex>
                <v-flex xs12 sm8 md9>
                  <v-text-field
                    placeholder="Designation"
                    v-model="dietitianInfo.designation"
                    :rules="[requiredValidator('Designation ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h4 class="black--text text-body-2">Certification</h4>
                </v-flex>
                <v-flex xs12 sm8 md9>
                  <v-text-field
                    placeholder="Certification"
                    v-model="dietitianInfo.certification"
                    :rules="[requiredValidator('Certification ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12 sm4 md3>
                  <h4 class="black--text text-body-2">Currently Active</h4>
                </v-flex>
                <v-flex xs12 sm8 md9>
                  <v-radio-group row v-model="dietitianInfo.isActive">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n6 mt-sm-0 mb-sm-n5">
            <v-spacer></v-spacer>

            <v-btn
              color="secondary"
              class="white--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="updateDietitian"
              :disabled="!valid"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import {
  requiredValidator,
  minLengthValidator,
  maxLengthValidator
} from "../../core/methods/validations";
export default {
  data() {
    return {
      show1: false,
      requiredValidator: requiredValidator,
      minLengthValidator: minLengthValidator,
      maxLengthValidator: maxLengthValidator,
      btnloading: false,
      dialog: false,
      addedArea: [],
      firstnameEn: "",
      lastnameEn: "",
      phone: "",
      password: "",
      myCroppa: null,
      image: null,
      imageUrl: process.env.VUE_APP_BASE_URL,
      snackbar: false,
      snackbarColor: "",
      text: "",
      doesPhoneExist: false,
      valid: true,
      listOfDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      userRoleId: "",
      userTypes:[] 
    };
  },
  created() {
    // this.dietitianInfo = JSON.parse(JSON.stringify(this.dietitian));
    // if (this.dietitianInfo.user.userRole) {
    //   this.userRoleId = this.dietitianInfo.user.userRole._id;
    // }
  },
  watch:{
  dietitian:{
    handler(){
    this.dietitianInfo = JSON.parse(JSON.stringify(this.dietitian));
    if (this.dietitianInfo.user.userRole) {
      this.userRoleId = this.dietitianInfo.user.userRole._id;
    }
    },
   immediate :true

  }
  },
  props: {
    dietitian: {
      required: true
    },
    // userTypes: {
    //   // required: true
    // },
    buttonMode: {}
  },
  apollo: {
    getAllUserRole() {
      return {
        query: require("../../permission/api/getAllUserRole.graphql"), 
        result({ data }) {
          this.userTypes = data.getAllUserRole;
        }
      };
    }
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloatNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getImage() {
      if (this.dietitianInfo.image)
        return this.imageUrl + this.dietitianInfo.image;
      else return require("../../assets/no-image.png");
    },
    updateDietitian() {
      if (this.myCroppa.hasImage()) {
        this.myCroppa.generateBlob(blob => {
          this.image = blob;
          this.updateDietitianAPI();
        });
      }else{
        this.updateDietitianAPI()
      }
    },
    updateDietitianAPI() {
      this.btnloading = true;
      const user = {
        firstnameEn: this.dietitianInfo.user.firstnameEn,
        lastnameEn: this.dietitianInfo.user.lastnameEn,
        password: this.password,
        phone: this.dietitianInfo.user.phone
      };
      let input = {
        isActive: this.dietitianInfo.isActive,
        designation: this.dietitianInfo.designation,
        descriptionEn: this.dietitianInfo.descriptionEn,
        descriptionAr: this.dietitianInfo.descriptionAr,
        certification: this.dietitianInfo.certification,
        image: this.image
      };
      // console.log(input);
      this.$apollo
        .mutate({
          mutation: require("../api/updateDietitian.graphql"),
          variables: {
            dietitianId: this.dietitianInfo._id,
            userId: this.dietitianInfo.user._id,
            userInput: user,
            input: input,
            userTypeId: this.userRoleId
          },
          update: () => {
            // this.$refs.form.reset();
            this.btnloading = false;
            this.dialog = false;
            // this.$root.$emit("updateGetDietitianById");
          }
        })
        .then(() => {
          this.text = "Dietitian details updated";
          this.snackbar = true;
          this.snackbarColor = "success";
          this.$root.$emit("updateDietitian");
          this.btnloading = false;
          this.dialog = false;
        })
        .catch(error => {
          this.btnloading = false;
          if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
            this.doesPhoneExist = true;
          }
          this.text = error.graphQLErrors[0].message;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
