<template>
  <div>
    <!-- <v-dialog v-model="show_dialog_box" max-width="500px"> -->
    <!-- <template v-slot:activator="{ on }">
        <v-btn
          class="black--text text-capitalize mt-1 mb-5"
          color="secondary"
          v-on="on"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon>Add
        </v-btn>
      </template> -->

    <!-- <v-spacer></v-spacer> -->
    <v-card class="pa-6" color="pop_bg" flat>
      <!-- <v-row>
          <v-card-title class="text-h6 black--text mt-n2"
            >Add Dietitian Appointment</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row> -->
      <!-- <v-divider class="mt-n2 mb-6"></v-divider> -->
      <!-- {{ appointment }} -->
      <v-container>
        <v-form @submit="createAppointment()" v-model="valid" ref="form">
          <v-layout wrap>
            <v-flex xs12 sm4 md3>
              <h5 class="font-weight-medium text-body-2 mt-2">Date</h5>
            </v-flex>
            <v-flex xs12 sm7 md7>
              <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="formatDate" readonly label="Date"
                    :rules="[requiredValidator('date'), dietitianOff()]" solo dense
                    append-icon="mdi-calendar-month-outline" v-bind="attrs" v-on="on" @click:clear="date = null">
                  </v-text-field>
                </template>
                <!-- @change="menu = false" -->
                <v-date-picker v-model="date" :min="currentDate" @change="onChangeDietitian" color="secondary">
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <!-- offset-md-1 -->
          <v-layout wrap>
            <v-flex xs12 sm4 md3>
              <h5 class="font-weight-medium text-body-2 mt-2">Dietitian</h5>
            </v-flex>
            <v-flex xs12 sm7 md7>
              <!-- item-text="user.firstnameEn" -->
              <v-autocomplete readonly label="Dietitian" v-model="dietitian" solo dense attach
                :rules="[requiredValidator('Dietitian'), dietitianOff()]" :items="dietitianList"
                item-text="user.firstnameEn" item-value="_id" return-object @change="onChangeDietitian('data')">
                <template slot="no-data">
                  <v-flex xs12 md12 class="mt-2 mb-2">
                    <h5 class="font-weight-medium ml-4">
                      Search dietitian by name...
                    </h5>
                  </v-flex>
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.user.firstnameEn }}
                  {{ data.item.user.lastnameEn }}
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="dietitian">
            <v-flex xs12 sm4 md3>
              <h5 class="font-weight-medium text-body-2 mt-1">
                Mode of appointment
              </h5>
            </v-flex>
            <v-flex xs12 sm7 md7>
              <v-select :items="modeList" :rules="[requiredValidator('Mode of appointment')]" v-model="mode"
                item-text="text" item-value="value" dense attach solo @change="formatFee()"></v-select>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="dietitian && appointment.additionalFee">
            <v-flex xs12 sm4 md3>
              <h5 class="font-weight-medium text-body-2 mt-2">
                Addition Fee
              </h5>
            </v-flex>
            <v-flex xs12 sm7 md7>
              <v-text-field v-model="additionalFee" dense attach solo @change="formatTotalFee()"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="dietitian && mode">
            <v-flex xs12 sm4 md3>
              <h5 class="font-weight-medium text-body-2 mt-2">
                {{ mode == "ON_CALL" ? "Fee" : "BookingFee" }}
              </h5>
            </v-flex>
            <!-- :value="formatFee()" -->
            <v-flex xs12 sm7 md7>
              <v-text-field v-model="fee" dense attach solo></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="appointment.additionalFee && mode">
            <v-flex xs12 sm4 md3>
              <h5 class="font-weight-medium text-body-2 mt-2">
                Total Fee
              </h5>
            </v-flex>
            <v-flex xs12 sm7 md7>
              <v-text-field :value="formatTotalFee()" readonly dense attach solo></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="date && dietitian">
            <v-flex xs12 sm4 md3>
              <h5 class="font-weight-medium text-body-2 mt-2">Shift</h5>
            </v-flex>
            <v-flex xs12 sm7 md7>
              <v-select :items="shiftList" :rules="[requiredValidator('shift')]" v-model="shift" dense attach solo
                @change="onShiftChange"></v-select>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="shift">
            <v-flex xs12 sm4 md3>
              <h5 class="font-weight-medium text-body-2 mt-2">Slot</h5>
            </v-flex>
            <!-- {{shiftSlot}}  -->
            <v-flex xs12 sm7 md7>
              <v-combobox :items="shiftSlotList" :rules="[requiredValidator('slot')]" v-model="shiftSlot" dense solo
                item-text="startTime"></v-combobox>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="shift">
            <v-flex xs12 sm4 md3>
              <h5 class="font-weight-medium text-body-2 mt-2">
                Payment Method
              </h5>
            </v-flex>
            <v-flex xs12 sm7 md7>
              <v-select :items="avalilablePaymentMethod" :rules="[requiredValidator('payment method')]"
                v-model="paymentMethod" dense solo item-text="text" item-value="value"></v-select>
            </v-flex>
          </v-layout>
          <v-layout row class="mt-4" justify-center>
            <v-btn width="120" color="secondary" class="text-capitalize black--text" @click="createAppointment()"
              :loading="btnloading" :disabled="!valid">Save</v-btn>
          </v-layout>
        </v-form>
      </v-container>
      <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{ text }}</v-snackbar>
    </v-card>
    <!-- </v-dialog> -->
  </div>
</template>
<script>
import { requiredValidator } from "../../../core/methods/validations";
import moment from "moment";
// import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "../../../utils/utils";
export default {
  components: {},
  props: {
    appointment: {
      required: true
    },
    loadingBtn: {}
  },
  watch: {
    loadingBtn: {
      handler(item) {
        if (!item) {
          this.btnloading = false;
        }
      }
    }
  },
  apollo: {
    getAllDietitians() {
      return {
        query: require("@/dietitian/api/getAllDietitians.graphql"),
        variables: {
          skip: 0,
          limit: 1000,
          searchKey: ""
        },
        result({ data, loading }) {
          this.loading = loading;

          this.dietitianList = data.getAllDietitians.dietitians;
        }
      };
    }
  },
  created() {
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_ONLINE)) {
      const data = this.paymentMethods[0];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_OFFLINE)) {
      const data = this.paymentMethods[1];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_FREE)) {
      const data = this.paymentMethods[2];
      this.avalilablePaymentMethod.push(data);
    }
    this.dietitian = this.appointment.dietitian;
    if (this.appointment.additionalFee) {
      this.additionalFee = this.appointment.additionalFee;
    }
  },
  data() {
    return {
      avalilablePaymentMethod: [],

      paymentMethod: "",
      date: "",
      time: "",
      menu: "",
      menu1: "",
      dietitianLoading: false,
      dietitiansSearchKey: "",
      dietitianList: [],
      dietitian: "",
      awaitingSearch: false,
      btnDisabled: false,
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackColor: "",
      snackbar: false,
      text: "",
      variables: {
        limit: 0,
        skip: 0
      },
      shiftList: ["Morning Shift", "Evening Shift"],
      shift: "",
      shiftSlotList: [],
      shiftSlot: "",
      slotList: null,
      slot: "",
      paymentMethods: [
        { text: "Online", value: "ONLINE" },
        { text: "Offline", value: "OFFLINE" },
        { text: "Free", value: "FREE" }
      ],
      isDietitianOff: false,
      dietitianLeaveSlot: null,
      mode: "",
      slotMasterId: "",
      slotId: "",
      modeList: [],
      fee: "",
      additionalFee: ""
    };
  },
  methods: {
    feeModeChange() {
      console.log("changed");
    },
    onShiftChange() {
      this.shiftSlot = "";
      this.shiftChange();
    },
    dietitianOff() {
      if (this.isDietitianOff) {
        return "Dietitian is Off on this day";
      }
      if (this.dietitianLeaveSlot == "FULL_DAY") {
        return "Dietitian is leave on this day";
      }
      return true;
    },
    shiftChange() {
      if (this.slotList !== null) {
        this.shiftSlotList = this.slotList.slots.filter(
          x => x.shift == this.shift && x.customer == null
        );
      } else {
        this.shiftSlotList = [];
      }
      // this.formatFee();
    },
    onChangeDietitian(data) {
      console.log("hey");
      this.shiftSlot = "";
      this.menu = false;
      if (data) {
        this.mode = "";
        let modeArray = new Array();
        this.modeList = [];
        console.log(this.dietitian);

        if (this.dietitian.consultationMode)
          if (this.dietitian.consultationMode.length) {
            modeArray = this.dietitian.consultationMode;
          }
        if (modeArray.includes("ON_CALL")) {
          let data = { text: "On Call", value: "ON_CALL" };
          this.modeList.push(data);
        }
        // else {
        //   this.modeList.filter(x => x.value !== "ON_CALL");
        // }
        if (modeArray.includes("ON_VISIT")) {
          let data = { text: "Visit", value: "ON_VISIT" };
          this.modeList.push(data);
        }
        //  else {
        //   this.modeList.filter(x => x.value !== "ON_VISIT");
        // }
      }
      if (this.date && this.dietitian) {
        this.getDietitianSlots();
      }
    },
    getDietitianSlots() {
      this.dietitianLeaveSlot = null;
      console.log(this.dietitian._id);
      console.log(this.date);
      this.$apollo
        .watchQuery({
          // .query({
          query: require("@/dietitian/dietitian_settings/api/getDietitanSlots.graphql"),
          variables: {
            dietitianId: this.dietitian._id,
            date: this.date
            // dietitianId: "61e3b93ba4581739e42ae9db",
            // date: "2022-01-26"
          }
        })
        .refetch({
          variables: {
            dietitianId: this.dietitian._id,
            date: this.date
          }
        })
        .then(data => {
          if (data.data) {
            console.log(data.data);
            this.slotList = data.data.getAvailableSlot;
            this.slotMasterId = this.slotList._id;
            this.isDietitianOff = false;
            this.shiftChange();
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            // console.error(error.graphQLErrors);
            this.shiftChange();
            if (error.graphQLErrors[0].message === "DIETITIAN_IS_OFF") {
              this.isDietitianOff = true;
              this.slotList = null;
              this.shiftChange();
            } else if (error.graphQLErrors[0].message === "FULL_DAY") {
              console.log("full day");
              this.dietitianLeaveSlot = "FULL_DAY";
            } else {
              this.text = "Something bad is happend";
              this.snackColor = "error";
              this.snackbar = true;
            }
          }
          this.btnloading = false;
        });
    },
    // getDietitianSlots() {
    //   this.dietitianLeaveSlot = null;
    //   this.$apollo
    //     .watchQuery({
    //       // .query({
    //       query: require("@/dietitian/dietitian_settings/api/getDietitanSlots.graphql"),
    //       variables: {
    //         dietitianId: this.dietitian._id,
    //         date: this.date
    //         // dietitianId: "61e3b93ba4581739e42ae9db",
    //         // date: "2022-01-26"
    //       }
    //     })
    //     .refetch({
    //       variables: {
    //         dietitianId: this.dietitian._id,
    //         date: this.date
    //       }
    //     })
    //     .then(data => {
    //       if (data.data) {
    //         console.log(data.data);
    //         this.slotList = data.data.getAvailableSlot;
    //         this.slotMasterId = this.slotList._id;
    //         this.isDietitianOff = false;
    //         this.shiftChange();
    //       }
    //     })
    //     .catch(error => {
    //       if (error.graphQLErrors != null) {
    //         // console.error(error.graphQLErrors);
    //         this.shiftChange();
    //         if (error.graphQLErrors[0].message === "DIETITIAN_IS_OFF") {
    //           this.isDietitianOff = true;
    //           this.slotList = null;
    //           this.shiftChange();
    //         } else if (error.graphQLErrors[0].message === "FULL_DAY") {
    //           console.log("full day");
    //           this.dietitianLeaveSlot = "FULL_DAY";
    //         } else {
    //           this.text = "Something bad is happend";
    //           this.snackColor = "error";
    //           this.snackbar = true;
    //         }
    //       }
    //       this.btnloading = false;
    //     });
    // },
    formatFee() {
      if (this.dietitian) {
        let data = 0;
        if (this.mode == "ON_CALL") {
          if (this.dietitian.callModeFee) data = this.dietitian.callModeFee.fee;
        } else if (this.mode == "ON_VISIT") {
          if (this.dietitian.visitModeBookingFee)
            data = this.dietitian.visitModeBookingFee.fee;
        }
        this.fee = data;
      }
    },
    formatTotalFee() {
      let data = 0;
      if (this.fee) data = parseFloat(this.fee);
      if (this.additionalFee) {
        data += parseFloat(this.additionalFee);
      }
      return data;
    },
    close() {
      this.show_dialog_box = false;
    },
    createAppointment() {
      this.btnloading = true;
      // "Morning Shift", "Evening Shift"
      let shift;
      this.shift == "Morning Shift"
        ? (shift = "MORNING_SHIFT")
        : (shift = "EVENING_SHIFT");
      // let input=new Object()
      let input = {
        // customer: this.customer_id,
        additionalFee: parseFloat(this.additionalFee),
        totalFee: this.formatTotalFee(),
        followUpFee: parseFloat(this.fee),
        appointmentTime: this.shiftSlot.startTime,
        appointmentDate: this.date,
        // dietitian: this.dietitian._id,
        slotId: this.shiftSlot._id,
        paymentMethod: this.paymentMethod,
        modeOfAppointment: this.mode,
        shift
      };
      // if(this.appointment.modeOfAppointment=="ON_VISIT"){
      //   if(this.additionalFee=="")
      // }

      console.log(input);
      this.$emit("updateSlotAppointmentFollowUp", input);
      // this.btnloading = false;
      // this.$apollo
      //   .mutate({
      //     mutation: require("@/dietitian/dietitian_settings/api/createSlotAppointment.graphql"),
      //     variables: {
      //       input: {
      //         customer: this.customer_id,
      //         appointmentTime: this.shiftSlot.startTime,
      //         appointmentDate: this.date,
      //         dietitian: this.dietitian._id,
      //         slotId: this.shiftSlot._id,
      //         paymentMethod: this.paymentMethod,
      //         modeOfAppointment: this.mode,
      //         shift
      //       }
      //     }
      //   })
      //   .then(() => {
      //     this.snackbar = true;
      //     this.snackColor = "success";
      //     this.text = "Dietitian appoinment added";
      //     this.$root.$emit("updateDietitianAppointments");
      //     this.$refs.form.reset();
      //     this.btnloading = false;
      //     this.close();
      //   })
      //   .catch(error => {
      //     if (error.graphQLErrors != null) {
      //       console.error(error.graphQLErrors);
      //       if (error.graphQLErrors[0].message === "ALREADY_CREATED") {
      //         this.text = "Appoinment already created";
      //         this.snackColor = "error";
      //         this.snackbar = true;
      //       }
      //     }
      //     this.btnloading = false;
      //   });
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    },
    currentDate() {
      let data = new Date();
      return moment(data).format("YYYY-MM-DD");
    }
  }
};
</script>
