const isAdmin = () => {
	try {
		return localStorage.DONE_USER_ROLE === 'ADMIN';
	} catch (e) {
		return false;
	}
};

const isManager = () => {
	try {
		return localStorage.DONE_USER_ROLE === 'MANAGER';
	} catch (e) {
		return false;
	}
};
const isDietitian = () => {
	try {
		return localStorage.DONE_USER_ROLE === 'DIETITIAN';
	} catch (e) {
		return false;
	}
};
const isSalesManager = () => {
	try {
		return localStorage.DONE_USER_PERMISSIONS.includes('Sales');
	} catch (e) {
		return false;
	}
};
const isOperationManager = () => {
	try {
		return localStorage.DONE_USER_PERMISSIONS.includes('Operation');
	} catch (e) {
		return false;
	}
};
const isReportsManager = () => {
	try {
		return localStorage.DONE_USER_PERMISSIONS.includes('Reports');
	} catch (e) {
		return false;
	}
};


// get current user from localstorage
const getCurrentUser = () => {
	try {
		return JSON.parse(localStorage.DONE_USER);
	}
	catch (e) {
		return false;
	}
};

export { isAdmin, isManager, isDietitian, isSalesManager, isOperationManager, isReportsManager, getCurrentUser };
