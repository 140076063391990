<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn @click="exportExcel" width="150" :loading="loading" class="mt-n3 white--text text-capitalize btn_fnt"
        color="btncolor">
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table id="DietitianAppoinments" hide-default-footer :mobile-breakpoint="0" :headers="headers"
        :items="dietitianAppointmentList" class="elevation-1 mt-1">
        <template v-slot:[`item.user`]="{ item }">

          {{
              item.customer.user.firstnameEn[0].toUpperCase() +
              item.customer.user.firstnameEn.substring(1)
          }}
          {{
              item.customer.user.lastnameEn[0].toUpperCase() +
              item.customer.user.lastnameEn.substring(1)
          }}

        </template>
        <template v-slot:[`item.dietitian`]="{ item }">

          {{
              item.dietitian.user.firstnameEn[0].toUpperCase() +
              item.dietitian.user.firstnameEn.substring(1)
          }}
          {{
              item.dietitian.user.lastnameEn[0].toUpperCase() +
              item.dietitian.user.lastnameEn.substring(1)
          }}

        </template>
        <template v-slot:[`item.bookedDate`]="{ item }">
          {{ new Date(parseInt(item.createdDate)) | moment("DD MMM YYYY") }}
        </template>
        <template v-slot:[`item.appointmentDate`]="{ item }">
          {{
              new Date(parseInt(item.followUp.appointmentDate))
              | moment("DD MMM YYYY")
          }}
          : {{ item.followUp.appointmentTime }}
        </template>
        <template v-slot:[`item.mode`]="{ item }">
          {{ mode(item.followUp) }}
        </template>
        <template v-slot:[`item.shift`]="{ item }">
          {{ shift(item.followUp) }}
        </template>
        <template v-slot:[`item.followUpType`]="{ item }">
          {{ followUpType(item.followUp) }}
        </template>
        <template v-slot:[`item.actionType`]="{ item }">
          {{ item.followUp.actionType }}
        </template>
        <template v-slot:[`item.actionStatus`]="{ item }">
          {{ item.followUp.actionStatus }}
        </template>
        <template v-slot:[`item.comment`]="{ item }">
          <span v-if="item.followUp.comment.length">
            <ul v-for="comment in item.followUp.comment" :key="comment.message">
              <li>
                {{ comment.message ? comment.message : "--" }}
              </li>
              <li style=" list-style-type: none;">
                {{
                    comment.actionStatusFrom || comment.actionStatusTo
                      ? " ( "
                      : ""
                }}
                {{ comment.actionStatusFrom ? comment.actionStatusFrom : "" }}
                {{ comment.actionStatusTo ? " - " + comment.actionStatusTo : ""
                }}{{
    comment.actionStatusFrom || comment.actionStatusTo
      ? " ) "
      : ""
}}
              </li>
            </ul>
          </span>
        </template>
        <template v-slot:[`item.bookingFee`]="{ item }">
          {{ bookingFee(item.followUp) }}
        </template>
        <template v-slot:[`item.additionalFee`]="{ item }">
          {{ additionalFee(item.followUp) }}
        </template>
        <template v-slot:[`item.totalFee`]="{ item }">
          {{ totalFee(item.followUp) }}
        </template>
        <template v-slot:[`item.paymentStatus`]="{ item }">


          <div>
            BOOKING FEE:
            <span :class="
              item.followUp.payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE'
                ? 'green--text'
                : item.followUp.payment_status == 'PENDING'
                  ? 'orange--text'
                  : item.followUp.payment_status == 'CANCELLED'
                    ? 'blue--text'
                    : item.followUp.payment_status == 'FAILED'
                      ? 'red--text'
                      : ''
            ">
              {{ item.followUp.payment_status }}

            </span>

          </div>

          <div
            v-if="item.followUp.actionStatus == 'FINISHED' && item.followUp.modeOfAppointment == 'ON_VISIT' && item.followUp.additional_payment_status">
            ADDITIONAL FEE :
            <span :class="
              item.followUp.additional_payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE'
                ? 'green--text'
                : item.followUp.additional_payment_status == 'PENDING'
                  ? 'orange--text'
                  : item.followUp.additional_payment_status == 'CANCELLED'
                    ? 'blue--text'
                    : item.followUp.additional_payment_status == 'FAILED'
                      ? 'red--text'
                      : ''
            ">
              {{ item.followUp.additional_payment_status }}

            </span>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    },
    input: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dietitianAppointmentList: []
    };
  },

  methods: {
    mode(followUp) {
      if (followUp.modeOfAppointment) {
        let result = "--";
        if (followUp.modeOfAppointment == "ON_CALL") {
          result = "On Call";
        }
        if (followUp.modeOfAppointment == "ON_VISIT") {
          result = "Visit";
        }
        return result;
      }
    },
    shift(followUp) {
      let result = "--";
      if (followUp.shift) {
        if (followUp.shift == "MORNING_SHIFT") {
          result = "Morning Shift";
        }
        if (followUp.shift == "EVENING_SHIFT") {
          result = "Evening Shift";
        }
      }
      return result;
    },
    followUpType(followUp) {
      let result = "--";
      if (followUp.followType > 0) {
        result = `Follow Up - ${followUp.followType}`;
      } else if (followUp.followType == 0) {
        result = "First";
      }
      return result;
    },
    actionStatus(followUp) {
      let result = "--";
      if (followUp.actionStatus) {
        result = followUp.actionStatus;
      }
      return result;
    },
    bookingFee(followUp) {
      let result = 0;
      if (followUp.bookingFee) {
        result = followUp.bookingFee;
      }
      return result;
    },
    additionalFee(followUp) {
      let result = 0;
      if (followUp.additionalFee) {
        result = followUp.additionalFee;
      }
      return result;
    },
    paymentStatus(followUp) {
      let result = "--";
      if (
        followUp.actionStatus == "FINISHED" &&
        followUp.modeOfAppointment == "ON_VISIT"
      ) {
        if (followUp.additional_payment_status) {

          result = followUp.additional_payment_status;
        }
      } else {
        if (followUp.payment_status) {

          result = followUp.payment_status;
        }
      }

      return result;
    },
    totalFee(followUp) {
      let result = 0;
      if (followUp.modeOfAppointment == "ON_VISIT")
        result =
          parseFloat(this.additionalFee(followUp)) +
          parseFloat(this.bookingFee(followUp));
      else result = followUp.onCallFee;
      return result;
    },
    getSlotAppointmentDetails() {
      this.$apollo
        .watchQuery({
          query: require("../api/getAllSlotAppointments.graphql"),
          variables: {
            input: this.input.input,
            filterData: this.input.filterData,
            searchKey: this.input.searchKey
          }
        })
        .refetch({})
        .then(data => {
          this.loading = false;

          if (data.data) {
            // if(data.data.getAllSlotAppointments.slotAppointments.length){
            //   for(let x of )
            // }
            this.dietitianAppointmentList =
              data.data.getAllSlotAppointments.slotAppointments;
            if (this.dietitianAppointmentList.length) {
              for (let [i, x] of this.dietitianAppointmentList.entries()) {
                x.index = i + 1;
              }
            }
            setTimeout(() => {
              Utils.htmlToExcel(
                "DietitianAppoinments",
                "Dietitian Appoinments",
                false
              );
            }, 100);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getAllActiveDietitianAppoinments() {
      this.$apollo
        .watchQuery({
          query: require("@/dietitian/dietitian_settings/api/getAllSlotAppointments.graphql"),
          variables: this.variables
        })
        .refetch({
          variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.customerDietitianApptExportList =
              data.data.getAllActiveDietitianAppoinments.dietitianAppointments;
            setTimeout(() => {
              Utils.htmlToExcel(
                "DietitianAppoinments",
                "Dietitian Appoinments",
                false
              );
            }, 100);
          }
        });
      this.loading = false;
    },

    exportExcel() {
      this.loading = true;
      //   this.getAllActiveDietitianAppoinments();
      this.getSlotAppointmentDetails();
    }
  }
};
</script>
