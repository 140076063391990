<template>
  <div>
    <!-- {{ promocode.customerDetails }} -->
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="promocode.customerDetails"
      class="elevation-1"
      :items-per-page="25"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.customer="{ item }">
        {{
          item.customer.user.firstnameEn[0].toUpperCase() +
          item.customer.user.firstnameEn.substring(1)
        }}
        {{
          item.customer.user.lastnameEn[0].toUpperCase() +
          item.customer.user.lastnameEn.substring(1)
        }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ new Date(item.dateTime) | moment("DD MMM YYYY") }}
      </template>
    </v-data-table>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    promocode: {
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Customer", value: "customer", width: 150 },
        { text: "Dicounted Amount", value: "discountedAmount", width: 100 },
        // { text: "Answer", value: "answer" },
        { text: "Date", value: "date", width: 120 },
      ],
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  methods: {},
};
</script>