<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <h2 class="black--text text-h5 font-weight-bold mb-4">Driver Report</h2>
    <v-layout class="mt-2 ms-n5 ps-5 ps-sm-0" wrap>
      <v-flex xs8 sm3 md3 lg2 class="mt-5 mt-sm-0 ms-sm-5 me-5">
        <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDate"
              readonly
              label="Date"
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            color="secondary"
            v-model="date"
            @change="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 sm4 md2 lg2 class="mt-md-0 ms-sm-5 ms-md-0 mb-8 mb-sm-0">
        <v-btn
          class="ms-sm-5 px-6 white--text text-capitalize"
          color="secondary"
          @click="searchReport"
          >Search
        </v-btn>
      </v-flex>
    </v-layout>
    <v-expansion-panels v-if="driverOrders">
      <v-expansion-panel v-if="driverOrders.morningDrivers">
        <v-expansion-panel-header>
          Morning ({{
            getAllTimeCount(driverOrders.morningDrivers)
          }})</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="driver in driverOrders.morningDrivers"
              :key="driver._id"
            >
              <v-expansion-panel-header>
                <span v-if="driver.user">
                  {{ driver.user.firstnameEn }} {{ driver.user.lastnameEn }} ({{
                    getDriverWiseCount(driver)
                  }})</span
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="area in driver.areas"
                    :key="area._id"
                  >
                    <v-expansion-panel-header>
                      {{ area.nameEn }}
                      {{ area.nameAr }} ({{ area.orders.length }})
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel
                          v-for="order in area.orders"
                          :key="order._id"
                        >
                          <v-expansion-panel-header>
                            <span v-if="order.customer">
                              <span v-if="order.customer.user">
                                {{ order.customer.user.firstnameEn }}
                                {{ order.customer.user.lastnameEn }}
                                <strong style="float: right">
                                  {{ order.delivery_status }}
                                </strong>
                              </span>
                            </span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div v-if="order.menu">
                              <v-expansion-panels>
                                <v-expansion-panel
                                  v-for="menu in order.menu.filter(
                                    x => x.menu.length > 0
                                  )"
                                  :key="menu._id"
                                >
                                  <v-expansion-panel-header>
                                    <span v-if="menu.category">
                                      {{ menu.category.nameEn }}
                                      {{ menu.category.nameAr }}
                                    </span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-expansion-panels>
                                      <v-expansion-panel
                                        v-for="menuItem in menu.menu"
                                        :key="menuItem._id"
                                      >
                                        <v-expansion-panel-header>
                                          {{ menuItem.nameEn }}
                                          {{ menuItem.nameAr }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                          <div
                                            v-if="
                                              menuItem.dislikedIngrediants &&
                                                menuItem.dislikedIngrediants
                                                  .length > 0
                                            "
                                          >
                                            Dislikes :
                                            <div
                                              v-for="dislike in menuItem.dislikedIngrediants"
                                              :key="dislike._id"
                                            >
                                              <strong>{{
                                                dislike.nameEn
                                              }}</strong>
                                            </div>
                                          </div>
                                          <div v-else>
                                            <strong>No Dislikes</strong>
                                          </div>
                                        </v-expansion-panel-content>
                                      </v-expansion-panel>
                                    </v-expansion-panels>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="driverOrders.eveningDrivers">
        <v-expansion-panel-header>
          Evening ({{
            getAllTimeCount(driverOrders.eveningDrivers)
          }})</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="driver in driverOrders.eveningDrivers"
              :key="driver._id"
            >
              <v-expansion-panel-header>
                {{ driver.user.firstnameEn }} {{ driver.user.lastnameEn }} ({{
                  getDriverWiseCount(driver)
                }})
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="area in driver.areas"
                    :key="area._id"
                  >
                    <v-expansion-panel-header>
                      {{ area.nameEn }}
                      {{ area.nameAr }} ({{ area.orders.length }})
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel
                          v-for="order in area.orders"
                          :key="order._id"
                        >
                          <v-expansion-panel-header>
                            <span v-if="order.customer">
                              <span v-if="order.customer.user">
                                {{ order.customer.user.firstnameEn }}
                                {{ order.customer.user.lastnameEn }}
                                <strong style="float: right">
                                  {{ order.delivery_status }}
                                </strong>
                              </span>
                            </span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div v-if="order.menu">
                              <v-expansion-panels>
                                <v-expansion-panel
                                  v-for="menu in order.menu.filter(
                                    x => x.menu.length > 0
                                  )"
                                  :key="menu._id"
                                >
                                  <v-expansion-panel-header>
                                    {{ menu.category.nameEn }}
                                    {{ menu.category.nameAr }}
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-expansion-panels>
                                      <v-expansion-panel
                                        v-for="menuItem in menu.menu"
                                        :key="menuItem._id"
                                      >
                                        <v-expansion-panel-header>
                                          {{ menuItem.nameEn }}
                                          {{ menuItem.nameAr }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                          <div
                                            v-if="
                                              menuItem.dislikedIngrediants &&
                                                menuItem.dislikedIngrediants
                                                  .length > 0
                                            "
                                          >
                                            Dislikes :
                                            <div
                                              v-for="dislike in menuItem.dislikedIngrediants"
                                              :key="dislike._id"
                                            >
                                              <strong>{{
                                                dislike.nameEn
                                              }}</strong>
                                            </div>
                                          </div>
                                          <div v-else>
                                            <strong>No Dislikes</strong>
                                          </div>
                                        </v-expansion-panel-content>
                                      </v-expansion-panel>
                                    </v-expansion-panels>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels v-else>
      <v-expansion-panel>
        <v-expansion-panel-header> Morning (0)</v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header> Evening (0)</v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  components: {},

  apollo: {
    getDriverReport() {
      let dateTime = moment().format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      return {
        query: require("../api/getDriverReport.graphql"),
        variables: {
          status: "All",
          date: modifiedDate
        },
        result({ data, loading }) {
          this.loading = loading;
          this.driverOrders = data.getDriverReport;
        }
      };
    }
  },

  data() {
    return {
      loading: true,
      date: new Date().toISOString().substr(0, 10),
      menu: "",
      driverOrders: [],
      headers: [
        {
          text: "Sl No",
          value: "index"
        },
        { text: "Name", value: "user", width: 120 },
        { text: "Phone", value: "user.phone" },
        { text: "Is Active", value: "isActive" },
        { text: "Assigned Zone", value: "morningShift" },
        { text: "Shift", value: "eveningShift" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DRIVER_REPORT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  },

  methods: {
    getDriverWiseCount(driver) {
      let totalCount = 0;
      if (driver)
        if (driver.areas)
          driver.areas.forEach(element => {
            if (element.orders) totalCount = totalCount + element.orders.length;
          });
      return totalCount;
    },
    getAllTimeCount(drivers) {
      let totalCount = 0;
      if (drivers)
        drivers.forEach(driver => {
          if (driver.areas)
            driver.areas.forEach(element => {
              if (element.orders)
                totalCount = totalCount + element.orders.length;
            });
        });
      return totalCount;
    },
    searchReport() {
      const dateTime = moment(`${this.date}`).format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      this.$apollo.queries.getDriverReport.refetch({
        status: "All",
        date: modifiedDate
      });
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    }
  }
};
</script>
