var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-form',{ref:"profileform",staticClass:"mt-8",model:{value:(_vm.validProfile),callback:function ($$v) {_vm.validProfile=$$v},expression:"validProfile"}},[_c('v-layout',{staticClass:"mt-5 mt-md-0",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-text-field',{attrs:{"label":"Firstname","rules":[_vm.requiredValidator('First Name')],"solo":"","dense":""},model:{value:(_vm.firstnameEn),callback:function ($$v) {_vm.firstnameEn=$$v},expression:"firstnameEn"}})],1),_c('v-flex',{staticClass:"ms-sm-10",attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-text-field',{attrs:{"label":"Lastname","rules":[_vm.requiredValidator('Last Name')],"solo":"","dense":""},model:{value:(_vm.lastnameEn),callback:function ($$v) {_vm.lastnameEn=$$v},expression:"lastnameEn"}})],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-text-field',{attrs:{"label":"Email Id","rules":[
            _vm.requiredValidator('email'),
            _vm.emailValidator(),
            this.doesEmailExist != true || 'email already exists.'
          ],"solo":"","dense":""},on:{"click":function($event){_vm.doesEmailExist = false}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-flex',{staticClass:"ms-sm-10",attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-text-field',{staticClass:"inputPhone",attrs:{"label":"Mobile Number","solo":"","dense":"","type":"number","rules":[
            _vm.requiredValidator('phone'),
            this.doesPhoneExist != true || 'phone already exists.'
          ]},on:{"click":function($event){_vm.doesPhoneExist = false}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c('v-layout',{staticClass:"mt-5 mt-md-0",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-text-field',{attrs:{"label":"User Name","rules":[_vm.requiredValidator('user name')],"solo":"","dense":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c('v-flex',{staticClass:"pe-2",attrs:{"xs12":"","sm12":"","md10":"","lg9":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"white--text text-capitalize mt-4 font-weight-bold",attrs:{"color":"btncolor","width":_vm.$vuetify.breakpoint.smAndUp ? 150 : 100,"loading":_vm.btnProfileLoading,"disabled":!_vm.validProfile},on:{"click":_vm.updateProfile}},[_vm._v("Update")])],1)],1)],1),_c('v-form',{ref:"formPassword",staticClass:"mt-10",on:{"submit":function($event){return _vm.updatePassword()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('h3',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Change Password")]),_c('v-layout',{staticClass:"mt-4",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-text-field',{attrs:{"label":"Current Password","rules":[_vm.requiredValidator('Current Password')],"append-icon":_vm.show3 ? 'mdi-eye black--text' : 'mdi-eye-off black--text',"type":_vm.show3 ? 'text' : 'password',"name":"input-10-1","solo":"","dense":""},on:{"click:append":function($event){_vm.show3 = !_vm.show3}},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-text-field',{attrs:{"label":"New Password","rules":[
            _vm.requiredValidator('New Password'),
            _vm.minLengthValidator('password', 8)
          ],"append-icon":_vm.show1 ? 'mdi-eye black--text' : 'mdi-eye-off black--text',"type":_vm.show1 ? 'text' : 'password',"name":"input-10-1","solo":"","dense":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('v-flex',{staticClass:"ms-sm-10",attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-text-field',{attrs:{"label":"Confirm Password","solo":"","dense":"","append-icon":_vm.show2 ? 'mdi-eye black--text' : 'mdi-eye-off black--text',"type":_vm.show2 ? 'text' : 'password',"name":"input-10-1","rules":[_vm.confirmPasswordValidator(_vm.newPassword)]},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1),_c('v-layout',{staticClass:"mt-8",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"pe-2",attrs:{"xs12":"","sm12":"","md10":"","lg9":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"white--text text-capitalize text-end font-weight-bold",attrs:{"color":"btncolor","width":_vm.$vuetify.breakpoint.smAndUp ? 150 : 100,"loading":_vm.btnPasswordLoading,"disabled":!_vm.valid},on:{"click":_vm.updatePassword}},[_vm._v("Update")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":"3000","right":"","top":"","color":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.text))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }