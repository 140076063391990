<template>
  <v-container class="px-0 mx-0">
    <v-btn @click="exportExcel" width="150" :loading="loading" class="mt-n3 white--text text-capitalize btn_fnt"
      color="btncolor">
      <v-icon small class="me-2">mdi-export</v-icon>Export
    </v-btn>
    <div v-show="false">
      <v-data-table id="exportSalesOrder" hide-default-footer :items-per-page="-1" :headers="headers"
        :items="salesOrderExportList" class="elevation-1 mt-6">
        <template v-slot:[`item.date`]="{ item }">{{
        new Date(parseInt(item.created_At)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.startDate`]="{ item }">{{
        new Date(parseInt(item.start_date)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.endDate`]="{ item }">{{
        new Date(parseInt(item.end_date)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.plan`]="{ item }">{{
        item.package.titleEn
        }}</template>
        <template v-slot:[`item.package_price`]="{ item }">{{
        numberWithCommas(item.package_price)
        }}</template>
        <template v-slot:[`item.discount`]="{ item }">{{
        numberWithCommas(item.discount)
        }}</template>
        <template v-slot:[`item.net_price`]="{ item }">{{ numberWithCommas(item.net_price) }}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <PriceSplitup :subscribedPackage="item" />
        </template>
        <template v-slot:[`item.paymentStatus`]="{ item }">
          <span :class="
            item.payment_status == 'SUCCESS'
              ? 'green--text'
              : item.payment_status == 'PENDING'
              ? 'orange--text'
              : item.payment_status == 'UPCOMING'
              ? 'blue--text'
              : item.payment_status == 'EXPIRED'
              ? 'red--text'
              : ''
          ">
            {{ item.payment_status }}</span>
        </template>
        <template v-slot:[`item.referredUser`]="{ item }">
          <span v-if="item.referredByUser">
            {{ toTitleCase(item.referredByUser.firstnameEn) }}
            {{ toTitleCase(item.referredByUser.lastnameEn) }}
          </span>
          <span v-else>--</span>
        </template>
        <template v-slot:[`item.referredUserRole`]="{ item }">
          <span v-if="item.referredByUser">
            {{ item.referredByUser.role }}
          </span>
          <span v-else>--</span>
        </template>
        <template v-slot:[`item.customer`]="{ item }">
          <router-link v-if="!item.is_deleted" :to="{
            name: 'CustomerDetails',
            params: { customerId: item.customer },
            query: { customerId: item.customer }
          }" class="mx-auto">
            {{ toTitleCase(item.user.firstnameEn) }}
            {{ toTitleCase(item.user.lastnameEn) }}
          </router-link>

          <span v-else>
            {{ toTitleCase(item.customer.firstnameEn) }}
            {{ toTitleCase(item.customer.lastnameEn) }}
          </span>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    },
    endDate: {
      required: true
    }
  },
  data() {
    return {
      salesOrderExportList: [],
      loading: false
    };
  },

  created() {
    // this.subscribePackagesEndDateReminder();
  },

  methods: {
    subscribePackagesEndDateReminder() {
      this.$apollo
        .query({
          query: require("../api/subscribedPackageEndDateReminder.graphql"),
          variables: {
            input: { limit: 0, skip: 0 },
            endDate: this.endDate
          }
        })
        .then(data => {
          if (data.data) {
            this.salesOrderExportList =
              data.data.subscribePackagesEndDateReminder.sales_orders;
            setTimeout(() => {
              Utils.htmlToExcel("exportSalesOrder", "End Date Reminder", false);
              this.loading = false;
            }, 100);
            console.log(this.salesOrderExportList.length);
          }
        });
    },
    exportExcel() {
      this.loading = true;
      this.subscribePackagesEndDateReminder();
    },
    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    numberWithCommas(x) {
      if (x)
        return x
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    }
  }
};
</script>
