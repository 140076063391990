<template>
  <div>
    <v-btn color="btncolor" @click="paid()">Paid</v-btn>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="confirmPaymentDialog" max-width="310">
      <v-card>
        <v-card-title class="headline">Confirm</v-card-title>
        <v-card-text>Are you sure want to set this package ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success " text @click="confirmPaymentDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error " text @click="confirmPaid()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script >
import Vue from "vue";
export default Vue.extend({
  props: {
    unpaidPackage: {
      require: true,
    },
  },
  methods: {
    showDialogBox() {
      this.showDialog = true;
    },

    close() {
      this.showDialog = false;
    },
    paid() {
      this.confirmPaymentDialog = true;
    },
    confirmPaid() {
      this.overlay = true;
      this.$apollo
        .mutate({
          mutation: require("../../api/confirmModifiedPackagePayment.graphql"),
          variables: {
            id: this.unpaidPackage._id,
          },
        })
        .then(() => {
          this.$root.$emit("updateGetCustomerById");
          this.$root.$emit("confirmModifiedPackagePayment");
          this.overlay = false;
          this.close();
        })
        .catch((error) => {
          this.$root.$emit("updateGetCustomerById");
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.overlay = false;
          this.close();
        });
    },
  },
  data() {
    return {
      loading: true,
      overlay: false,
      confirmPaymentDialog: false,
    };
  },
});
</script>