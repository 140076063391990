<template>
  <div class="mx-5">

    <v-layout>
      <v-flex>
        <h4 class="black--text text-h6  mt-5 font-weight-bold">
          Dietitian Appointment Report
        </h4>

      </v-flex>
    </v-layout>


    <v-layout wrap justify-end class="mt-5">
      <v-flex xs5 sm3 md2 class="mr-4 mx-sm-2">
        <v-autocomplete v-model="filterData.paymentStatus" :items="paymentStatuses" item-text="text" item-value="value"
          solo dense attach label="Payment Status" clearable @change="filterSearch">

        </v-autocomplete>
      </v-flex>

      <v-flex xs5 sm3 md2 class="mr-4 mx-sm-2">
        <v-autocomplete v-model="filterData.filterDietitian" :items="dietitianList" item-text="user.firstnameEn"
          item-value="_id" solo dense attach label="Search dietitian" clearable @change="filterSearch">
          <template slot="no-data">
            <v-flex xs12 md12 class="mt-2 mb-2">
              <h5 class="font-weight-medium ml-4">
                Search for dietitian...
              </h5>
            </v-flex>
          </template>

          <template slot="selection" slot-scope="data">
            {{ data.item.user.firstnameEn }}
            {{ data.item.user.lastnameEn }}
          </template>
          <template slot="item" slot-scope="data">{{ data.item.user.firstnameEn }}
            {{ data.item.user.lastnameEn }}
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs5 sm3 md2 class="ml-4 mx-sm-2">
        <v-layout>
          <v-menu v-model="menuFromDate" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="searchStartDateDate" label="From Date" solo class=""
                append-icon="fa-calendar-alt black--text" v-bind="attrs" v-on="on" @click:clear="appDate = null" dense>
              </v-text-field>
            </template>
            <v-date-picker color="secondary" v-model="appDate" @change="selectFromDate"></v-date-picker>
          </v-menu>
        </v-layout>

      </v-flex>
      <v-flex xs5 sm3 md2 class="ml-4 mx-sm-2">
        <v-layout>
          <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="searchEndDateDate" label="To Date" solo class=""
                append-icon="fa-calendar-alt black--text" v-bind="attrs" v-on="on" @click:clear="appToDate = null"
                dense>
              </v-text-field>
            </template>
            <v-date-picker color="secondary" v-model="appToDate" @change="selectToDate"></v-date-picker>
          </v-menu>
        </v-layout>

      </v-flex>
      <v-flex xs5 sm3 md2 class="mr-4 mx-sm-2">
        <v-autocomplete v-model="filterData.actionType" :items="actionTypes" item-text="text" item-value="value" solo
          dense attach label="Action Type" clearable @change="filterSearch">

        </v-autocomplete>
      </v-flex>

      <v-flex xs5 sm3 md1 class="ml-4 mx-sm-2">
        <v-btn class="white--text ml-5 " color="btncolor" dense @click="clearSearch()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs5 sm3 md2 class="ml-4 mx-sm-2">
        <export-dietitian-appointment v-if="isAdmin" :headers="headers" :input="{
          input: { limit: totalItemsCount, skip: 1 },
          filterData,
          searchKey
        }" />

      </v-flex>
    </v-layout>

    <v-layout wrap justify-end class="mt-5">


    </v-layout>
    <v-data-table :mobile-breakpoint="0" :headers="headers" :items="dietitianAppointmentList" class="elevation-1 mt-1"
      :loading="loading" :server-items-length="totalItemsCount" :options.sync="options"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">
      <template v-slot:[`item.user`]="{ item }">
        <router-link v-if="item.customer" :to="{
          name: 'CustomerDetails',
          params: { customerId: item.customer._id },
          query: { customerId: item.customer._id }
        }" class="mx-auto">
          {{
              item.customer.user.firstnameEn[0].toUpperCase() +
              item.customer.user.firstnameEn.substring(1)
          }}
          {{
              item.customer.user.lastnameEn[0].toUpperCase() +
              item.customer.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>
      <template v-slot:[`item.dietitian`]="{ item }">
        <router-link :to="{
          name: 'DietitianDetails',
          params: { dietitianId: item.dietitian._id },
          query: { dietitianId: item.dietitian._id }
        }" class="mx-auto">
          {{
              item.dietitian.user.firstnameEn[0].toUpperCase() +
              item.dietitian.user.firstnameEn.substring(1)
          }}
          {{
              item.dietitian.user.lastnameEn[0].toUpperCase() +
              item.dietitian.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>
      <template v-slot:[`item.bookedDate`]="{ item }">
        {{ bookedDate(item) }}
      </template>
      <template v-slot:[`item.appointmentDate`]="{ item }">
        {{ appointmentDate(item.followUp) }}
      </template>
      <template v-slot:[`item.mode`]="{ item }">
        {{ mode(item.followUp) }}
      </template>
      <template v-slot:[`item.shift`]="{ item }">
        {{ shift(item.followUp) }}
      </template>
      <!-- followUpType -->
      <template v-slot:[`item.followUpType`]="{ item }">
        {{ followUpType(item.followUp) }}

        <!-- {{  (item.followUp.updatedDate)}} -->
      </template>
      <template v-slot:[`item.actionType`]="{ item }">
        {{ item.followUp.actionType }}
      </template>
      <template v-slot:[`item.actionStatus`]="{ item }">
        {{ item.followUp.actionStatus }}
      </template>

      <template v-slot:[`item.bookingFee`]="{ item }">
        {{ bookingFee(item.followUp) }}
      </template>
      <template v-slot:[`item.additionalFee`]="{ item }">
        {{ additionalFee(item.followUp) }}
      </template>
      <template v-slot:[`item.callFee`]="{ item }">
        {{ callFee(item.followUp) }}
      </template>
      <template v-slot:[`item.totalFee`]="{ item }">
        {{ totalFee(item.followUp) }}
      </template>
      <template v-slot:[`item.paymentStatus`]="{ item }">


        <div>
          BOOKING FEE:
          <span :class="
            item.followUp.payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE'
              ? 'green--text'
              : item.followUp.payment_status == 'PENDING'
                ? 'orange--text'
                : item.followUp.payment_status == 'CANCELLED'
                  ? 'blue--text'
                  : item.followUp.payment_status == 'FAILED'
                    ? 'red--text'
                    : ''
          ">
            {{ item.followUp.payment_status }}

          </span>

        </div>

        <div
          v-if="item.followUp.actionStatus == 'FINISHED' && item.followUp.modeOfAppointment == 'ON_VISIT' && item.followUp.additional_payment_status">
          ADDITIONAL FEE :
          <span :class="
            item.followUp.additional_payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE'
              ? 'green--text'
              : item.followUp.additional_payment_status == 'PENDING'
                ? 'orange--text'
                : item.followUp.additional_payment_status == 'CANCELLED'
                  ? 'blue--text'
                  : item.followUp.additional_payment_status == 'FAILED'
                    ? 'red--text'
                    : ''
          ">
            {{ item.followUp.additional_payment_status }}

          </span>
        </div>
      </template>
    </v-data-table>

    <v-layout class="mt-10">
      <h2 v-if="isAdmin" class="black--text text-h5 font-weight-bold">
        Calendar View
      </h2>
      <h2 v-else class="primary--text text-h5 font-weight-bold">
        Calendar View
      </h2>
    </v-layout>
    <div>
    </div>
    <v-spacer></v-spacer>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{ text }}</v-snackbar>
  </div>
</template>
<script>
import moment from "moment";


import ExportDietitianAppointment from "../components/ExportDietitianAppointment.vue";

export default {
  components: {


    ExportDietitianAppointment,

  },


  data: () => ({

    appDate: "",
    appToDate: "",
    menu: false,
    menuFromDate: false,
    dialog: false,
    headers: [
      {
        text: "Sl No",
        value: "index",
        sortable: false,
        width: 70
      },
      { text: "Name", value: "user", sortable: false, width: 130 },
      {
        text: "Mobile Number",
        value: "customer.user.phone",
        sortable: false
      },
      {
        text: "Appointment On",
        value: "appointmentDate",
        sortable: false,
        width: 200
      },
      { text: "Booked Date", value: "bookedDate", sortable: false, width: 120 },
      {
        text: "Mode",
        value: "mode",
        sortable: false
        //  width: 120
      },
      { text: "Shift", value: "shift", sortable: false, width: 100 },
      { text: "Dietitian", value: "dietitian", sortable: false, width: 120 },
      { text: "Type", value: "followUpType", sortable: false, width: 120 },
      { text: "Action Type", value: "actionType", sortable: false, width: 120 },
      {
        text: "Action Status",
        value: "actionStatus",
        sortable: false,
        width: 120
      },

      {
        text: "Booking Fee / Call Fee",
        value: "bookingFee",
        sortable: false,
        width: 120
      },
      {
        text: "Additional Fee",
        value: "additionalFee",
        sortable: false,
        width: 120
      },
      { text: "Total", value: "totalFee", sortable: false, width: 120 },
      {
        text: "Payment Status",
        value: "paymentStatus",
        sortable: false,
        width: 240
      }
      //   { text: "Action", value: "actions", sortable: false, width: 10 }
    ],
    dietitianAppointmentList: [],
    totalCount: 0,
    paymentStatuses: [
      { text: "PENDING", value: "PENDING" },
      { text: "SUCCESS", value: "SUCCESS" }
    ],

    actionTypes: [
      { text: "Customer First Booking", value: "CUSTOMER_FIRST" },
      { text: "First", value: "FIRST" },
      { text: "Followups", value: "FOLLOW_UP" },
      { text: "Followup 1", value: "FOLLOW_UP_1" },
      { text: "Followup 2", value: "FOLLOW_UP_2" },
      { text: "Followup 3", value: "FOLLOW_UP_3" }
    ],
    showDialogue: false,
    oldValue: [],
    oldActionStatus: "",
    selectedArrayValue: "",
    otherDatas: {},

    search: "",
    // filterDietitian: "",
    dietitianList: [],
    // filterActionType: "",
    // filterActionStatus: "",
    // filterShift: "",
    filterData: {
      filterActionStatus: "",
      filterActionType: "",
      filterDietitian: "",
      filterShift: "",
      appointmentSearchDate: "",
      paymentStatus: "",
      startDate: "",
      endDate: ""
    },
    input: {
      limit: 25,
      skip: 0
    },
    searchKey: {
      searchKey: "",
      searchType: ""
    },
    searchList: [
      { text: "Name", value: "NAME" },
      { text: "Mobile Number", value: "PHONE" }
    ],
    options: {
      itemsPerPage: 25,
      page: 1,
      sortDesc: [false],
      sortBy: []
    },
    totalItemsCount: 10,
    loading: false,
    isAdmin: true,
    snackbar: false,
    snackColor: "green",
    text: ""
    // dietitanId: "",
  }),
  created() {

    if (!this.hasRole(this.AdminUserPermissions.DIETITIAN_APPOINTMENTS_REPORT)) {
      this.$router.push({
        name: "Error404"
      });
    }

  },
  watch: {
    options: {
      handler(item) {
        this.input.limit = item.itemsPerPage;
        this.input.skip = item.page;
        this.getSlotAppointmentDetails();
      }
    }
  },
  methods: {

    filterSearch() {
      this.getSlotAppointmentDetails();

    },
    selectToDate() {
      this.menu = false;
      this.filterData.endDate = this.appToDate;
      this.getSlotAppointmentDetails();
    },
    selectFromDate() {
      this.menuFromDate = false;
      this.filterData.startDate = this.appDate;
      this.getSlotAppointmentDetails();
    },

    runSearch() {
      this.getSlotAppointmentDetails();
    },
    clearSearch() {
      this.filterData.filterActionStatus = this.filterData.startDate =
        this.filterData.filterActionType = this.filterData.filterDietitian =
        this.filterData.filterShift = this.searchKey.searchKey =
        this.searchKey.searchType =
        this.filterData.endDate =
        this.filterData.actionType = "";
      this.getSlotAppointmentDetails();
    },
    updateDietitianSlotAppointments() {
      this.showDialogue = false;
      this.getSlotAppointmentDetails();
    },
    closeDialogue(item) {
      item.followUp.actionStatus = this.oldActionStatus;
      // this.dietitianAppointmentList[
      //   this.selectedArrayValue
      // ].followUp.actionStatus = this.oldActionStatus;
      this.showDialogue = false;
    },


    bookedDate(followUp) {
      if (followUp.createdDate) {
        return moment(parseInt(followUp.createdDate)).format("DD MMM YYYY");
      }
    },
    appointmentDate(followUp) {
      if (followUp.appointmentDate) {
        let res1 = moment(parseInt(followUp.appointmentDate)).format(
          "DD MMM YYYY"
        );
        let res2 = followUp.appointmentTime;
        let result = res1 + " : " + res2;
        return result;
      }
    },
    mode(followUp) {
      if (followUp.modeOfAppointment) {
        let result = "--";
        if (followUp.modeOfAppointment == "ON_CALL") {
          result = "On Call";
        }
        if (followUp.modeOfAppointment == "ON_VISIT") {
          result = "Visit";
        }
        return result;
      }
    },
    shift(followUp) {
      let result = "--";
      if (followUp.shift) {
        if (followUp.shift == "MORNING_SHIFT") {
          result = "Morning";
        }
        if (followUp.shift == "EVENING_SHIFT") {
          result = "Evening";
        }
      }
      return result;
    },
    followUpType(followUp) {
      let result = "--";
      if (followUp.followType > 0) {
        result = `Follow Up - ${followUp.followType}`;
      } else if (followUp.followType == 0) {
        result = "First";
      }
      return result;
    },
    getActionStatus(data) {
      return data;
    },
    actionStatus(followUp) {
      let result = "--";
      if (followUp.actionStatus) {
        result = followUp.actionStatus;
      }
      return result;
    },

    bookingFee(followUp) {
      let result = "";
      if (followUp.modeOfAppointment == "ON_VISIT") {
        if (followUp.bookingFee) {
          result = followUp.bookingFee;
        }
      } else {
        if (followUp.onCallFee) {
          result = followUp.onCallFee;
        }
      }
      return result;
    },
    additionalFee(followUp) {
      let result = 0;
      if (followUp.modeOfAppointment == "ON_VISIT") {
        if (followUp.additionalFee) {
          result = followUp.additionalFee;
        }
      }

      return result;
    },
    callFee(followUp) {
      let result = "";
      if (followUp.onCallFee) {
        result = followUp.onCallFee;
      }
      return result;
    },
    paymentStatus(followUp) {
      let result = "--";
      if (
        followUp.actionStatus == "FINISHED" &&
        followUp.modeOfAppointment == "ON_VISIT"
      ) {
        if (followUp.additional_payment_status) {
          // if (followUp.payment_status == "SUCCESS") {
          //   result = "Success";
          // }
          result = followUp.additional_payment_status;
        }
      } else {
        if (followUp.payment_status) {
          // if (followUp.payment_status == "SUCCESS") {
          //   result = "Success";
          // }
          result = followUp.payment_status;
        }
      }

      return result;
    },
    totalFee(followUp) {
      let result = 0;
      if (followUp.modeOfAppointment == "ON_VISIT") {
        if (followUp.bookingFee) {
          result += parseFloat(followUp.bookingFee);
        }
        if (followUp.additionalFee) {
          result += parseFloat(followUp.additionalFee);
        }
      } else result = followUp.onCallFee;
      return result;
    },

    getAllDietitians() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../api/getAllDietitians.graphql"),
          variables: {
            skip: 0,
            limit: 1000
          }
        })
        .refetch({})
        .then(data => {
          this.loading = false;
          this.dietitianList = data.data.getAllDietitians.dietitians;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getSlotAppointmentDetails() {
      let dietitanId = "";
      if (this.$route.name === "Appointments") {
        let data = JSON.parse(localStorage.getItem("DONE_USER"));
        if (data) {
          dietitanId = data._id;
        }
      }
      this.loading = true;

      this.$apollo
        .watchQuery({
          query: require("../api/getAllSlotAppointments.graphql"),
          variables: {
            input: this.input,
            filterData: this.filterData,
            searchKey: this.searchKey,
            dietitanId
          }
        })
        .refetch({})
        .then(data => {
          this.loading = false;

          if (data.data) {
            // if(data.data.getAllSlotAppointments.slotAppointments.length){
            //   for(let x of )
            // }
            this.dietitianAppointmentList =
              data.data.getAllSlotAppointments.slotAppointments;
            if (this.dietitianAppointmentList.length) {
              for (let [i, x] of this.dietitianAppointmentList.entries()) {
                x.index =
                  i + (this.options.page - 1) * this.options.itemsPerPage + 1;
              }
            }
            // console.log(data.data.getAllSlotAppointments);
            this.oldValue = data.data.getAllSlotAppointments.slotAppointments;
            this.totalItemsCount = data.data.getAllSlotAppointments.totalCount;
            this.getAllDietitians();
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  computed: {
    searchStartDateDate() {
      return this.filterData.startDate
        ? moment(this.filterData.startDate).format("DD-MM-YYYY")
        : "";
    },
    searchEndDateDate() {
      return this.filterData.endDate
        ? moment(this.filterData.endDate).format("DD-MM-YYYY")
        : "";
    }
  }
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
