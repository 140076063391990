const updateClientCache = async (store, _data, _var, isVariables, methodName, _variables, limitSkip) => {
	let fullPath = getFullPath(_var.path);

	let variables = getVariables(_var.mutaion_name);

	const query = {
		query: fullPath,
		// variables: isVariables ? variables : null
		variables: isVariables ? limitSkip ?? variables : null
	};

	// read old data from store
	const data = store.readQuery(query);

	// console.log('query data', data);

	if (methodName == 'create') {
		//push new data to store
		if (
			_var.mutaion_name == 'createProduct' ||
			_var.mutaion_name == 'createTask' ||
			_var.mutaion_name == 'createSeller' || _var.mutaion_name == 'addPayment'
		) {

			if (
				_var.mutaion_name == 'addPayment'
			) {
				// const index = data[_var.query_name]['transaction'].push(_data[_var.mutaion_name]);

				let currentIndex = data[_var.query_name]['payment'].findIndex((t) => t.user._id === _variables.user);
				// console.log(currentIndex);
				data[_var.query_name]['payment'][currentIndex]['paidAmount'] = data[_var.query_name]['payment'][currentIndex]['paidAmount'] + _data[_var.mutaion_name]['amount'];

			} else {
				// const index = data[_var.query_name][_var.query_name].push(_data[_var.mutaion_name]);
				data[_var.query_name]['totalCount'] = data[_var.query_name]['totalCount'] + 1;
			}


		} else {
			// const index = data[_var.query_name].push(_data[_var.mutaion_name]);
		}
	} else if (methodName == 'update') {
		//update new data to store
		if (
			_var.mutaion_name == 'updateProduct' ||
			_var.mutaion_name == 'updateTask' ||
			_var.mutaion_name == 'updateSeller' ||
			_var.mutaion_name == 'updateProductFeatured' ||
			_var.mutaion_name == 'updateProductStatus' || _var.mutaion_name == 'updateSellerStatus'
		) {
			if (_var.query_name == 'pendingApprovalList') {
				let currentIndex = data[_var.query_name]['products'].findIndex((t) => t._id === _variables.id);
				data[_var.query_name]['products'][currentIndex] = _data[_var.mutaion_name];
			} else {
				let currentIndex = data[_var.query_name][_var.query_name].findIndex((t) => t._id === _variables.id);
				data[_var.query_name][_var.query_name][currentIndex] = _data[_var.mutaion_name];
			}
		} else if (_var.mutaion_name == 'changeApprovalStatus') {
			let currentIndex = data[_var.query_name]['products'].findIndex((t) => t._id === _variables.id);
			data[_var.query_name]['products'][currentIndex] = _data[_var.mutaion_name];
		} else {
			let currentIndex = data[_var.query_name].findIndex((t) => t._id === _variables.id);
			data[_var.query_name][currentIndex] = _data[_var.mutaion_name];
		}
	} else {
		//delete data from store
		if (
			_var.mutaion_name == 'deleteProduct' ||
			_var.mutaion_name == 'deleteTask' ||
			_var.mutaion_name == 'deleteSeller'
		) {
			if (_var.query_name == 'pendingApprovalList') {
				let currentIndex = data[_var.query_name]['products'].findIndex((t) => t._id === _variables.id);
				data[_var.query_name]['products'].splice(currentIndex, 1);
				data[_var.query_name]['totalCount'] = data[_var.query_name]['totalCount'] - 1;
			} else {
				let currentIndex = data[_var.query_name][_var.query_name].findIndex((t) => t._id === _variables.id);
				data[_var.query_name][_var.query_name].splice(currentIndex, 1);
				data[_var.query_name]['totalCount'] = data[_var.query_name]['totalCount'] - 1;
			}
		} else {
			let currentIndex = data[_var.query_name].findIndex((t) => t._id === _variables.id);
			data[_var.query_name].splice(currentIndex, 1);
		}
	}

	//update data to store
	store.writeQuery({ ...query, data });
};

//concat full path
let getFullPath = (_path) => require('@/' + _path + '.graphql');

let getVariables = (v) => {
	if (
		v === 'createSeller' ||
		v === 'updateSeller' ||
		v === 'deleteSeller' ||
		v === 'updateSellerStatus' ||
		v === 'deleteProduct' ||
		v === 'createProduct' ||
		v === 'updateProduct' ||
		v === 'updateProductStatus' ||
		v === 'updateProductFeatured' ||
		v === 'createTask' ||
		v === 'deleteTask' ||
		v === 'updateTask' ||
		v === 'changeApprovalStatus'
	) {
		return (v = {
			limit: 10,
			skip: 0
		});
	}
	else if (v === 'addPayment') {
		return (v = {
			limit: 0,
			skip: 0
		});
	}
};
export { updateClientCache };
