<template>
  <div>
    <v-btn color=" mx-2 error mt-1" @click="onShowDialog" depressed
      >Switch Packages</v-btn
    >
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center"
          >Do you really want to switch all plans?</v-card-text
        >

        <v-card-text class="subtitle-1 mb-0 mt-6 text-center"
          ><v-checkbox
            label="Are you sure want to switch"
            v-model="confirm"
          ></v-checkbox>
          <v-card-text v-if="errors" class="error--text font-weight-bold">
            <li v-for="error in errors" :key="error">
              {{ error }}
            </li>
          </v-card-text>
        </v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 success"
              depressed
              :loading="button_loading"
              :disabled="!confirm"
              @click="switchAllCustomerPlans()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      snackbar: false,
      text: "",
      snackbarColor: "",
      valid: true,
      dialog: false,
      button_loading: false,
      error: null,
      confirm: false,
      errors: []
    };
  },
  methods: {
    onShowDialog() {
      this.dialog = true;
      this.confirm = false;
    },
    switchAllCustomerPlans() {
      this.snackbarColor = "black";
      this.text = "Please check Log after sometime to show the result.";
      this.snackbar = true;
      this.dialog = false;
      this.$apollo
        .mutate({
          mutation: require("../api/switchAllCustomerPlans.graphql")
        })
        .then(data => {
          if (data.data.switchAllCustomerPlans.length > 0) {
            this.errors = data.data.switchAllCustomerPlans;
            this.snackbarColor = "error";
            this.text = "some error occured";
            this.snackbar = true;
          } else {
            this.snackbarColor = "green";
            this.text = "plan switch successfully completed";
            this.dialog = false;
            this.errors = null;
            this.snackbar = true;
          }
          this.button_loading = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            this.button_loading = false;
            this.errors = null;
            this.dialog = false;
            this.text = error.graphQLErrors;
            this.snackbarColor = "error";
            this.snackbar = true;
          }
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    }
  }
};
</script>
