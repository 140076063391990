<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <!-- <v-overlay :value="btnloading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <h2 class="primary--text text-h5 font-weight-bold">Referrals</h2>

    <v-layout wrap>
      <v-flex xs6 sm4 md3 class="mt-4">
        <v-select
          :items="userRoles"
          item-value="value"
          item-text="item"
          label="Role"
          v-model="userType"
          @change="getAllReferralTransactions()"
          dense
          solo
        ></v-select>
      </v-flex>
    </v-layout>
    <!-- {{ usersList }} -->

    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      :loading="loading"
      class="elevation-1 mt-4 mt-sm-6"
      :options.sync="options"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.slNo="{ item }">{{ item.slNo }}</template>

      <template v-slot:item.firstName="{ item }">{{
        item.user.firstnameEn
      }}</template>
      <template v-slot:item.lastName="{ item }">{{
        item.user.lastnameEn
      }}</template>
      <template v-slot:item.role="{ item }">{{ item.userRole }}</template>
      <template v-slot:item.phone="{ item }">{{ item.user.phone }}</template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  components: {},
  created() {
    if (!this.hasRole(this.AdminUserPermissions.REFERRAL_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
    this.getAllReferralTransactions();
  },
  watch: {
    options: {
      handler() {
        this.getAllReferralTransactions();
        this.tableIndex =
          this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage;
      },
    },
    userType: {
      handler() {
        this.tableIndex = 0;
        this.options.itemsPerPage = 25;
        this.options.page = 1;
      },
    },
  },
  methods: {
    getAllReferralTransactions() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/referral-transactions/api/getAllReferralTransactions.graphql"),
          variables: {
            userRole: this.userType,
            skip: this.variables.skip,
            limit: this.variables.limit,
          },
        })
        .refetch({})
        .then((data) => {
          this.referralTransactions = [];
          this.loading = false;
          if (data.data.getAllReferralTransactions) {
            this.referralTransactions =
              data.data.getAllReferralTransactions.userReferrals;
            this.totalItemsCount =
              data.data.getAllReferralTransactions.totalCount;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  data() {
    return {
      referralTransactions: [],
      loading: true,
      totalItemsCount: 25,
      btnloading: true,
      tableIndex: 0,
      userType: "CELEBRITY",
      headers: [
        { text: "Sl.No", value: "slNo", width: 50, sortable: false },
        {
          text: "First Name",
          value: "firstName",
          width: 150,
          sortable: false,
        },
        {
          text: "Last Name",
          value: "lastName",
          width: 150,
          sortable: false,
        },
        { text: "Type", value: "role", width: 150, sortable: false },
        { text: "Phone", value: "phone", width: 150, sortable: false },
      ],

      userRoles: [
        {
          item: "Customer",
          value: "CUSTOMER",
        },

        {
          item: "Manager",
          value: "MANAGER",
        },
        {
          item: "Celebrity",
          value: "CELEBRITY",
        },
      ],
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      variables: {
        skip: 0,
        limit: 25,
        userRole: null,
      },
    };
  },
  computed: {
    itemsWithSno() {
      if (this.referralTransactions == null) return [];
      return this.referralTransactions.map((d, index) => ({
        ...d,
        slNo: index + 1,
      }));
    },
  },
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
