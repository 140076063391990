<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-progress-linear v-if="loading" color="btncolor"></v-progress-linear>
    <!-- <v-layout justify-end>
        <AuditTrialLog auditType="MEALS_LOG" :id="$route.query.mealId" />
      </v-layout> -->
    <div v-if="mealInfo">
      <v-row>
        <v-col cols="4" md="1">
          <v-avatar :width="80" :height="80">
            <v-img
              v-if="mealInfo.image != null"
              :src="imageUrl + mealInfo.image"
            ></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="6" md="4" align-self="center" class="ms-sm-4">
          <v-row>
            <h2 class="black--text mb-n2">
              {{ mealInfo.nameEn }}
            </h2>
          </v-row>
          <v-row class="mt-4">
            <h4>M {{ mealInfo.itemCode }}</h4>
          </v-row>
        </v-col>
        <v-col  class="text-right "
          ><AuditTrialLog auditType="MEALS_LOG" :id="$route.query.mealId"
        /></v-col>
      </v-row>
      <!-- <v-row class="mt-8 mt-sm-0">
        <v-spacer></v-spacer>
      </v-row> -->

      <v-row>
        <v-col cols="12" sm="6" md="6">
          <MealInfoLeft :mealInfo="mealInfo" />
        </v-col>
        <!-- <v-divider
          vertical
          class="mx-md-4"
          v-show="$vuetify.breakpoint.smAndUp"
        ></v-divider> -->
        <!-- <v-divider class="mx-6" v-show="$vuetify.breakpoint.xs"></v-divider> -->
        <v-col cols="12" sm="6" md="6">
          <MealInfoRight :mealInfo="mealInfo" />
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <v-layout wrap class="mt-8 mb-6">
        <v-tabs
          v-model="tabs"
          :mobile-breakpoint="0"
          background-color="bg"
          slider-size="3"
          slider-color="black"
        >
          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
              
            "
            >Meal Ingredients
            <router-link
              :to="{ path: '/dashboard', params: { mealId: this.mealId } }"
            ></router-link>
          </v-tab>

          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            "
            >Meal Ratings</v-tab
          >
          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            "
            >Users</v-tab
          >
        </v-tabs>
      </v-layout>
      <v-tabs-items v-model="tabs" class="mb-6 mb-sm-0">
        <v-tab-item>
          <v-card class="ma-0 pa-3 pa-sm-6" color="pop_bg">
            <v-card-text>
              <v-row>
                <h3 class="black--text text-h5 text-sm-h6 ms-2 mb-4">
                  Meal Ingredient
                </h3>
              </v-row>
              <v-divider></v-divider>

              <!-- <v-layout class="mt-6 mt-md-4 ms-md-n4"> -->
              <IngredientTable
                class="mt-5"
                :ingredientList="ingredients"
                :isEdit="false"
              />
              <!-- </v-layout> -->
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <MealRating :mealId="mealId" />
        </v-tab-item>
        <v-tab-item>
          <MenuUsingCustomers :menuItemInfo="mealInfo" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import IngredientTable from "../components/IngredientTable.vue";
import MealInfoLeft from "../components/MealInfoLeft.vue";
import MealInfoRight from "../components/MealInfoRight.vue";
import MenuUsingCustomers from "../components/MenuUsingCustomers.vue";
import MealRating from "@/menu-packages/meal-ratings/components/MealRating.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
export default {
  components: {
    MealInfoLeft,
    MealInfoRight,
    MealRating,
    IngredientTable,
    MenuUsingCustomers,
    AuditTrialLog
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DIETITIAN_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getMenuItemById();
  },

  watch: {
    "$route.query.mealId": {
      handler() {
        this.getMenuItemById();
      }
    }
  },
  methods: {
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    getMenuItemById() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getMenuItemById.graphql"),
          variables: {
            mealId: this.$route.query.mealId
          }
        })
        .refetch({
          variables: {
            mealId: this.$route.query.mealId
          }
        })
        .then(data => {
          this.loading = false;
          if (data.data.getMenuItemById) {
            this.mealInfo = data.data.getMenuItemById;
            let ingredients = new Array();
            this.mealInfo.ingredients.forEach(ingredient => {
              if (ingredient.ingredient) {
                if (!ingredient.ingredient.meanInfo) {
                  ingredient.ingredient.meanInfo = {
                    fat: 0,
                    protein: 0,
                    carbs: 0,
                    calories: 0
                  };
                }
                ingredients.push(ingredient);
              }
            });
            this.ingredients = ingredients;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  data() {
    return {
      isDisable: true,
      imageUrl: process.env.VUE_APP_BASE_URL,
      dietitian: this.$route.query.dietitianId,
      snackbar: false,
      text: "",
      tabs: null,
      btnloading: false,
      loading: true,
      mealId: this.$route.query.mealId,
      mealInfo: this.mealInfo,
      ingredients: []
    };
  }
};
</script>
