var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',{staticClass:"black--text text-h5 font-weight-bold mb-4 mt-4"},[_vm._v(" Universal Pause Days ")]),_c('v-layout',{attrs:{"justify-end":""}},[_c('AuditTrial',{staticClass:"mr-5 mt-1",attrs:{"auditType":'UNIVERSAL_PAUSE_LOG'}}),(_vm.hasRole(_vm.AdminUserPermissions.UNIVERSAL_PAUSE_ADD))?_c('AddUniversalPause',{attrs:{"universalPauses":_vm.universalPauses},on:{"updateUniversalPause":_vm.updateUniversalPause}}):_vm._e()],1),_c('v-layout',{attrs:{"justify-end":""}}),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"headers":_vm.headers,"items":_vm.universalPauses,"options":_vm.options,"mobile-breakpoint":0,"items-per-page":25,"server-items-length":_vm.totalItemsCount,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.siNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.universalPauses .map(function(x) { return x._id; }) .indexOf(item._id) + 1 + _vm.options.itemsPerPage * (_vm.options.page - 1))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(new Date(parseInt(item.date)), false))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(new Date(parseInt(item.createdAt)), true))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.hasRole(_vm.AdminUserPermissions.UNIVERSAL_PAUSE_DELETE))?_c('DeleteUniversalPause',{attrs:{"universalPause":item},on:{"updateUniversalPause":_vm.updateUniversalPause}}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }