<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="accountList"
      class="elevation-1 mt-10"
      :mobile-breakpoint="0"
      :items-per-page="25"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.siNo`]="{ item }">
        {{
          accountList
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) +
            1 +
            options.itemsPerPage * (options.page - 1)
        }}
      </template>
      <template v-slot:[`item.customerName`]="{ item }">
        {{ capitalizeFirstLetter(item.user.firstnameEn) }}
        {{ capitalizeFirstLetter(item.user.lastnameEn) }}
      </template>

      <template v-slot:[`item.date`]="{ item }">
        {{ dateFormat(new Date(parseInt(item.createdAt))) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ numberWithCommas(item.amount) }}
      </template>
      <template v-slot:[`item.discount`]="{ item }">
        {{ numberWithCommas(item.discount) }}
      </template>
      <!-- <template v-slot:[`item.taxes`]="{ item }">
        {{ numberWithCommas(item.tax) }}
      </template> -->
      <template v-slot:[`item.total`]="{ item }">
        {{ numberWithCommas(item.total) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <InvoiceDownload :invoice="item" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import InvoiceDownload from "@/accounts/components/InvoiceDownload.vue";
export default {
  components: {
    InvoiceDownload
  },
  data: () => ({
    accountList: [],
    loading: true,
    headers: [
      {
        text: "Si No",
        value: "siNo"
      },

      { text: "Date and Time", value: "date" },
      { text: "Amount", value: "amount" },
      { text: "Discount", value: "discount" },
      { text: "Total ", value: "total" },
      { text: "Action ", value: "action" }
    ],
    totalItemsCount: 0,
    variables: {
      limit: 25,
      skip: 0
    },
    options: {
      itemsPerPage: 25,
      page: 1
    }
  }),

  created() {
    this.getAllInvoiceTransaction();
  },
  methods: {
    getAllInvoiceTransaction() {
      this.$apollo
        .query({
          query: require("@/accounts/api/getAllInvoiceTransaction.graphql"),
          variables: {
            skip: 0,
            limit: 0,
            customerId: this.$route.query.customerId
          }
        })
        .then(data => {
          if (data.data.getAllInvoiceTransactions) {
            this.accountList =
              data.data.getAllInvoiceTransactions.transactionList;
          }
          this.loading = false;
        });
    },
    dateFormat(date) {
      return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY, h:mm:ss A");
    }
  }
};
</script>
