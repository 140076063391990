<template>
  <v-flex col-12 sm6 md4 lg2 class="px-0">
    <v-card max-width="250" height="200" class="px-2 py-6 mx-auto" tile>
      <v-row
        justify="center"
        class="text-center black--text text-h6 font-weight-light px-8 px-lg-6 mb-xl-3"
        :class="index == 3 ? 'px-lg-9' : index === 4 ? 'mx-md-6' : ''"
        >{{ cardItem.title }}</v-row
      >

      <v-row justify="center" :class="index == 5 ? 'mt-lg-11 mt-xl-2' : ''">
        <v-icon
          class="mt-3"
          :color="
            index == 0
              ? '#22F55E'
              : index == 1
              ? 'primary'
              : index == 2
              ? 'black'
              : index == 3
              ? '#DBDC00'
              : '#FBD159'
          "
          :size="$vuetify.breakpoint.xl ? 54 : 46"
          >{{ cardItem.image }}</v-icon
        >
      </v-row>

      <v-card-actions class="mt-2">
        <v-list-item-title class="text-center text-h6 font-weight-black">{{
          cardItem.count
        }}</v-list-item-title>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>
<script>
export default {
  props: {
    cardItem: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      actionStyle: {
        textDecoration: "none",
        fontSize: "12px",
      },
      headingStyle: {
        fontFamily: "Franklin Gothic",
        fontSize: "18px",
      },
    };
  },
};
</script>
