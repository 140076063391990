<template>
  <div>
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="red">mdi-delete</v-icon>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text">Do you really want to expire/delete this sales
          order ?</v-card-text>
        <v-form @submit="expireSalesOrder()" v-model="valid" ref="form">
          <v-layout wrap>
            <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
              <h4 class="text-body-2 black--text ">Expire Backage By</h4>

              <v-radio-group v-model="expiredFrom" @change="onExpDateClick">
                <v-radio value="NOW" label="From Now"></v-radio>
                <v-radio value="DAY" label="Remove Days"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>

          <v-layout v-if="expiredFrom == 'DAY'">
            <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
              No of Days
            </v-flex>
            <v-flex xs12 sm8 md9 lg3>
              <v-text-field solo dense @change="recalculateEndDate()" v-model="noOfDays"
                :rules="[requiredValidator('no of days')]"></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="minDate">
            <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
              <h4 class="text-body-2 black--text ">Exp Date</h4>
            </v-flex>
            <v-flex xs12 sm8 md9 lg3>
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field solo :value="formatDate" :disabled="true" :loading="endDateLoading" dense
                    append-icon="fa-calendar-alt black--text" readonly v-bind="attrs" v-on="on"
                    :rules="[requiredValidator('expire date')]"></v-text-field>
                </template>

                <v-date-picker :min="minDate" v-model="expDate" @input="menu2 = false" color="secondary">
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
              <h4 class="text-body-2 black--text">Refund Amount</h4>
            </v-flex>
            <v-flex xs12 sm8 md9 lg4>
              <v-text-field solo dense type="number" v-m v-model="refundAmount"
                :rules="[requiredValidator('refund amount'), maxValue]"></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
              <h4 class="text-body-2 black--text ">Reason</h4>
            </v-flex>
            <v-flex xs12 sm8 md9 lg8>
              <v-text-field solo dense v-model="reason" :rules="[requiredValidator('reason')]"></v-text-field>
            </v-flex>
          </v-layout>

          <v-card-text v-if="error" class="error--text font-weight-bold">{{
              error
          }}</v-card-text>
          <v-card-actions justify="center">
            <v-row justify="center">
              <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
              <v-btn color=" mx-2 success" depressed :loading="button_loading" @click="
  button_loading = true;
expireSalesOrder(salesOrder);
              " :disabled="!valid">Yes</v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { DATE_FORMAT } from "../../../utils/utils";
import { requiredValidator } from "../../../core/methods/validations";
import moment from "moment";
export default {
  props: {
    salesOrder: {
      required: true
    }
  },
  data() {
    return {
      menu2: false,
      maxValue: value => value <= this.salesOrder.net_price || 'Max should not be above package price.',
      noOfDays: "",
      expiredFrom: "NOW",
      expDate: "",
      refundAmount: "",
      refundTax: "",
      total: "",
      requiredValidator,
      valid: true,
      isPermanentlyDelete: false,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      snackbar: false,
      snackbarColor: "",
      text: "",
      minDate: moment().format("YYYY-MM-DD"),
      endDateLoading: false,
      reason: ""
    };
  },
  created() {
    let minDate = moment();
    this.onExpDateClick();
    if (moment(new Date(parseInt(this.salesOrder.start_date))) > minDate) {
      minDate = moment(new Date(parseInt(this.salesOrder.start_date)));
    }
    this.minDate = minDate.format("YYYY-MM-DD");
  },
  methods: {
    onExpDateClick() {
      if (this.expiredFrom == "NOW") {
        this.expDate = moment().format("YYYY-MM-DD");
      } else {
        this.recalculateEndDate();
      }
    },
    getTotalNumberOfDays() {
      let availableDays = parseInt(
        this.salesOrder.package.availableChoices[0].availableNumOfDays
      );

      let currentTotalDays = availableDays;

      let noOfDays = this.noOfDays ? parseInt(this.noOfDays) : 0;

      currentTotalDays = currentTotalDays - noOfDays;

      return currentTotalDays;
    },
    recalculateEndDate() {
      let availableDays = this.salesOrder.package.availableChoices[0]
        .availableDays;
      this.endDateLoading = true;
      this.$apollo
        .mutate({
          mutation: require("./api/reCalculateEndDate.graphql"),
          variables: {
            input: {
              start_date: this.salesOrder.start_date,
              availableDays: availableDays,
              customer: this.$route.query.customerId,
              noOfDays: this.getTotalNumberOfDays(),
              subscribedPackageId: this.salesOrder._id
            }
          }
        })
        .then(data => {
          this.endDateLoading = false;
          this.expDate = moment(
            data.data.reCalculateEndDate,
            "DD-MM-YYYY"
          ).toDate();
        })
        .catch(error => {
          this.endDateLoading = false;
          console.log(error);
        });
    },

    expireSalesOrder() {
      let refundAmount = parseFloat(this.refundAmount);

      this.$apollo
        .mutate({
          mutation: require("./api/updateSalesOrderToExpire.graphql"),
          variables: {
            input: {
              customerId: this.$route.query.customerId,
              subscribedPackageId: this.salesOrder._id,
              isPermanentlyDelete: this.isPermanentlyDelete,
              net_price: refundAmount,
              basePrice: refundAmount,
              endDate: moment(this.expDate).format("MM-DD-YYYY"),
              noOfDays: parseInt(this.noOfDays),
              is_expire: this.expiredFrom == "NOW" ? true : false,
              reason: this.reason
            }
          }
        })
        .then(() => {
          this.$root.$emit("updateGetCustomerById");
          this.button_loading = false;
          this.dialog = false;
          this.text = "Sales order updated as Expired";
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch(error => {
          console.log(error);
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.dialog = false;
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    }
  },
  computed: {
    formatDate() {
      return this.expDate ? moment(this.expDate).format(DATE_FORMAT) : "";
    }
  }
};
</script>
