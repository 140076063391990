<template>
  <v-container fluid class="bg mx-0 px-0">
    <v-layout class="mt-8" wrap>
      <!-- <v-flex xs3 sm2 md1 class="ms-sm-2 ms-md-0">
        <h4 class="black--text">From</h4>
      </v-flex> -->
      <v-flex xs8 sm3 md3 class="d-flex">
        <span class="black--text me-3">From</span>
        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="fromDate"
              readonly
              label="From"
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              @click:clear="fDate = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="secondary"
            v-model="fDate"
            @change="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs8 sm3 md3  class="d-flex ms-sm-8 ">
        <span class="black--text me-3">To</span>
        <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="ps-6 ps-sm-0"
              :value="toDate"
              readonly
              label="To"
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              @click:clear="tDate = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="secondary"
            v-model="tDate"
            @change="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex> 
      <!-- <v-flex xs8 sm4 md3 lg3 class="d-flex ms-sm-8 pe-sm-5">
        <v-text-field
            placeholder="Meals"
            v-model="search"
            solo
            dense
            @keydown.enter="runSearch()"
        ></v-text-field>
      </v-flex> -->
      <v-flex xs8 sm3  md3 class="d-flex ms-sm-8">  
        <!-- <span class="black--text me-3"></span> -->
        <v-text-field placeholder="Search.." v-model="searchKey" solo dense>
          <template v-slot:append>
            <v-icon
              color="black"
              v-if="searchKey.length > 0"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon color="black" v-if="searchKey.length == 0" @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field> 
      </v-flex>
    </v-layout>

    <InwardStockTable :fDate="fDate" :tDate="tDate" :searchKey="searchKey" />
  </v-container>
</template>
<script>
import { format, parseISO } from "date-fns";
import InwardStockTable from "../components/InwardStockTable.vue";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  components: {
    InwardStockTable
  },
  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format(DATE_FORMAT) : "";
    },
    toDate() {
      return this.tDate ? moment(this.tDate).format(DATE_FORMAT) : "";
    }
  },
  methods: {
    runSearch() {
      this.searchKey = this.search;
    }
  },
  data() {
    return {
      loading: true,
      fDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      tDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      stockList: [],
      menu1: false,
      menu2: false,
      totalItemsCount: 0,
      search: "",
      searchKey: ""
    };
  }
};
</script>
