<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-layout wrap>
      <h2 class="black--text text-h5 font-weight-bold">Meals Rating</h2>
    </v-layout>
    <v-layout justify-end><ExportMealRating :headers="headers" /></v-layout>

    <!-- {{ mealRatings }} -->

    <v-data-table
      :mobile-breakpoint="0"
      class="mt-6 elevation-1"
      :headers="headers"
      :items="mealRatings"
      :items-per-page="10"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
    >
      <template v-slot:[`item.rating`]="{ item }">
        <v-rating
          small
          color="yellow"
          length="5"
          size="64"
          :value="item.rating"
          :readonly="true"
        ></v-rating>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ new Date(parseInt(item.date)) | moment("MMM DD YYYY") }}
      </template>

      <template v-slot:[`item.customer`]="{ item }">
        <div v-if="item.customer">
          {{ item.customer.user.firstnameEn }}
          {{ item.customer.user.lastnameEn }}
        </div>
      </template>
      <template v-slot:item.customer="{ item }">
        <div v-if="item.customer">
          <div v-if="item.customer">
            <div v-if="item.customer.user">
              <router-link
                v-if="!item.customer.is_deleted"
                :to="{
                  name: 'CustomerDetails',
                  params: { customerId: item.customer._id },
                  query: { customerId: item.customer._id },
                }"
                class="mx-auto"
              >
                <div v-if="item.customer">
                  <div v-if="item.customer.user">
                    {{
                      item.customer.user.firstnameEn[0].toUpperCase() +
                      item.customer.user.firstnameEn.substring(1)
                    }}
                    {{
                      item.customer.user.lastnameEn[0].toUpperCase() +
                      item.customer.user.lastnameEn.substring(1)
                    }}
                  </div>
                </div>
                <!-- <div class="mx-1" /> -->
              </router-link>
              <div v-else>
                <!-- <div class="mx-1" /> -->
                <div v-if="item.customer">
                  <div v-if="item.customer.user">
                    {{
                      item.customer.user.firstnameEn[0].toUpperCase() +
                      item.customer.user.firstnameEn.substring(1)
                    }}
                    {{
                      item.customer.user.lastnameEn[0].toUpperCase() +
                      item.customer.user.lastnameEn.substring(1)
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </v-row> -->
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <div v-if="item.customer">
          {{ item.customer.user.phone }}
        </div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ new Date(parseInt(item.createdAt)) | moment("MMM DD YYYY") }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ExportMealRating from "../components/ExportMealRating.vue";

export default {
  components: {
    ExportMealRating,
  },
  props: {
    subscribedPackages: {
      require: true,
    },
  },

  watch: {
    options: {
      handler() {
        this.getMealRatings();
      },
      deep: true,
    },
  },
  methods: {
    getMealRatings() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../customer/api/getAllCustomerRating.graphql"),
          variables: {
            customerId: "",
            limit: 0,
            skip: 0,
            sortDesc: false,
            sortBy: "",
          },
        })
        .refetch({
          customerId: "",
          limit: this.options.itemsPerPage,
          sortDesc: this.options.sortDesc ? this.options.sortDesc[0] : "",
          sortBy: this.options.sortBy ? this.options.sortBy[0] : "",
          skip: this.options.page * this.options.itemsPerPage - this.options.itemsPerPage,
        })
        .then((data) => {
          this.loading = false;

          if (data.data.getAllCustomerRating) {
            this.mealRatings = data.data.getAllCustomerRating.customerMealRating;
            this.totalItems = data.data.getAllCustomerRating.totalCount;
          }
        })
        .catch((error) => {
          this.loading = false;

          console.log(error);
        });
    },
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.MEALS_RATING_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
    this.getMealRatings();
  },
  data() {
    return {
      options: {
        itemsPerPage: 10,
        page: 1,
        sortDesc: [],
        sortBy: [],
      },
      mealRatings: [],
      totalItems: 0,
      loading: true,
      headers: [
        { text: "Date", value: "date", width: 120 },
        { text: "Rating", value: "rating" },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Contact", value: "phone", sortable: false },
        { text: "Meal", value: "meal.nameEn", sortable: false },
        { text: "Comment", value: "comment", sortable: false },
        { text: "Created Date", value: "createdAt", width: 130 },
      ],
    };
  },
};
</script>
