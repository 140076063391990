var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('div',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs8":"","sm3":"","md3":"","lg3":""}},[_c('h2',{staticClass:"secondary--text text-h5 font-weight-bold"},[_vm._v(" End Date Reminder ")])]),_c('v-flex',{attrs:{"xs8":"","sm3":"","md3":"","lg2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ms-sm-2",attrs:{"value":_vm.formatEndDate,"label":"End Date","solo":"","append-icon":"fa-calendar-alt black--text","dense":""},on:{"click:clear":function($event){_vm.endDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":_vm.selectEndDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('v-spacer'),_c('v-layout',{staticClass:"text-end",attrs:{"justify-end":""}},[_c('ExportEndDate',{attrs:{"headers":_vm.headers,"endDate":_vm.endDate}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"options":_vm.options,"items":_vm.salesOrderList,"loading":_vm.loading,"items-per-page":25,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] },"server-items-length":_vm.totalItemsCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.created_At)),"MMM DD YYYY")))]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.package.titleEn))]}},{key:"item.package_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.package_price)))]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.discount)))]}},{key:"item.net_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.net_price))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('PriceSplitup',{attrs:{"subscribedPackage":item}})]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(!item.is_deleted)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'CustomerDetails',
          params: { customerId: item.customer },
          query: { customerId: item.customer }
        }}},[_vm._v(" "+_vm._s(_vm.toTitleCase(item.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.user.lastnameEn))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.lastnameEn))+" ")])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }