<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="notificationList"
      class="elevation-1"
      :items-per-page="25"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.notificationType="{ item }">
       <span v-if="item.notificationType == 'DateAndTime'">Date And Time</span>
       <span v-if="item.notificationType == 'DayAndTime'">Day And Time</span>
       <span v-if="item.notificationType == 'EveryMonth'">Every Month</span>
      </template>

      <template v-slot:item.createdDate="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.createTime="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("hh:mm:A") }}
      </template>
      <template v-slot:[`item.info`]="{ item }">
        <ScheduleNotificationDetails
          v-if="hasRole(AdminUserPermissions.MASTER_DATA_DELETE)"
          :notification="item"
          :notificationList="notificationList"
        />
      </template>


      <template v-slot:[`item.actions`]="{ item }">
        <ScheduleNotificationDelete
          v-if="hasRole(AdminUserPermissions.MASTER_DATA_DELETE)"
          :notification="item" 
          
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ScheduleNotificationDelete from "../components/ScheduleNotificationDelete";
import ScheduleNotificationDetails from "../components/ScheduleNotificationDetails";
export default {
  components :{
    ScheduleNotificationDelete,
    ScheduleNotificationDetails
  },
  apollo: {
    getAllScheduleNotification() {
      return {
        query: require("../api/getAllScheduleNotification.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.notificationList = data.getAllScheduleNotification.notifications;
          this.totalItemsCount = data.getAllScheduleNotification.totalCount;
        },
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.variables.limit = this.options.itemsPerPage;
        this.variables.skip =
          this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage;
        this.$apollo.queries.getAllScheduleNotification.refetch({
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500,
          skip: this.variables.skip,
        });
      },
      deep: true,
    },
  },
  data: () => ({
    dialog: false,
    notificationList: [],
    totalItemsCount: 25,
    options: {
      itemsPerPage: 25,
      page: 1,
    },
    variables: {
      limit: 25,
      skip: 0,
    },

    loading: true,
    headers: [
      {
        text: "Title",
        value: "title",
        width: 400,
        sortable: false,
      },
      
      { text: "Date", value: "createdDate", width: 120, sortable: false },
      
      { text: "Notification Details", value: "info", width: 120, sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: 50 },
      
    ],

  }),
  mounted() {
    this.$root.$on("updateNotifications", () => {
      this.loading = true;
      this.$apollo.queries.getAllScheduleNotification.refetch({
        variables: this.variables
      });
    });
  },

  methods: {},
};
</script>

