var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"bg"},[(_vm.hasRole(_vm.AdminUserPermissions.CUSTOMER_SALES_ORDER_ADD))?_c('CustomerAddSalesOrder',{attrs:{"customer_id":_vm.customer_id,"customer":_vm.customer,"subscribedPackages":_vm.subscribedPackages}}):_vm._e()],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.salesOrderInfo,"items-per-page":10,"loading":_vm.loading},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.dateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getDate(new Date(parseInt(item.created_At))),"MMM DD YYYY hh:mm"))+" ")]}},{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
        name: 'CustomerDetails',
        params: { customerId: _vm.customer_id, packageId: item._id },
        query: { customerId: _vm.customer_id, packageId: item._id },
      }}},[_vm._v(" "+_vm._s(item.order_number)+" ")])]}},{key:"item.start_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getDate(new Date(parseInt(item.start_date))),"MMM DD YYYY"))+" ")]}},{key:"item.end_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getDate(new Date(parseInt(item.end_date))),"MMM DD YYYY"))+" ")]}},{key:"item.planStatus",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.currentPlanStatus(item) == 'Active'
          ? 'green--text'
          : _vm.currentPlanStatus(item) == 'Payment Pending'
            ? 'orange--text'
            : _vm.currentPlanStatus(item) == 'Upcoming'
              ? 'blue--text'
              : _vm.currentPlanStatus(item) == 'Expired'
                ? 'red--text'
                : ''},[_vm._v(" "+_vm._s(_vm.currentPlanStatus(item)))])]}},{key:"item.packagePrice",fn:function(ref){
                var item = ref.item;
return [(item.package_price)?_c('span',[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.package_price))+" ")]):_vm._e()]}},{key:"item.discount",fn:function(ref){
                var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas( item.discount + item.promoCodeDiscount + item.walletApplied )))]}},{key:"item.taxAmount",fn:function(ref){
                var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.taxAmount)))]}},{key:"item.total",fn:function(ref){
                var item = ref.item;
return [_c('PriceSplitup',{attrs:{"subscribedPackage":item}})]}},{key:"item.createdBy",fn:function(ref){
                var item = ref.item;
return [(item.createdBy)?_c('span',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.createdBy.firstnameEn))+" "+_vm._s(_vm.capitalizeFirstLetter(item.createdBy.lastnameEn))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[(_vm.isEditEnabled(item))?_c('div',{staticClass:"me-3"},[(_vm.hasRole(_vm.AdminUserPermissions.CUSTOMER_SALES_ORDER_UPDATE))?_c('CustomerSalesorderUpdate',{attrs:{"salesOrderInfo":item,"customer_id":_vm.customer_id,"allPackages":_vm.salesOrderInfo}}):_vm._e()],1):_vm._e()]),_c('v-col',{attrs:{"cols":"3"}},[(_vm.isPaymentPanding(item))?_c('div',{staticClass:"me-3"},[(_vm.hasRole(_vm.AdminUserPermissions.CUSTOMER_SALES_ORDER_UPDATE))?_c('RegenerateSalesOrderPayment',{attrs:{"salesOrder":item}}):_vm._e()],1):_vm._e()]),_c('v-col',{attrs:{"cols":"3"}},[(_vm.isDeleteEnabled(item))?_c('div',[(_vm.hasRole(_vm.AdminUserPermissions.CUSTOMER_SALES_ORDER_DELETE))?_c('ExpireSalesOrder',{attrs:{"salesOrder":item}}):_vm._e()],1):_vm._e()])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }