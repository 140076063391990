<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row class="mt-2 me-sm-1 mb-sm-4 mb-md-0">
      <h2 class="black--text text-h5 font-weight-bold ms-2">Delivery</h2>
      <v-spacer></v-spacer>
      <InstantView class="mt-2" />
    </v-row>
    <DeliveryTable />
  </v-container>
</template>

<script>
import DeliveryTable from "../components/DeliveryTable.vue";
import InstantView from "../components/InstantView.vue";
export default {
  components: {
    DeliveryTable,
    InstantView
  },
  data() {
    return {
      tabs: ""
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DELIVERY_REPORT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
