<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="mt-n3 white--text text-capitalize btn_fnt"
        color="btncolor"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="purchaseExport"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="purchaseList"
        class="elevation-1 mt-6"
      >
        <template #[`item.nameEn`]="{ item }">{{ item.nameEn }}</template>
        <template #[`item.nameAr`]="{ item }">{{ item.nameAr }}</template>
        <template #[`item.id`]="{ item }">{{ item.itemId }}</template>
        <template #[`item.brandName`]="{ item }">{{ item.brand }}</template>
        <template #[`item.unit`]="{ item }">{{ item.quantityUnit }}</template>
        <template #[`item.inwardTotal`]="{ item }">{{ item.inwardTotal }}</template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    fromDate: {
      require: true
    },
    toDate: {
      require: true
    },
  },
  data() {
    return {
      purchaseList: [],
      loading: false,
      variables: {
        limit: 0,
        skip: 0,
        fromDate:this.fromDate,
        toDate:this.toDate
      },
      headers: [
          { text: "Item ID", value: "id", width: 100 },
          { text: "Item Name (English)", value: "nameEn" },
          { text: "Item Name (Arabic)", value: "nameAr" },
          { text: "Brand Name", value: "brandName" },
          { text: "Unit", value: "unit" },
          { text: "Monthly Inward", value: "inwardTotal" },
      ]
    };
  },

  created() {
    this.head = this.headers.filter(x => x.value != "actions");
  },

  methods: {
    getAllIngredients() {
      this.$apollo
        .query({
          query: require("../../api/monthlyPurchase.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.purchaseList = data.data.monthlyPurchase.ingredients;
            console.log(this.purchaseList.length);
            setTimeout(() => {
              this.loading = false;
              Utils.htmlToExcel("purchaseExport", "Monthly Purchase Report", false);
            }, 100);
          }
        });
    },

    exportExcel() {
      this.loading = true;
      this.getAllIngredients();
    }
  }
};
</script>
