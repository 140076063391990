var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.purchaseDetails,"items-per-page":5,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticClass:"mt-3"},[_c('v-flex',{attrs:{"md8":""}},[_c('v-text-field',{attrs:{"rules":[_vm.requiredValidator('quantity')],"type":"number","min":"0","outlined":"","dense":""},on:{"change":function($event){return _vm.calculateTotalPrice(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)],1)]}},{key:"item.unitPrice",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticClass:"mt-3"},[_c('v-flex',{attrs:{"md8":""}},[_c('v-text-field',{attrs:{"rules":[_vm.requiredValidator('quantity')],"type":"number","min":"0","outlined":"","dense":"","suffix":"KD"},on:{"change":function($event){return _vm.calculateTotalPrice(item)}},model:{value:(item.unitPrice),callback:function ($$v) {_vm.$set(item, "unitPrice", $$v)},expression:"item.unitPrice"}})],1)],1)]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.totalPrice).toFixed(3))+" KD ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item.ingredient.itemId)}}},[_vm._v("mdi-delete")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th'),_c('th'),_c('th'),_c('th',[_vm._v("Total")]),_c('th',[_vm._v(_vm._s(_vm.purchasePrice)+" KD")])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }