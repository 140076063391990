<template>
  <v-container>
    <!-- <div class="box"> -->
    <h2 class="black--text text-h5 font-weight-bold mb-4 mt-4">
      Universal Pause Days
    </h2>
    <v-layout justify-end>
      <AuditTrial class="mr-5 mt-1" :auditType="'UNIVERSAL_PAUSE_LOG'" />
      <AddUniversalPause
        v-if="hasRole(AdminUserPermissions.UNIVERSAL_PAUSE_ADD)"
        :universalPauses="universalPauses"
        @updateUniversalPause="updateUniversalPause"
      />
    </v-layout>
    <v-layout justify-end> </v-layout>
    <v-data-table
      :headers="headers"
      :items="universalPauses"
      class="elevation-1 mt-2"
      :options.sync="options"
      :mobile-breakpoint="0"
      :items-per-page="25"
      :server-items-length="totalItemsCount"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.siNo`]="{ item }">
        {{
          universalPauses
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) +
            1 +
            options.itemsPerPage * (options.page - 1)
        }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ dateFormat(new Date(parseInt(item.date)), false) }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ dateFormat(new Date(parseInt(item.createdAt)), true) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <DeleteUniversalPause
          :universalPause="item"
          @updateUniversalPause="updateUniversalPause"
          v-if="hasRole(AdminUserPermissions.UNIVERSAL_PAUSE_DELETE)"
        />
      </template>
    </v-data-table>
    <!-- </div> -->
  </v-container>
</template>
<script>
import moment from "moment";
import AddUniversalPause from "../components/AddUniversalPause.vue";
import DeleteUniversalPause from "../components/DeleteUniversalPause.vue";
import AuditTrial from "../../audit-trial-log/components/AuditTrialLog.vue";
export default {
  components: {
    AddUniversalPause,
    DeleteUniversalPause,
    AuditTrial
  },
  data: () => ({
    universalPauses: [],
    loading: true,
    headers: [
      {
        text: "Si No",
        value: "siNo"
      },
      { text: "Pause Date ", value: "date" },
      { text: "Created At", value: "createdAt" },
      { text: "Action ", value: "action" }
    ],
    totalItemsCount: 0,
    variables: {
      limit: 25,
      skip: 0
    },
    options: {
      itemsPerPage: 25,
      page: 1
    }
  }),
  watch: {
    options: {
      handler() {
        if (!this.loading) {
          this.variables.limit = this.options.itemsPerPage;
          this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage;
          this.getAllUniversalPause();
        }
      },
      deep: true
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.UNIVERSAL_PAUSE_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }

    this.getAllUniversalPause();
  },
  methods: {
    updateUniversalPause() {
      this.getAllUniversalPause();
    },
    getAllUniversalPause() {
      this.$apollo
        .watchQuery({
          query: require("../api/getAllUniversalPause.graphql"),
          variables: this.variables
        })
        .refetch({})
        .then(data => {
          if (data.data.getAllUniversalPause) {
            this.universalPauses =
              data.data.getAllUniversalPause.universalPauses;
            this.totalItemsCount = data.data.getAllUniversalPause.totalCount;
            console.log(this.universalPauses.length);
          }
          this.loading = false;
        });
      this.loading = false;
    },
    dateFormat(date, mint) {
      if (mint)
        return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY, h:mm:ss A");
      else return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY");
    }
  }
};
</script>
<style>
.box {
  background-color: #e5e5e5;
}
</style>
