<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="550px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="white--text text-capitalize font-weight-bold"
          color="secondary"
          ><v-icon small class="me-1">mdi-plus</v-icon> Add</v-btn
        >
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-row>
          <v-card-title class="black--text text-h6 mt-n2 ms-2"
            >Add Contact Method</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>

        <v-card-text>
          <v-container class="mt-2">
            <!-- <h3 class="ms-sm-8">Enter Contact Method Details</h3> -->
            <v-form @submit="createContactMethod()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="text-body-2 black--text">
                    Contact Method English
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field
                    v-model="method"
                    :rules="[requiredValidator('contact method')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="text-body-2 black--text">Contact Method Arabic</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field
                    v-model="methodAr"
                    :rules="[requiredValidator('contact method arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap>
                <v-flex xs12 sm4>
                  <h4 class="text-body-2 black--text">Currently Active</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-radio-group row>
                    <v-radio label="Yes"></v-radio>
                    <v-radio label="No"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4>
                  <h4 class="text-body-2 black--text">Show Order</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field solo dense></v-text-field>
                </v-flex>
              </v-layout> -->
              <v-layout row justify-end class="mt-5">
                <v-btn
                  :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                  color="secondary"
                  class="white--text text-capitalize"
                  @click="createContactMethod()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >Save</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {},
  data() {
    return {
      requiredValidator: requiredValidator,
      method: "",
      methodAr: "",
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false
    };
  },
  methods: {
    createContactMethod() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/contact-method/api/createContactMethod.graphql"),
          variables: {
            method: this.method,
            methodAr: this.methodAr
          },
          update: (store, { data: { createContactMethod } }) => {
            try {
              const query = {
                query: require("@/contact-method/api/getAllContactMethods.graphql"),
                variables: {
                  limit: 25,
                  skip: 0
                }
              };

              let allContactMethods = store.readQuery(query);
              allContactMethods.getAllContactMethods.methods.unshift(
                createContactMethod
              ); //push to top
              store.writeQuery({
                ...query,
                data: allContactMethods
              });
              this.text = "Contact Method Added";
              this.snackbar = true;
              this.show_dialog_box = false;
            } catch (e) {
              // console.log(e);
              console.log("Something bad happend- CategoryForm.vue");
            }
            this.$refs.form.reset();
            this.btnloading = false;
          }
        })
        .then(() => {
          this.$root.$emit("updateMethods");
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    close() {
      this.show_dialog_box = false;
    }
  }
};
</script>
