<template>
  <div>
    <CustomerAddDislike @updateDislikes="updateDislikes" class="bg" />
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="dislikedItems"
      :items-per-page="20"
    >
      <template v-slot:item.actions="{ item }">
        <v-row>
          <DislikeDeleteButton
            :dislike="item"
            @updateDislikes="updateDislikes"
          />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DislikeDeleteButton from "./DislikeDeleteButton.vue";
import CustomerAddDislike from "./CustomerAddDislike.vue";
export default {
  components: { DislikeDeleteButton, CustomerAddDislike },
  props: {
    dislikedList: {
      require: [],
    },
  },
  methods: {
    updateDislikes(dislikes) {
      this.dislikedItems = dislikes;
    },
  },
  created() {
    this.dislikedItems = this.dislikedList;
  },
  watch: {
    dislikedList: {
      handler(newVal) {
        this.dislikedItems = newVal;
      },
      deep: true,
    },
  },
  data() {
    return {
      dislikedItems: [],
      headers: [
        { text: "Item ID", value: "itemId" },
        { text: "Name", value: "nameEn" },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
};
</script>
