<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black">mdi-square-edit-outline</v-icon>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Update Offer </span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-sm-8 mt-n6" @submit="updateOffer()" ref="form">
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="text-body-2 black--text">Promo Code</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-autocomplete
                    item-text="promoCode"
                    v-model="offer.promoCode"
                    item-value="_id"
                    :items="promoCodeList"
                    :disabled="true"
                    :rules="[requiredValidator('promo Code ')]"
                    label="Select Promo Code"
                    dense
                    attach
                    solo
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for promoCode...
                        </h5>
                      </v-flex>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex md4>
                  <v-layout>
                    <v-flex xs12 sm3 md12 class="">
                      <v-img
                        :key="keyCount"
                        :width="315"
                        class="ms-md-4"
                        :src="`${this.imageUrl}${this.offer.image}`"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout class="mt-3 mt-sm-0" wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Offer Title</h4>
                </v-flex>
                <v-flex xs12 sm5 md4>
                  <v-text-field
                    label="Title (English)"
                    v-model="offer.promoCode.titleEn"
                    :disabled="true"
                    :rules="[requiredValidator('Offer title')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Offer Title (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm5 md4 class="mt-sm-5 mt-md-0">
                  <v-text-field
                    label="Title (Arabic)"
                    v-model="offer.promoCode.titleAr"
                    :disabled="true"
                    :rules="[requiredValidator('Offer title Arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-4 mt-sm-0">
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Choose Image</h4>
                </v-flex>

                <v-flex xs12 sm8 md7 style="">
                  <croppa
                    class="ml-2 mt-2"
                    v-model="myCroppa"
                    :width="315"
                    :height="123"
                    canvas-color="black"
                    :placeholder="'Choose an image'"
                    :placeholder-font-size="16"
                    placeholder-color="yellow"
                    :accept="'image/*'"
                    :file-size-limit="0"
                    :quality="2"
                    :zoom-speed="3"
                    :disabled="false"
                    :disable-drag-and-drop="false"
                    :disable-click-to-choose="false"
                    :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false"
                    :disable-rotation="false"
                    :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false"
                    :show-remove-button="true"
                    :remove-button-color="'red'"
                    :remove-button-size="20"
                  ></croppa>

                  <v-layout class="mb-3">
                    <span style="color: red">
                      ****Use mouse scroll wheel to crop image****
                    </span>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="text-body-2 black--text">Start Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        @click="onClick"
                        solo
                        dense
                        :value="formatStartDate"
                        append-icon="mdi-calendar black--text"
                        readonly
                        :rules="[requiredValidator('start date')]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      v-model="startDate"
                      :min="promoStartDate"
                      :max="promoEndDate"
                      @input="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="text-body-2 black--text">End Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        @click="onClick"
                        solo
                        dense
                        :value="formatEndDate"
                        append-icon="mdi-calendar black--text"
                        readonly
                        :rules="[requiredValidator('end date')]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      v-model="endDate"
                      :min="promoStartDate"
                      :max="promoEndDate"
                      @input="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="text-body-2 black--text">Active</h4>
                </v-flex>
                <v-flex xs12 md3 align-self-center>
                  <v-radio-group v-model="offer.active" :mandatory="false" row>
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout justify-center>
                <v-flex xs8 md2 class="mt-8"> </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n16">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="white--text text-capitalize"
              :loading="btnloading"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              @click="updateOffer"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import { DATE_FORMAT } from "../../utils/utils";
import moment from "moment";
import { format, fromUnixTime } from "date-fns";
export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      keyCount: 0,
      dialog: false,
      addedArea: [],
      offerList: {},
      snackbar: false,
      text: "",
      areaList: [],
      menuStartDate: false,
      menuEndDate: false,
      startDate: "",
      endDate: "",
      promoCodeList: [],
      titleEn: "",
      titleAr: "",
      promoStartDate: "",
      promoEndDate: "",
      date: "",
      myCroppa: null,
      imageUrl: process.env.VUE_APP_BASE_URL
    };
  },
  props: {
    offer: {
      required: true
    }
  },

  created() {},
  apollo: {
    getAllPromoCodes() {
      return {
        query: require("@/promotion/api/getAllPromoCodes.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.promoCodeList = data.getAllPromoCodes;
        }
      };
    }
  },
  methods: {
    onClick() {
      const promoExist = this.promoCodeList.find(
        promo => promo._id === this.offer.promoCode._id
      );
      const dateEnd = fromUnixTime(promoExist.endDate / 1000);
      this.promoEndDate = format(dateEnd, "yyyy-MM-dd");
      const dateStart = fromUnixTime(promoExist.startDate / 1000);
      this.promoStartDate = format(dateStart, "yyyy-MM-dd");
    },
    updateOffer() {
      if (typeof this.offerList.promoCode == "object") {
        this.offerList.promoCode = this.offer.promoCode._id;
      }

      this.btnloading = true;
      if (this.myCroppa.hasImage()) {
        this.myCroppa.generateBlob(blob => {
          this.updateOfferAPI(blob);
        });
      } else {
        this.updateOfferAPI("");
      }
    },

    updateOfferAPI(image) {
      this.$apollo
        .mutate({
          mutation: require("../api/updateOffer.graphql"),
          variables: {
            id: this.offer._id,
            image: image,
            startDate: this.startDate,
            endDate: this.endDate,
            active: this.offer.active,
            promoCode: this.offer.promoCode._id
          }
        })
        .then(data => {
          if (data.data.updateOffer) {
            this.offer.image = data.data.updateOffer.image;
            this.myCroppa = null;
            this.$root.$emit("updateOffer");
            this.text = "Offer is Updated";
            this.snackbar = true;
            this.dialog = false;
            this.btnloading = false;
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },

    close() {
      this.dialog = false;
    }
  },
  computed: {
    formatStartDate() {
      return this.startDate
        ? moment(this.startDate).format(DATE_FORMAT)
        : moment(new Date(parseInt(this.offer.startDate))).format("DD-MM-YYYY");
    },
    formatEndDate() {
      return this.endDate
        ? moment(this.endDate).format(DATE_FORMAT)
        : moment(new Date(parseInt(this.offer.endDate))).format("DD-MM-YYYY");
    }
  }
};
</script>
