<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <!-- <template v-slot:activator="{ on }">
      <v-icon v-on="on" color="red" class="ms-2">mdi-delete</v-icon>
    </template> -->
    <!-- class="pa-6" -->
    <v-card class="pa-6">
      <div v-if="!proceedView">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2">{{
              finishView && isFollowUp ? "Follow Up" : "Update Appointment"
          }}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2 mb-6"></v-divider>
      </div>
      <div v-if="proceedView">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center">Do you really want to change the action status from
          <span class="font-weight-bold">{{ getFromActionType(otherDatas.from) }}
          </span>
          to
          <span class="font-weight-bold">
            {{
                slotAppointment
                  ? getFromActionType(slotAppointment.followUp.actionStatus)
                  : "--"
            }}
          </span>
          ?
        </v-card-text>
        <v-layout justify-center>
          <v-flex xs10>
            <v-textarea append-icon="mdi-comment" class="mx-2" label="Write any comment..." rows="1" v-model="comment">
            </v-textarea>
          </v-flex>
        </v-layout>
        <div class="mb-5 " v-if="this.slotAppointment">
          <!--  -->
          <!--  this.slotAppointment.followUp.modeOfAppointment=="ON_VISIT"-->
          <v-layout justify-center v-if="this.slotAppointment.followUp.actionStatus == 'FINISHED'">
            <v-flex xs4 class="ml-4">Follow Up Needed ?</v-flex>
            <v-flex xs6>
              <div class="mt-n5 mr-4   ">
                <v-checkbox v-model="isFollowUp"></v-checkbox>
              </div>
            </v-flex>
          </v-layout>
          <v-layout justify-center v-if="
            this.slotAppointment.followUp.modeOfAppointment == 'ON_VISIT' &&
            this.slotAppointment.followUp.actionStatus == 'FINISHED'
          ">
            <v-flex xs4 class="ml-4 mt-2 ">Additional Fee</v-flex>
            <v-flex xs6>
              <v-text-field v-model="additionalFee" dense attach solo :rules="[positive('Fee')]"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout justify-center v-if="
            this.slotAppointment.followUp.modeOfAppointment == 'ON_VISIT' &&
            !isFollowUp &&
            this.slotAppointment.followUp.actionStatus == 'FINISHED'
          ">
            <v-flex xs4 class="ml-4 mt-2 ">
              Payment Method
            </v-flex>
            <v-flex xs6>
              <v-select :items="avalilablePaymentMethod" v-model="paymentMethod" dense solo item-text="text"
                item-value="value"></v-select>
            </v-flex>
          </v-layout>
        </div>
        <!-- <div v-if="finishView  && isFollowUp"> 
          <FinishFollowUp
            :appointment="slotAppointment"
            @updateSlotAppointmentFollowUp="updateSlotAppointmentFollowUp"
          />
        </div> -->
        <v-card-text v-if="error" class="error--text font-weight-bold">{{
            error
        }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
         
            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
            <v-btn color=" mx-2 success" depressed :loading="button_loading" @click="proceedDietitianAppointment();
            ">Yes</v-btn>
          </v-row>
        </v-card-actions>
      </div>
      <div v-else>
        <div v-if="rescheduleView">
          <update-appointment-popup :appointment="slotAppointment" @updateSlotAppointments="updateSlotAppointments" />
        </div>
        <div v-if="finishView && isFollowUp">
          <FinishFollowUp :appointment="slotAppointment" :loadingBtn="loadingBtn"
            @updateSlotAppointmentFollowUp="updateSlotAppointmentFollowUp" />
        </div>
      </div>
      <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">{{ text }}</v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import { positive } from "../../../core/methods/validations";
import FinishFollowUp from "./FinishAppFollowUp.vue";
import updateAppointmentPopup from "./updateAppointmentPopup.vue";
// import AddCustomerDietitianAppoinment from "customer/components/customerDietitian/AddCustomerDietitianAppoinment.vue";
export default {
  components: { updateAppointmentPopup, FinishFollowUp },
  props: {
    showDialogue: {
      required: true
    },
    slotAppointment: {
      required: true
    },
    otherDatas: {
      required: true
    }
  },
  watch: {
    showDialogue: {
      handler(item) {
        if (item) {
          this.dialog = true;
        }
      }
    }
  },
  created() {
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_ONLINE)) {
      const data = this.paymentMethods[0];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_OFFLINE)) {
      const data = this.paymentMethods[1];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_FREE)) {
      const data = this.paymentMethods[2];
      this.avalilablePaymentMethod.push(data);
    }
    this.appointment
      ? (this.dietitian = this.appointment.dietitian)
      : (this.dietitian = {});
  },
  data() {
    return {
      positive,
      snackbarColor: "green",
      snackbar: false,
      text: "",
      valid: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      alertData: {
        value: true,
        msg: " Dietitian Appointment deleted !",
        msg_ar: "تم حذف العضو!",
        type: "success"
      },
      paymentMethods: [
        { text: "Online", value: "ONLINE" },
        { text: "Offline", value: "OFFLINE" },
        { text: "Free", value: "FREE" }
      ],
      comment: "",
      proceedView: true,
      rescheduleView: false,
      finishView: false,
      isFollowUp: false,
      additionalFee: "",
      avalilablePaymentMethod: [],
      paymentMethod: "",
      loadingBtn: false
    };
  },
  methods: {
    updateSlotAppointmentFollowUp(data) {
      console.log(data);
      let variables = new Object();
      variables.input = data;
      if (this.comment) variables.input.comment = this.comment;
      variables.updateSlotAppointmentId = this.slotAppointment.appointmentId;
      // this.slotAppointment._id;
      variables.input.isFollowUp = this.isFollowUp;
      variables.input.followUpId = this.slotAppointment.followUp._id;
      if (this.slotAppointment.modeOfAppointment == "ON_VISIT") {
        variables.input.additionalFee = parseFloat(this.additionalFee);
      }

      variables.input.actionStatus = this.slotAppointment.followUp.actionStatus;
      this.proceedView = true;
      this.rescheduleView = false;
      this.updateAppointment(variables);
    },
    updateSlotAppointments(data) {
      let variables = new Object();
      variables.input = data;
      if (this.comment) variables.input.comment = this.comment;
      variables.updateSlotAppointmentId = this.slotAppointment.appointmentId;
      // _id;
      console.log(variables);
      this.proceedView = true;
      this.finishView = false;
      this.updateAppointment(variables);
    },
    updateAppointment(variables) {
      this.$apollo
        .mutate({
          mutation: require("../api/updateSlotAppointment.graphql"),
          variables
        })
        .then(() => {
          this.$emit("updateDietitianSlotAppointments");
          this.button_loading = false;
          this.error = null;
          this.dialog = false;
          this.loadingBtn = false;
          this.comment = "";
          this.text = "Successfully updated";
          this.snackbarColor = "green";
          this.snackbar = true;
        })
        .catch(error => {
          this.loadingBtn = false;
          this.text = "Some Error";
          this.snackbarColor = "red";
          this.snackbar = true;
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.button_loading = false;
        });
    },
    proceedDietitianAppointment() {
      debugger
      let variables = new Object();
      this.button_loading = true;
      variables.updateSlotAppointmentId = this.slotAppointment.appointmentId;

      variables.input = new Object();
      variables.input.followUpId = this.slotAppointment.followUp._id;

      this.slotAppointment.followUp.actionStatus = this.slotAppointment.followUp.actionStatus == "NEXT_RESCHEDULE" ? "RESCHEDULE" : this.slotAppointment.followUp.actionStatus;
      variables.input.actionStatus = this.slotAppointment.followUp.actionStatus;
      variables.input.additionalFee = parseFloat(this.additionalFee);

      if (this.comment) variables.input.comment = this.comment;
      if (this.slotAppointment.followUp.actionStatus == "CONFIRMED") {
        this.updateAppointment(variables);
      } else if (this.slotAppointment.followUp.actionStatus == "BOOKED") {
        this.snackbarColor = "red";
        this.snackbar = true;
        this.text = "Can't change status to Booked";
        this.button_loading = false;
        return;
      } else if (this.slotAppointment.followUp.actionStatus == "FINISHED") {
        this.loadingBtn = true;
        if (this.slotAppointment.followUp.modeOfAppointment == "ON_VISIT") {
          this.slotAppointment.additionalFee = this.additionalFee;
          if (parseFloat(this.additionalFee) < 0 || this.additionalFee == "") {
            (this.snackbarColor = "red"),
              (this.snackbar = true),
              (this.text = "Additional price should be valid number");
            this.button_loading = false;
            this.comment = "";
            return;
          }
        }
        if (!this.isFollowUp) {
          variables.input.actionStatus = this.slotAppointment.followUp.actionStatus;
          variables.input.isFollowUp = false;
          variables.input.paymentMethod = this.paymentMethod;
          this.updateAppointment(variables);
        } else {
          this.proceedView = false;
          this.finishView = true;
        }


        // variables.input.actionStatus = this.slotAppointment.followUp.actionStatus;
      } else if (this.slotAppointment.followUp.actionStatus == "RESCHEDULE") {

        this.proceedView = false;
        this.rescheduleView = true;
        variables.input.actionStatus = this.slotAppointment.followUp.actionStatus;
      } else if (this.slotAppointment.followUp.actionStatus == "CANCELLED") {

        variables.input.slotId = this.slotAppointment.followUp.slotId;
        this.updateAppointment(variables);
      }

      // else {
      //   this.proceedView = false;
      // }

      // if (this.proceedView)
      // this.$apollo
      //   .mutate({
      //     mutation: require("../api/updateSlotAppointment.graphql"),
      //     variables
      //   })
      //   .then(() => {
      //     this.$root.$emit("updateDietitianSlotAppointments");
      //     this.button_loading = false;
      //     this.error = null;
      //     this.dialog = false;
      //   })
      //   .catch(error => {
      //     if (error.graphQLErrors != null) {
      //       console.error(error.graphQLErrors);
      //     }
      //     this.button_loading = false;
      //   });
    },
    getFromActionType(type) {
      if (type == "BOOKED") {
        return "Booked";
      } else if (type == "RESCHEDULE") {
        return "Reshedule";
      } else if (type == "CONFIRMED") {
        return "Confirmed";
      } else if (type == "CANCELLED") {
        return "cancelled";
      } else if (type == "FINISHED") {
        return "Finished";
      }
    },
    close() {
      this.error = null;
      this.dialog = false;
      this.proceedView = true;
      this.comment = "";
      this.$emit("closeDialogue", this.slotAppointment);
    }
  }
};
</script>
