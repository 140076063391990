<template>
  <v-container>
    <v-row>
      <a href="#" @click="showDialog = true">
        <strong style="color: red"> Price: {{ varientInfo.price }} KWD </strong>
      </a>
    </v-row>
    <v-dialog v-model="showDialog" width="500">
      <v-card class="pa-6" v-if="varientInfo">
        <v-card-text class="subtitle-1 mb-0 mt-6">
          <div v-for="(choice, idx) in varientInfo.dayChoices" :key="idx">
            <div v-if="choice.mealCount">
              <div v-if="choice.mealCount.some((x) => parseInt(x.count) > 0)">
                <h4>
                  <u> {{ choice.day }}</u>
                </h4>

                <div v-for="(category, idxc) in choice.mealCount" :key="idxc">
                  <div v-if="parseInt(category.count) > 0">
                    {{ category.count }} -
                    <span v-if="category.category">
                      {{ category.category.nameEn }}
                    </span>
                    <span v-else>
                      {{ category.nameEn }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-else-if="choice.mealCount">
              <h4>
                <u> {{ choice.day }}</u>
              </h4>
              <div v-for="(category, idxc) in choice.mealCount" :key="idxc">
                {{ category.count }}
                {{ category.category.nameEn }}
              </div>
            </div> -->
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  components: {},
  props: {
    varient: {
      required: true,
    },
  },
  created() {
    this.varientInfo = this.varient;
  },
  watch: {
    varient: {
      handler(newVal) {
        this.varientInfo = newVal;
      },
      deep: true,
    },
  },
  data() {
    return {
      showDialog: false,
      varientInfo: [],
    };
  },
};
</script>