
<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="red" class="ms-2">mdi-delete</v-icon>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center"
          >Do you really want to delete this?</v-card-text
        >

        <v-card-text v-if="error" class="error--text font-weight-bold">{{
          error
        }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 success"
              depressed
              :loading="button_loading"
              @click="
                button_loading = true;
                deleteArea(area._id);
              "
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>
<script>
export default {
  props: {
    area: {
      required: true,
    },
  },
  data() {
    return {
      snackbar: false,
      text: "",
      snackbarColor: "",
      valid: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      alertData: {
        value: true,
        msg: "Area deleted !",
        msg_ar: "تم حذف العضو!",
        type: "success",
      },
    };
  },
  methods: {
    deleteArea(id) {
      console.log(id);
      this.$apollo
        .mutate({
          mutation: require("../api/deleteArea.graphql"),
          variables: {
            id,
          },
          update: (store) => {
            const query = {
              query: require("../api/getAllAreas.graphql"),
            };
            // Read the query from cache
            const data = store.readQuery(query);
            // console.log(data);
            // Look for the deleted item
            const index = data.getAllAreas.areas.findIndex((m) => m._id === id);
            // console.log(index);
            if (index !== -1) {
              // Mutate cache result
              data.getAllAreas.areas.splice(index, 1);
              // Write back to the cache
              store.writeQuery({
                ...query,
                data,
              });
            }
          },
        })
        .then(() => {
          this.button_loading = false;
          this.error = null;
          this.dialog = false;
        })
        .catch((error) => {
          // Error
          if (error.graphQLErrors != null) {
            this.button_loading = false;
            this.error = null;
            this.dialog = false;
            console.error(error.graphQLErrors);

            if (error.graphQLErrors[0].message === "ALREADY_IN_USE") {
              this.text = "Area already in use";
            }

            this.snackbarColor = "error";
            this.snackbar = true;
          }
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    },
  },
};
</script>