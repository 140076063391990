<template>
  <v-container>
    <div class="dv-switch">
      <v-data-table
        class="myTable "
        :mobile-breakpoint="0"
        :headers="mainHeaders"
        :items="item"
        :items-per-page="10"
      >
        <template v-slot:[`item.from`]="items">
          <span v-show="false">{{ items }}</span>
          <v-data-table
            hide-default-footer
            :mobile-breakpoint="0"
            :headers="headers"
            :items="switchPackageList"
            :items-per-page="5"
          >
            <template v-slot:[`item.choice`]="item">
              <div>
                {{ item.item.from.choice.name }}
              </div>
            </template>
            <template v-slot:[`item.package`]="item">
              <div>
                {{ item.item.from.package.name }}
              </div>
            </template>
            <template v-slot:[`item.variant`]="item">
              <div>
                {{ item.item.from.variant.name }}
              </div>
            </template>
          </v-data-table>
        </template>
        <template v-slot:[`item.to`]>
          <v-data-table
            hide-default-footer
            :mobile-breakpoint="0"
            :headers="headers"
            :items="switchPackageList"
            :items-per-page="10"
          >
            <template v-slot:[`item.choice`]="item">
              <div>
                {{ item.item.to.choice.name }}
              </div>
            </template>
            <template v-slot:[`item.package`]="item">
              <div>
                {{ item.item.to.package.name }}
              </div>
            </template>
            <template v-slot:[`item.variant`]="item">
              <div>
                {{ item.item.to.variant.name }}
              </div>
            </template>
          </v-data-table>
        </template>
        <template v-slot:[`item.action`]>
          <v-data-table
            hide-default-footer
            :mobile-breakpoint="0"
            :headers="headersAction"
            :items="switchPackageList"
            :items-per-page="10"
          >
            <template v-slot:[`item.delete`]="item">
              <v-icon
                color="red"
                @click="
                  deleteTempPackage(
                    item.item.from.package.id,
                    item.item.to.package.id
                  )
                "
                class="ms-2"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </template>
      </v-data-table>
    </div>
    <v-layout class="mt-4 " justify-end>
      <v-btn
        width="120"
        color="secondary"
        class="text-capitalize white--text"
        @click="createPlanSwitch()"
        :loading="btnloading"
        :disabled="!valid"
      >
        <v-icon left>mdi-plus</v-icon>Create</v-btn
      >
    </v-layout>
     <v-snackbar v-model="snack" right top :timeout="3000" :color="snackColor">
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  props: {
    switchPakage: {
      required: true
    }
  },
  data() {
    return {
      snack:false,
      snackColor:"",
      snackText:"",
      mainHeaders: [
        { text: "From", value: "from", align: "center",width:45 },
        { text: "To", value: "to", align: "center",width:45 },
        { text: "Action", value: "action", align: "center",width:10 }
      ],
      headers: [
        { text: "Package", value: "package", align: "center" },
        { text: "Choice", value: "choice", align: "center" },
        { text: "Variant", value: "variant", align: "center" }
      ],
      headersAction: [{ text: "Delete", value: "delete", align: "center" }],
      item: [{ from: "from", to: "to" }],

      switchPackageList: [],
      valid: false,
      btnloading: false
    };
  },

  watch: {
    switchPakage: {
      handler() {
        this.switchPackageList.push(this.switchPakage);
        if (this.switchPackageList.length) {
          this.valid = true;
        }
      },
      deep: true
    }
  },
  methods: {
    deleteTempPackage(fromId, toId) {
      this.switchPackageList = this.switchPackageList.filter(
        x => x.to.package.id !== toId && x.from.package.id !== fromId
      );
      console.log(this.switchPackageList.length);
    },
    createPlanSwitch() {
      this.btnloading = true;
      let savedData = new Array();
      for (let switchData of this.switchPackageList) {
        let to = new Object();
        let from = new Object();
        let result = new Object();
        to.package = switchData.to.package.id;
        to.choice = switchData.to.choice.id;
        to.variant = switchData.to.variant.id;
        from.package = switchData.from.package.id;
        from.choice = switchData.from.choice.id;
        from.variant = switchData.from.variant.id;
        result.to = to;
        result.from = from;
        savedData.push(result);
      }
      this.$apollo
        .mutate({
          mutation: require("../api/createPlanSwitch.graphql"),
          variables: {
            input: {
              planSwitch: savedData
            }
          }
        })
        .then(() => {
          
          this.snackColor = "success";
          this.snackText = "plan switch added";
          this.snack = true;   
          this.$emit("updatePlanSwitch");
          this.$refs.form.reset();
          this.btnloading = false;
          this.switchPackageList = [];
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
           
          this.snackColor = "error"; 
          this.snackText = error.graphQLErrors[0].message; 
           this.snack = true;
            // }
          }
          this.btnloading = false;
        });
    }
  }
};
</script>

<style scoped>
.dv-switch .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.dv-switch .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.dv-switch .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.dv-switch .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.dv-switch .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.dv-switch .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0;
}
</style>
